import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { UserCard } from "../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import useGridHook from "../../hooks/useGridHook";
import GridButton from "../../common/GridButton";
import { AddUser, UserGrid } from "./Components/User";
import { getUsers, deleteOrgUser } from "../../actions/user";
import useOrgResources from "../../hooks/useOrgResources";
import "./scss/Users.scss";

const rowId = "orgUserId";

export default (props) => {
  const dispatch = useDispatch();
  const { gridData, handleChange, handleRowClick, setGridData } = useGridHook(
    rowId,
    handleDelete
  );
  const { users } = useSelector((state) => state.user);
  const labels = useOrgResources();
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = () => {
    dispatch(getUsers());
  };

  const setUsers = (users) => {
    if (users) setGridData(users);
  };

  useEffect(fetchUsers, []);

  useEffect(() => setUsers(users), [users]);

  const { show: showAddUser, toggle: toggleAddUser } = useToggle();

  const handleAddEdit = (userId) => {
    setSelectedUser(gridData.find((c) => c[rowId] == userId));
    toggleAddUser();
  };

  function handleDelete(userId) {
    dispatch(deleteOrgUser(userId));
  }

  return (
    <div class="plm_user_list_div">
      <div class="container-fluid prn_cfuid">
        <div class="row prn_row">
          <div class="col-12 prn_col12">
            <UserCard title="Users">
              <UserGrid
                {...labels}
                rowId={rowId}
                users={gridData}
                handleEdit={handleAddEdit}
                handleDelete={handleDelete}
                handleChange={handleChange.bind(this)}
                handleRowClick={handleRowClick.bind(this)}
              />
              <div className="hr-divider"></div>
              <GridButton handleAdd={handleAddEdit} />
              {showAddUser && (
                <AddUser
                  showAddUser
                  toggleModal={toggleAddUser}
                  selectedUser={selectedUser}
                />
              )}
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
