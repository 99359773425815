import APIUtil from './APIUtil';

class InvoicesAPI
{
    createInvoiceView(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Invoices/CreateInvoiceView`;
        return fetch(url, options);
    }

    addTransactionView(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Invoices/AddTransactionView`;
        return fetch(url, options);
    }

    getTransactions(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Invoices/GetTransactions`;
        return fetch(url, options);
    }

    createInvoice(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Invoices/CreateInvoice`;
        return fetch(url, options);
    }

    getInvoices(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Invoices/GetInvoices`;
        return fetch(url, options);
    }

    getInvoiceDetails(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Invoices/GetInvoiceDetails`;
        return fetch(url, options);
    }

    validateInvoiceNo(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Invoices/ValidateInvoiceNo`;
        return fetch(url, options);
    }

    updateInvoice(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Invoices/UpdateInvoice`;
        return fetch(url, options);
    }
}

export default new InvoicesAPI();