import React from "react";
import useActivityTypes from "../../../hooks/useActivityTypes";
import { TimesheetTab } from "../../TimeAndExpenses/Components/TimesheetTabs";
import useTimesheetHook from "../../hooks/useTimesheetHook";
import useUserDetail from "../../../hooks/useUserDetail";
import { getWeekDays } from "../../../libs/dateHelper";
import { LookUp } from "../../../utils/lookUpConstant";
import { ReportContainer } from "../../../components/Container";
import { DisplayLabel } from "../../../components/Label";
import { StaffLabel } from "../Components";

const rowId = "timesheetId";
const weekDays = getWeekDays();

export default ({ timesheetDates, isReport, employeeName }) => {
  const { activityTypes } = useActivityTypes();
  const { gridProps } = useTimesheetHook(rowId, activityTypes, timesheetDates);
  const { theme } = useUserDetail();

  return (
    <div className="timesheetRpt">
      {employeeName && <StaffLabel employeeName={employeeName} />}
      <div style={{ marginBottom: 15 }}></div>
      {activityTypes &&
        activityTypes.map((c) => (
          <React.Fragment key={c.value}>
            <div class="table-header-blue">{c.text}</div>
            <div className="table-responsive">
              <TimesheetTab
                {...c}
                gridProps={{
                  ...gridProps,
                  ...timesheetDates,
                  rowId,
                  isReport: true,
                }}
                weekDays={weekDays}
                hideTotal={c.text != LookUp.NON_CHARGEABLE}
              />
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};
