import React, { useEffect, useState } from "react";
import {
  getMomentDate,
  getWeekStartAndEnd,
  getStartOfWeek,
} from "../libs/dateHelper";
import { AppConstant } from "../utils/constant";
import { SelectBox } from "../components/SelectBox";
import { UserButton, LinkButton } from "../components/Button";
import useWeekHook from "../hooks/useWeekHook";

const { NO_OF_TIMESHEET_WEEKS } = AppConstant;

export default (props) => {
  const {
    handleChange,
    label,
    hideDefaultOption,
    selectClassName,
    showMonthYear,
    hideActions,
    suffix,
  } = props;
  let { weekNo, options, weekStartDate } = useWeekHook(props);
  const [monthYear, setMonthYear] = useState();

  useEffect(() => {
    if (weekNo >= 0) {
      const selected = options.find((c) => c.value == weekNo);
      setMonthYear(selected?.startDate.format("MMMM, YYYY"));
    }
  }, [weekNo]);

  useEffect(() => {
    if (options && options.length > 0) {
      if (!hideDefaultOption) {
        let startDt = getStartOfWeek(new Date());
        const { startDate: optionStartDate } = options[0];
        if (optionStartDate && optionStartDate > startDt)
          startDt = optionStartDate;

        const selected = options.find((c) =>
          c.startDate.isSame(startDt, "day")
        );
        handleChange({
          startDate: startDt.clone(),
          endDate: startDt.add(6, "days"),
          weekNo: selected?.value,
        });
      }
    }
  }, [options]);

  const handleClick = (isNext) => {
    let isUpdated;
    if (isNext && weekNo < NO_OF_TIMESHEET_WEEKS) {
      weekNo += 1;
      isUpdated = true;
    } else if (!isNext && weekNo > 0) {
      weekNo -= 1;
      isUpdated = true;
    }
    if (isUpdated) onWeekChange(null, weekNo);
  };

  const onWeekChange = (name, value) => {
    const startDate = getMomentDate(weekStartDate).add(value - 1, "week");
    handleChange({ ...getWeekStartAndEnd(startDate), weekNo: value });
  };

  return (
    <div className="row">
      <div className="col-12 weekBox">
        {!hideActions && (
          <div className="btWrap">
            <LinkButton
              icon={<i className="fa fa-chevron-left"></i>}
              className="btn-blue cal-prev"
              onClick={() => handleClick(false)}
              showIconFirst={true}
            />
          </div>
        )}
        <div className={hideActions ? "col-12 " : "" + "selectWrap"}>
          {showMonthYear && <span>{monthYear}</span>}
          <SelectBox
            name="weekNo"
            value={weekNo}
            className={selectClassName}
            onChange={onWeekChange}
            options={options}
            label={label}
          />
          {suffix && <>{suffix}</>}
        </div>
        {!hideActions && (
          <div className="btWrap">
            <LinkButton
              icon={<i className="fa fa-chevron-right"></i>}
              className="btn-blue cal-next"
              onClick={() => handleClick(true)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
