import React from "react";
import { UserDatePicker } from "../../../components/DateControl";
import useUserDetail from "../../../hooks/useUserDetail";
import useWeekHook from "../../../hooks/useWeekHook";

export default ({ name, value, label, onChange }) => {
  const { finStartDate, finEndDate } = useUserDetail();
  const { weekNo } = useWeekHook({ weekDate: value });

  return (
    <UserDatePicker
      name={name}
      value={value}
      label={label}
      onChange={onChange}
      minDate={finStartDate}
      maxDate={finEndDate}
      title={weekNo}
    />
  );
};
