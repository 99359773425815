import { useSelector } from "react-redux";
import { useMemo, useCallback, useState, useEffect } from "react";
import { AppConstant } from "../utils/constant";

const { REPORT_FIRST_ITEM_TEXT } = AppConstant;

const useOrgResources = (orgPartnerId, partnerSourceId) => {
  const { orgResources } = useSelector((state) => state.pages);

  const getLabel = useCallback(
    (label) => {
      const resource = orgResources.find((c) => c.name == label);
      return resource ? resource.value : "";
    },
    [orgResources]
  );

  const getResources = useMemo(() => {
    return {
      partnerLabel: getLabel("Partner"),
      sourceLabel: getLabel("Source"),
    };
  }, [orgResources]);

  const { orgPartners, partnerSources } = useSelector((state) => state.common);
  const [orgPartner, setOrgPartner] = useState(null);
  const [partnerSource, setPartnerSource] = useState(null);

  useEffect(() => {
    if (orgPartnerId && orgPartners) {
      setOrgPartner(orgPartners.find((c) => c.value == orgPartnerId)?.text);
    }
  }, [orgPartnerId, orgPartners]);

  useEffect(() => {
    if (orgPartnerId && orgPartners) {
      setPartnerSource(
        partnerSources.find((c) => c.value == partnerSourceId)?.text
      );
    }
  }, [partnerSourceId, partnerSources]);

  return {
    ...getResources,
    orgPartner: orgPartner ? orgPartner : REPORT_FIRST_ITEM_TEXT,
    partnerSource: partnerSource ? partnerSource : REPORT_FIRST_ITEM_TEXT,
  };
};

export default useOrgResources;
