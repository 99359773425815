import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PartnerSource from "../../../common/PartnerSource";
import {
  formatAPIDate,
  getReportStartAndEndDate,
} from "../../../libs/dateHelper";
import { UserButton } from "../../../components/Button";
import { UserDatePicker } from "../../../components/DateControl";
import useUserDetail from "../../../hooks/useUserDetail";
import { getTimesheetEmployees } from "../../../actions/report";
import EmployeeSelectBox from "../../../common/EmployeeSelectBox";
import { setInvoice } from "../../../actions/invoice";
import { AppConstant } from "../../../utils/constant";
import ReportFilterWrapper from "../../../common/ReportFilterWrapper";

const { REPORT_FIRST_ITEM_TEXT } = AppConstant;

const reportDates = getReportStartAndEndDate();

export default React.forwardRef(({ handleView, hideEmployee }, ref) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hideEmployee) dispatch(getTimesheetEmployees());

    dispatch(
      setInvoice({
        invoices: [],
        invoiceDetails: [],
      })
    );
  }, []);

  const [state, setState] = useState(reportDates);
  const { orgPartnerId, partnerSourceId, startDate, endDate, employeeId } =
    state;
  const { finStartDate, finEndDate } = useUserDetail();

  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onClick = () => {
    handleView({
      startDate: formatAPIDate(startDate),
      endDate: formatAPIDate(endDate),
      orgPartnerId,
      partnerSourceId,
      userId: employeeId,
    });
  };

  return (
    <>
      <div className="ctop">
        <div className="ctop-flex">
          <PartnerSource
            hideAsterix={true}
            handleChange={handleChange}
            orgPartnerId={orgPartnerId}
            partnerSourceId={partnerSourceId}
            firstItemText={REPORT_FIRST_ITEM_TEXT}
            isReport
          />
          <ReportFilterWrapper label="From" labelClassName="back-blue">
            <UserDatePicker
              name="startDate"
              value={startDate}
              onChange={handleChange}
              className="filter-control"
              maxDate={finEndDate}
            />
          </ReportFilterWrapper>
          <ReportFilterWrapper label="To" labelClassName="back-blue">
            <UserDatePicker
              name="endDate"
              value={endDate}
              onChange={handleChange}
              className="filter-control"
              maxDate={finEndDate}
            />
          </ReportFilterWrapper>
          {!hideEmployee && (
            <EmployeeSelectBox
              value={employeeId}
              orgPartnerId={orgPartnerId}
              partnerSourceId={partnerSourceId}
              onChange={handleChange}
              placeholder={REPORT_FIRST_ITEM_TEXT}
              isReport
            />
          )}
        </div>
        <UserButton text="View" onClick={onClick} className="btn btn-purple" />
      </div>
    </>
  );
});
