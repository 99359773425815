import { Invoice } from "../actionTypes/invoice";

const initialState = {
  transactions: [],
  clientDetail: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Invoice.CREATE_INVOICE_VIEW:
      return {
        ...initialState,
      };
    case Invoice.SET_TRANSACTIONS:
    case Invoice.INVOICE_SUCCESS:
      return {
        ...state,
        ...action.result,
      };
    default:
      return {
        ...state,
      };
  }
};
