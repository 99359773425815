import {OrgBilling} from '../actionTypes/orgBilling';

const initialState={

}

export default (state=initialState,action)=>
{
    switch(action.type)
    {
        case OrgBilling.ORG_BILLING_SUCCESS:
            return {
                ...state,
                ...action.result
            }
        default:
            return {
                ...state
            }
    }
}