import React from "react";
import useClientHook from "../containers/hooks/useClientHook";
import { CustomInput } from "../components/TextInput";
import ReportFilterWrapper from "./ReportFilterWrapper";

export default ({
  state,
  onChange,
  errors,
  asterix,
  codeLabel,
  nameLabel,
  isReport,
}) => {
  const { clientProps } = useClientHook();
  const { clientCode, clientName } = state;

  if (isReport) {
    return (
      <>
        <ReportFilterWrapper label={codeLabel ? codeLabel : "Code"}>
          <CustomInput
            {...clientProps}
            name="clientCode"
            value={clientCode}
            asterix={asterix}
            errors={errors}
            onChange={onChange}
          />
        </ReportFilterWrapper>
        <ReportFilterWrapper label={nameLabel ? nameLabel : "Name"}>
          <CustomInput
            name="clientName"
            {...clientProps}
            value={clientName}
            asterix={asterix}
            errors={errors}
            onChange={onChange}
          />
        </ReportFilterWrapper>
      </>
    );
  }

  return (
    <div className="row">
      <div className="col-6">
        <CustomInput
          {...clientProps}
          name="clientCode"
          label={codeLabel ? codeLabel : "Code"}
          value={clientCode}
          asterix={asterix}
          errors={errors}
          onChange={onChange}
        />
      </div>
      <div className="col-6">
        <CustomInput
          label={nameLabel ? nameLabel : "Name"}
          name="clientName"
          {...clientProps}
          value={clientName}
          asterix={asterix}
          errors={errors}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
