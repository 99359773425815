import { takeLatest, call, put } from 'redux-saga/effects';
import ClientAPI from "../api/ClientAPI";
import { Client } from "../actionTypes/client";
import callAPI from './common';
import { Common } from '../actionTypes/common';

function* addEditClient(action)
{
    yield call(callAPI,ClientAPI.addEditClient,action,setResult);
}

function* addClientView(action)
{
    yield call(callAPI,ClientAPI.addClientView,action,addClientViewResult);
}

function* addClientViewResult(result)
{
    yield put({type:Common.SET_COMMON_DATA,result});
}

function* getClients(action)
{
    yield call(callAPI,ClientAPI.getClients,action,setResult);
}

function* deleteClient(action)
{
    yield call(callAPI,ClientAPI.deleteClient,action,setResult);
}

function* getClientSelectList(action)
{
    yield call(callAPI,ClientAPI.getClientSelectList,action,setCommonData);
}

function* getClientsWIP(action)
{
    yield call(callAPI,ClientAPI.getClientsWIP,action,setResult);
}

function* setResult(result)
{
    yield put({type:Client.CLIENT_SUCCESS,result});
}

function* setCommonData({common})
{
    yield put({type:Common.SET_COMMON_DATA,result:common});
}

export default function* rootSaga() {
    yield takeLatest(Client.ADD_CLIENT, addEditClient);
    yield takeLatest(Client.ADD_CLIENT_VIEW, addClientView);
    yield takeLatest(Client.GET_CLIENTS, getClients);
    yield takeLatest(Client.DELETE_CLIENT, deleteClient);
    yield takeLatest(Client.GET_CLIENT_SELECT_LIST, getClientSelectList);
    yield takeLatest(Client.GET_CLIENTS_WIP, getClientsWIP);
}