import React, { useState, useEffect, useRef } from 'react';
import {CheckBox} from '../CheckBox';
import {TextInput} from '../TextInput';
import useToggle from '../../hooks/useToggle';
import { UserLabel } from '../Label';
import Styles from './SelectBox.module.css';
import {uniqBy} from 'lodash';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const Option=(props)=>{

    return (
            <li 
                className="itemName-">
                <CheckBox 
                   {...props} 
                />
            </li>
    )
}


function SelectBox(props){

    const node=useRef();
    const {show,setShow}=useOnClickOutside(node);
    let [options,setOptions]=useState([]);
    const {options:initOptions,value}=props;  
    const [searchBy,setSearchBy]=useState('');

    useEffect(()=>{
        let options=initOptions;
        if(value && value.length>0)
            options=options.map(c=>({
                ...c,
                checked:value.includes(c.value)
            }));

        setOptions(uniqBy(options,c=>c.value));
    },[initOptions]);

    SelectBox.handleClickOutside=()=>setShow(false);


    const handleAll=()=>
    {
        const isAllChecked=options.every(c=>c.checked);
        options=options.map(c=>({...c,checked:!isAllChecked}));
        setSelected(options);
    }

    const selectedItems=()=>
    {
        return options.filter(c=>c.checked).map(c=>c.text).join(',');
    }

    const handleChange=(index,checked)=>
    {
        options[index].checked=checked;
        setSelected(options);
    }   

    const handleSearch=(name,text)=>
    {
        const filtered=initOptions.filter(item=>item.text.includes(text));
        setOptions(uniqBy(filtered,c=>c.value));
        setSearchBy(text);
    }

    const setSelected=(options)=>{
        const {handleChange,name}=props;
        handleChange(name,options.filter(c=>c.checked).map(c=>c.value));
    }

    const {label,showSelected,showSearch}=props;

    return (
        <div 
            ref={node}
            onClick={()=>setShow(true)} 
        >
            {label &&
                <UserLabel
                    label={label}
                />
            }
            <div 
                tabIndex="0" 
                style={{border: '1px solid #e6e6e6', background:'#e6e6e6', borderRadius:10}}
            >
                {showSelected && selectedItems()}
                {showSearch && <i className="fa fa-search" style={{marginLeft:5,color:'#a7abc3'}}></i>}
            </div>
            {show &&
            <ul className={Styles.itemList}>
                <li className="pos-top itemName-" key="search">
                    <TextInput  
                        name="searchBy"
                        value={searchBy}
                        placeholder="Search Here"
                        onChange={handleSearch.bind(this)}
                    />
                </li>
                <Option
                    label='All'
                    value={options.every(c=>c.checked)}
                    onChange={handleAll.bind(this)}
                />
                {options.map((c,index)=>
                    <Option
                        key={c.value}
                        value={c.checked}
                        label={c.text}
                        onChange={(name,value)=>handleChange(index,value)}
                    />
                )}
            </ul>
            }
        </div>
    )

};

export default SelectBox;