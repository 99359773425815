import { takeLatest, call, put } from 'redux-saga/effects';
import ReportAPI from "../api/ReportAPI";
import { Report } from "../actionTypes/report";
import callAPI from './common';
import { Common } from '../actionTypes/common';
import { downloadFile } from '../libs/fileHelper';

function* getReportView(action)
{
    yield call(callAPI,ReportAPI.getReportView,action,setCommonData);
}

function* getTimesheetEmployees(action)
{
    yield call(callAPI,ReportAPI.getTimesheetEmployees,action,setCommonData);
}

function* downloadReport(action)
{
    yield call(callAPI,ReportAPI.downloadReport,action,fileResult);
}

function* sendReportEmail(action)
{
    yield call(callAPI,ReportAPI.sendReportEmail,action);
}

function* fileResult(result)
{
    downloadFile(result);
}

function* setCommonData({common})
{
    yield put({type:Common.SET_COMMON_DATA,result:common});
}

export default function* rootSaga() {
    yield takeLatest(Report.GET_REPORT_VIEW, getReportView);
    yield takeLatest(Report.GET_TIMESHEET_EMPLOYEES, getTimesheetEmployees);
    yield takeLatest(Report.DOWNLOAD_REPORT, downloadReport);
    yield takeLatest(Report.SEND_REPORT_EMAIL, sendReportEmail);
}