import React, {useEffect } from 'react';
import { useDispatch} from 'react-redux';
import useForm from '../../../../hooks/useForm';
import { UserModal } from '../../../../components/Modal';
import { TextInput } from '../../../../components/TextInput';
import { addEditOrgRole } from '../../../../actions/user';

const stateSchema={
    
};

const validationSchema={
    roleName:{
        required:true
    },
    description:{
        required:true
    }
}

const AddRole=({roleDetail,handleChange,errors})=>{

        const {
            roleName,
            description
        }=roleDetail;

        return (
                <div className='row'>
                    <div className='col-6'>
                        <TextInput
                            name="roleName"
                            label="Role Name"
                            value={roleName}
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    <div className='col-6'>
                        <TextInput
                            name="description"
                            value={description}
                            label="Description"
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                </div>
        )
}


export default ({showAddRole,toggleModal,selectedRole})=>{

    const {
        state:roleDetail,
        errors,
        handleChange,
        handleSubmit,
        handleStateChange
    }=useForm(stateSchema,validationSchema,handleSave);

    const dispatch=useDispatch();

    useEffect(()=>{
        if(selectedRole)
            handleStateChange(selectedRole);
    },[]);

    function handleSave(roleDetail){
        dispatch(addEditOrgRole({
            ...roleDetail
        }));
        toggleModal();
    }

    return (
        <div className='col-12'>
            <UserModal
                show={showAddRole}
                handleClose={toggleModal}
                title="Add Role"
                handleSave={handleSubmit}
            >
                <AddRole
                    roleDetail={roleDetail}
                    handleChange={handleChange}
                    errors={errors}
                />
            </UserModal>
        </div>
    )
};