import React from "react";
import { UserButton } from "../components/Button";

export default ({
  handleCancel,
  handleNext,
  middleBtnText,
  handlePrev,
  screenToShow,
  totalScreens,
}) => {
  return (
    <div className="tbBtn plm_cmn_cardbtn_alone_div invoiceBtn">
      {screenToShow < totalScreens && (
        <UserButton
          text={middleBtnText ? middleBtnText : "Next"}
          className="btn btn-primary"
          onClick={handleNext}
        />
      )}
      {screenToShow > 1 ? (
        <UserButton
          text="Previous"
          className="btn btn-primary"
          onClick={handlePrev}
        />
      ) : (
        <UserButton
          text="Cancel"
          className="btn btn-primary"
          onClick={handleCancel}
        />
      )}
    </div>
  );
};
