import React from 'react';
import { UserTable } from '../../../components/Table';

export default ({transactions})=>{

    let headers=[
        {key:'employee', text:'Employee'},
        {key:'activityDesc', text:'Description'},
        {key:'timesheetType', text:'Type'},
        {key:'invoiceUnits', text:'Units'},
        {key:'rate', text:'Rate'},
        {key:'amount', text:'Costing'}
    ];
    

    return (
        <UserTable
            headers={headers}
            data={transactions}
        />
    )
}