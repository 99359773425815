import React, { useEffect, useState } from "react";
import { LookUp } from "../../../../utils/lookUpConstant";
import { Chargeable } from ".";
import Expenses from "./Expenses";
import { TotalRow } from "../Chargeable";
import TimesheetHelper from "../../../../libs/TimesheetHelper";

export default ({ text, value, gridProps, weekDays, hideTotal }) => {
  const { gridData: initData, isReport } = gridProps;
  const [gridData, setData] = useState([]);
  const [totalRow, setTotal] = useState([]);

  useEffect(() => {
    const gridData = initData.filter((c) => c.timesheetTypeId == value);
    setData(gridData);
    if (text !== LookUp.EXPENSES)
      setTotal(
        TimesheetHelper.getTotalRow(initData, weekDays, {
          minWidth: 50,
          width: 50,
          maxWidth: 50,
        })
      );
  }, [value, initData]);

  switch (text) {
    case LookUp.EXPENSES:
      return (
        <Expenses
          timesheetTypeId={value}
          {...gridProps}
          gridData={gridData}
          initData={initData}
        />
      );
    case LookUp.CHARGEABLE:
    case LookUp.NON_CHARGEABLE:
    default:
      return (
        <div className="total_timeslot_content">
          <Chargeable
            timesheetTypeId={value}
            type={text}
            {...gridProps}
            hideClient={text == LookUp.NON_CHARGEABLE}
            gridData={gridData}
            initData={initData}
          >
            {!hideTotal && isReport && (
              <TotalRow
                totalRow={totalRow}
                isReport={isReport}
                text="Time Sheet Totals (Chargeable + Non-Chargeable)"
                wrapperClass="totalRowBlue"
              />
            )}
          </Chargeable>
          {!hideTotal && !isReport && (
            <div className="grand_total_div col-12 col-md-12 col-xs-12">
              <TotalRow totalRow={totalRow} text="Grand Total" />
            </div>
          )}
        </div>
      );
  }
};
