import {ActivityCode} from '../actionTypes/activityCode';

const initialState={

}

export default (state=initialState,action)=>
{
    switch(action.type)
    {
        case ActivityCode.ACTIVITY_CODE_SUCCESS:
            return {
                ...state,
                ...action.result
            }
        default:
            return {
                ...state
            }
    }
}