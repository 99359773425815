import { useSelector } from 'react-redux';
import { Roles } from '../utils/rolesConstant';
import {  useMemo, useCallback } from 'react';

const useRole=()=>{

    const {roles,isLoggedIn}=useSelector(state=>state.account);

    const userInRole=useCallback((role)=>
        roles && roles.includes(role)
    ,[roles]);

    const getUserRole=useMemo(()=>
        ({
            isAdmin:userInRole(Roles.ADMIN),
            isCustomer:userInRole(Roles.CUSTOMER),
            isUser:userInRole(Roles.USER)
        })
        ,[roles]
    )

   

    return {
        isLoggedIn,
        ...getUserRole
    };
}

export default useRole;