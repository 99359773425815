import { fork } from "redux-saga/effects";
import customer from './customer';
import account from './account';
import orgPartner from './orgPartner';
import client from './client';
import user from './user';
import activityCode from './activityCode';
import orgBilling from './orgBilling';
import taxRate from './taxRate';
import timesheet from './timesheet';
import invoice from './invoice';
import report from './report';

export default function* root() {
    yield fork(customer);
    yield fork(account);
    yield fork(orgPartner);
    yield fork(client);
    yield fork(user);
    yield fork(activityCode);
    yield fork(orgBilling);
    yield fork(taxRate);
    yield fork(timesheet);
    yield fork(invoice);
    yield fork(report);
}