import { sumBy } from "lodash";
import { useEffect, useState } from "react";
import { getWeekDays } from "../../libs/dateHelper";
import { GridAction } from "../../utils/GridAction";
import { Timesheet as Constant } from "../../utils/timesheetConstant";

const { DAYS_VALUE } = Constant;
const headers = getWeekDays();

const useDayGrid = ({ gridData, isReport }) => {
  const [gridHeaders, setGridHeader] = useState([]);
  const [daysData, setDaysData] = useState([]);

  useEffect(() => {
    let gridHeader = headers.map((c) => ({
      ...c,
      type: isReport ? null : GridAction.TEXT_INPUT,
      className: "gridInput",
      actionProps: {
        maxLength: 2,
      },
    }));
    gridHeader.push({
      key: "total",
      text: "Total",
      headerProps: isReport ? null : { style: { minWidth: 72, width: 72 } },
    });
    if (isReport) {
      gridHeader = gridHeader.map((c) => ({
        ...c,
        actionProps: { className: "gap-cell" },
      }));
    }
    setGridHeader(gridHeader);
  }, []);

  useEffect(() => {
    if (gridData && gridData.every((c) => c.workDetails)) {
      const daysData = gridData.map((c) => ({
        ...c,
        ...c.workDetails.reduce(
          (prev, curr) => ({ ...prev, [curr.key]: curr[DAYS_VALUE] }),
          {}
        ),
        total: sumBy(c.workDetails, (s) => (s[DAYS_VALUE] ? s[DAYS_VALUE] : 0)),
      }));

      setDaysData(daysData);
    }
  }, [gridData]);

  return {
    gridHeaders,
    daysData,
  };
};

export default useDayGrid;
