import React from 'react';
import { UserButton } from '../../../../../../components/Button';
import { RadioButton } from '../../../../../../components/RadioButton';
import { TextInput } from '../../../../../../components/TextInput';

const Rates= ({rates,handleChange,errors})=>{

    const handleAdd=()=>{
        let updatedRates=rates.slice();
        updatedRates.push('');
        handleChange(updatedRates);
    }
       
    const onChange=(index,name,value)=>{
        let updatedRates=rates.slice();
        updatedRates[index]={...updatedRates[index],[name]:value};
        handleChange(updatedRates);
    }
    const handleCheck=(index,name,value)=>{
        handleChange(rates.map((c,i)=>({
                ...c,
                [name]:i==index?value:false
            }))
        );
    }

    const handleDelete=(index)=>{
        rates.splice(index,1);
        handleChange(rates);
    }


    return (
        <div className='row'>
            <div className='col-12'>
                <UserButton
                    text="Add"
                    icon={<i className='fa fa-plus'></i>}
                    className="btn btn-primary btn-P"
                    onClick={handleAdd}
                    showIconFirst={true}
                />
            </div>
            <div className='col-6'>
                Rates per hours
            </div>
            <div className='col-3'>
                Is Default
            </div>
            <div className='col-3'>
            </div>
            {rates && rates.map((c,index)=>(
                <React.Fragment key={index}>
                    <div className='col-6'>
                        <TextInput
                            name="ratePerHour"
                            type="number"
                            value={c.ratePerHour}
                            onChange={onChange.bind(this,index)}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    <div className='col-3'>
                        <RadioButton
                            name="isDefault"
                            value={c.isDefault}
                            onChange={handleCheck.bind(this,index)}
                        />
                    </div>
                    <div className='col-3'>
                        <UserButton
                            icon={<i className='fa fa-minus'></i>}
                            className="btn btn-primary btn-M"
                            onClick={()=>handleDelete(index)}
                            showIconFirst={true}
                        />
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}

export default Rates;