import React from "react";

export default ({ label, value, width }) => (
  <div className="emp_topvaldiv">
    <div className="label-wraper">
      {label && (
        <>
          <label className="" style={{ width: width ? width : "auto" }}>
            <strong>{label}</strong>
          </label>
          <span className="mx-1">
            <strong>:</strong>
          </span>
        </>
      )}
      <span title={value ? value : ""} className="l-value-wrap">
        <span>{value}</span>
      </span>
    </div>
  </div>
);
