import { useEffect, useState } from "react";
import {
  getEndOfWeek,
  getMomentDate,
  getStartOfWeek,
} from "../libs/dateHelper";
import { AppConstant } from "../utils/constant";
import useUserDetail from "./useUserDetail";

const { NO_OF_TIMESHEET_WEEKS, DATE_DISPLAY_FORMAT } = AppConstant;

export default ({ startDate, endDate, year, weekDate }) => {
  const { finStartDate, orgFinStartDate, finEndDate } = useUserDetail();
  const [state, setState] = useState({
    options: [],
  });
  const { options, weekStartDate } = state;

  let [weekNo, setWeekNo] = useState(null);

  useEffect(() => {
    if (finStartDate) {
      let total_weeks=finEndDate.diff(orgFinStartDate,"weeks");
      const startDate = getStartOfWeek(orgFinStartDate).clone();
      const weekStartDate = startDate.clone();
      let options = [...new Array(total_weeks).keys()].map((c) => { //NO_OF_TIMESHEET_WEEKS
        const option = {
          startDate: startDate.clone(),
          endDate: getEndOfWeek(startDate).clone(),
          value: c + 1,
          text: `Week ${c + 1} [${startDate.format(DATE_DISPLAY_FORMAT)}]`,
        };
        
        startDate.add("week", 1);

        return option;
      });
      if (year)
        options = options.filter((c) => c.startDate.format("YYYY") == year);

      setState({
        options,
        weekStartDate,
      });
    }
  }, [year, finStartDate]);

  useEffect(() => {
    if (startDate && endDate && options.length > 0) {
      const weekNo = getWeekNo(startDate, endDate);
      if (weekNo >= 0) setWeekNo(weekNo);
    }
  }, [startDate, endDate, options]);

  useEffect(() => {
    if (weekDate) {
      const momentWeekDate = getMomentDate(weekDate);
      const option = options.find((c) =>
        momentWeekDate.isBetween(c.startDate, c.endDate, "day", "[]")
      );
      if (option) setWeekNo(option.value);
    }
  }, [weekDate]);

  const getWeekNo = (startDate, endDate) => {
    if (options.length > 0) {
      const minDate = getMomentDate(startDate);
      const maxDate = getMomentDate(endDate);
      const option = options.find((c) =>
        c.startDate.isBetween(minDate, maxDate, "day", "[]")
      );
      if (option) return option.value;
    }
  };

  return {
    options,
    weekNo,
    weekStartDate,
    getWeekNo,
  };
};
