import React, { useState, useEffect, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DisplayLabel } from "../../../components/Label";
import useForm from "../../../hooks/useForm";
import { UserDatePicker } from "../../../components/DateControl";
import { TextInput } from "../../../components/TextInput";
import { SelectBox } from "../../../components/SelectBox";
import {
  formatCost,
  applyOperator,
  formatNegativeCost,
} from "../../../libs/functions";
import { validateInvoiceNo } from "../../../actions/invoice";
import "./AddEditInvoice.scss";
import { AppConstant } from "../../../utils/constant";

const stateSchema = {
  invoiceDt: new Date(),
};

const validationSchema = {
  invoiceDt: {
    required: true,
  },
};

export default React.forwardRef(({ handleSave }, ref) => {
  const dispatch = useDispatch();

  const {
    clientDetail,
    invoiceNo: invoiceNoModel,
    taxRates,
    invoiceDetail,
  } = useSelector((state) => state.invoice);
  const { state, errors, handleChange, handleStateChange, handleSubmit } =
    useForm(stateSchema, validationSchema, saveDetail);
  const [taxOptions, setTaxOptions] = useState([]);
  const [prefixList, setPrefixList] = useState([]);
  const [suffixList, setSuffixList] = useState([]);
  const { invNoPrefixList, invNoSuffixList } = invoiceNoModel
    ? invoiceNoModel
    : {};

  useImperativeHandle(ref, () => ({
    addEditInvoice() {
      handleSubmit();
    },
  }));

  useEffect(() => {
    handleStateChange({ ...invoiceDetail, ...invoiceNoModel, ...clientDetail });
  }, [invoiceDetail]);

  useEffect(() => {
    if (taxRates)
      setTaxOptions(
        taxRates.map((c) => ({
          ...c,
          text: c.taxName,
          value: c.orgTaxId,
          taxAmount: c.value,
          taxValue: `${c.operation} ${c.value + (c.isPercent ? "%" : "")} ${
            c.taxTypeName
          }`,
        }))
      );
  }, [taxRates]);

  useEffect(() => {
    if (invNoPrefixList) {
      setPrefixList(
        invNoPrefixList.map((c) => ({
          text: c,
          value: c,
        }))
      );
    }
  }, [invNoPrefixList]);

  useEffect(() => {
    if (invNoSuffixList) {
      setSuffixList(
        invNoSuffixList.map((c) => ({
          text: c,
          value: c,
        }))
      );
    }
  }, [invNoSuffixList]);

  const {
    invoiceDt,
    actualAmt,
    surplusDeficitAmt,
    orgTaxId,
    taxAmount,
    invoiceId,
    taxValue,
    totalAmount,
    calculatedAmt,
    invoiceNumber,
    invNoPrefix,
    invNoSuffix,
    clientName,
  } = state;

  useEffect(() => {
    if (taxOptions && actualAmt) {
      const selected = taxOptions.find((c) => c.orgTaxId == orgTaxId);
      let taxValue,
        isPercent,
        taxAmount = 0,
        operation;
      if (selected) {
        ({ taxValue, isPercent, taxAmount, operation } = selected);
        if (isPercent) taxAmount = (parseInt(actualAmt) * taxAmount) / 100;
      }
      const totalAmount = taxAmount
        ? applyOperator(operation, actualAmt, taxAmount)
        : actualAmt;

      handleStateChange({
        taxValue,
        taxAmount: formatCost(taxAmount),
        surplusDeficitAmt: formatCost(actualAmt - calculatedAmt),
        totalAmount: formatCost(totalAmount),
      });
    }
  }, [orgTaxId, actualAmt]);

  function saveDetail(invoiceDetail) {
    dispatch(
      validateInvoiceNo(
        {
          invoiceId,
          invoiceNumber,
        },
        ({ isValid }) => {
          if (isValid) handleSave(invoiceDetail);
        }
      )
    );
  }

  return (
    <div class="plm_addedit_inv_div">
      <div className="row plm_feditrow invoice-detail-top">
        <div className="col-2 pl-0">
          <UserDatePicker
            name="invoiceDt"
            label="Invoice Date"
            onChange={handleChange}
            value={invoiceDt}
            asterix={true}
          />
        </div>
        <div className="col-4">
          <div className="row plm_feditrow invoice-lbl">
            <div className="col-4 ">
              <SelectBox
                name="invNoPrefix"
                value={invNoPrefix}
                onChange={handleChange}
                label="Invoice No"
                options={prefixList}
                placeholder="Select Prefix"
              />
            </div>
            <div className="col-4">
              <TextInput
                name="invoiceNumber"
                value={invoiceNumber}
                onChange={handleChange}
                type="number"
                label=" "
              />
            </div>
            <div className="col-4">
              <SelectBox
                name="invNoSuffix"
                value={invNoSuffix}
                onChange={handleChange}
                options={suffixList}
                label=" "
                placeholder="Select Suffix"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="row plm_feditrow invoice-preview"
        style={{ marginLeft: "10%" }}
      >
        <div className="col-12 transparentLabel text-blue">
          <DisplayLabel
            label="Fees as per Costing"
            value={formatNegativeCost(calculatedAmt)}
            width="20%"
          />
        </div>
        <div className="col-12 plm_fedit_col transparentLabel value-bg">
          <TextInput
            name="actualAmt"
            label="Actual Fee to Client"
            value={actualAmt}
            onChange={handleChange}
            type="float"
            prefix={AppConstant.CURRENCY}
          />
        </div>
        <div className="col-12 transparentLabel text-red">
          <DisplayLabel
            label="Surplus/Deficit"
            value={formatNegativeCost(surplusDeficitAmt)}
            width="200px"
          />
        </div>
        <div className="col-12 mform-inline transparentLabel value-bg">
          <SelectBox
            name="orgTaxId"
            label="Tax Component"
            value={orgTaxId}
            onChange={handleChange}
            options={taxOptions}
            addFirstItem={true}
            firstItemText="No Tax"
          />
        </div>
        <div className="col-12 transparentLabel">
          <DisplayLabel label="Tax" value={taxValue} width="200px" />
        </div>
        <div className="col-12 transparentLabel value-bg">
          <DisplayLabel
            label="Tax Amount"
            value={formatNegativeCost(taxAmount)}
            width="200px"
          />
        </div>
        <div className="col-12 transparentLabel text-blue">
          <DisplayLabel
            label="Total Amount"
            value={formatNegativeCost(totalAmount)}
            width="200px"
          />
        </div>
      </div>
    </div>
  );
});
