import { useSelector } from "react-redux";
import { AppConstant } from "../utils/constant";
import { getMomentDate } from "../libs/dateHelper";
import { useEffect, useMemo } from "react";
import useTheme from "./useTheme";
import useForm from "./useForm";

const todayDate = getMomentDate(new Date());

const { NO_OF_TIMESHEET_WEEKS } = AppConstant;

const useUserDetail = () => {
  const { userDetail } = useSelector((state) => state.account);
  const { state, handleStateChange } = useForm({
    finStartDate: null,
    finEndDate: null,
  });

  let finYearStartDate, emailId, theme;
  const { theme: defaultTheme } = useTheme();
  if (userDetail) ({ finYearStartDate, emailId, theme } = userDetail);
  if (!theme) theme = defaultTheme;

  useEffect(() => {
    if (finYearStartDate) {
      const startDate = getMomentDate(finYearStartDate);
      let { finStartDate, finEndDate } = getFinancialDates(startDate);
      let orgFinStartDate = finStartDate;
      
      while (finEndDate < todayDate) {
        ({ finStartDate, finEndDate } = getFinancialDates(
          finEndDate.clone().add(1, "day")
        ));
      }

      handleStateChange({ finStartDate, finEndDate, orgFinStartDate, finEndDate });
    }
  }, [finYearStartDate]);

  const getFinancialDates = (startDate) => {
    return {
      finStartDate: startDate.clone(),
      finEndDate: startDate.clone().add(NO_OF_TIMESHEET_WEEKS, "weeks"),
    };
  };

  return useMemo(
    () => ({
      finYearStartDate,
      ...state,
      emailId,
      theme,
    }),
    [defaultTheme]
  );
};

export default useUserDetail;
