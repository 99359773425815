import {createStore, applyMiddleware} from "redux";
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import createRootReducer from '../reducers'
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createBrowserHistory();

export default ()=> {
    const persistConfig = {
        key: 'root',
        storage,
        blacklist: ['router']
    };
    const rootReducer=createRootReducer(history);
    const persistedReducer = persistReducer(persistConfig,rootReducer);
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        persistedReducer,
        {},
        composeWithDevTools
            (
                applyMiddleware(
                    sagaMiddleware,
                    routerMiddleware(history)
                ),
            ),
    )
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);

  return {store,persistor};
}
