import React, { useEffect } from "react";
import { UserTable, CommonTable } from "../../../components/Table";
import { useSelector } from "react-redux";
import { groupBy, map, sumBy, uniqBy } from "lodash";
import useGridHook from "../../../hooks/useGridHook";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import useUserDetail from "../../../hooks/useUserDetail";
import { displayCost } from "../../../libs/functions";
import usePrintHook from "../../hooks/usePrintHook";
import useOrgResources from "../../../hooks/useOrgResources";
import { ReportContainer } from "../../../components/Container";
import { displayDate } from "../../../libs/dateHelper";
import WordReport from "./WordReport";
import { PartnerLabel, SourceLabel, StaffLabel } from "../Components";
import TableCell from "../../../components/Table/Cell";
import TableHeader from "../../../components/Table/Header";
import { normalizeData } from "../../../libs/functions";

const rowId = "";

const TotalRow = ({ text, units, cost, className }) => {
  return (
    <UserTable
      tblClass="table table-stripe mb0 nonChargeableReport nonChargeableTotalRow"
      tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
      wrapperClass={`totalRow ${className}`}
      headers={[
        { text, colSpan:3 },
        { text: units },
        { text: "0" },
        { text: displayCost(cost) },
      ].map((c) => ({ ...c, headerActionProps: { className: "gap-cell" } }))}
    />
  );
};

const headers = [
  { key: "workDate", text: "Date" },
  { key: "activity", text: "Job Type" },
  { key: "activityDesc", text: "Job Desc" },
  { key: "noOfUnits", text: "Units" },
  { key: "rate", text: "Rate" },
  { key: "cost", text: "Value" },
].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

export default (props) => {
  const {
    isReport,
    reportType,
    startDate,
    endDate,
    orgPartner,
    partnerSource,
  } = props;
  const { gridData, setGridData } = useGridHook(rowId);
  const { timesheets } = useSelector((state) => state.timesheet);
  const { WORD, EXCEL } = usePrintHook({});
  const { partnerLabel, sourceLabel } = useOrgResources();

  useEffect(() => setTimesheets(timesheets), [timesheets]);

  const setTimesheets = (timesheets) => {
    if (timesheets) {
      let allTimsheets = [];
      timesheets.forEach((c) => {
        allTimsheets = allTimsheets.concat(
          c.partners
            ? c.partners.map((p) => ({
                ...c,
                ...p,
                cost: TimesheetHelper.calAmount({
                  ...c,
                  ...p,
                  invoiceUnits: c.noOfUnits,
                }),
              }))
            : []
        );
      });
      const group = groupBy(allTimsheets, (c) => c.orgPartnerId);
      const gridData = map(group, (c) => {
        const sourceGroup = groupBy(c, (s) => s.partnerSourceId);
        return {
          partnerName: c[0].partnerName,
          totalUnits: sumBy(c, (t) => t.noOfUnits),
          totalCost: sumBy(c, (t) => parseFloat(t.cost)),
          sourceDetails: map(sourceGroup, (s) => {
            const empGroup = groupBy(s, (e) => e.employeeId);
            return {
              sourceName: s[0].sourceName,
              empDetails: map(empGroup, (e) => ({
                employeeName: e[0].fullName,
                gridData: e.map((g) => ({
                  ...g,
                  workDate: displayDate(g.workDate),
                  cost: displayCost(g.cost),
                })),
                totalUnits: sumBy(e, (t) => t.noOfUnits),
                totalCost: sumBy(e, (t) => parseFloat(t.cost)),
              })),
              totalUnits: sumBy(s, (t) => t.noOfUnits),
              totalCost: sumBy(s, (t) => parseFloat(t.cost)),
            };
          }),
        };
      });
      setGridData(gridData);
    }
  };

  const getDataTable = (data, totalRows=[]) => {

          var rows=[];

            {normalizeData(data, rowId).map((row, i) => 
                rows.push(<tr
                    className="tbody"
                    key={i}>
                    {headers.map((prop) => 
                      <TableCell
                        {...prop}
                        key={prop.key + "" + i}
                        data={row.find((r) => r.key === prop.key)}
                        selected={row.find((r) => r.key === "selected" && r.text)}
                      />
                    )}
                  </tr>)
              )};

            {
              totalRows.map((totalRow)=> {
                  rows.push(<tr className="tbody totalRow-wrap">
                    {
                      totalRow.map((t)=> <td className={`td ${t.className}`} colspan={t.colSpan}><div className="gap-cell">{t.text}</div></td>)
                    }
                  </tr>)
              })
            }

      return rows;
  }

  if (reportType == WORD || reportType == EXCEL) {
    return (
      <WordReport
        orgPartner={orgPartner}
        partnerLabel={partnerLabel}
        sourceLabel={sourceLabel}
        partnerSource={partnerSource}
        startDate={startDate}
        endDate={endDate}
        gridData={gridData}
        headers={headers.filter((c) => c.key != "blank")}
      />
    );
  }

  return (
    <ReportContainer hasData={gridData.length > 0}>
      <div className="nonChargeableRpt">
        {gridData.map((c, i) => {
          var datatable=[];                                                                                                                                                                                                                                           
          return (
            <React.Fragment key={`P${i}`}>
              <PartnerLabel partnerName={c.partnerName} />
              {c.sourceDetails.map((s, sIndex) => (
                <React.Fragment key={`S${sIndex}`}>
                  <SourceLabel sourceName={s.sourceName} />                
                  {s.empDetails.map((e, index) => (
                    <React.Fragment key={`E${index}`}>
                      <StaffLabel employeeName={e.employeeName} />
  
                      {
                        datatable= [datatable, getDataTable(e.gridData, [
                          [
                            { text:`Totals of: ${e.employeeName}`, colSpan:3, className: "total back-light-blue" },
                            { text: e.totalUnits, className: "total back-light-blue" },
                            { text: "0", className: "total back-light-blue" },
                            { text: displayCost(e.totalCost), className: "total back-light-blue" },
                          ]])]
                      }
                     
                    </React.Fragment>
                  ))}

                {
                  datatable=[datatable, (<tr className="tbody totalRow-wrap">
                    <td className={`td total back-purple`} colspan="3"><div className="gap-cell">{`Totals of: ${c.partnerName}`}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{c.totalUnits}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">0</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{displayCost(c.totalCost)}</div></td>
                  </tr>)]
                }
                                 
                </React.Fragment>
              ))}

                <CommonTable tblClass="nonChargeableReport" headers={headers} rows={datatable} />

            </React.Fragment>
          )
        })}
      </div>
    </ReportContainer>
  );
};
