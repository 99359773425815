import React from "react";
import { UserLabel, Error } from "../Label";
import { CustomPopup } from "../Modal";

export default (props) => {
  const { errors, label, name, actionProps, value, onChange } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-section">
      <div className="form-section__field">
        {label ? (
          <div className="cst_tbox_popmdiv">
            <UserLabel {...props} />
            <div className="form-control">
              <CustomPopup {...actionProps} value={value} onChange={onChange} />
            </div>
          </div>
        ) : (
          <div className="row m-0">
            <div className="col-sm-12 col-xs-12 col-lg-12 px-0 form-control">
              <CustomPopup {...actionProps} value={value} onChange={onChange} />
            </div>
          </div>
        )}
        {error && <Error error={error} />}
      </div>
    </div>
  );
};
