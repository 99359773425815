import React, { useRef, useState, useEffect } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { UserTable } from "../Table";
import { Cell } from "../Table/Cell";
import { TextInput } from "../TextInput";
import { contains } from "../../libs/functions";

function CustomPopup({
  headers,
  data,
  rowId,
  value,
  onChange,
  actions,
  addNewName,
  tblClass,
  showSearch,
}) {
  const node = useRef();
  const { show, setShow } = useOnClickOutside(node);
  const [searchBy, setSearchBy] = useState(value);
  const [tableData, setData] = useState([]);
  const [defaultSortBy, setSortBy] = useState({});

  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data]);

  useEffect(() => {
    if (headers && headers.length > 0) {
      setSortBy({ [headers[0].key]: "asc" });
    }
  }, [headers]);

  useEffect(() => {
    if (searchBy && data) {
      var keys = headers.map((c) => c.key);
      const filtered = data.filter((c) =>
        keys.some((prop) => contains(c[prop], searchBy))
      );
      setData(filtered);
      if (addNewName && filtered.length == 0) {
        onChange({ [addNewName]: searchBy });
      }
    } else if (data) setData(data);
  }, [searchBy]);

  CustomPopup.handleClickOutside = () => setShow(false);

  const handleClick = (event) => {
    if (event) event.stopPropagation();

    setShow(true);
  };

  const handleChange = (name, value) => {
    setSearchBy(value);
  };

  const handleRowClick = (id) => {
    setShow(false);
    if (showSearch) onChange(rowId, [id]);
    else {
      const selectedRow = data.find((c) => c[rowId] == id);
      onChange(selectedRow);
    }
  };

  const handleClear = (event) => {
    setShow(false);
    if (event) event.stopPropagation();
    onChange(rowId, null);
  };

  return (
    <div
      className="plm_cstpopupbox_wrap"
      ref={node}
      onClick={handleClick}
      style={{ height: showSearch ? 22 : 30 }}>
      {!show ? (
        <>
          {value}
          {showSearch && (
            <i
              className="fa fa-search"
              style={{ marginLeft: 5, color: "#a7abc3" }}></i>
          )}
        </>
      ) : (
        <>
          <TextInput
            value={searchBy}
            className="gridInput"
            name="searchBy"
            onChange={handleChange}
          />
          {showSearch && (
            <div className="pull-right clearIc" onClick={handleClear}>
              <i className="fa fa-times"></i>
            </div>
          )}
          <div className="popUpContainer" style={{ height: "unset" }}>
            <UserTable
              headers={headers}
              data={tableData}
              rowId={rowId}
              handleRowClick={handleRowClick}
              tblClass={tblClass}
              defaultSortBy={defaultSortBy}
            />
            {actions && actions.map((c, index) => <Cell key={index} {...c} />)}
          </div>
        </>
      )}
    </div>
  );
}

export default CustomPopup;
