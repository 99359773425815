import React, { useState, useEffect } from "react";
import {
  ClientSelect,
  TransactionSelect,
  InvoiceDetails,
  BillingNarratives,
  PreviewScreen,
  PrintInvoice,
} from "./CreateInvoice";
import { useDispatch, useSelector } from "react-redux";
import { createInvoiceView, setInvoice } from "../../actions/invoice";
import "./CreateInvoiceScreen.scss";
import { RouteConstant } from "../../utils/routeConstant";
import { DisplayLabel } from "../../components/Label";

const totalScreens = 6;

const { INVOICES } = RouteConstant;

const Screens = (props) => {
  const { screenToShow } = props;
  const actionProps = { ...props, totalScreens };

  switch (screenToShow) {
    case 1:
      return (
        <ClientSelect actionProps={actionProps} totalScreens={totalScreens} />
      );
    case 2:
      return (
        <>
          <TransactionSelect
            actionProps={actionProps}
            totalScreens={totalScreens}
          />
        </>
      );
    case 3:
      return (
        <>
          <InvoiceDetails
            actionProps={actionProps}
            totalScreens={totalScreens}
          />
        </>
      );
    case 4:
      return (
        <>
          <BillingNarratives
            actionProps={actionProps}
            totalScreens={totalScreens}
          />
        </>
      );
    case 5:
      return (
        <>
          <PreviewScreen
            actionProps={actionProps}
            totalScreens={totalScreens}
          />
        </>
      );
    case 6:
      return <PrintInvoice />;
    default:
      return (
        <ClientSelect actionProps={actionProps} totalScreens={totalScreens} />
      );
  }
};

export default (props) => {
  const dispatch = useDispatch();
  const [screenToShow, setScreen] = useState(1);

  const handleNext = () => {
    setScreen(screenToShow + 1);
  };

  const handlePrev = () => {
    setScreen(screenToShow - 1);
  };

  const initInvoice = () => {
    dispatch(createInvoiceView());
  };

  useEffect(() => initInvoice(), []);

  const handleCancel = () => {};

  return (
    <div class="plm_creat_inv_div">
      <div class="container-fluid prn_cfuid">
        <div class="row prn_row">
          <div class="col-12 prn_col12">
            <div class="plm_crt_wizdiv">
              {/* <div className="tbBtn plm_cmn_cardbtn_alone_div">
                                <UserLink
                                    text="Invoice Enquiry"
                                    className="btn btn-primary"
                                    icon={<i className="fa fa-arrow-left"></i>}
                                    url={INVOICES}
                                    showIconFirst={true}
                                />
                            </div> */}
              <Screens
                screenToShow={screenToShow}
                handleCancel={handleCancel}
                handleNext={handleNext}
                handlePrev={handlePrev}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
