import React, { useEffect, useState } from "react";
import { ActivityGrid, AddActivity } from ".";
import GridButton from "../../../../common/GridButton";
import { useDispatch, useSelector } from "react-redux";
import useGridHook from "../../../../hooks/useGridHook";
import useToggle from "../../../../hooks/useToggle";
import {
  getActivityCodes,
  deleteActivityCode,
} from "../../../../actions/activityCode";

const rowId = "orgActivityCodeId";

export default ({ activityType, activityTypeId }) => {
  const dispatch = useDispatch();
  const {
    gridData,
    handleChange,
    handleRowClick,
    setGridData,
    deleteRow,
  } = useGridHook(rowId, handleDelete);
  const { activities } = useSelector((state) => state.activityCode);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const fetchActivity = () => {
    dispatch(getActivityCodes(activityTypeId));
  };

  const setActivity = (activities) => {
    if (activities) setGridData(activities);
  };

  useEffect(fetchActivity, []);

  useEffect(() => setActivity(activities), [activities]);

  const { show: showAddActivity, toggle: toggleAddActivity } = useToggle();

  const handleAddEdit = (orgActivityCodeId) => {
    setSelectedActivity(gridData.find((c) => c[rowId] == orgActivityCodeId));
    toggleAddActivity();
  };

  function handleDelete(orgActivityCodeId) {
    dispatch(deleteActivityCode(orgActivityCodeId));
  }

  return (
    <>
      <ActivityGrid
        rowId={rowId}
        activities={gridData}
        handleChange={handleChange.bind(this)}
        handleRowClick={handleRowClick.bind(this)}
        handleEdit={handleAddEdit}
        handleDelete={handleDelete}
      />
      <div className="hr-divider"></div>
      <GridButton handleAdd={handleAddEdit} />
      {showAddActivity && (
        <AddActivity
          activityType={activityType}
          activityTypeId={activityTypeId}
          showAddActivity={showAddActivity}
          toggleModal={toggleAddActivity}
          selectedActivity={selectedActivity}
        />
      )}
    </>
  );
};
