import { takeLatest, call, put } from 'redux-saga/effects';
import CustomerAPI from "../api/CustomerAPI";
import { Customer } from "../actionTypes/customer";
import callAPI from './common';

function* addCustomerView(action)
{
    yield call(callAPI,CustomerAPI.addCustomerView,action,setResult);
}

function* addEditCustomer(action)
{
    yield call(callAPI,CustomerAPI.addEditCustomer,action,setResult);
}

function* getCustomers(action)
{
    yield call(callAPI,CustomerAPI.getCustomers,action,setResult);
}

function* getActiveCustomers(action)
{
    yield call(callAPI,CustomerAPI.getActiveCustomers,action,setResult);
}

function* enableDisableCustomers(action)
{
    yield call(callAPI,CustomerAPI.enableDisableCustomer,action,setResult);
}

function* setResult(result)
{
    yield put({type:Customer.CUSTOMER_SUCCESS,result});
}


export default function* rootSaga() {
    yield takeLatest(Customer.ADD_CUSTOMER, addEditCustomer);
    yield takeLatest(Customer.ADD_CUSTOMER_VIEW, addCustomerView);
    yield takeLatest(Customer.GET_CUSTOMERS, getCustomers);
    yield takeLatest(Customer.GET_ACTIVE_CUSTOMERS, getActiveCustomers);
    yield takeLatest(Customer.ENABLE_DISABLE_CUSTOMERS, enableDisableCustomers);
}