import APIUtil from './APIUtil';

class TimesheetAPI
{
    addTimesheetView(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/AddTimesheetView`;
        return fetch(url, options);
    }

    getTimesheets(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/GetTimesheets`;
        return fetch(url, options);
    }

    getTimesheetDetails(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/GetTimesheetDetails`;
        return fetch(url, options);
    }

    addEditTimesheet(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/AddEditTimesheet`;
        return fetch(url, options);
    }

    authorisationView(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/AuthorisationView`;
        return fetch(url, options);
    }

    getSubmittedTimesheets(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/GetSubmittedTimesheets`;
        return fetch(url, options);
    }

    getTimesheetByStatus(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/GetTimesheetByStatus`;
        return fetch(url, options);
    }

    getTimesheetByType(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/GetTimesheetByType`;
        return fetch(url, options);
    }

    updateTimesheetsStatus(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Timesheet/UpdateTimesheetsStatus`;
        return fetch(url, options);
    }
    
}

export default new TimesheetAPI();