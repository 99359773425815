import React from "react";
import { Logo } from "../../../components/Header";
import { displayDate } from "../../../libs/dateHelper";

const date = displayDate(new Date());

export default ({ heading }) => {
  return (
    <div className="ctop" style={{ marginBottom: 20 }}>
      <div className="ctop-flex">
        <div className="logo-inps">
          <Logo />
        </div>
        <div
          className="logo-inps"
          style={{
            textAlign: "center",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            fontSize: 20,
          }}
        >
          {heading}
        </div>
        <div
          className="logo-inps"
          style={{
            textAlign: "right",
          }}
        >
          {date}
        </div>
      </div>
    </div>
  );
};
