export const RouteConstant={
    ADMIN_LOGIN:'/Admin/Account/Login',
    LOGIN:'/Account/Login',
    RESET_PASSWORD:'/Account/ResetPassword',
    CONFIRM_ACCOUNT:'/Account/ConfirmAccount',
    ADMIN_CUSTOMERS:'/Admin/Customers',
    DASHBOARD:'/Dashboard',
    ADMIN_HOME:'/Admin/Home',

    //Setup
    SETUP_TAX_RATES:'/Setup/TaxRates',
    SETUP_GROUPS:'/Setup/Groups',
    SETUP_CLIENTS:'/Setup/Clients',
    SETUP_USERS:'/Setup/Users',
    SETUP_USER_ROLES:'/Setup/UserRoles',
    SETUP_RENAMING_GROUPS:'/Setup/RenamingGroups',
    SETUP_ACTIVITY_CODES:'/Setup/ActivityCodes',
    SETUP_BILLING_NARRATIVES:'/Setup/BillingNarratives',

    //TimeAndExpenses
    TIME_AND_EXPENSES:'/Customer/TimeAndExpenses',

    //Authorisation
    AUTHORISATION:'/Customer/Authorisation',

    //Invoices
    INVOICES:'/Customer/Invoices',
    CREATE_INVOICE:'/Customer/CreateInvoice',

    //Report
    REPORTS:'/Customer/Reports',
    REPORT_CLIENT_LIST:'/Reports/General/ClientList',
    REPORT_WEEKLY_TIMESHEET:'/Reports/General/WeeklyTimesheet',
    REPORT_NON_SUBMITTED_TIMESHEET:'/Reports/General/NonSubmittedTimesheet',
    REPORT_NONCHARGEABLE:'/Reports/General/NonChargeable',
    REPORT_CLIENT_WIP_DETAILS:'/Reports/WIP/ClientWIPDetails'

}