import React from "react";
import { CheckBox } from "../CheckBox";
import { GridAction } from "../../utils/GridAction";
import { UserButton, ToggleButton } from "../Button";
import { CustomPopup } from "../Modal";
import { TextInput } from "../TextInput";
import { UserDatePicker } from "../DateControl";
import { isObject } from "lodash";

export const Cell = ({ type, name, text, handleChange, actionProps }) => {
  switch (type) {
    case GridAction.CHECKBOX:
      return (
        <CheckBox
          name={name}
          value={text}
          onChange={handleChange}
          {...actionProps}
        />
      );
    case GridAction.TEXT_INPUT:
      return (
        <TextInput
          name={name}
          value={text}
          onChange={handleChange}
          {...actionProps}
        />
      );
    case GridAction.CUSTOM_POPUP:
      return (
        <CustomPopup
          name={name}
          value={text}
          onChange={handleChange}
          {...actionProps}
        />
      );
    case GridAction.BUTTON:
      return (
        <UserButton
          text={text}
          className="btn btn-primary"
          onClick={handleChange}
          {...actionProps}
        />
      );
    case GridAction.TOGGLE_BUTTON:
      return (
        <ToggleButton
          className="btn btn-primary"
          onChange={handleChange}
          checked={text}
          {...actionProps}
        />
      );
    case GridAction.DATE_PICKER:
      return (
        <UserDatePicker
          name={name}
          value={text}
          onChange={handleChange}
          {...actionProps}
        />
      );
    default:
      return <div {...actionProps}>{text}</div>;
  }
};

export default (props) => {
  let {
    data,
    handleChange,
    type,
    selected,
    actionProps,
    hasMultipleAction,
    disable,
    forceEnable,
    cellProps,
  } = props;
  let { key, text, rowId, disableCell } = data ? data : {};

  let cellClass;
  if (hasMultipleAction && isObject(text)) {
    ({ type, text, actionProps } = text);
    ({ cellClass } = actionProps ? actionProps : {});
  }

  const handleCellChange = (name, value) => {
    if (handleChange && !disable) handleChange(rowId, name, value);
  };

  return (
    <td
      className={cellClass ? cellClass : "td" + (selected ? " selected" : "")}
      {...cellProps}
    >
      <Cell
        text={text}
        name={key}
        handleChange={handleCellChange}
        type={disableCell && !forceEnable ? null : type}
        hasMultipleAction={hasMultipleAction}
        actionProps={actionProps}
      />
    </td>
  );
};
