import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClients, setClient } from "../../../actions/client";
import useGridHook from "../../../hooks/useGridHook";
import { ClientGrid, Report } from "../Client";
import useOrgResources from "../../../hooks/useOrgResources";
import PartnerSource from "../../../common/PartnerSource";
import "./ClientReport.scss";
import { AppConstant } from "../../../utils/constant";
import { UserButton } from "../../../components/Button";
import useUserDetail from "../../../hooks/useUserDetail";

const rowId = "clientId";

const { REPORT_FIRST_ITEM_TEXT } = AppConstant;

export default React.forwardRef(
  ({ showReport, reportTitle, reportType, toggleReportBtn, gridRef }, ref) => {
    const dispatch = useDispatch();
    const { gridData, setGridData } = useGridHook(rowId);

    useEffect(() => {
      dispatch(setClient({ clients: [] }));
    }, []);

    const [state, setState] = useState({});
    const { orgPartnerId, partnerSourceId } = state;
    const { theme } = useUserDetail();

    const { clients } = useSelector((state) => state.client);
    const labels = useOrgResources();

    const fetchClients = () => {
      dispatch(getClients());
    };

    const setClients = (clients) => {
      if (clients) setGridData(clients.map((c) => ({ ...c, ...c.address })));
    };

    useEffect(() => {
      setClients(clients);
      toggleReportBtn(clients && clients.length > 0);
    }, [clients]);

    useEffect(() => {
      if (clients) {
        let gridData = clients.slice();
        if (orgPartnerId)
          gridData = gridData.filter((c) => c.orgPartnerId == orgPartnerId);
        if (partnerSourceId)
          gridData = gridData.filter(
            (c) => c.partnerSourceId == partnerSourceId
          );

        setClients(gridData);
      }
    }, [orgPartnerId, partnerSourceId, clients]);
    const handleChange = (name, value) =>
      setState((prev) => ({ ...prev, [name]: value }));

    return (
      <>
        <div className="ctop">
          <div className="ctop-flex">
            <PartnerSource
              hideAsterix={true}
              handleChange={handleChange}
              firstItemText={REPORT_FIRST_ITEM_TEXT}
              orgPartnerId={orgPartnerId}
              partnerSourceId={partnerSourceId}
              isReport
            />
          </div>
          <UserButton
            text="View"
            className="btn btn-purple"
            onClick={fetchClients}
          />
        </div>
        <ClientGrid {...labels} rowId={rowId} clients={gridData} />
        {showReport && (
          <div className="reportDataContainer">
            <div ref={gridRef}>
              <Report
                labels={labels}
                rowId={rowId}
                gridData={gridData}
                reportTitle={reportTitle}
                reportType={reportType}
              />
            </div>
          </div>
        )}
      </>
    );
  }
);
