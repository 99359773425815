import React from "react";
import { DisplayLabel } from "../../../components/Label";

export default ({ emailId, status }) => {
  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <DisplayLabel label="Employee" value={emailId} />
      </div>
      <div className="col-12 col-md-6">
        <DisplayLabel label="Timesheet Status" value={status} width="100" />
      </div>
    </div>
  );
};
