import React from 'react';
import { useDispatch} from 'react-redux';
import useForm from '../../../../hooks/useForm';
import { TextInput } from '../../../../components/TextInput';
import { UserButton } from '../../../../components/Button';
import { addTaxType } from '../../../../actions/taxRate';

const stateSchema={
    
};

const validationSchema={
    taxTypeName:{
        required:true
    }
}


export default ()=>{

    const {
        state:taxTypeDetail,
        errors,
        handleChange,
        handleSubmit
    }=useForm(stateSchema,validationSchema,handleSave);

    const {taxTypeName}=taxTypeDetail;

    const dispatch=useDispatch();


    function handleSave(taxTypeDetail){
        dispatch(addTaxType(taxTypeDetail));
        handleChange('taxTypeName',null);
    }

    return (
        <div className='row'>
            <div className='col-10'>
                <TextInput
                    name="taxTypeName"
                    label="Enter New TAX Type"
                    value={taxTypeName}
                    onChange={handleChange}
                    errors={errors}
                /> 
            </div>
            <div className='col-2 px-0'>
                <UserButton
                    text="Add"
                    icon={<i className='fa fa-plus'></i>}
                    className="btn btn-P mar-top-30"
                    onClick={handleSubmit}
                />
            </div>
        </div>
    )
};