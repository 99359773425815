import React from 'react';
import PropTypes from 'prop-types';
import { Error, UserLabel } from '../Label';

const Input=(props)=>
{
    const {name,disabled,value,onChange,selected}=props;
    return <>
                <input
                    type="radio"
                    name={name}
                    checked={selected==value}                   
                    disabled={disabled}
                    onChange={onChange}
                    value={value}
                />
                <span></span>
            </>
   }


const RadioButtonGroup=(props)=> {
    const {errors,name,value,disabled,onChange,options}=props;
    const error=errors?errors[name]:null;

    const onFieldChange=(event)=> {
        if(event)
            event.stopPropagation();
            
        if(!disabled)
            onChange(name,event.target.value);
    }

       

        return (
           
                <div className="radio_ibox">
                    
                {options && options.map((option) => 
                    <div className="radio_inner"                       
                        key={option.value}
                    >
                        <UserLabel
                            {...option}
                        />
                        <Input
                            {...option}
                            onChange={onFieldChange.bind(this)}
                            selected={value}
                        />
                    </div>
                )}
                {error &&
                    <Error
                        error={error}
                    />
                }
            </div>
           
        
        )
    }


RadioButtonGroup.propTypes = {
    id: PropTypes.string,
    error: PropTypes.string,
    class: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

export default RadioButtonGroup;