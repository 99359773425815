import {Timesheet} from '../../utils/timesheetConstant';
const { default: useCustomGrid } = require("./useCustomGrid");
const { useSelector } = require("react-redux");

const {BILLING_GRID_ID}=Timesheet;

const useBillingHook=()=>{

    const {billingNarratives}=useSelector(state=>state.common);
    const billingData=useCustomGrid(BILLING_GRID_ID,billingNarratives);

    const billingProps={
            actionProps:{
            headers:[
                {key:'billingRef',text:'Code'},
                {key:'billNarrativesDesc',text:'Name'}
            ],
            ...billingData,
            addNewName:'billNarrativesDesc'
        }
    }

    return {
        billingProps
    }
}

export default useBillingHook;