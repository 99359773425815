import React, { useEffect, useState } from "react";
import { UserTable, CommonTable } from "../../../components/Table";
import { useSelector } from "react-redux";
import { groupBy, map, sumBy } from "lodash";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { displayDate } from "../../../libs/dateHelper";
import "./TransactionGrid.scss";
import { displayCost } from "../../../libs/functions";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import WordReport from "./WordReport";
import TableCell from "../../../components/Table/Cell";
import TableHeader from "../../../components/Table/Header";
import { normalizeData } from "../../../libs/functions";

const rowId = "";

const TotalRow = ({ text, cost,units, className }) => {
  const headers = [
    { text, colSpan:2 },
    { text: units },
    { text: displayCost(cost) },
  ].map((c) => ({ ...c, headerActionProps: { className: "gap-cell" } }));

  return (
    <UserTable
      headers={headers}
      tblClass="table table-stripe mb0 wipDetailReportGrid wipDetailTotalRow"
      tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
      wrapperClass={`totalRow ${className}`}
    />
  );
};

export default (props) => {
  const { isReport, clientName, timesheetDate, isJob, reportType } = props;
  const { WORD, EXCEL } = usePrintHook({});
  let headingType = "Employee";
  if (isJob) headingType = "Job Type";

  const [state, setState] = useState({
    gridData: [],
    total: {},
  });
  const { theme } = useUserDetail();
  const { transactions } = useSelector((state) => state.invoice);

  useEffect(() => setTransactions(transactions), [transactions, isJob]);

  const setTransactions = (transactions) => {
    if (transactions) {
      const allTransactions = transactions.map((c) => ({
        ...c,
        cost: TimesheetHelper.calAmount({ ...c, invoiceUnits: c.noOfUnits }),
      }));
      const group = groupBy(allTransactions, (c) =>
        isJob ? c.orgActivityCodeId : c.employeeId
      );
      const gridData = map(group, (c) => ({
        heading: isJob ? c[0].activityName : c[0].employee,
        gridData: c.map((g) => ({
          ...g,
          timesheetDate: displayDate(g.timesheetDate),
          cost: displayCost(g.cost),
        })),
        totalUnits: sumBy(c, (t) => t.noOfUnits),
        totalCost: sumBy(c, (t) => parseFloat(t.cost)),
      }));
      setState({
        gridData,
        total: {
          ...calTotal(gridData),
        },
      });
    }
  };

  const calTotal = (data) => ({
    totalUnits: sumBy(data, (c) => c.totalUnits),
    totalCost: sumBy(data, (c) => c.totalCost),
  });

  const headers = [
    { key: "timesheetDate", text: "Date" },
    {
      key: !isJob ? "activityDesc" : "employee",
      text: !isJob ? "Job Type" : "Employee",
    },
    { key: "noOfUnits", text: "Units" },
    { key: "cost", text: "Value" },
    { key: "extra", text: "" },
  ].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

  const {
    gridData,
    total: { totalUnits, totalCost },
  } = state;

  if (reportType == WORD || reportType == EXCEL) {
    return (
      <WordReport
        clientName={clientName}
        gridData={gridData}
        headers={headers.filter((c) => c.key != "blank")}
        totalCost={totalCost}
        totalUnits={totalUnits}
        headingType={headingType}
      />
    );
  }

  const getDataTable = (data, totalRows=[]) => {

    var rows=[];

      {normalizeData(data, rowId).map((row, i) => 
          rows.push(<tr
              className="tbody"
              key={i}>
              {headers.map((prop) => 
                <TableCell
                  {...prop}
                  key={prop.key + "" + i}
                  data={row.find((r) => r.key === prop.key)}
                  selected={row.find((r) => r.key === "selected" && r.text)}
                />
              )}
            </tr>)
        )};

      {
        totalRows.map((totalRow)=> {
            rows.push(<tr className="tbody totalRow-wrap">
              {
                totalRow.map((t)=> <td className={`td ${t.className}`} colspan={t.colSpan}><div className="gap-cell">{t.text}</div></td>)
              }
            </tr>)
        })
      }

return rows;
}

  var datatable=[];

  return (
    <ReportContainer hasData={transactions && transactions.length > 0}>
      {transactions && transactions.length > 0 && (
        <div className="wipDataRpt">
          <div class="header-row">
            <div class="table-header">
              Client: <span>{clientName}</span>
            </div>
            <div class="table-header">
              All WIP up to and including:{" "}
              <span>{displayDate(timesheetDate)}</span>
            </div>
          </div>

          {gridData.map((c, i) => 
          {
            datatable=[];

            return (
              <React.Fragment key={i}>
                <div class="header-row">
                  <div class="table-header light-blue">
                    {headingType}: <span>{c.heading}</span>
                  </div>
                </div>
                
                {
                    datatable= [datatable, getDataTable(c.gridData, [
                    [
                      { text:`Totals of: ${c.heading}`, colSpan:2, className: "total back-light-blue" },
                      { text: c.totalUnits, className: "total back-light-blue" },
                      { text: displayCost(c.totalCost), className: "total back-light-blue" },
                      { text: '', className: "total back-light-blue" },
                    ]])]
                }

                {
                  i+1 < gridData.length &&
                  <CommonTable tblClass="wipDetailReportGrid wipReportGrid" headers={headers} rows={datatable} />
                }
              </React.Fragment>
            )
          }
          
          )}

          {
            datatable=[datatable, (<tr className="tbody totalRow-wrap">
              <td className={`td total back-blue`} colspan="2"><div className="gap-cell">{`Totals for Client: ${clientName}`}</div></td>
              <td className={`td total back-blue`}><div className="gap-cell">{totalUnits}</div></td>
              <td className={`td total back-blue`}><div className="gap-cell">{displayCost(totalCost)}</div></td>
              <td className={`td total back-blue`}><div className="gap-cell">&nbsp;</div></td>
            </tr>)]
          }

            <CommonTable tblClass="wipDetailReportGrid wipReportGrid" headers={headers} rows={datatable} />

        </div>
      )}
    </ReportContainer>
  );
};
