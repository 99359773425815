import APIUtil from './APIUtil';

class TaxRateAPI
{
    addTaxType(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/TaxRate/AddEditTaxType`;
        return fetch(url, options);
    }

    addTaxRateView(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/TaxRate/AddTaxRateView`;
        return fetch(url, options);
    }

    addTaxRates(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/TaxRate/AddEditTaxRate`;
        return fetch(url, options);
    }

    getTaxRates(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/TaxRate/GetTaxRates`;
        return fetch(url, options);
    }

    deleteTaxRate(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/TaxRate/DeleteTaxRate`;
        return fetch(url, options);
    }
}

export default new TaxRateAPI();