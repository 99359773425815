import APIUtil from './APIUtil';

class AccountAPI
{
    validateUser(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/ValidateUser`;
        return fetch(url, options);
    }

    impersonateUser(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/ImpersonateUser`;
        return fetch(url, options);
    }

    stopImpersonation(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/StopImpersonation`;
        return fetch(url, options);
    }

    setUserTheme(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/SetUserTheme`;
        return fetch(url, options);
    }

    verifyUser(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/VerifyUser`;
        return fetch(url, options);
    }

    changePassword(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/ChangePassword`;
        return fetch(url, options);
    }

    forgotPassword(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/ForgotPassword`;
        return fetch(url, options);
    }

    confirmAccount(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/ConfirmAccount`;
        return fetch(url, options);
    }

    resetPassword(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Login/ResetPassword`;
        return fetch(url, options);
    }
}

export default new AccountAPI();