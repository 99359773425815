import {Invoice} from '../actionTypes/invoice';

export const createInvoiceView=(payload)=>({
    type:Invoice.CREATE_INVOICE_VIEW,
    payload
});

export const addTransactionView=(payload)=>({
    type:Invoice.ADD_TRANSACTION_VIEW,
    payload
});

export const getTransactions=(payload)=>({
    type:Invoice.GET_TRANSACTIONS,
    payload
});

export const createInvoice=(payload)=>({
    type:Invoice.CREATE_INVOICE,
    payload
});

export const saveTransactions=(result)=>({
    type:Invoice.SET_TRANSACTIONS,
    result
});

export const setInvoice=(result)=>({
    type:Invoice.INVOICE_SUCCESS,
    result
});

export const getInvoices=(payload)=>({
    type:Invoice.GET_INVOICES,
    payload
});

export const getInvoiceDetails=(payload)=>({
    type:Invoice.GET_INVOICE_DETAILS,
    payload
});

export const validateInvoiceNo=(payload,cb)=>({
    type:Invoice.VALIDATE_INVOICE_NO,
    payload,
    cb
});

export const updateInvoice=(payload)=>({
    type:Invoice.UPDATE_INVOICE,
    payload
});