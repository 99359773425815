import { takeLatest, call, put } from 'redux-saga/effects';
import OrgPartnerAPI from "../api/OrgPartnerAPI";
import { OrgPartner } from "../actionTypes/orgPartner";
import callAPI from './common';
import { Pages } from '../actionTypes/pages';

function* addEditOrgPartner(action)
{
    yield call(callAPI,OrgPartnerAPI.addEditOrgPartner,action,setResult);
}

function* addEditPartnerSource(action)
{
    yield call(callAPI,OrgPartnerAPI.addEditPartnerSource,action,setResult);
}

function* getOrgPartners(action)
{
    yield call(callAPI,OrgPartnerAPI.getOrgPartners,action,setResult);
}

function* deleteOrgPartner(action)
{
    yield call(callAPI,OrgPartnerAPI.deleteOrgPartner,action,setResult);
}

function* deletePartnerSource(action)
{
    yield call(callAPI,OrgPartnerAPI.deletePartnerSource,action,setResult);
}

function* addEditOrgResource(action)
{
    yield call(callAPI,OrgPartnerAPI.addEditResource,action,setResources);
}

function* setResult(result)
{
    yield put({type:OrgPartner.ORG_PARTNER_SUCCESS,result});
}

function* setResources(result)
{
    yield put({type:Pages.SET_PAGES,result});
}


export default function* rootSaga() {
    yield takeLatest(OrgPartner.ADD_EDIT_ORG_PARTNER, addEditOrgPartner);
    yield takeLatest(OrgPartner.ADD_EDIT_PARTNER_SOURCE, addEditPartnerSource);
    yield takeLatest(OrgPartner.GET_ORG_PARTNERS, getOrgPartners);
    yield takeLatest(OrgPartner.DELETE_ORG_PARTNER, deleteOrgPartner);
    yield takeLatest(OrgPartner.DELETE_PARTNER_SOURCE, deletePartnerSource);
    yield takeLatest(OrgPartner.ADD_EDIT_RESOURCES, addEditOrgResource);
}