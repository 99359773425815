import React, { useState, useEffect } from "react";
import { UserTable } from "../../../components/Table";
import { GridAction } from "../../../utils/GridAction";
import useJobHook from "../../hooks/useJobHook";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { isObject, times } from "lodash";
import { displayCost } from "../../../libs/functions";

export default ({
  timesheets,
  rowId,
  handleRowChange,
  setGridData,
  handleChange,
  disableTimesheet,
  handleHeaderChange,
  calAmount,
}) => {
  const { activityProps } = useJobHook();
  const { employeeProps } = useEmployeeHook();
  const [gridData, setGrid] = useState([]);

  const handleClick = (id, name, value) => {
    const index = timesheets.findIndex((c) => c[rowId] == id);
    if (index >= 0) {
      const { included } = timesheets[index];
      if (isObject(included)) {
        setGridData(timesheets.filter((c) => c[rowId] != id));
      } else calAmount(id, name, value);
    }
  };

  useEffect(() => {
    if (timesheets)
      setGrid(
        timesheets.map((c) => ({
          ...c,
          amount: displayCost(c.amount),
        }))
      );
  }, [timesheets]);

  let headers = [
    { key: "timesheetType", text: "Type" },
    { key: "timesheetDate", text: "Date" },
    {
      key: "employee",
      text: "Employee",
      type: GridAction.CUSTOM_POPUP,
      ...employeeProps,
      handleChange: handleRowChange,
    },
    {
      key: "activityName",
      text: "Job Code",
      type: GridAction.CUSTOM_POPUP,
      ...activityProps,
      handleChange: handleRowChange,
    },
    {
      key: "activityDesc",
      text: "Description",
      type: GridAction.CUSTOM_POPUP,
      ...activityProps,
      handleChange: handleRowChange,
    },
    { key: "noOfUnits", text: "Units" },
    {
      key: "allotUnits",
      text: "Allot Units",
      hasMultipleAction: true,
      type: GridAction.TEXT_INPUT,
      forceEnable: true,
      handleChange: calAmount,
      actionProps: {
        type: "number",
      },
    },
    {
      key: "rate",
      text: "Rate",
      type: GridAction.TEXT_INPUT,
      handleChange: calAmount,
    },
    { key: "amount", text: "Amount" },
    { key: "isAuthorised", text: "Authorised" },
    {
      key: "included",
      text: timesheets.every((c) => c.included),
      type: GridAction.CHECKBOX,
      forceEnable: true,
      hasMultipleAction: true,
      hType: GridAction.CHECKBOX,
      hName: "included",
      headerActionProps: { className: "headerCheckbox" },
      handleChange: handleClick,
    },
  ];

  return (
    <UserTable
      headers={headers}
      data={gridData}
      rowId={rowId}
      disable={disableTimesheet}
      handleChange={handleChange}
      handleHeaderChange={handleHeaderChange}
    />
  );
};
