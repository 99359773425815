import React, { useState, useEffect } from "react";
import { UserCard } from "../../../components/Container";
import TransactionGrid from "./TransactionGrid";
import ScreenButton from "../../../common/ScreenButton";
import { DisplayLabel } from "../../../components/Label";
import { UserButton } from "../../../components/Button";
import { SelectBox } from "../../../components/SelectBox";
import { useSelector, useDispatch } from "react-redux";
import useGridHook from "../../../hooks/useGridHook";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import { maxBy, sumBy } from "lodash";
import { displayDate } from "../../../libs/dateHelper";
import useToast from "../../../hooks/useToast";
import { saveTransactions } from "../../../actions/invoice";
import { formatCost } from "../../../libs/functions";
import { GridAction } from "../../../utils/GridAction";
import { TextInput } from "../../../components/TextInput";
import { Client } from "../Common";

const options = [...new Array(5)].map((c, i) => ({
    text: i + 1,
    value: i + 1,
  })),
  rowId = "rowId";

export default ({ actionProps }) => {
  const dispatch = useDispatch();

  const {
    gridData,
    handleChange,
    handleRowChange,
    setGridData,
    handleHeaderChange,
  } = useGridHook(rowId);

  const [state, setState] = useState({
    noOfRows: 1,
  });
  const { showError } = useToast();
  const { noOfRows, allocatedUnits, totalAmount } = state;

  const { transactions } = useSelector((state) => state.invoice);

  const setTransactions = () => {
    if (transactions)
      setGridData(
        transactions.map((c, index) => ({
          ...c,
          included: false,
          isAuthorised: c.isAuthorised ? "Yes" : "No",
          disableCell: true,
          invoiceUnits: 0,
          timesheetDate: displayDate(c.timesheetDate),
          allotUnits: {
            text: 0,
            type: c.isExpenses ? null : GridAction.TEXT_INPUT,
          },
          [rowId]: index,
        }))
      );
  };

  useEffect(() => setTransactions(), [transactions]);

  const handleStateChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const addRow = () => {
    const maxId = maxBy(gridData, (c) => c[rowId])?.[rowId];
    const futureRows = TimesheetHelper.getFutureRows(parseInt(noOfRows), maxId);
    setGridData(futureRows.concat(gridData));
  };

  useEffect(() => {
    if (gridData) {
      const allocatedUnits = sumBy(gridData, (c) =>
        c.invoiceUnits ? parseInt(c.invoiceUnits) : 0
      );
      const totalAmount = sumBy(
        gridData.filter((c) => c.included),
        (c) => (c.amount ? parseFloat(c.amount) : 0)
      );
      setState((prev) => ({
        ...prev,
        allocatedUnits,
        totalAmount: formatCost(totalAmount),
      }));
    }
  }, [gridData]);

  const calAmount = (id, name, value) => {
    const index = gridData.findIndex((c) => c[rowId] == id);
    if (index >= 0) {
      let selected = gridData[index];
      let {
        noOfUnits,
        timesheetDetailId,
        employeeId,
        orgActivityCodeId,
        isPrevious,
        isExpenses,
      } = selected;

      if (isPrevious && value > 0) value = value * -1;

      if (!employeeId) {
        showError("Please select employee");
        return;
      }
      if (!orgActivityCodeId) {
        showError("Please select job");
        return;
      }
      if (
        !timesheetDetailId ||
        Math.abs(value) <= Math.abs(noOfUnits) ||
        isExpenses
      ) {
        selected = { ...selected, [name]: value };
        if (name == "allotUnits")
          selected = { ...selected, invoiceUnits: value };
        if (name == "included") {
          let invoiceUnits = 0;
          if (value) invoiceUnits = noOfUnits;

          selected = {
            ...selected,
            invoiceUnits,
            allotUnits: {
              text: invoiceUnits,
              type: isExpenses ? null : GridAction.TEXT_INPUT,
            },
          };
        }
        const amount = TimesheetHelper.calAmount(selected);
        handleRowChange(id, {
          ...selected,
          amount,
          included: value && value != 0,
        });
      } else showError("Allocated units should be less than Units.");
    }
  };

  const handleIncluded = (name, value) => {
    setGridData(
      gridData.map((data) => {
        data = {
          ...data,
          [name]: value,
          invoiceUnits: value ? data.noOfUnits : 0,
        };
        return {
          ...data,
          allotUnits: {
            text: data.invoiceUnits,
            type: data.isExpenses ? null : GridAction.TEXT_INPUT,
          },
          amount: TimesheetHelper.calAmount(data),
        };
      })
    );
  };

  const handleSave = () => {
    if (gridData.some((c) => !c.timesheetDetailId && !c.employeeId)) {
      showError("Please select employee for Future time");
      return;
    } else if (
      gridData.some((c) => !c.timesheetDetailId && !c.orgActivityCodeId)
    ) {
      showError("Please select job for Future time");
      return;
    } else if (gridData.some((c) => !c.timesheetDetailId && !c.invoiceUnits)) {
      showError("	Please allocate units for Future time");
      return;
    } else if (!gridData.some((c) => c.included)) {
      showError("Please select atleast one transaction");
      return;
    }

    dispatch(
      saveTransactions({
        transactions: gridData,
        invoiceDetail: {
          calculatedAmt: totalAmount,
          actualAmt: totalAmount,
        },
      })
    );
    actionProps.handleNext();
  };

  return (
    <div class="plm_cmn_wiz_step plm_invwiz_stepw2">
      <UserCard title="2. Select Transactions to Include">
        <Client />
        <div class="plm_cmn_wiz_content plm_inv_wiz_step2">
          <div className="row frm_row_nopad_ctrldiv plm_operiv">
            {/* <div className="col-3">
              <DisplayLabel label="Transactions" value={transactions.length} />
            </div> */}
          </div>
          <TransactionGrid
            timesheets={gridData}
            rowId={rowId}
            handleChange={handleChange}
            handleRowChange={handleRowChange}
            handleHeaderChange={handleIncluded}
            setGridData={setGridData}
            calAmount={calAmount}
          />
        </div>
      </UserCard>
      {/* <div className="row">
        <div className="col-5" style={{ textAlign: "right" }}>
          <div className="strong-text">Grand Total</div>
        </div>
        <div className="col-1"><div className="strong-text">Units</div></div>
        <div className="col-1"><div className="text-value-bg">{allocatedUnits}</div></div>
        <div className="col-1"></div>
        <div className="col-1"><div className="text-value-bg">{totalAmount}</div></div>
      </div> */}
      <div className="row">
        <div className="col-12">
          <div className="tb-wrap trans-detail-bottom-row">
            <div className="table-responsive plm_cmn_tbldiv">
              <table>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div className="strong-text text-right-strong">
                      Grand Total
                    </div>
                  </td>
                  <td>
                    <div className="strong-text text-right-strong">Units</div>
                  </td>
                  <td>
                    <div className="text-value-bg">{allocatedUnits}</div>
                  </td>
                  <td></td>
                  <td>
                    <div className="text-value-bg">{totalAmount}</div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row invoice-btn-bottom">
        <div className="col-12 px-0">
          <span className="strong-text">Future Time</span>
        </div>
        <div className="col-2 norLbl">Number of rows</div>
        <div className="col-4" style={{ right: -30 }}>
          <div className="btn-Input-Container">
            <div class="finl_ctrldiv norInput btn-input-child">
              <TextInput
                name="noOfRows"
                value={noOfRows}
                onChange={handleStateChange}
              />
            </div>
            <div className="btn-Container btn-input-child">
              <UserButton
                text="Add"
                className="btn btn-primary"
                onClick={addRow}
              />
            </div>
          </div>
        </div>

        <div className="col-4"></div>
        <div className="col-2 pr-0">
          <ScreenButton {...actionProps} handleNext={handleSave} />
        </div>
      </div>
    </div>
  );
};
