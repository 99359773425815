import React, { useState, useEffect } from 'react';
import { UserTab, TabPanel } from '../../components/Tabs';
import { ScreenDetails, PartnerSource } from '.';
import useOrgResources from '../../hooks/useOrgResources';

export default ({permissionIdList,assignedSources,
    handleSourceChange,handlePermission})=>{

    const [partnerTitle,setTitle]=useState(null);
    const {partnerLabel,sourceLabel}=useOrgResources();

    useEffect(()=>{
        setTitle(partnerLabel+'/'+sourceLabel);
    },[])

    return (
        <div className='col-12'> 
            <TabPanel
                defaultTab="screenDetail"
            >
                <UserTab
                    eventKey="screenDetail"
                    title="Screen Details"
                >
                    <ScreenDetails
                        handleChange={handlePermission}
                        permissionIdList={permissionIdList}
                    />
                </UserTab>
                <UserTab
                    eventKey="partners"
                    title={partnerTitle}
                >
                    <PartnerSource
                        title={partnerTitle}
                        handleChange={handleSourceChange}
                        assignedSources={assignedSources?assignedSources:[]}
                    />
                </UserTab>
            </TabPanel>
        </div>
    )
}