import {Common} from '../actionTypes/common';

const initialState={
   
}

export default (state=initialState,action)=>{
    switch(action.type)
    {
        case Common.SET_COMMON_DATA:
            return {
                ...state,
                ...action.result
            }
        default:
            return {
                ...state
            }
    }
}