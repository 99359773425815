export const Timesheet={
    ADD_TIMESHEET_VIEW:'ADD_TIMESHEET_VIEW',
    ADD_EDIT_TIMESHEETS:'ADD_EDIT_TIMESHEETS',
    GET_TIMESHEETS:'GET_TIMESHEETS',
    GET_TIMESHEET_DETAILS:'GET_TIMESHEET_DETAILS',
    GET_TIMESHEET_BY_STATUS:'GET_TIMESHEET_BY_STATUS',
    GET_TIMESHEET_BY_TYPE:'GET_TIMESHEET_BY_TYPE',
    TIMESHEET_SUCCESS:'TIMESHEET_SUCCESS',
    AUTHORISATION_VIEW:'AUTHORISATION_VIEW',
    UPDATE_TIMESHEETS_STATUS:'UPDATE_TIMESHEETS_STATUS',
    GET_SUBMITTED_TIMESHEETS:'GET_SUBMITTED_TIMESHEETS',
}