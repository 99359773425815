import React, { useEffect, useState } from "react";
import { UserTable } from "../../../components/Table";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { sumBy } from "lodash";
import { useSelector } from "react-redux";
import { displayDate } from "../../../libs/dateHelper";
import { ReportHeader } from "../Components";
import { displayCost } from "../../../libs/functions";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";

const rowId = "";
let headerProps = { className: "costingSummaryHeader" };
const TotalRow = ({ text, invoiceUnits, cost, className }) => {
  const totalRowProps = { className: headerProps.className };
  return (
    <div className={"totalRow " + className}>
      <UserTable
        tblClass="reportTbl costingSummaryTotalRow"
        headers={[
          { text, totalRowProps, colSpan: 3 },
          // { text: "", totalRowProps },
          // { text: "", totalRowProps },
          { text: invoiceUnits?.toString(), totalRowProps, colSpan: 2 },
          // { text: "", totalRowProps },
          { text: cost, totalRowProps },
        ]}
      />
    </div>
  );
};

export default (props) => {
  const [state, setState] = useState({
    gridData: [],
    totalRow: {},
  });

  const { invoiceDetails } = useSelector((state) => state.invoice);
  const { theme } = useUserDetail();
  const { isReport, selectedInvoice, reportTitle, reportType } = props;
  const { clientCode, clientName, invoiceNo, invoiceDate } = selectedInvoice;
  const { WORD, EXCEL } = usePrintHook({});

  const calTotal = (data) => ({
    invoiceUnits: sumBy(data, (t) => t.invoiceUnits),
    cost: TimesheetHelper.calTotal(data, "amount"),
  });

  useEffect(() => {
    if (invoiceDetails) {
      let gridData = invoiceDetails
        .filter((c) => !c.isPrevious)
        .map((c) => ({
          ...c,
          cost: displayCost(c.amount),
          timesheetDate: displayDate(c.timesheetDate),
        }));
      gridData.push({
        timesheetDate: "Current Time Totals",
        ...calTotal(gridData),
      });
      const futureTimesheets = invoiceDetails
        .filter((c) => c.isFuture)
        .map((c) => ({
          ...c,
          cost: displayCost(c.amount),
          timesheetDate: "Future Time",
        }));
      gridData = gridData.concat(futureTimesheets);
      gridData.push({
        timesheetDate: "Future Time Totals",
        ...calTotal(futureTimesheets),
      });
      const prevTimesheets = invoiceDetails
        .filter((c) => c.isPrevious && !c.isFuture)
        .map((c) => ({
          ...c,
          cost: displayCost(c.amount),
          timesheetDate: "Prev Future Time",
        }));
      gridData = gridData.concat(prevTimesheets);
      gridData.push({
        timesheetDate: "Prev Future Time Totals",
        ...calTotal(prevTimesheets),
      });
      setState({
        gridData,
        totalRow: calTotal(invoiceDetails),
      });
    }
  }, [invoiceDetails]);

  const headers = [
    { key: "timesheetDate", text: "Date", headerProps },
    { key: "employee", text: "Username", headerProps },
    { key: "activityDesc", text: "JobType", headerProps },
    { key: "invoiceUnits", text: "Units", headerProps },
    { key: "rate", text: "Rate", headerProps },
    { key: "cost", text: "Cost", headerProps },
  ];

  const className = isReport ? "col-12 col-md-12 col-xs-12" : "col-12";

  const { totalRow, gridData } = state;

  return (
    <div className={"row plm_rp_rptort_col App " + theme}>
      <div className="col-xs-12 col-md-12 reportWrapper rwrapperClientCostSum rwrapPdf">
        <ReportContainer hasData paddingLeft={10} paddingRight={15}>
          <ReportHeader heading={reportTitle} reportType={reportType} />
          {reportType == WORD || reportType == EXCEL ? (
            <table width="100%">
              <tr>
                <td width="50%">
                  <CustomDisplayLabel
                    label="Ref"
                    value={clientCode}
                    customClass="plm_carep_top_first"
                  />
                </td>
                <td>
                  <CustomDisplayLabel
                    label="Invoice No"
                    width={120}
                    value={invoiceNo}
                    customClass="plm_carep_top_first"
                  />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  <CustomDisplayLabel
                    label="Client"
                    value={clientName}
                    customClass="plm_carep_top_first"
                  />
                </td>
                <td>
                  <CustomDisplayLabel
                    label="Invoice Date"
                    width={120}
                    value={displayDate(invoiceDate)}
                    customClass="plm_carep_top_first"
                  />
                </td>
              </tr>
            </table>
          ) : (
            <React.Fragment>
              <div class="header-row">
              <div class="table-header w-20">
                <DisplayLabel label="Ref" value={clientCode} />
              </div>
              <div class="table-header w-20">
                  <DisplayLabel
                    label="Invoice No"
                    width={100}
                    value={invoiceNo}
                  />
              </div>
              <div class="table-header w-20">
                  <DisplayLabel label="Client" value={clientName} />
              </div>
              <div class="table-header w-40">
                  <DisplayLabel
                    label="Invoice Date"
                    value={displayDate(invoiceDate)}
                  />
              </div>
            </div>
            <br />
            </React.Fragment>
          )}
          <div className={"costingSummaryWrap " + theme}>
            <div className={className}>
              <UserTable
                headers={headers}
                data={gridData}
                rowId={rowId}
                tblClass="reportTbl costingSummaryGrid"
              />
            </div>
            <TotalRow text="Grand Totals" {...totalRow} className={className} />
          </div>
        </ReportContainer>
      </div>
    </div>
  );
};
