import React, { useEffect, useState } from "react";
import AddCustomer from "./AddCustomer";
import useToggle from "../../hooks/useToggle";
import { CustomerGrid } from "./components";
import { UserCard } from "../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers, enableDisableCustomers } from "../../actions/customer";
import useGridHook from "../../hooks/useGridHook";
import AdminGridButton from "../../common/AdminGridButton";
import { impersonateUser } from "../../actions/account";
import { TextInput } from "../../components/TextInput";
import { contains } from "../../libs/functions";
import "./CustomerList.scss";

const rowId = "customerId";

export default (props) => {
  const dispatch = useDispatch();
  const {
    gridData,
    handleChange,
    handleRowClick,
    setGridData,
    handleHeaderChange,
  } = useGridHook(rowId);
  const { customers } = useSelector((state) => state.customer);
  const [searchBy, setSearchBy] = useState(null);
  const fetchCustomers = () => {
    dispatch(getCustomers());
  };

  const setCustomers = (customers) => {
    if (customers)
      setGridData(
        customers.map((c) => ({ ...c, enterSystem: null, enable: !c.disable }))
      );
  };

  useEffect(fetchCustomers, []);

  useEffect(() => setCustomers(customers), [customers]);

  const { show: showAddCustomer, toggle: toggleAddCustomer } = useToggle();

  const handleNew = () => {
    setCustomers(gridData.map((c) => ({ ...c, selected: false })));
    toggleAddCustomer();
  };

  const handleSearch = (name, value) => {
    setSearchBy(value);
    setCustomers(
      customers.filter(
        (c) =>
          contains(c.orgName, value) ||
          contains(c.fullName, value) ||
          contains(c.emailId, value) ||
          contains(c.accountId, value)
      )
    );
  };

  const handleSave = (customerId, enable) => {
    const updated = gridData
      .filter((g) => g[rowId] == customerId)
      .map((c) => ({
        orgId: c.orgId,
        customerId: c.customerId,
        disable: !enable,
      }));

    dispatch(enableDisableCustomers(updated));
  };

  const handleEnterSystem = (customerId, event) => {
    if (event) event.stopPropagation();

    dispatch(impersonateUser({ customerId }));
  };

  return (
    <UserCard title="Customers Details">
      <CustomerGrid
        rowId={rowId}
        customers={gridData}
        handleChange={handleSave}
        handleRowClick={handleRowClick.bind(this)}
        handleHeaderChange={handleHeaderChange.bind(this)}
        handleEnterSystem={handleEnterSystem}
      />
      <div className="headerSearch">
        <i className="fa fa-search searchIc"></i>
        <TextInput
          name="searchBy"
          value={searchBy}
          onChange={handleSearch}
          placeholder="Search"
        />
      </div>
      <AdminGridButton
        handleAdd={handleNew}
        handleEdit={toggleAddCustomer}
        handleDelete={handleSave.bind(this)}
        gridData={gridData}
      />
      {showAddCustomer && (
        <AddCustomer
          showAddCustomer={showAddCustomer}
          toggleModal={toggleAddCustomer}
          selectedCustomer={gridData.find((c) => c.selected)}
        />
      )}
    </UserCard>
  );
};
