import {OrgBilling} from '../actionTypes/orgBilling';

export const addBillingNarratives=(payload)=>({
    type:OrgBilling.ADD_BILLING_NARRATIVES,
    payload
});

export const getBillingNarratives=(payload)=>({
    type:OrgBilling.GET_BILLING_NARRATIVES,
    payload
});

export const deleteBillingNarratives=(payload)=>({
    type:OrgBilling.DELETE_BILLING_NARRATIVES,
    payload
});