import { takeLatest, call, put } from 'redux-saga/effects';
import ActivityCodeAPI from "../api/ActivityCodeAPI";
import { ActivityCode } from "../actionTypes/activityCode";
import callAPI from './common';
import { Common } from '../actionTypes/common';

function* addEditActivityCode(action)
{
    yield call(callAPI,ActivityCodeAPI.addEditActivityCode,action,setResult);
}

function* getActivityTypes(action)
{
    yield call(callAPI,ActivityCodeAPI.getActivityTypes,action,setCommonData);
}

function* setCommonData(result)
{
    yield put({type:Common.SET_COMMON_DATA,result});
}

function* getActivityCodes(action)
{
    yield call(callAPI,ActivityCodeAPI.getActivityCodes,action,setResult);
}

function* deleteActivityCode(action)
{
    yield call(callAPI,ActivityCodeAPI.deleteActivityCode,action,setResult);
}

function* setResult(result)
{
    yield put({type:ActivityCode.ACTIVITY_CODE_SUCCESS,result});
}


export default function* rootSaga() {
    yield takeLatest(ActivityCode.ADD_EDIT_ACTIVITY_CODE, addEditActivityCode);
    yield takeLatest(ActivityCode.GET_ACTIVITY_TYPES, getActivityTypes);
    yield takeLatest(ActivityCode.GET_ACTIVITY_CODES, getActivityCodes);
    yield takeLatest(ActivityCode.DELETE_ACTIVITY_CODE, deleteActivityCode);
}