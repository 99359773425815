import React from 'react';
import { TextInput } from '../components/TextInput';

export default ({contactDetail,errors,handleChange,requiredField})=>{

    const {
        contactPerson,
        country,
        address,
        phoneno,
        mobileno,
        state,
        city,
        website
    }=contactDetail;

    const {
            contactPerson:contactPersonReq
    }=requiredField?requiredField:{};

    return (
            <>
                <div className='col-6'>
                    <TextInput
                        name="contactPerson"
                        value={contactPerson}
                        label="Contact Person"
                        onChange={handleChange}
                        errors={errors}
                        asterix={contactPersonReq}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        name="country"
                        value={country}
                        label="Country"
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        name="address"
                        value={address}
                        label="Address"
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        name="phoneno"
                        value={phoneno}
                        label="Phone"
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        name="city"
                        value={city}
                        label="City"
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        name="mobileno"
                        value={mobileno}
                        label="Mobile"
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        name="state"
                        value={state}
                        label="State"
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        name="website"
                        value={website}
                        label="Website"
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
            </>
    )
}
