export const LookUp={
    //Activities
    CHARGEABLE:'Chargeable',
    NON_CHARGEABLE:'NonChargeable',
    EXPENSES:'Expenses',

    //TimesheetStatus
    OPEN_TS :"Open",
    SUBMITTED_TS : "Submitted",
    AUTHORISED_TS : "Authorised"
}