import React from 'react';

export default ({label,value,width,customClass})=>(
        
            <div className="emp_topvaldiv">
                <div className="label-wraper">
                    <table>
                        <tr className={customClass?customClass:""}>
                            <td>
                            {label && 
                            <>
                                <label className="" style={{width:(width?width:'auto')}}><strong>{label}</strong></label>
                                <span className="mx-1"><strong>:</strong></span>
                            </>
                            }
                            <span  title={value?value:''} className="l-value-wrap"><span>&nbsp;{value}</span></span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
       
    )
