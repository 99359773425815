import auth from "../libs/auth";

class APIUtils {
  getBaseUrl() {
    //return process.env.REACT_APP_API_URL || "https://localhost:5001/";
    return process.env.REACT_APP_API_URL || "http://88.215.0.135:56490/";
  }

  postOptions(payload) {
    const options = {};
    options.method = "POST";
    if (payload) options.body = JSON.stringify(payload);

    options.headers = this.getHeadersPost();

    return options;
  }

  getOptions() {
    const options = {};
    options.method = "GET";
    options.headers = this.getHeadersPost();

    return options;
  }

  getHeadersPost() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    let userKey = auth.userKey;
    if (userKey) {
      headers.set("Authorization", "Bearer " + userKey);
    }
    return headers;
  }
}

export default new APIUtils();
