import React, { useState } from "react";
import { Invoice } from "../Components";
import { Report } from "../Invoice";
import useVerifyReport from "../../hooks/useVerifyReport";
import useUserDetail from "../../../hooks/useUserDetail";

export default React.forwardRef(
  ({ showReport, reportTitle, reportType, toggleReportBtn, gridRef, toggleRowPrint }, ref) => {
    const [state, setState] = useState({});
    //useVerifyReport(showReport, state);
    const { theme } = useUserDetail();

    return (
      <div className="prn_row pln_cmnreport_rowbox cltcost_sum_rowbox">
        <Invoice setState={setState} toggleReportBtn={toggleReportBtn} toggleRowPrint={toggleRowPrint} />
        {showReport && (
          <div className="col-12 reportDataContainer">
            <div className={"row plm_rp_rptort_col invoice_report_table " + theme} ref={gridRef}>
              <div className="col-xs-12">
                <Report
                  selectedInvoice={state}
                  reportTitle={reportTitle}
                  reportType={reportType}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);
