import React, { useEffect, useState } from "react";
import { WordLabel } from "../../../components/Label";
import { LabelColor } from "../../../components/Label/WordLabel";
import { WordTable } from "../../../components/Table";
import { displayDate } from "../../../libs/dateHelper";
import { displayCost } from "../../../libs/functions";
import { WordReportHeader } from "../Components";

const totalCellStyle = {
  color: "#eeeeee",
  border: "none",
};

const totalRow = (headers, totalText, cost, noOfUnits, backgroundColor) => ({
  ...headers.reduce(
    (prev, current) => ({
      ...prev,
      [current.key]: "",
      [current.key + "Props"]: { hidden: true },
    }),
    {}
  ),
  timesheetDate: totalText,
  timesheetDateProps: {
    style: {
      ...totalCellStyle,
      backgroundColor,
      width: (100 * 3) / headers.length + "%",
    },
    colSpan: 3,
  },
  cost: displayCost(cost),
  costProps: {
    style: {
      ...totalCellStyle,
      backgroundColor,
      width: 100 / headers.length + "%",
    },
  },
});

export default ({
  clientName,
  timesheetDate,
  totalCost,
  totalUnits,
  gridData,
  headingType,
  headers: initHeaders,
}) => {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (initHeaders) {
      setHeaders(
        initHeaders.map((c) => ({
          ...c,
          headerStyle: { width: 100 / initHeaders.length + "%" },
        }))
      );
    }
  }, [initHeaders]);

  return (
    <>
      <WordReportHeader heading="Client Work in progress (WIP)" />
      <table
        style={{ border: "none", width: "100%" }}
        cellSpacing={5}
        cellPadding={0}
      >
        <tr>
          <td style={{ border: "none", width: "30%" }}>
            <WordLabel
              label="Client"
              value={clientName}
              backgroundColor={LabelColor.Blue}
            />
          </td>
          <td style={{ border: "none", width: "50%" }}>
            <WordLabel
              label="All WIP up to and including"
              value={displayDate(timesheetDate)}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        {gridData.map((c, i) => (
          <React.Fragment key={i}>
            <tr>
              <td style={{ border: "none" }} colSpan={2}>
                <WordLabel
                  label={headingType}
                  value={c.heading}
                  backgroundColor={LabelColor.LightBlue}
                />
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }} colSpan={2}>
                <WordTable
                  headers={headers}
                  data={c.gridData}
                  tableProps={{ cellSpacing: 0 }}
                />
              </td>
            </tr>
            <tr>
              <td style={{ border: "none" }} colSpan={2}>
                <WordTable
                  headers={headers}
                  hideHeaders
                  tableProps={{ cellSpacing: 0 }}
                  data={[
                    totalRow(
                      headers,
                      `Totals of: ${c.heading}`,
                      c.totalCost,
                      c.totalUnits,
                      LabelColor.LightBlue
                    ),
                  ]}
                />
              </td>
            </tr>
          </React.Fragment>
        ))}
        <tr>
          <td style={{ border: "none" }} colSpan={2}>
            <WordTable
              headers={headers}
              tableProps={{ cellSpacing: 0 }}
              hideHeaders
              data={[
                totalRow(
                  headers,
                  `Totals of: ${clientName}`,
                  totalCost,
                  totalUnits,
                  LabelColor.Blue
                ),
              ]}
            />
          </td>
        </tr>
      </table>
    </>
  );
};
