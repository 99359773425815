import { useMemo } from "react";
import { Timesheet } from "../../utils/timesheetConstant";
const { default: useCustomGrid } = require("./useCustomGrid");
const { useSelector } = require("react-redux");

const { RATE_GRID_ID } = Timesheet;

const useRateHook = () => {
  const { userRates } = useSelector((state) => state.common);
  const rateData = useCustomGrid(RATE_GRID_ID, userRates);
  const defaultRate = useMemo(() => {
    if (userRates) return userRates.find((x) => x.isDefault);
  }, [userRates]);

  const rateProps = {
    actionProps: {
      headers: [
        { key: "rate", text: "Rate" },
        { key: "isDefault", text: "IsDefault" },
      ],
      ...rateData,
    },
  };

  return {
    rateProps,
    defaultRate,
  };
};

export default useRateHook;
