import React from "react";
import { formatAPIDate, displayDate } from "./dateHelper";
import { sumBy } from "lodash";
import { Timesheet as Constant } from "../utils/timesheetConstant";
import { GridAction } from "../utils/GridAction";
import { formatCost, displayCost, formatNegativeCost } from "./functions";
import { AppConstant } from "../utils/constant";

const { DAYS_VALUE, DATE_NAME } = Constant;

class TimesheetHelper {
  isLastRowFilled = (gridData, timesheetTypeId) => {
    const timesheetData = gridData.filter(
      (c) => c.timesheetTypeId == timesheetTypeId
    );
    const length = timesheetData.length;
    let lastRow;
    if (length > 0) lastRow = timesheetData[length - 1].workDetails;

    if (!lastRow || lastRow.some((s) => s[DAYS_VALUE])) return true;
  };

  addTimesheetRow = (gridData, timesheetTypeId) => {
    const timesheetData = gridData.filter(
      (c) => c.timesheetTypeId == timesheetTypeId
    );
    const length = timesheetData.length;
    let lastRow;
    if (length > 0) lastRow = timesheetData[length - 1];

    if (!lastRow || lastRow.rate) {
      let data = gridData.slice();
      data.push({
        rowId: data.length,
        timesheetTypeId,
        clientCode: "",
        clientName: "",
        activityName: "",
        activityDesc: "",
        rate: "",
        workDetails: [{ [DATE_NAME]: "" }],
      });
      return data;
    }
  };

  addDaysRow = (gridData, timesheetTypeId, weekDays) => {
    if (weekDays && this.isLastRowFilled(gridData, timesheetTypeId)) {
      let data = gridData.slice();
      data.push({
        workDetails: weekDays.map((c) => ({
          ...c,
          [DATE_NAME]: formatAPIDate(c.weekDate),
        })),
        rowId: data.length,
        timesheetTypeId,
        clientCode: "",
        clientName: "",
        activityName: "",
        activityDesc: "",
      });
      return data;
    }
  };

  getTotalRow = (data, weekDays, styleProps) => {
    let totalRow = weekDays.map((c) => ({
      key: c.key,
      headerProps: { style: styleProps },
      text: sumBy(
        data
          .filter((s) => s.workDetails)
          .map((s) =>
            s.workDetails.filter((w) => w.key == c.key && w[DAYS_VALUE])
          )
          .flat(1),
        (s) => s[DAYS_VALUE]
      ),
    }));
    totalRow.push({ key: "total", text: sumBy(totalRow, (c) => c.text) });
    return totalRow;
  };

  getFutureRows(noOfRows, maxId) {
    maxId = isNaN(maxId) ? 0 : maxId;
    return [...new Array(noOfRows)].map((c, index) => ({
      timesheetDate: displayDate(new Date()),
      timesheetType: "Future",
      employee: null,
      rowId: maxId + index + 1,
      activityDesc: "",
      activityName: "",
      invoiceUnits: "",
      allotUnits: "",
      rate: "",
      included: {
        text: false,
        type: GridAction.BUTTON,
        actionProps: {
          icon: <i className="fa fa-minus"></i>,
        },
      },
    }));
  }

  calAmount({ rate, invoiceUnits, unitsPerHour, isExpenses, amount }) {
    if (isExpenses) return amount;
    else if (unitsPerHour > 0)
      return formatCost((rate * invoiceUnits) / unitsPerHour);

    return 0;
  }

  calPercent({ actualAmt, calculatedAmt }) {
    if (calculatedAmt > 0)
      return formatCost((actualAmt / calculatedAmt) * 100) + "%";

    return (0).toFixed(AppConstant.DECIMAL_POMITS) + "%";
  }

  getSurplusDeficit(c){
    return (c.actualAmt + c.expenses)- c.calculatedAmt;
  }

  calSurplusDeficit(data){
    return displayCost(this.getSurplusDeficit(data));
  }

  calTotal = (data, name) => displayCost(sumBy(data, (t) => t[name]));

  getTotal = (data, name) => sumBy(data, (t) => t[name]);
}

export default new TimesheetHelper();
