import {Client} from '../actionTypes/client';

const initialState={

}

export default (state=initialState,action)=>
{
    switch(action.type)
    {
        case Client.CLIENT_SUCCESS:
            return {
                ...state,
                ...action.result
            }
        default:
            return {
                ...state
            }
    }
}