import React from "react";
import { UserButton } from "../components/Button";

export default ({ handleAdd, handleEdit, gridData, enable }) => {
  const disabled = !enable && (!gridData || !gridData.some((c) => c.selected));

  return (
    <div className="tbBtn plm_cmn_cardbtn_div">
      <UserButton
        text="New"
        className="btn btn-primary"
        onClick={() => handleAdd(false)}
      />
      <UserButton
        text="Edit"
        className="btn btn-primary"
        onClick={() => handleEdit(true)}
        disabled={disabled}
      />
    </div>
  );
};
