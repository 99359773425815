import APIUtil from './APIUtil';

class ActivityCodeAPI
{
    
    addEditActivityCode(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Activity/AddEditActivityCode`;
        return fetch(url, options);
    }

    getActivityTypes(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Activity/GetActivityTypes`;
        return fetch(url, options);
    }

    deleteActivityCode(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Activity/DeleteActivityCode`;
        return fetch(url, options);
    }

    getActivityCodes(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Activity/GetActivityCodes`;
        return fetch(url, options);
    }
}

export default new ActivityCodeAPI();