import React, { useState, useEffect } from "react";
import { UserTable } from "../../../../components/Table";

export default ({ totalRow, text, isReport, wrapperClass, isExpenses }) => {
  const [totalData, setTotalData] = useState([]);
  const width = isExpenses ? "80%" : "50%";
  //const width = isExpenses ? 895 : '58.33%';

  useEffect(() => {
    if (isReport) {
      if (!isExpenses)
        totalRow = totalRow.map((c) => ({
          ...c,
          headerProps: {},
          headerActionProps: { className: "gap-cell" },
        }));
      else {
        totalRow.splice(0, 0, {
          key: "text",
          text,
          headerProps: {
            className: "th totalFirstCol",
            width,
          },
          headerActionProps: { className: "gap-cell f-700" },
        });
      }
      setTotalData(totalRow);
    }
  }, [totalRow, isExpenses]);

  if (isExpenses) {
    return (
      <UserTable
        headers={totalData}
        tblClass="table table-stripe mb20"
        tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
        wrapperClass={`totalRow ${wrapperClass ? wrapperClass : ""}`}
      />
    );
  }

  if (isReport) {
    return (
      <tr className={`totalRow ${wrapperClass ? wrapperClass : ""}`}>
        <td width="50%">
          <div className="gap-cell f-700">{text}</div>
        </td>
        <td width="50%" className="daysGridWrapper">
          <UserTable
            headers={totalData}
            tblClass="table table-stripe mb0"
            tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
            wrapperClass={`totalRow ${wrapperClass ? wrapperClass : ""}`}
          />
        </td>
      </tr>
    );
  }

  return (
    <div className="row">
      <div className="col-8">
        <div className="float-right totalRowHead">
          <h5>{text}</h5>
        </div>
      </div>
      <div className={"totalRow col-4"}>
        <UserTable headers={totalRow} />
      </div>
    </div>
  );
};
