import React from 'react';

export const SelectContainer=({title,children})=>(
    <div className="col-md-5 col-sm-12 mt-2">
        <label>{title}</label>
        {children}
    </div>
)

export const SelectHandler=({handleClick})=>(
        <div className="col-md-2 col-sm-12 mt-2 centerContent" style={{height: 110}}>
            <div className="form-section__field">
                <i 
                    className="fa fa-arrow-right" 
                    style={{fontSize:38,color:'blue'}}
                    onClick={()=>handleClick(true)}
                >
                </i>
                <br />
                <i  
                    className="fa fa-arrow-left" 
                    style={{fontSize:38,color:'red'}}
                    onClick={()=>handleClick(false)}
                >
                </i>
            </div>
        </div>
)

export default ({children})=>{

        return (
                <div className='row flex-sm-nowrap'>
                    {children}
                </div>
        )
}

