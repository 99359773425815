import React, { useState, useEffect } from "react";
import {
  UserCard,
  CardFoot,
  UserContainer,
  HalfContainer,
} from "../../components/Container";
import { UserList } from "../../components/List";
import useToggle from "../../hooks/useToggle";
import { AddBranch, AddPartner } from "./Components/Groups";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrgPartners,
  deleteOrgpartner,
  deletePartnerSource,
} from "../../actions/orgPartner";
import GridButton from "../../common/GridButton";
import useOrgResources from "../../hooks/useOrgResources";
import "./scss/Groups.scss";

const branchName = "branch",
  partnerName = "partner";

const Group = ({
  title,
  handleAdd,
  handleEdit,
  handleDelete,
  items,
  handleClick,
  name,
  value,
}) => {
  return (
    <HalfContainer>
      <UserCard title={title}>
        <GridButton handleAdd={handleAdd} enable={value} />
        <UserList
          items={items}
          handleClick={handleClick}
          name={name}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          showActions={true}
          value={value}
        />
        <CardFoot></CardFoot>
      </UserCard>
    </HalfContainer>
  );
};

export default (props) => {
  const dispatch = useDispatch();
  const { show: showAddBranch, toggle: toggleAddBranch } = useToggle();
  const { show: showAddPartner, toggle: toggleAddPartner } = useToggle();
  const { orgPartners } = useSelector((state) => state.orgPartner);
  const [branchList, setBranches] = useState([]);
  const [sourceList, setSources] = useState([]);
  const [state, setState] = useState({});
  const { partnerLabel, sourceLabel } = useOrgResources();

  let { branch, partner, selectedBranch, selectedPartner } = state;

  useEffect(() => {
    dispatch(getOrgPartners());
  }, []);

  useEffect(() => {
    if (orgPartners) {
      setBranches(
        orgPartners.map((c) => ({ text: c.partnerName, value: c.orgPartnerId }))
      );
      if (orgPartners.length > 0) {
        const orgPartnerId = selectedBranch
          ? selectedBranch.orgPartnerId
          : orgPartners[0].orgPartnerId;
        handleChange(branchName, orgPartnerId);
      } else handleChange(branchName, null);
    }
  }, [orgPartners]);

  useEffect(() => {
    if (selectedBranch) {
      setSources(
        selectedBranch.sources.map((c) => ({
          value: c.partnerSourceId,
          text: c.sourceName,
        }))
      );
      handleChange(partnerName, null);
    } else {
      setSources([]);
    }
  }, [selectedBranch]);

  const handleChange = (name, value) => {
    if (name == branchName)
      selectedBranch = orgPartners.find((c) => c.orgPartnerId == value);
    else if (name == partnerName)
      selectedPartner = sourceList.find((c) => c.value == value);

    setState((state) => ({
      ...state,
      [name]: value,
      selectedBranch,
      selectedPartner,
    }));
  };

  const handleAddEditBranch = (value) => {
    handleChange(branchName, value);
    toggleAddBranch();
  };

  const handleAddEditPartner = (value) => {
    handleChange(partnerName, value);
    toggleAddPartner();
  };

  const deletePartner = (value) => {
    dispatch(deleteOrgpartner(value));
  };

  const deleteSource = (value) => {
    dispatch(deletePartnerSource(value));
  };

  return (
    <>
      <UserContainer>
        <Group
          title={partnerLabel + " List"}
          handleAdd={handleAddEditBranch}
          handleEdit={handleAddEditBranch}
          items={branchList}
          handleClick={handleChange}
          name={branchName}
          value={branch}
          handleDelete={deletePartner}
        />
        <Group
          title={`${sourceLabel} List for ${
            selectedBranch ? selectedBranch.partnerName : ""
          }`}
          items={sourceList}
          handleClick={handleChange}
          name={partnerName}
          value={partner}
          handleAdd={handleAddEditPartner}
          handleEdit={handleAddEditPartner}
          handleDelete={deleteSource}
        />
      </UserContainer>
      {showAddBranch && (
        <AddBranch
          title={"Add " + partnerLabel}
          showAddBranch={showAddBranch}
          toggleModal={toggleAddBranch}
          selectedBranch={selectedBranch}
        />
      )}
      {showAddPartner && (
        <AddPartner
          title={"Add " + sourceLabel}
          showAddPartner={showAddPartner}
          toggleModal={toggleAddPartner}
          selectedPartner={selectedPartner}
          selectedBranch={selectedBranch}
        />
      )}
    </>
  );
};
