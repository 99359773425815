import { Client } from "../actionTypes/client";

export const addClientView = (payload) => ({
  type: Client.ADD_CLIENT_VIEW,
  payload,
});

export const addEditClient = (payload) => ({
  type: Client.ADD_CLIENT,
  payload,
});

export const getClients = (payload) => ({
  type: Client.GET_CLIENTS,
  payload,
});

export const getClientSelectList = (payload) => ({
  type: Client.GET_CLIENT_SELECT_LIST,
  payload,
});

export const getClientsWIP = (payload) => ({
  type: Client.GET_CLIENTS_WIP,
  payload,
});

export const deleteClient = (payload) => ({
  type: Client.DELETE_CLIENT,
  payload,
});

export const setClient = (result) => ({
  type: Client.CLIENT_SUCCESS,
  result,
});
