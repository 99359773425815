import { takeLatest, call, put } from "redux-saga/effects";
import InvoicesAPI from "../api/InvoicesAPI";
import { Invoice } from "../actionTypes/invoice";
import callAPI from "./common";
import { Common } from "../actionTypes/common";
import { push } from "connected-react-router";
import { RouteConstant } from "../utils/routeConstant";

function* createInvoiceView(action) {
  yield call(callAPI, InvoicesAPI.createInvoiceView, action, setInvoiceData);
}

function* createInvoice(action) {
  yield call(callAPI, InvoicesAPI.createInvoice, action, onInvoiceCreated);
}

function* onInvoiceCreated() {
  yield put(push(RouteConstant.INVOICES));
}

function* validateInvoiceNo(action) {
  yield call(callAPI, InvoicesAPI.validateInvoiceNo, action, action.cb);
}

function* getInvoices(action) {
  yield call(callAPI, InvoicesAPI.getInvoices, action, setInvoiceData);
}

function* getInvoiceDetails(action) {
  yield call(callAPI, InvoicesAPI.getInvoiceDetails, action, setResult);
}

function* updateInvoice(action) {
  yield call(callAPI, InvoicesAPI.updateInvoice, action, setResult);
}

function* setInvoiceData({ common, invoiceData }) {
  yield put({ type: Common.SET_COMMON_DATA, result: common });
  yield call(setResult, invoiceData);
}

function* addTransactionView(action) {
  yield call(
    callAPI,
    InvoicesAPI.addTransactionView,
    action,
    setTransactionData
  );
}

function* getTransactions(action) {
  yield call(callAPI, InvoicesAPI.getTransactions, action, setTransactionData);
}

function* setTransactionData({ common, transactionData }) {
  yield put({ type: Common.SET_COMMON_DATA, result: common });
  yield call(setResult, transactionData);
}

function* setResult(result) {
  yield put({ type: Invoice.INVOICE_SUCCESS, result });
}

export default function* rootSaga() {
  yield takeLatest(Invoice.CREATE_INVOICE_VIEW, createInvoiceView);
  yield takeLatest(Invoice.ADD_TRANSACTION_VIEW, addTransactionView);
  yield takeLatest(Invoice.GET_TRANSACTIONS, getTransactions);
  yield takeLatest(Invoice.CREATE_INVOICE, createInvoice);
  yield takeLatest(Invoice.GET_INVOICES, getInvoices);
  yield takeLatest(Invoice.GET_INVOICE_DETAILS, getInvoiceDetails);
  yield takeLatest(Invoice.VALIDATE_INVOICE_NO, validateInvoiceNo);
  yield takeLatest(Invoice.UPDATE_INVOICE, updateInvoice);
}
