import React from 'react';
import { Error, UserLabel } from '../Label';
import {AppConstant} from '../../utils/constant';

const Select=(props)=>
{
    const {disabled,id,className,name,value,onChange,placeholder
        ,addFirstItem,errors,options,firstItemText}=props;
    const error=errors?errors[name]:null;
    return(
        <div className="form-section">
            <div className="form-section__field">
                <select 
                    id={id}
                    className={"form-input form-control"+(className?" "+className:"")} 
                    name={name} 
                    value={value==null?"":value} 
                    onChange={onChange}
                    autoComplete="off"
                    disabled={disabled}
                >
                    {placeholder &&
                        <option value="">{placeholder}</option>
                    }
                    {addFirstItem && 
                      <option value="">
                          {firstItemText?firstItemText:AppConstant.FIRST_ITEM_TEXT}
                      </option>
                    }
                    {options && options.map((option, index) => 
                        <option 
                            key={index} 
                            value={option.value}
                            {...option.props}
                        >
                            {option.text}
                        </option>
                    )}
                </select>
                {error &&
                    <Error
                        error={error}
                    />
                }
        </div>
    </div>
    );
}

export default (props)=>
{

    const onSelectChange=(event)=> {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        props.onChange(name, value);
    }

    const {label}=props;
    return (
        <div className='form-group'>
            {label &&  
                <UserLabel
                    {...props}
                />
            }
            <Select
                {...props}
                onChange={onSelectChange.bind(this)}
            />
        </div>
    )
};