import React from "react";
import { UserCard } from "../../components/Container";
import { TabPanel, UserTab } from "../../components/Tabs";
import { ActivityTab } from "./Components/ActivityCodes";
import useActivityTypes from "../../hooks/useActivityTypes";
import "./scss/ActivityCodes.scss";

export default (props) => {
  const { activityTypes, defaultActivity } = useActivityTypes();

  return (
    <div class="plm_actcodes_list_div">
      <div class="container-fluid prn_cfuid">
        <div class="row prn_row">
          <div class="col-12 prn_col12">
            <UserCard title="Activity Codes">
              <div class="plm_cmn_tabs plm_tabs_typ1">
                <TabPanel
                  defaultTab={defaultActivity}
                  unmountOnExit={true}
                  mountOnEnter={true}>
                  {activityTypes &&
                    activityTypes.map((c) => (
                      <UserTab eventKey={c.text} title={c.text} key={c.value}>
                        <ActivityTab
                          key={c.value}
                          activityTypeId={c.value}
                          activityType={c.text}
                        />
                      </UserTab>
                    ))}
                </TabPanel>
              </div>
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
