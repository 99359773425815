import React, { useEffect, useState } from 'react';
import { CheckBox } from '../../components/CheckBox';
import { useSelector } from 'react-redux';
import { updateInTree, getFromTree } from '../../libs/functions';
import {cloneDeep} from 'lodash';

const Screen=({item:{
    pageName,selected,pageId
},children,handleChange})=>{

    const onChange=(name,value)=>
        handleChange(pageId,name,value);

    return <>
            <div className='row'>
                <div className='col-4'>
                    {pageName}
                </div>
                <div className='col-4'>
                    <CheckBox
                        name="selected"
                        value={selected}
                        onChange={onChange}
                    />
                </div>
            </div>
            {children.map((s,cIndex)=>(
                <Screen
                    key={cIndex}
                    {...s}
                    handleChange={handleChange}
                />
            ))}
        </>
}


export default ({handleChange,permissionIdList})=>{
  
    const {
        pages:{
            permissions:initPermissions
        }
    }=useSelector(state=>state);
    let [permissions,setPermissions]=useState([]);

    const handleCheck=(pageId,name,value)=>{
        const updatedPermissions=updateInTree(
                cloneDeep(permissions),'pageId',[pageId],name,value,true
            );
        let permissionIds=getFromTree(updatedPermissions,name,true,'pageId');

        handleChange(permissionIds);
    }

    useEffect(()=>{
        setPermissions(initPermissions.slice());
    },[]);

    useEffect(()=>{
        if(permissionIdList)
        {
            const permissions=updateInTree(cloneDeep(initPermissions),'pageId',permissionIdList,'selected',true);
            setPermissions(permissions);
        }
    },[permissionIdList,initPermissions]);
    
    return (
        <div className='row'>
            <div className='col-4'>
                Page Group Name
            </div>
            <div className='col-4'>
                Page Name
            </div>
            <div className='col-4'>
                Allow/Deny
            </div>
            {permissions && permissions.map((c,index)=>(
                <React.Fragment key={index}>
                    <div className='col-4'>
                        {c.item.pageName}
                    </div>
                    <div className='col-8'>
                        <Screen
                            key={c.pageId}
                            {...c}
                            handleChange={handleCheck}
                        />
                        
                    </div>
                </React.Fragment>
            ))}
        </div>
    )
}
