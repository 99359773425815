import React, { useEffect } from "react";
import { UserCard } from "../../../components/Container";
import ScreenButton from "../../../common/ScreenButton";
import { useDispatch, useSelector } from "react-redux";
import useGridHook from "../../../hooks/useGridHook";
import { maxBy } from "lodash";
import { setInvoice } from "../../../actions/invoice";
import BillingGrid from "./BillingGrid";
import { UserButton } from "../../../components/Button";
import "./BillingNarratives.scss";
import { Client } from "../Common";

const rowId = "rowId";

export default ({ actionProps }) => {
  const dispatch = useDispatch();
  const { billingNarratives } = useSelector((state) => state.invoice);

  const { gridData, handleRowChange, setGridData } = useGridHook(rowId);

  const setBillingData = () => {
    if (billingNarratives) setGridData(billingNarratives);
    else
      setGridData(
        [...new Array(5)].map((c, index) => ({
          billNarrativesDesc: "",
          isDeleted: false,
          [rowId]: index,
          deleteBtn: "Delete",
        }))
      );
  };

  useEffect(() => setBillingData(), []);

  const addRow = () => {
    const maxId = maxBy(gridData, (c) => c[rowId])?.[rowId];
    setGridData(
      gridData.concat([
        {
          rowId: maxId >= 0 ? maxId + 1 : 0,
          billNarrativesDesc: "",
          isDeleted: false,
          deleteBtn: "Delete",
        },
      ])
    );
  };

  const handleDelete = (id) => {
    setGridData(gridData.filter((c) => c[rowId] != id));
  };

  const handleSave = () => {
    dispatch(
      setInvoice({
        billingNarratives: gridData.filter(
          (c) => c.orgBillingId || c.billNarrativesDesc
        ),
      })
    );
    actionProps.handleNext();
  };

  return (
    <div class="plm_cmn_wiz_step plm_invwiz_stepw4">
      <UserCard title="4. Billing Narratives">
        <Client />
        <div class="plm_cmn_wiz_content plm_inv_wiz_step4">
          <div className="plm_cmn_cardbtn_alone_div plm_addnarate">
            <UserButton
              text="Add"
              icon={<i className="fa fa-plus"></i>}
              onClick={addRow}
            />
          </div>
          <BillingGrid
            gridData={gridData}
            rowId={rowId}
            handleRowChange={handleRowChange}
            handleDelete={handleDelete}
          />
        </div>
      </UserCard>
      <ScreenButton {...actionProps} handleNext={handleSave} />
    </div>
  );
};
