import React, { useEffect, useState } from "react";
import { TimesheetGrid } from "../Expenses";
import useToast from "../../../../hooks/useToast";
import TimesheetHelper from "../../../../libs/TimesheetHelper";
import { sumBy } from "lodash";
import { Timesheet as Constant } from "../../../../utils/timesheetConstant";
import { TotalRow } from "../Chargeable";
import { displayCost } from "../../../../libs/functions";

const { DATE_NAME, CLIENT_GRID_ID, ACTIVITY_GRID_ID } = Constant;

export default (props) => {
  const { showError } = useToast();
  const [total, setTotal] = useState(null);
  const {
    gridData,
    initData,
    handleRowChange,
    setGridData,
    timesheetTypeId,
    rowId,
    startDate,
    endDate,
    disableTimesheet,
    isReport,
  } = props;

  useEffect(() => {
    addRow();
  }, [startDate, initData]);

  useEffect(() => {
    setTotal(sumBy(gridData, (c) => c.rate));
  }, [gridData]);

  const addRow = () => {
    const data = TimesheetHelper.addTimesheetRow(initData, timesheetTypeId);
    if (data) setGridData(data);
  };

  const handleGridChange = (id, data) => {
    const index = initData.findIndex((c) => c[rowId] == id);
    if (index >= 0) handleRowChange(id, { ...data, timesheetTypeId });
  };

  const validate = (data) => {
    if (data[CLIENT_GRID_ID]) {
      if (data[ACTIVITY_GRID_ID]) {
        if (data.workDetails.some((c) => c[DATE_NAME])) return true;
        else showError("Please select date");
      } else showError("Please select job");
    } else showError("Please select client");
  };

  const handleChange = (id, name, value) => {
    const index = initData.findIndex((c) => c[rowId] == id);
    let data = initData[index];
    if (name == DATE_NAME) {
      const { workDetails } = data;
      data = {
        ...data,
        workDetails: workDetails.map((c) => ({ ...c, [name]: value })),
      };
      handleRowChange(id, data);
    } else if (validate(data)) {
      data = {
        ...data,
        [name]: value,
      };
      handleRowChange(id, data);
    }
  };

  return (
    <div className="total_expenses_content">
      <div className="total_exp_coldiv">
        <div className="tdivexps_top col-md-12 col-xs-12">
          <TimesheetGrid
            rowId={rowId}
            timesheets={gridData}
            handleRowChange={handleGridChange}
            handleChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            isReport={isReport}
            disableTimesheet={disableTimesheet}
            timesheetTypeId={timesheetTypeId}
          />
        </div>
        {isReport ? (
          <TotalRow
            text="Expenses Total"
            totalRow={[
              {
                text: displayCost(total),
                headerProps: { style: { width: 94 } },
              },
            ]}
            isReport={isReport}
            isExpenses={true}
          />
        ) : (
          <div className="tdivexps_btm">
            <div className="tdivexps_btm_inner">Total {total}</div>
          </div>
        )}
      </div>
    </div>
  );
};
