export const downloadFile = ({ file }) => {
  const { fileContents: bas64String, fileDownloadName: fileName } = file;
  var blob = convertByteToBlob(bas64String);
  saveAs(blob, fileName);
};

export function readFile(path, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      if (xhr.status == 200) {
        callback(xhr.responseText);
      } else {
        callback(null);
      }
    }
  };
  xhr.open("GET", path);
  xhr.send();
}

function convertByteToBlob(bas64String) {
  var byteArray = Uint8Array.from(atob(bas64String), (c) => c.charCodeAt(0));
  return new Blob([byteArray], { type: "application/octet-stream" });
}

export function saveAs(blob, fileName) {
  var url = window.URL.createObjectURL(blob);
  var anchorElem = document.createElement("a");
  anchorElem.style = "display: none";
  anchorElem.href = url;
  anchorElem.download = fileName;
  document.body.appendChild(anchorElem);
  anchorElem.click();
  document.body.removeChild(anchorElem);
  setTimeout(function () {
    window.URL.revokeObjectURL(url);
  }, 1000);
}
