import React, { useEffect } from "react";
import { UserTable, CommonTable } from "../../../components/Table";
import useGridHook from "../../../hooks/useGridHook";
import useOrgResources from "../../../hooks/useOrgResources";
import { useSelector } from "react-redux";
import { groupBy, map, sumBy } from "lodash";
import { CustomDisplayLabel } from "../../../components/Label";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import "./IncomeGrid.scss";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";
import { PartnerLabel, SourceLabel } from "../Components";
import { displayCost, displayPercent } from "../../../libs/functions";
import TableCell from "../../../components/Table/Cell";
import { normalizeData } from "../../../libs/functions";
import {AppConstant} from '../../../utils/constant';

const rowId = "";

const TotalRow = ({
  text,
  expenses,
  calculatedAmt,
  actualAmt,
  className,
  taxAmount,
  totalAmount,
  surplusDeficitAmt,
}) => {
  return (
    <UserTable
      tblClass="table table-stripe mb0  feeIncomeSummaryTotalRow"
      tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
      wrapperClass={`totalRow ${className}`}
      headers={[
        { text, colSpan:2 },
        // { text: "" },
        { text: expenses },
        { text: calculatedAmt },
        { text: actualAmt },
        { text: taxAmount },
        { text: totalAmount },
        { text: surplusDeficitAmt },
        { text: "" },
      ].map((c) => ({ ...c, headerActionProps: { className: "gap-cell" } }))}
    />
  );
};

export default ({ isReport, reportType }) => {
  const { gridData, setGridData } = useGridHook();
  const { invoices } = useSelector((state) => state.invoice);
  const { theme } = useUserDetail();
  const { WORD, EXCEL } = usePrintHook({});

  const { partnerLabel, sourceLabel } = useOrgResources();

  const calTotal = (data, surplus=false) =>{ 
    var expenses = TimesheetHelper.getTotal(data, "expenses");
    var actualAmt=TimesheetHelper.getTotal(data, "actualAmt");
    var calculatedAmt= TimesheetHelper.getTotal(data, "calculatedAmt");
    var surplusAmt= TimesheetHelper.calSurplusDeficit({actualAmt, calculatedAmt: calculatedAmt, expenses});
    var percent = TimesheetHelper.calPercent({actualAmt, calculatedAmt: calculatedAmt + expenses});
    return {
    expenses: displayCost(expenses),
    calculatedAmt: displayCost(calculatedAmt),
    actualAmt: displayCost(actualAmt),
    taxAmount: TimesheetHelper.calTotal(data, "taxAmount"),
    totalAmount: displayCost(TimesheetHelper.getTotal(data, "totalAmount")),
    surplusDeficitAmt: surplus?surplusAmt:"",
    percent: percent
  };
}

  useEffect(() => {
    if (invoices) {
      const group = groupBy(invoices, (c) => c.orgPartnerId);
      setGridData(
        map(group, (c) => {
          const { orgPartner } = c[0];
          const sourceGroup = groupBy(c, (s) => s.partnerSourceId);
          return {
            gridData: map(sourceGroup, (s) => {
              const { partnerSource } = s[0];
              const clientGroup = groupBy(s, (cl) => cl.clientId);

              return {
                orgPartner,
                partnerSource,
                gridData: map(clientGroup, (cl) => {
                  const totalData = calTotal(cl, true);
                  return {
                    ...cl[0],
                    ...totalData
                  };
                }),
                ...calTotal(s),
              };
            }),
            orgPartner,
            ...calTotal(c),
          };
        })
      );
    }
  }, [invoices]);

  const headers = [
    { key: "clientCode", text: "Ref" },
    { key: "clientName", text: "Client Name" },
    { key: "expenses", text: "Expenses" },
    { key: "calculatedAmt", text: "Costing" },
    { key: "actualAmt", text: "Net Inv" },
    { key: "taxAmount", text: "Tax" },
    { key: "totalAmount", text: "Gross" },
    { key: "surplusDeficitAmt", text: "Surplus/Deficit" },
    { key: "percent", text: "%" },
  ].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

    const getDataTable = (data, totalRows=[]) => {

          var rows=[];

            {normalizeData(data, rowId).map((row, i) => 
                rows.push(<tr
                    className="tbody"
                    key={i}>
                    {headers.map((prop) => 
                      <TableCell
                        {...prop}
                        key={prop.key + "" + i}
                        data={row.find((r) => r.key === prop.key)}
                        selected={row.find((r) => r.key === "selected" && r.text)}
                      />
                    )}
                  </tr>)
              )};

            {
              totalRows.map((totalRow)=> {
                  rows.push(<tr className="tbody totalRow-wrap">
                    {
                      totalRow.map((t)=> <td className={`td ${t.className}`} colspan={t.colSpan}><div className="gap-cell">{t.text}</div></td>)
                    }
                  </tr>)
              })
            }

      return rows;
    }

  const className = isReport ? "col-12 col-md-12 col-xs-12" : "col-12";
  var datatable=[];

  return (
    <ReportContainer hasData={gridData.length > 0}>
      {gridData.map((g, index) => (
        <div className="feeIncomeSummaryRpt" key={`P${index}`}>
          {g.gridData.map((c, cIndex) => 
          {
            return (
              <React.Fragment key={`S${index}`}>
                {reportType == WORD || reportType == EXCEL ? (
                  <React.Fragment>
                    <div className={className + " labelWrapper1"}>
                      <CustomDisplayLabel
                        label={partnerLabel}
                        value={c.orgPartner}
                        customClass="labelWrapper1"
                      />
                    </div>
                    <div className={className + " labelWrapper"}>
                      <CustomDisplayLabel
                        label={sourceLabel}
                        value={c.partnerSource}
                        customClass="labelWrapper"
                      />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <PartnerLabel partnerName={c.orgPartner} />
                    <SourceLabel sourceName={c.partnerSource} />
                  </React.Fragment>
                )}
  
                {
                  datatable= [datatable, getDataTable(c.gridData, [
                    [
                      { text:`Totals of ${sourceLabel}: ${c.partnerSource}`, colSpan:2, className: "total back-blue" },
                      { text: c.expenses, className: "total back-blue" },
                      { text: c.calculatedAmt, className: "total back-blue" },
                      { text: c.actualAmt, className: "total back-blue" },
                      { text: c.taxAmount, className: "total back-blue" },
                      { text: c.totalAmount, className: "total back-blue" },
                      { text: c.surplusDeficitAmt, className: "total back-blue" },
                      { text: "", className: "total back-blue" },
                    ]])]            
                }

                {
                  cIndex+1 < g.gridData.length &&
                  <CommonTable tblClass="feeIncomeSummaryGrid" headers={headers} rows={datatable} />               
               }                
  
              </React.Fragment>
            )
          }
          )}

          {
              datatable=[datatable, (<tr className="tbody totalRow-wrap">
              <td className={`td total back-purple`} colspan="2"><div className="gap-cell">{`Totals of ${partnerLabel}: ${g.orgPartner}`}</div></td>
              <td className={`td total back-purple`}><div className="gap-cell">{g.expenses}</div></td>
              <td className={`td total back-purple`}><div className="gap-cell">{g.calculatedAmt}</div></td>
              <td className={`td total back-purple`}><div className="gap-cell">{g.actualAmt}</div></td>
              <td className={`td total back-purple`}><div className="gap-cell">{g.taxAmount}</div></td>
              <td className={`td total back-purple`}><div className="gap-cell">{g.totalAmount}</div></td>
              <td className={`td total back-purple`}><div className="gap-cell">{g.surplusDeficitAmt}</div></td>
              <td className={`td total back-purple`}><div className="gap-cell">&nbsp;</div></td>
            </tr>)]               
          }
    
            <CommonTable tblClass="feeIncomeSummaryGrid" headers={headers} rows={datatable} />

        </div>
      ))}
    </ReportContainer>
  );
};
