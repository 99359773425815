import React, { useState, useRef, useEffect } from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./navigation.scss";
import useTheme from "../../hooks/useTheme";
import Navbar from "react-bootstrap/Navbar";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { someInTree } from "../../libs/functions";
import { RouteConstant } from "../../utils/routeConstant";

const hideSubMenu = ["Setup", "Reports", "Invoices"];

const MainMenu = ({ page, refresh, setRefresh }) => {
  const {
    item: { pageName, pageUrl },
    children,
  } = page;
  const hasChildren =
    children && children.length > 0 && !hideSubMenu.includes(pageName);
  const { pathname } = useLocation();
  const node = useRef();
  const { show, setShow } = useOnClickOutside(node);

  useEffect(() => {
    setShow(false);
  }, [refresh]);

  // MainMenu.handleClickOutside=()=>{
  //     setShow(hasChildren);
  // }

  const handleClick = (e) => {
    if (hasChildren) {
      setShow(true);
      e.preventDefault();
    } else {
      setRefresh(!refresh);
      // history.push('/'+pageUrl);
    }
  };

  const path = pathname.substring(1);

  return (
    <li
      data-menuname={"mnm_" + pageName}
      ref={node}
      className={
        path == pageUrl || someInTree(children, "pageUrl", path) ? "active" : ""
      }>
      <NavLink onClick={handleClick} to={`${hasChildren ? "" : "/" + pageUrl}`}>
        <span className="linkIc">
          <i className={"fa fa-" + pageName?.replace(" ", "") + " icon"}></i>
        </span>
        <span className="linkText">
          {pageName}
          {hasChildren && (
            <span className="LinkIcRight">
              <i className="fa fa-chevron-down"></i>
            </span>
          )}
        </span>
      </NavLink>
      {hasChildren && show && (
        <ul className="subMenuList">
          {children.map((subPage, index) => (
            <MainMenu
              key={index}
              page={subPage}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default () => {
  const { permissions } = useSelector((state) => state.pages);
  const { theme } = useTheme();
  const [refresh, setRefresh] = useState(false);
  const { pathname } = useLocation();

  return (
    <div className={`navigation${pathname==RouteConstant.REPORTS?' reportHeader':''}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={theme + "sideBar"}>
              <Navbar collapseOnSelect expand="md" className="navtgldiv">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <ul className="inner_navmenu">
                    {permissions &&
                      permissions.map((page, key) => (
                        <MainMenu
                          page={page}
                          parentUrl={page.item.pageUrl}
                          key={key}
                          refresh={refresh}
                          setRefresh={setRefresh}
                        />
                      ))}
                  </ul>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
