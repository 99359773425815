import { Timesheet } from "../../utils/timesheetConstant";
import { useState, useEffect } from "react";
const { default: useCustomGrid } = require("./useCustomGrid");
const { useSelector } = require("react-redux");

const { EMPLOYEE_GRID_ID } = Timesheet;

const useEmployeeHook = () => {
  const { employeeList: initEmployeeList } = useSelector(
    (state) => state.common
  );
  const [employeeList, setEmployeeList] = useState([]);
  const employeeData = useCustomGrid(EMPLOYEE_GRID_ID, employeeList);

  useEffect(() => {
    if (initEmployeeList)
      setEmployeeList(
        initEmployeeList.map((c) => ({
          ...c,
          rateUnits: c.rate + "/" + c.unitsPerHour,
        }))
      );
  }, [initEmployeeList]);

  const employeeProps = {
    actionProps: {
      headers: [
        { key: "employee", text: "Employeee" },
        { key: "rateUnits", text: "Rate/Units" },
      ],
      ...employeeData,
    },
  };

  return {
    employeeProps,
  };
};

export default useEmployeeHook;
