import React from "react";
import { TimesheetGrid } from ".";
import { ReportHeader } from "../Components";
import useOrgResources from "../../../hooks/useOrgResources";
import { AppConstant } from "../../../utils/constant";
import moment from "moment";
import { DisplayLabel } from "../../../components/Label";
import usePrintHook from "../../hooks/usePrintHook";

const { DATE_DISPLAY_FORMAT } = AppConstant;

export default (props) => {
  const { startDate, endDate, orgPartnerId, partnerSourceId, reportType } =
    props;
  const { partnerLabel, sourceLabel, orgPartner, partnerSource } =
    useOrgResources(orgPartnerId, partnerSourceId);
  const { WORD, EXCEL } = usePrintHook({});

  return (
    <>
      {reportType == WORD || reportType == EXCEL ? (
        <TimesheetGrid {...props} />
      ) : (
        <>
          <ReportHeader
            heading="Non-Submitted Timesheet Report"
            reportType={reportType}
          />

          <div class="header-row non_submitted_report_header">
            <div class="table-header w-25">
              <DisplayLabel
                  label="Date From"
                  value={moment(startDate).format(DATE_DISPLAY_FORMAT)}
                />
            </div>
            <div class="table-header w-25">
              <DisplayLabel
                  label="Date To"
                  value={moment(endDate).format(DATE_DISPLAY_FORMAT)}
                />
            </div>
            <div class="table-header w-25">
              <DisplayLabel label={partnerLabel} value={orgPartner} />
            </div>
            <div class="table-header w-25">
              <DisplayLabel label={sourceLabel} value={partnerSource} />
            </div>
          </div>
          <br />
          <div className="col-md-12 col-xs-12">
            <TimesheetGrid {...props} />
          </div>
        </>
      )}
    </>
  );
};
