import React from 'react';
import TextInput from '../TextInput/TextInput';
import {UserButton} from '../Button';
import { UserCropImage } from '../Image';


export default (props)=>{

    const {onChange,onError,multiple,name,file,fileSrc,
        handleSave,fileSrcName,fileName}=props;
    let croppedImage,showSaveBtn,
        src=process.env.PUBLIC_URL+'/assets/img/thumb/profile-company.png';
    if(file && file.fileDataAsBase64)
    {
        showSaveBtn=true;
        croppedImage=`data:image/jpeg;base64,${file.fileDataAsBase64}`;
    }
    if(fileSrc)
        src=`data:image/jpeg;base64,${fileSrc.fileDataAsBase64}`;

    return (
        <div className='row mx-3 form-group'>
            <div className="col-12 pl-0" style={{textAlign:'center'}}>
               <UserCropImage
                    src={src}
                    className='logo-img'
                    onChange={onChange}
                    fileName={fileName}
                    name={name}
                    croppedImage={croppedImage}
                    imageStyle={{width:150,height:150}}
               />
            </div>
            <div className="col-12 pl-0" style={{textAlign:'center'}}>
                <div className="logo-btn-wrap">
            <div className="" style={{marginTop:20}}>
                <TextInput
                    className="form-control form-input hidden"
                    name={fileSrcName?fileSrcName:"file"}
                    type="file"
                    id="file"
                    value=''
                    maxLength={50}
                    placeholder=""
                    label="Upload Logo"
                    labelClassName="btn btn-blue mt-0"
                    onChange={onChange}
                    multiple={multiple}
                    accept="image/x-png,image/gif,image/x-jpg,image/jpeg"
                />
            </div>
            {showSaveBtn &&
            <div className="" style={{marginTop:20}}>
                <UserButton  
                    text='Save'
                    className="btn btn-primary"
                    title='Save'
                    onClick={handleSave}
                />
            </div>
            }
            </div>
            </div>
    </div>
    )
}

