import { useMemo, useEffect, useCallback } from "react";
import useGridHook from '../../hooks/useGridHook';

const useCustomGrid=(rowId,data)=>{

    const {
        gridData
        ,handleRowClick
        ,setGridData
    }=useGridHook(rowId);

    useEffect(()=>{
        if(data)
            setGridData(data);
    },[data]);

    const getSelected=useCallback((key)=>{
        return gridData.filter(c=>c.selected).map(c=>c[key]);
    },[gridData]);

    return {
        getSelected,
        handleRowClick,
        data:gridData,
        rowId
    }
}

export default useCustomGrid;