import React from "react";
import { ClientGrid } from ".";
import { ReportHeader } from "../Components";
import useOrgResources from "../../../hooks/useOrgResources";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { displayDate } from "../../../libs/dateHelper";
import usePrintHook from "../../hooks/usePrintHook";
import { words } from "lodash";

export default (props) => {
  const {
    timesheetDate,
    orgPartnerId,
    partnerSourceId,
    reportTitle,
    reportType,
  } = props;
  const {
    partnerLabel,
    sourceLabel,
    orgPartner,
    partnerSource,
  } = useOrgResources(orgPartnerId, partnerSourceId);
  const { WORD, EXCEL } = usePrintHook({});

  return (
    <div className="clientWipSummaryRptContainer">
      <ReportHeader heading={reportTitle} reportType={reportType} />
      <section className="plm_pdf_report_wrap">
        {reportType == WORD || reportType == EXCEL ? (
          <table width="100%">
            <tr>
              <td width="50%">
                <CustomDisplayLabel
                  label="All WIP up to and including"
                  value={displayDate(timesheetDate)}
                  width={200}
                  customClass="plm_carep_top_first"
                />
              </td>
              <td>
                <CustomDisplayLabel
                  label={sourceLabel}
                  value={partnerSource}
                  customClass="plm_carep_top_first"
                />
              </td>
            </tr>

            <tr>
              <td width="50%"></td>
              <td>
                <CustomDisplayLabel
                  label={partnerLabel}
                  value={orgPartner}
                  customClass="plm_carep_top_first"
                />
              </td>
            </tr>
          </table>
        ) : (

          <div class="header-row">
            <div class="table-header w-30">
              <DisplayLabel
                label="All WIP up to and including"
                value={displayDate(timesheetDate)}
                width={240}
              />
            </div>
            <div class="table-header w-30">
              <DisplayLabel label={sourceLabel} value={partnerSource} />
            </div>
            <div class="table-header w-30">
              <DisplayLabel label={partnerLabel} value={orgPartner} />
            </div>
          </div>
        )}
        <div className="clientwip_grid" style={{ marginTop: 10 }}>
          <ClientGrid {...props} isReport={true} reportType={reportType} />
        </div>
      </section>
    </div>
  );
};
