import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserLabel, Error } from "../Label";
import { getMomentDate, formatAPIDate } from "../../libs/dateHelper";
import "./_date.scss";

const UserDatePicker = ({ value, onChange, placeholder, minDate, maxDate }) => {
  return (
    <DatePicker
      showPopperArrow={false}
      selected={value ? getMomentDate(value).toDate() : value}
      onChange={onChange}
      placeholderText={placeholder}
      dateFormat="dd/MM/yyyy"
      minDate={minDate ? getMomentDate(minDate).toDate() : minDate}
      maxDate={maxDate ? getMomentDate(maxDate).toDate() : maxDate}
      pref
      popperPlacement="top-end"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: "5px, 10px",
        },
      }}
    />
  );
};

const DateControl = (props) => {
  const { title } = props;

  return (
    <>
      {title ? (
        <div className="dateWrapper">
          <div className="dateTitle">Week {title}:</div>
          <UserDatePicker {...props} />
        </div>
      ) : (
        <UserDatePicker {...props} />
      )}
    </>
  );
};

export default (props) => {
  const handleChange = (value) => {
    const { onChange, name } = props;
    onChange(name, formatAPIDate(value));
  };

  const { label, errors, name } = props;
  const error = errors ? errors[name] : null;

  return (
    <>
      {label ? (
        <>
          <UserLabel {...props} />
          <DateControl {...props} onChange={handleChange.bind(this)} />
        </>
      ) : (
        <DateControl {...props} onChange={handleChange.bind(this)} />
      )}
      {error && <Error error={error} />}
    </>
  );
};
