import React from "react";
import { UserCard } from "../../../components/Container";
import { useSelector, useDispatch } from "react-redux";
import { DisplayLabel } from "../../../components/Label";
import ScreenButton from "../../../common/ScreenButton";
import TransactionPrevGrid from "./TransactionPrevGrid";
import { displayDate, formatAPIDate } from "../../../libs/dateHelper";
import { UserModal } from "../../../components/Modal";
import useToggle from "../../../hooks/useToggle";
import { createInvoice } from "../../../actions/invoice";
import "./PreviewScreen.scss";
import { formatNegativeCost } from "../../../libs/functions";
import { Client } from "../Common";

const InvoiceLabel = ({ label, value }) => (
  <div className="col-12 transparentLabel">
    <DisplayLabel label={label} value={value} />
  </div>
);

const InvliceNo = ({ invoiceNumber, invNoPrefix, invNoSuffix }) => (
  <DisplayLabel
    label="Invoice No"
    value={invNoPrefix + invoiceNumber + invNoSuffix}
    width="100%"
  />
);

export default ({ actionProps }) => {
  const dispatch = useDispatch();
  const {
    clientDetail,
    transactions,
    invoiceDetail,
    billingNarratives,
  } = useSelector((state) => state.invoice);
  const { show: showConfirmModal, toggle } = useToggle();

  const {
    invoiceDt,
    actualAmt,
    surplusDeficitAmt,
    taxAmount,
    taxValue,
    totalAmount,
    calculatedAmt,
  } = invoiceDetail;

  const selctedTransactions = transactions.filter((c) => c.included);

  const handleSubmit = () => {
    dispatch(
      createInvoice({
        ...clientDetail,
        ...invoiceDetail,
        transactions: selctedTransactions.map((c) => ({
          ...c,
          timesheetDate: formatAPIDate(c.timesheetDate),
        })),
        orgBillingList: billingNarratives,
      })
    );

    toggle();
  };

  return (
    <div class="plm_cmn_wiz_step plm_invwiz_stepw5">
      <div class="plm_cmn_wiz_content plm_inv_wiz_step5">
        <UserCard title="5. Preview">
          <Client />
          <div className="row">
            <div className="col-3 transparentLabel invoice-lbl">
              <InvliceNo {...invoiceDetail} />
            </div>
            <div className="col-4 invoice-lbl">
              <InvoiceLabel
                label="Invoice Date"
                value={displayDate(invoiceDt)}
              />
            </div>
          </div>
          <div
            className="row plm_feditrow invoice-preview"
            style={{ marginLeft: "10%" }}>
            <div className="col-12 text-blue">
              <div className="row">
                <InvoiceLabel
                  label="Fees as per Costing"
                  value={formatNegativeCost(calculatedAmt)}
                />
              </div>
            </div>
            <div className="col-12 value-bg">
              <div className="row">
                <InvoiceLabel
                  label="Actual Fee to Client"
                  value={formatNegativeCost(actualAmt)}
                />
              </div>
            </div>
            <div className="col-12 text-red">
              <div className="row">
                <InvoiceLabel
                  label="Surplus/Deficit"
                  value={formatNegativeCost(surplusDeficitAmt)}
                />
              </div>
            </div>
            <div className="col-12 value-bg">
              <div className="row">
                <InvoiceLabel label="Tax " value={taxValue} />
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <InvoiceLabel
                  label="Tax Amount"
                  value={formatNegativeCost(taxAmount)}
                />
              </div>
            </div>
            <div className="col-12 text-blue">
              <div className="row">
                <InvoiceLabel
                  label="Total Invoice"
                  value={formatNegativeCost(totalAmount)}
                />
              </div>
            </div>
          </div>
          <div class="plm_prview_mid">
            <div className="row prn_row">
              <div className="col-md-12 prn_colh trans-detail-tbl">
                <UserCard title="Transaction Details">
                  <TransactionPrevGrid transactions={selctedTransactions} />
                </UserCard>
              </div>
              <div className="col-md-12 prn_colh">
                <div className="plm_prview_list">
                  <UserCard title="Billing Narratives">
                    <ul>
                      {billingNarratives &&
                        billingNarratives.map((c) => (
                          <li key={c.orgBillingId}>{c.billNarrativesDesc}</li>
                        ))}
                    </ul>
                  </UserCard>
                </div>
              </div>
            </div>
          </div>
        </UserCard>
      </div>

      <ScreenButton
        {...actionProps}
        middleBtnText="Confirm"
        handleNext={toggle}
      />
      {showConfirmModal && (
        <UserModal
          show={showConfirmModal}
          handleClose={toggle}
          title="Create Invoice"
          handleSave={handleSubmit}>
          Are you sure you want to Save Invoice?
        </UserModal>
      )}
    </div>
  );
};
