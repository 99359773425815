import { useState } from 'react';
import useToast from './useToast';

const useGridHook=(rowId,handleDelete)=>{

    const [gridData,setGridData]=useState([]);
    const {showError}=useToast();

    const handleChange=(id,name,value)=>{
        const index=gridData.findIndex(c=>c[rowId]===id);
        if(index>=0)
        {
            let data=gridData.slice();
            data[index]={...data[index],[name]:value};
            setGridData(data);
        }
    }

    const handleRowClick=(id)=>{
        const index=gridData.findIndex(c=>c[rowId]===id);
        if(index>=0)
        {
            setGridData(gridData.map(c=>({
                    ...c,
                    selected:c[rowId]===id?!c.selected:false
                }))
            );
        }
    }

    const handleRowChange=(id,rowData)=>{
        let data=gridData.slice();
        const index=data.findIndex(c=>c[rowId]==id);
        if(index>=0)
            data[index]={...data[index],...rowData};
        else
            data.push(rowData);
        
        setGridData(data);
    }

    const handleHeaderChange=(name,value)=>{
        setGridData(gridData.map(c=>({
            ...c,
            [name]:value
        })))
    }

    const deleteRow=()=>{
        const selected=gridData.find(c=>c.selected);
        if(selected)
            handleDelete(selected[rowId]);
        else
            showError('Please select row');
    }

    return {
        gridData,
        handleChange,
        handleRowClick,
        setGridData,
        handleHeaderChange,
        deleteRow,
        handleRowChange
    };
}

export default useGridHook;