import React from 'react';
import PropTypes from 'prop-types';
import { Error, UserLabel } from '../Label';

const Input=(props)=>
{
    const {name,placeholder,maxLength,toUpper,
    className,disabled,suffix,value,onChange,rows,cols}=props;
    return <span>
                <textarea
                    type="text"
                    name={`${name}`}
                    disabled={disabled?true:false}
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={maxLength ? maxLength : 300}
                    value={(toUpper && value ? value.toUpperCase() : (value?value:''))}
                    className={className ? `${className} form-control` : 'form-control'}
                    rows={rows ? rows : 3}
                    cols={cols?cols:3}
                />
                <span className="txtSuffix">{suffix}</span>
            </span>
   }


const TextInput=(props)=> {
    const onFieldChange=(event) =>{
        if(event)
            event.stopPropagation();

        const target = event.target;
        const value = target.value;
        const name = target.name;
        const {onChange}=props;
        onChange(name, value);
    }

        const {errors,label,name}=props;
        const error=errors?errors[name]:null;

        return (
            <div className='form-section'>
                <div className="form-section__field">
                {label?
                    <div>
                        <UserLabel
                            {...props}
                        />
                        <Input
                            {...props}
                            onChange={onFieldChange.bind(this)}
                        />
                    </div>
                :
                    <div className="row m-0">
                    <div className="col-sm-12 col-xs-12 col-lg-12 px-0">
                        <Input
                            {...props}
                            onChange={onFieldChange.bind(this)}
                        />
                    </div>
                    </div>
                }
                {error &&
                    <Error
                        error={error}
                    />
                }
            </div>
        </div>
        )
    }


TextInput.propTypes = {
    id: PropTypes.string,
    alhpaNumeric: PropTypes.bool,
    button: PropTypes.func,
    url: PropTypes.string,
    error: PropTypes.string,
    link: PropTypes.string,
    pattern: PropTypes.instanceOf(RegExp),
    class: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    maxLength: PropTypes.number,
    toUpper: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default TextInput;