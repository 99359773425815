import React, {useEffect } from 'react';
import { useDispatch} from 'react-redux';
import useForm from '../../../../hooks/useForm';
import { UserModal } from '../../../../components/Modal';
import { TextInput } from '../../../../components/TextInput';
import { addEditActivityCode } from '../../../../actions/activityCode';

const stateSchema={
    
};

const validationSchema={
    activityName:{
        required:true
    },
    activityDesc:{
        required:true
    }
}

const AddActivity=({activityDetail,handleChange,errors,activityType,activityTypeId})=>{

        const {
            activityName,
            activityDesc
        }=activityDetail;

        return (
                <div className='row'>
                    <div className='col-6'>
                        <TextInput
                            name="activityName"
                            label="Activity Code"
                            value={activityName}
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    <div className='col-6'>
                        <TextInput
                            name="activityDesc"
                            value={activityDesc}
                            label="Activity"
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                </div>
        )
}


export default ({showAddActivity,toggleModal,selectedActivity,activityType,activityTypeId})=>{

    const {
        state:activityDetail,
        errors,
        handleChange,
        handleSubmit,
        handleStateChange
    }=useForm(stateSchema,validationSchema,handleSave);

    const dispatch=useDispatch();

    useEffect(()=>{
        if(selectedActivity)
            handleStateChange(selectedActivity);
    },[]);

    function handleSave(activityDetail){
        dispatch(addEditActivityCode({
            ...activityDetail,
            activityTypeId
        }));
        toggleModal();
    }

    return (
        <div className='col-12'>
            <UserModal
                show={showAddActivity}
                handleClose={toggleModal}
                title={"Add "+activityType+" Activity"}
                handleSave={handleSubmit}
            >
                <AddActivity
                    activityDetail={activityDetail}
                    handleChange={handleChange}
                    errors={errors}
                    activityType={activityType}
                    activityTypeId={activityTypeId}
                />
            </UserModal>
        </div>
    )
};