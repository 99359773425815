import React, { useState, useEffect } from "react";
import { UserButton } from "../../../components/Button";
import { Dropdown } from "../../../components/SelectBox";
import { downloadReport } from "../../../actions/report";
import useToggle from "../../../hooks/useToggle";
import EmailModal from "./EmailModal";
import usePrintHook from "../../hooks/usePrintHook";

export default (props) => {
  let { setReportFormat } = props;
  const [format, setFormat] = useState();
  const { handleAction, formatOptions, PDF } = usePrintHook({
    ...props,
    format,
  });

  useEffect(() => {
    setFormat(PDF);
  }, []);

  const { show: showEmailModal, toggle } = useToggle();

  const handleChange = (name, value) => {
    setFormat(value);
    if (value) {
      setReportFormat(value);
    }
  };

  const handlePrint = () => {
    handleAction(downloadReport);
  };

  const handleEmail = () => {
    toggle();
  };

  return (
    <>
      <div className="c-format">
        <Dropdown
          name="format"
          value={format}
          label="Format"
          onChange={handleChange}
          options={formatOptions}
        />
      </div>
      <UserButton
        text="Email"
        icon={<i className="fa fa-email"></i>}
        className="btn btn-purple"
        onClick={handleEmail}
      />
      <UserButton
        text="Print"
        icon={<i className="fa fa-print"></i>}
        className="btn btn-purple"
        onClick={handlePrint}
      />
      {showEmailModal && (
        <EmailModal
          showModal={showEmailModal}
          toggleModal={toggle}
          sendEmail={handleAction}
        />
      )}
    </>
  );
};
