import React from "react";
import { UserTable } from "../../../../components/Table";

export default (props) => {
  const {
    users,
    rowId,
    handleRowClick,
    partnerLabel,
    sourceLabel,
    handleEdit,
    handleDelete,
  } = props;

  const headers = [
    { key: "fullName", text: "Name", search: true, sort: true },
    { key: "username", text: "User Name", search: true, sort: true },
    { key: "branch", text: partnerLabel, search: true, sort: true },
    { key: "partners", text: sourceLabel, search: true, sort: true },
    { key: "createdOn", text: "Created On", search: true, sort: true },
    { key: "lastLogin", text: "Last Login", search: true, sort: true },
    { key: "history", text: "History" },
  ];

  return (
    <UserTable
      headers={headers}
      data={users}
      rowId={rowId}
      handleRowClick={handleRowClick}
      showActions={true}
      actionProps={{ handleEdit, handleDelete, deleteTitle: "user" }}
    />
  );
};
