import React from "react";
import { UserTable } from "../../../../components/Table";

export default (props) => {
  const {
    clients,
    rowId,
    handleRowClick,
    partnerLabel,
    sourceLabel,
    handleDelete,
    handleEdit,
  } = props;

  const headers = [
    { key: "clientCode", text: "Ref", search: true, sort: true },
    { key: "clientName", text: "Client Name", search: true, sort: true },
    { key: "billingName", text: "Billing Name", search: true, sort: true },
    { key: "orgPartner", text: partnerLabel, search: true, sort: true },
    { key: "partnerSource", text: sourceLabel, search: true, sort: true },
  ];

  return (
    <UserTable
      headers={headers}
      data={clients}
      rowId={rowId}
      handleRowClick={handleRowClick}
      showActions={true}
      actionProps={{ handleEdit, handleDelete, deleteTitle: "client" }}
    />
  );
};
