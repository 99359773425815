export const Invoice={
    CREATE_INVOICE_VIEW:'CREATE_INVOICE_VIEW',
    ADD_TRANSACTION_VIEW:'ADD_TRANSACTION_VIEW',
    GET_TRANSACTIONS:'GET_TRANSACTIONS',
    SET_TRANSACTIONS:'SET_TRANSACTIONS',
    CREATE_INVOICE:'CREATE_INVOICE',
    VALIDATE_INVOICE_NO:'VALIDATE_INVOICE_NO',
    GET_INVOICES:'GET_INVOICES',
    GET_INVOICE_DETAILS:'GET_INVOICE_DETAILS',
    UPDATE_INVOICE:'UPDATE_INVOICE',
    INVOICE_SUCCESS:'INVOICE_SUCCESS'
}