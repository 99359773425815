import React, { useEffect, useState } from "react";
import WeekSelectBox from "../../../common/WeekSelectBox";
import { UserButton } from "../../../components/Button";
import { getStartOfWeek } from "../../../libs/dateHelper";
import { SelectBox } from "../../../components/SelectBox";
import { AppConstant } from "../../../utils/constant";
import useUserDetail from "../../../hooks/useUserDetail";
import { useSelector } from "react-redux";
import EmployeeSelectBox from "../../../common/EmployeeSelectBox";
import useToggle from "../../../hooks/useToggle";
import { Timesheet } from "../../TimeAndExpenses";
import { UserModal } from "../../../components/Modal";
import useTheme from "../../../hooks/useTheme";

const { NO_OF_TIMESHEET_WEEKS } = AppConstant;

const yearFormat = "YYYY";

export default (props) => {
  const { employeeList } = useSelector((state) => state.common);
  const { show: showTimesheet, toggle } = useToggle();
  const { finStartDate } = useUserDetail();
  const [yearOptions, setOptions] = useState([]);
  const [state, setState] = useState({});
  const { timesheetDates, year, employeeId } = state;
  const { theme } = useTheme();
  const { startDate } = timesheetDates ? timesheetDates : {};
  const { orgPartnerId, partnerSourceId } = props;

  useEffect(() => {
    if (finStartDate) {
      const startDate = getStartOfWeek(finStartDate).clone();
      const startYear = startDate.format(yearFormat);
      const endYear = startDate
        .add("week", NO_OF_TIMESHEET_WEEKS)
        .format(yearFormat);
      let options = [];
      options.push({ text: startYear, value: startYear });
      if (endYear != startYear) options.push({ text: endYear, value: endYear });

      setOptions(options);
    }
  }, [finStartDate]);

  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (timesheetDates) => {
    handleChange("timesheetDates", timesheetDates);
  };

  const handleClose = () => {
    props.handleView();
    toggle();
  };

  return (
    <div className="plm_vts_div row">
      <div className="selectWrap col-3">
        <EmployeeSelectBox
          value={employeeId}
          onChange={handleChange}
          orgPartnerId={orgPartnerId}
          partnerSourceId={partnerSourceId}
        />
      </div>
      <div className="selectWrap col-3">
        <SelectBox
          name="year"
          value={year}
          onChange={handleChange}
          options={yearOptions}
          addFirstItem={true}
          label="Year"
        />
      </div>
      <div className="col-3">
        <WeekSelectBox
          {...timesheetDates}
          handleChange={handleDateChange}
          year={year}
          label="Start Date"
          hideActions
        />
      </div>

      <div
        class="plm_cmn_cardbtn_alone_div col-3"
        style={{ marginTop: 25, paddingLeft: 0 }}>
        <UserButton text="View Timesheet" onClick={toggle} />
      </div>
      {showTimesheet && (
        <UserModal
          show={showTimesheet}
          handleClose={handleClose}
          hideFooter
          dialogClassName={theme + "largeModal App"}
          title="Time and Expenses">
          <Timesheet
            startDate={startDate.toDate()}
            employeeId={employeeId}
            emailId={
              employeeList.find((c) => c.employee.customerId == employeeId)
                ?.employee.emailId
            }
          />
        </UserModal>
      )}
    </div>
  );
};
