import  { useEffect } from 'react';
import useGridHook from '../../hooks/useGridHook';
import { getWeekDays, formatAPIDate, formatWeekDay } from '../../libs/dateHelper';
import { useSelector } from 'react-redux';
import { LookUp } from '../../utils/lookUpConstant';

export default (rowId,activityTypes,startDate)=>{

    const gridProps=useGridHook(rowId);
    const {setGridData}=gridProps;
    const {timesheetDetails}=useSelector(state=>state.timesheet);
    const expensesId=activityTypes?.find(c=>c.text==LookUp.EXPENSES)?.value;

    const setTimesheetDetails=(timesheetDetails,startDate)=>{
        if(timesheetDetails){
            const weekDays=getWeekDays(startDate);
            setGridData(timesheetDetails.map((c,index)=>({
                ...c.timesheetDetail,
                rowId:index,
                workDetails:c.timesheetDetail?.timesheetTypeId==expensesId?
                    c.workDetails.map(w=>({...w,workDateTime:formatAPIDate(w.workDatetime)}))
                    :weekDays.map(curr=>{
                    const workDetail=c.workDetails
                        .find(w=>formatWeekDay(w.workDatetime)==curr.key);
                    const value=workDetail?workDetail.noOfUnits:'';
                    return {
                        ...curr,
                        [curr.key]:value,
                        ...workDetail,
                        workDateTime:formatAPIDate(curr.weekDate)
                    };
                })
            })));
        }
            
    }

    useEffect(()=>{
        setTimesheetDetails(timesheetDetails,startDate);
    },[timesheetDetails,startDate]);

    return {
        gridProps
    }

}