import React, { useEffect, useState } from "react";
import { TimesheetGrid, DaysGrid, TotalRow } from "../Chargeable";
import { getWeekDays, formatAPIDate } from "../../../../libs/dateHelper";
import useToast from "../../../../hooks/useToast";
import TimesheetHelper from "../../../../libs/TimesheetHelper";
import { Timesheet as Constant } from "../../../../utils/timesheetConstant";

const {
  ACTIVITY_GRID_ID,
  CLIENT_GRID_ID,
  RATE_GRID_ID,
  DATE_NAME,
  DAYS_VALUE,
} = Constant;

export default (props) => {
  const { showError } = useToast();
  const [weekDays, setWeekDays] = useState([]);
  const [subTotalRow, setSubTotal] = useState([]);
  const {
    gridData,
    initData,
    handleRowChange,
    setGridData,
    timesheetTypeId,
    rowId,
    startDate,
    hideClient,
    disableTimesheet,
    isReport,
    type,
    children,
  } = props;

  useEffect(() => {
    const days = getWeekDays(startDate).map((c) => ({
      ...c,
      [DATE_NAME]: formatAPIDate(c.weekDate),
    }));
    setWeekDays(days);
    addDaysRow(days);
  }, [startDate]);

  useEffect(() => {
    setSubTotal(TimesheetHelper.getTotalRow(gridData, weekDays));
    addDaysRow();
  }, [gridData]);

  const addDaysRow = (days) => {
    if (!isReport) {
      const data = TimesheetHelper.addDaysRow(
        initData,
        timesheetTypeId,
        weekDays && weekDays.length > 0 ? weekDays : days
      );
      if (data) setGridData(data);
    }
  };

  const handleChange = (id, data) => {
    const index = initData.findIndex((c) => c[rowId] == id);
    if (index >= 0) handleRowChange(id, data);
  };

  const handleHourChange = (id, name, value) => {
    let data = initData.find((c) => c[rowId] == id);
    if (!hideClient && (!data || !data[CLIENT_GRID_ID])) {
      showError("Please select client");
      return;
    }
    if (!data[ACTIVITY_GRID_ID]) {
      showError("Please select job");
      return;
    }
    if (!data[RATE_GRID_ID]) {
      showError("Please select rate");
      return;
    }
    if (!data.rate) {
      showError("Rate must be greater than 0");
      return;
    }
    let { workDetails } = data;
    const weekIndex = workDetails.findIndex((c) => c.key == name);
    workDetails[weekIndex] = {
      ...workDetails[weekIndex],
      [DAYS_VALUE]: value ? parseInt(value) : 0,
    };
    data = {
      ...data,
      workDetails,
    };
    handleRowChange(id, data);
  };

  if (isReport) {
    return (
      <table
        className="table table-stripe mb20 report-tbl"
        style={{ tableLayout: "fixed", borderCollapse:"collapse" }}
      >
        <tr>
          <td width="50%">
            <TimesheetGrid
              rowId={rowId}
              hideClient={hideClient}
              disableTimesheet={disableTimesheet}
              timesheets={gridData}
              handleRowChange={handleChange}
              timesheetTypeId={timesheetTypeId}
              isReport={isReport}
            />
          </td>
          <td width="50%" className="daysGridWrapper">
            <DaysGrid
              rowId={rowId}
              gridData={gridData}
              disableTimesheet={disableTimesheet}
              handleChange={handleHourChange}
              handleRowClick={addDaysRow}
              isReport={isReport}
            />
          </td>
        </tr>
        <TotalRow totalRow={subTotalRow} isReport text="Totals" />
        {children}
      </table>
    );
  }

  return (
    <div className="col-12 col-md-12 col-xs-12 plm_timrcoldiv">
      <div className="row trow_time_top">
        <div className={`col-12 col-md-8 trow_time_tp_left`}>
          <TimesheetGrid
            rowId={rowId}
            hideClient={hideClient}
            disableTimesheet={disableTimesheet}
            timesheets={gridData}
            handleRowChange={handleChange}
            timesheetTypeId={timesheetTypeId}
            isReport={isReport}
          />
        </div>
        <div className={`col-12 col-md-4 trow_time_tp_right`}>
          <DaysGrid
            rowId={rowId}
            gridData={gridData}
            disableTimesheet={disableTimesheet}
            handleChange={handleHourChange}
            handleRowClick={addDaysRow}
            isReport={isReport}
          />
        </div>
      </div>
      {gridData.length > 0 && (
        <div className="trow_time_bottom">
          <TotalRow totalRow={subTotalRow} text="Sub Total" />
        </div>
      )}
    </div>
  );
};
