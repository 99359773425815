import React from "react";
import { useDispatch } from "react-redux";
import { showLoading } from "../../actions/common";
import { readFile } from "../../libs/fileHelper";
import { savePDF } from "@progress/kendo-react-pdf";

const PDF = "Pdf",
  WORD = "Word",
  EXCEL = "Excel";

const formatOptions = [
  { text: PDF, value: PDF },
  // { text: EXCEL, value: EXCEL },
  // { text: WORD, value: WORD },
];

const usePrintHook = ({
  showReport,
  toggleReport,
  targetRef,
  reportName,
  format,
}) => {
  const dispatch = useDispatch();

  const handleAction = (action, payload) => {
    toggleReport(true);
    
    setTimeout(() => {
      if (targetRef.current) {
        console.log(targetRef.current);
        targetRef.current.classList.add('k-pdf-export');
    
          savePDF(targetRef.current, {
            paperSize: 'A4',
            fileName: reportName,
            margin: '0.5cm',
            // landscape: 1
        });

        targetRef.current.classList.remove('k-pdf-export');
      }
        
      //toggleReport(false);
      },1000);

  };

  return {
    handleAction,
    formatOptions,
    PDF,
    WORD,
    EXCEL,
  };
};

export default usePrintHook;
