import React from 'react';
import { Error } from '.';

export default ({summary})=>(
    <>
        {summary && Object.keys(summary).map((c,index)=>(
            <div className='row' key={index}>
                <div className='col-12'>
                    <Error
                        error={summary[c]}
                    />
                </div>
            </div>
        ))}
    </>
)