import React, { useEffect } from "react";
import { UserCard } from "../../../components/Container";
import { RadioButtonGroup } from "../../../components/RadioButton";
import useForm from "../../../hooks/useForm";
import ScreenButton from "../../../common/ScreenButton";
import { addTransactionView } from "../../../actions/invoice";
import { useDispatch, useSelector } from "react-redux";
import ClientSelectBox from "../../../common/ClientSelectBox";
import TransactionDate from "../../../common/TransactionDate";
import { SelectBox } from "../../../components/SelectBox";

const options = [
  {
    text: "Include all",
    value: "false",
    className: "col-3",
  },
  {
    text: "Include only authorised",
    value: "true",
    className: "col-3",
  },
];

const stateSchema = {
  timesheetDate: new Date(),
  showAuthorised: "false",
};

const validationSchema = {
  clientName: {
    required: true,
  },
  clientCode: {
    required: true,
  },
};

export default ({ actionProps }) => {
  const dispatch = useDispatch();

  const {
    state,
    errors,
    handleChange,
    handleStateChange,
    handleSubmit,
  } = useForm(stateSchema, validationSchema, handleSave);

  const { clientDetail } = useSelector((state) => state.invoice);
  const { timesheetDate, showAuthorised, clientId } = state;

  useEffect(() => {
    if (clientDetail) handleStateChange(clientDetail);
  }, [clientDetail]);

  const fetchTransaction = () => {
    dispatch(
      addTransactionView({
        clientId,
        showAuthorised: true,
        timesheetDate,
      })
    );
  };

  function handleSave(clientDetail) {
    const { handleNext } = actionProps;
    fetchTransaction();
    handleNext("clientDetail", clientDetail);
  }

  return (
    <div className="plm_cmn_wiz_step">
      <UserCard title="1. Choose a Client">
        <div class="plm_cmn_wiz_content plm_inv_wiz_step1">
          <div className="col-6 col-md-4 px-0 pad-wrap-0">
            <ClientSelectBox
              state={state}
              asterix={true}
              errors={errors}
              onChange={handleStateChange}
            />
          </div>
          <div className="row">
            <div className="col-12 px-0">
              <TransactionDate
                timesheetDate={timesheetDate}
                onChange={handleChange}
                asterix={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 form-section invoice-container-wrap px-0">
              <SelectBox
                name="showAuthorised"
                value={showAuthorised}
                options={options}
                onChange={handleChange}
                label="Include only authorised transactions"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 invoice-btn-left">
              <ScreenButton {...actionProps} handleNext={handleSubmit} />
            </div>
          </div>
        </div>
      </UserCard>
    </div>
  );
};
