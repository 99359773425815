import React, { useEffect, useImperativeHandle } from "react";
import { TextInput } from "../../../components/TextInput";
import { UploadLogo } from "../../../components/FileInput";
import { UserDatePicker } from "../../../components/DateControl";
import { SelectBox } from "../../../components/SelectBox";
import { useSelector } from "react-redux";
import { UserButton } from "../../../components/Button";
import useGridHook from "../../../hooks/useGridHook";

const GridInput = ({ id, name, value, onChange, list, updateList }) => {
  const handleChange = (name, value) => {
    onChange(id, name, value);
  };

  const handleDelete = () => {
    updateList(
      list
        .filter((c) => c.id != id)
        .map((c, index) => ({
          ...c,
          id: index + 1,
        }))
    );
  };

  return (
    <div className="row">
      <div className="col-6">
        <TextInput
          name={name}
          value={value}
          onChange={handleChange}
          maxLength={2}
        />
      </div>
      <div className="col-3">
        <UserButton
          className="btn btn-primary"
          text="Delete"
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

const InvoiceDataGrid = ({ label, name, onChange, list, updateList }) => {
  const handleAdd = () => {
    updateList([...list, { id: list.length + 1 }]);
  };

  return (
    <>
      <div className="row">
        <div className="col-6">{label}</div>
        <div className="col-6">
          <UserButton
            className="btn btn-primary pull-right"
            text="Add New"
            onClick={handleAdd}
          />
        </div>
      </div>
      {list &&
        list.map((c, index) => (
          <GridInput
            key={`${name}_${index}`}
            id={index + 1}
            name={name}
            value={c[name]}
            onChange={onChange}
            list={list}
            updateList={updateList}
          />
        ))}
    </>
  );
};

const AddCustomer = React.forwardRef(
  ({ customerDetail, handleChange, uploadLogo, errors }, ref) => {
    const {
      orgName,
      accountId,
      firstName,
      lastName,
      address,
      phoneno,
      emailId,
      mobileno,
      finYearStartDate,
      croppedImg,
      orgLogo,
      theme,
      invoiceNoPrefixList,
      invoiceNoSuffixList,
    } = customerDetail;
    const {
      gridData: prefixList,
      setGridData: setPrefixList,
      handleChange: handlePrefixChange,
    } = useGridHook("id");
    const {
      gridData: suffixList,
      setGridData: setSuffixList,
      handleChange: handleSuffixChange,
    } = useGridHook("id");
    const { themes } = useSelector((state) => state.account);

    useEffect(() => {
      if (invoiceNoPrefixList && invoiceNoPrefixList.length > 0) {
        setPrefixList(
          invoiceNoPrefixList.map((c, index) => ({
            id: index + 1,
            invNoPrefix: c,
          }))
        );
      } else {
        setPrefixList([{ id: 1 }]);
      }
    }, [invoiceNoPrefixList]);

    useEffect(() => {
      if (invoiceNoSuffixList && invoiceNoSuffixList.length > 0) {
        setSuffixList(
          invoiceNoSuffixList.map((c, index) => ({
            id: index + 1,
            invNoSuffix: c,
          }))
        );
      } else {
        setSuffixList([{ id: 1 }]);
      }
    }, [invoiceNoSuffixList]);

    useImperativeHandle(ref, () => ({
      getPrefixSuffix() {
        return {
          invoiceNoPrefixList: prefixList
            .filter((c) => c.invNoPrefix)
            .map((c) => c.invNoPrefix),
          invoiceNoSuffixList: suffixList
            .filter((c) => c.invNoSuffix)
            .map((c) => c.invNoSuffix),
        };
      },
    }));

    return (
      <div className="row">
        <div className="col-6">
          <TextInput
            name="orgName"
            label="Customer Name"
            value={orgName}
            onChange={handleChange}
            errors={errors}
            asterix={true}
          />
        </div>
        <div className="col-6">
          <TextInput
            name="accountId"
            disabled
            value={accountId}
            label="Account Id"
            onChange={handleChange}
            errors={errors}
            asterix={true}
          />
        </div>
        <div className="col-6">
          <TextInput
            name="firstName"
            value={firstName}
            label="First Name"
            onChange={handleChange}
            errors={errors}
            asterix={true}
          />
        </div>
        <div className="col-6">
          <TextInput
            name="lastName"
            value={lastName}
            label="Last Name"
            onChange={handleChange}
            errors={errors}
            asterix={true}
          />
        </div>
        <div className="col-6">
          <TextInput
            name="address"
            value={address}
            label="Address"
            onChange={handleChange}
            errors={errors}
          />
        </div>
        <div className="col-6">
          <TextInput
            name="phoneno"
            value={phoneno}
            label="Phone"
            onChange={handleChange}
            errors={errors}
          />
        </div>
        <div className="col-6">
          <TextInput
            name="emailId"
            value={emailId}
            label="Email"
            onChange={handleChange}
            errors={errors}
            asterix={true}
          />
        </div>
        <div className="col-6">
          <TextInput
            name="mobileno"
            value={mobileno}
            label="Mobile"
            onChange={handleChange}
            errors={errors}
          />
        </div>
        <div className="col-6">
          <UserDatePicker
            name="finYearStartDate"
            value={finYearStartDate}
            label="Financial Year Start Date"
            onChange={handleChange}
            errors={errors}
            asterix={true}
          />
        </div>
        <div className="col-6">
          <SelectBox
            name="theme"
            value={theme}
            label="Theme"
            addFirstItem={true}
            onChange={handleChange}
            options={themes}
          />
        </div>
        <div className="col-12">
          <InvoiceDataGrid
            name="invNoPrefix"
            onChange={handlePrefixChange}
            label="Invoice No Prefix"
            list={prefixList}
            updateList={setPrefixList}
          />
        </div>
        <div className="col-12">
          <InvoiceDataGrid
            name="invNoSuffix"
            onChange={handleSuffixChange}
            label="Invoice No Suffix"
            list={suffixList}
            updateList={setSuffixList}
          />
        </div>
        <div className="col-12">
          <UploadLogo
            onChange={handleChange}
            name="croppedImg"
            file={croppedImg}
            fileSrcName="orgLogo"
            fileSrc={orgLogo}
            handleSave={uploadLogo}
            fileName={orgName}
          />
        </div>
      </div>
    );
  }
);

export default AddCustomer;
