import React, { useEffect, useState } from "react";
import { UserTable, CommonTable } from "../../../components/Table";
import useGridHook from "../../../hooks/useGridHook";
import { useSelector } from "react-redux";
import { groupBy, map, sumBy } from "lodash";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import { displayDate } from "../../../libs/dateHelper";
import "./IncomeGrid.scss";
import { displayCost, displayPercent } from "../../../libs/functions";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";
import useOrgResources from "../../../hooks/useOrgResources";
import { PartnerLabel, SourceLabel } from "../Components";
import TableCell from "../../../components/Table/Cell";
import { normalizeData } from "../../../libs/functions";

const rowId = "";

const TotalRow = ({
  text,
  expenses,
  calculatedAmt,
  actualAmt,
  className,
  taxAmount,
  totalAmount,
  surplusDeficitAmt,
}) => {
  return (
    <UserTable
      tblClass="table table-stripe mb0 feeIncomeSummaryGrid feeIncomeDetailTotalRow"
      tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
      wrapperClass={`totalRow ${className}`}
      headers={[
        { text, colSpan:2 },
        // { text: "" },
        { text: expenses },
        { text: calculatedAmt },
        { text: actualAmt },
        { text: taxAmount },
        { text: totalAmount },
        { text: surplusDeficitAmt },
        { text: "" },
      ].map((c) => ({ ...c, headerActionProps: { className: "gap-cell" } }))}
    />
  );
};

export default ({ isReport, selectedInvoice, reportType }) => {
  const { gridData, setGridData } = useGridHook();
  const { invoices } = useSelector((state) => state.invoice);
  const { theme } = useUserDetail();
  const { WORD, EXCEL } = usePrintHook({});
  const { partnerLabel, sourceLabel } = useOrgResources();

  const calTotal = (data) => ({
    expenses: TimesheetHelper.calTotal(data, "expenses"),
    calculatedAmt: TimesheetHelper.calTotal(data, "calculatedAmt"),
    actualAmt: TimesheetHelper.calTotal(data, "actualAmt"),
    taxAmount: TimesheetHelper.calTotal(data, "taxAmount"),
    totalAmount: TimesheetHelper.calTotal(data, "totalAmount"),
    //surplusDeficitAmt: TimesheetHelper.calTotal(data, "surplusDeficitAmt"),
  });

  useEffect(() => {
    if (invoices) {
      let filtered = invoices;
      if (selectedInvoice)
        filtered = filtered.filter(
          (c) => c.invoiceId == selectedInvoice.invoiceId
        );
      const partnerGroup = groupBy(filtered, (c) => c.orgPartnerId);
      const gridData = map(partnerGroup, (p) => {
        const sourceGroup = groupBy(p, (s) => s.partnerSourceId);
        return {
          partnerName: p[0].orgPartner,
          sourceDetails: map(sourceGroup, (s) => {
            const clientGroup = groupBy(
              s.map((c) => ({
                ...c,
                surplusDeficitAmt: TimesheetHelper.getSurplusDeficit(c),
                percent: TimesheetHelper.calPercent({actualAmt: c.actualAmt, calculatedAmt: c.calculatedAmt + c.expenses}),
                invoiceDate: displayDate(c.invoiceDt),
              })),
              (cl) => cl.clientId
            );
            return {
              sourceName: s[0].partnerSource,
              clientDetails: map(clientGroup, (c) => ({
                clientName: c[0].clientName,
                gridData: c.map((g) => ({
                  ...g,
                  expenses: displayCost(g.expenses),
                  calculatedAmt: displayCost(g.calculatedAmt),
                  actualAmt: displayCost(g.actualAmt),
                  taxAmount: displayCost(g.taxAmount),
                  totalAmount: displayCost(g.totalAmount),
                  surplusDeficitAmt: displayCost(g.surplusDeficitAmt),
                })),
                ...calTotal(c),
              })),
              ...calTotal(s),
            };
          }),
          ...calTotal(p),
        };
      });

      setGridData(gridData);
    }
  }, [invoices]);

  const headers = [
    { key: "invoiceDate", text: "Date" },
    { key: "invoiceNo", text: "Inv No." },
    { key: "expenses", text: "Expenses" },
    { key: "calculatedAmt", text: "Costing" },
    { key: "actualAmt", text: "Net Inv" },
    { key: "taxAmount", text: "Tax" },
    { key: "totalAmount", text: "Gross" },
    { key: "surplusDeficitAmt", text: "Surplus/Deficit" },
    { key: "percent", text: "%" },
  ].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

    const getDataTable = (data, totalRows=[]) => {

          var rows=[];

            {normalizeData(data, rowId).map((row, i) => 
                rows.push(<tr
                    className="tbody"
                    key={i}>
                    {headers.map((prop) => 
                      <TableCell
                        {...prop}
                        key={prop.key + "" + i}
                        data={row.find((r) => r.key === prop.key)}
                        selected={row.find((r) => r.key === "selected" && r.text)}
                      />
                    )}
                  </tr>)
              )};

            {
              totalRows.map((totalRow)=> {
                  rows.push(<tr className="tbody totalRow-wrap">
                    {
                      totalRow.map((t)=> <td className={`td ${t.className}`} colspan={t.colSpan}><div className="gap-cell">{t.text}</div></td>)
                    }
                  </tr>)
              })
            }

      return rows;
    }
    
  const className = isReport ? "col-12 col-md-12 col-xs-12" : "col-12";
  var datatable=[];

  return (
    <ReportContainer hasData={gridData.length > 0}>
      {gridData.map((p, pIndex) => 
      {
        datatable=[];
        return (
          <div className="feeIncomeDetailRpt" key={`P${pIndex}`}>
            {reportType == WORD || reportType == EXCEL ? (
              <CustomDisplayLabel
                label={partnerLabel}
                value={p.partnerName}
                customClass="labelWrapper1"
              />
            ) : (
              <PartnerLabel partnerName={p.partnerName} />
            )}
            {p.sourceDetails.map((s, sIndex) => 
            {
              datatable=[];
              return (
                <React.Fragment key={`S${sIndex}`}>
                  <SourceLabel sourceName={s.sourceName} />
                  {s.clientDetails.map((cl, clIndex) => 
                  {
                    datatable=[];
                    return (
                      <React.Fragment key={`Cl${clIndex}`}>
                        <div class="header-row">
                          <div class="table-header">
                            Client: <span>{cl.clientName}</span>
                          </div>
                        </div>

                        {
                          datatable= [datatable, getDataTable(cl.gridData, [
                            [
                              { text:`Totals for Client: ${cl.clientName}`, colSpan:2, className: "total back-blue" },
                              { text: cl.expenses, className: "total back-blue" },
                              { text: cl.calculatedAmt, className: "total back-blue" },
                              { text: cl.actualAmt, className: "total back-blue" },
                              { text: cl.taxAmount, className: "total back-blue" },
                              { text: cl.totalAmount, className: "total back-blue" },
                              { text: cl.surplusDeficitAmt, className: "total back-blue" },
                              { text: "", className: "total back-blue" },
                            ]])]            
                        }

                        {
                          clIndex+1 < s.clientDetails.length &&
                          <CommonTable tblClass="feeIncomeSummaryGrid" headers={headers} rows={datatable} />               
                      }

                      </React.Fragment>
                    )
                  }
                  )}

                {
                    datatable=[datatable, (<tr className="tbody totalRow-wrap">
                    <td className={`td total back-purple`} colspan="2"><div className="gap-cell">{`Totals for ${sourceLabel}: ${s.sourceName}`}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{s.expenses}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{s.calculatedAmt}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{s.actualAmt}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{s.taxAmount}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{s.totalAmount}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{s.surplusDeficitAmt}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">&nbsp;</div></td>
                  </tr>)]               
                }

                {
                  sIndex+1 < p.sourceDetails.length &&
                  <CommonTable tblClass="feeIncomeSummaryGrid" headers={headers} rows={datatable} />              
               }

                </React.Fragment>
              )
            })}

                {
                    datatable=[datatable, (<tr className="tbody totalRow-wrap">
                    <td className={`td total back-purple`} colspan="2"><div className="gap-cell">{`Totals for ${partnerLabel}: ${p.partnerName}`}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{p.expenses}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{p.calculatedAmt}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{p.actualAmt}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{p.taxAmount}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{p.totalAmount}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{p.surplusDeficitAmt}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">&nbsp;</div></td>
                  </tr>)]               
                }

                  <CommonTable tblClass="feeIncomeSummaryGrid" headers={headers} rows={datatable} />   

          </div>
        )
      }
      
      )}
    </ReportContainer>
  );
};
