import React, { useEffect, useState } from "react";
import { UserTable, CommonTable } from "../../../components/Table";
import { CustomDisplayLabel } from "../../../components/Label";
import { groupBy, map, sumBy } from "lodash";
import { useSelector } from "react-redux";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import useOrgResources from "../../../hooks/useOrgResources";
import useUserDetail from "../../../hooks/useUserDetail";
import { displayCost } from "../../../libs/functions";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";
import { PartnerLabel, SourceLabel } from "../Components";
import TableCell from "../../../components/Table/Cell";
import { normalizeData } from "../../../libs/functions";

const rowId = "";

const TotalRow = ({ text, cost, className }) => {
  return (
    <UserTable
      tblClass="table table-stripe mb0 wipDetailReportGrid wipSummaryTotalRow"
      tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
      wrapperClass={`totalRow ${className}`}
      headers={[{ text, colSpan:2 }, { text: displayCost(cost) }].map(
        (c) => ({ ...c, headerActionProps: { className: "gap-cell" } })
      )}
    />
  );
};

export default (props) => {
  const [gridData, setGridData] = useState([]);
  const { clientsWIP } = useSelector((state) => state.client);
  const { isReport, reportType } = props;
  const { partnerLabel, sourceLabel } = useOrgResources();
  const { theme } = useUserDetail();
  const { WORD, EXCEL } = usePrintHook({});

  useEffect(() => {
    if (clientsWIP) {
      const group = groupBy(
        clientsWIP.map((c) => ({
          ...c,
          cost: TimesheetHelper.calAmount({ ...c, invoiceUnits: c.noOfUnits }),
        })),
        (c) => c.orgPartnerId
      );
      setGridData(
        map(group, (c) => {
          const { orgPartner } = c[0];
          const sourceGroup = groupBy(c, (s) => s.partnerSourceId);
          return {
            gridData: map(sourceGroup, (s) => {
              const { partnerSource } = s[0];
              const clientGroup = groupBy(s, (cl) => cl.clientId);
              return {
                orgPartner,
                partnerSource,
                clients: map(clientGroup, (cl) => ({
                  ...cl[0],
                  units: sumBy(cl, (t) => t.noOfUnits),
                  cost: displayCost(sumBy(cl, (t) => parseFloat(t.cost))),
                })),
                totalUnits: sumBy(s, (t) => t.noOfUnits),
                totalCost: sumBy(s, (t) => parseFloat(t.cost)),
              };
            }),
            orgPartner,
            totalUnits: sumBy(c, (t) => t.noOfUnits),
            totalCost: sumBy(c, (t) => parseFloat(t.cost)),
          };
        })
      );
    }
  }, [clientsWIP]);

  const headers = [
    { key: "clientCode", text: "Ref" },
    { key: "clientName", text: "Client Name" },
    { key: "cost", text: "Value" },
    { key: "addl", text: "" },
  ].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

  const getDataTable = (data, totalRows=[]) => {

          var rows=[];

            {normalizeData(data, rowId).map((row, i) => 
                rows.push(<tr
                    className="tbody"
                    key={i}>
                    {headers.map((prop) => 
                      <TableCell
                        {...prop}
                        key={prop.key + "" + i}
                        data={row.find((r) => r.key === prop.key)}
                        selected={row.find((r) => r.key === "selected" && r.text)}
                      />
                    )}
                  </tr>)
              )};

            {
              totalRows.map((totalRow)=> {
                  rows.push(<tr className="tbody totalRow-wrap">
                    {
                      totalRow.map((t)=> <td className={`td ${t.className}`} colspan={t.colSpan}><div className="gap-cell">{t.text}</div></td>)
                    }
                  </tr>)
              })
            }

      return rows;
  }


  const className = isReport ? "col-12 col-md-12 col-xs-12" : "col-12";

  var datatable=[];

  return (
    <ReportContainer hasData={gridData.length > 0}>
      <div className="clientWipSummaryRpt">
        {gridData.map((g, index) => (
          <React.Fragment key={index}>
            {g.gridData.map((c) => 
            {
              datatable=[];

              return (
                <React.Fragment key={index}>
                  {reportType == WORD || reportType == EXCEL ? (
                    <React.Fragment>
                      <div className={className + " labelWrapper1"}>
                        <CustomDisplayLabel
                          label={partnerLabel}
                          value={c.orgPartner}
                          customClass="labelWrapper1"
                        />
                      </div>
                      <div className={className + " labelWrapper"}>
                        <CustomDisplayLabel
                          label={sourceLabel}
                          value={c.partnerSource}
                          customClass="labelWrapper"
                        />
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PartnerLabel partnerName={c.orgPartner} />
                      <SourceLabel sourceName={c.partnerSource} />
                    </React.Fragment>
                  )}

                  {
                      datatable= [datatable, getDataTable(c.clients, [
                      [
                        { text:`Totals of: ${c.partnerSource}`, colSpan:2, className: "total back-blue" },
                        { text: displayCost(c.totalCost), className: "total back-blue" },
                        { text: '', className: "total back-blue" },
                      ]])]
                  }
                                    
                </React.Fragment>
              )
            }
            )}

                {
                  datatable=[datatable, (<tr className="tbody totalRow-wrap">
                    <td className={`td total back-purple`} colspan="2"><div className="gap-cell">{`Totals of: ${g.orgPartner}`}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">{displayCost(g.totalCost)}</div></td>
                    <td className={`td total back-purple`}><div className="gap-cell">&nbsp;</div></td>
                  </tr>)]
                }

              </React.Fragment>
            ))}

                {
                  datatable.length>0 && 
                  <CommonTable tblClass="wipDetailReportGrid wipSummaryReportGrid" headers={headers} rows={datatable} />
                }
      </div>
    </ReportContainer>
  );
};
