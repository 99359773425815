import React, { useRef } from "react";
import { UserCard } from "../../../components/Container";
import ScreenButton from "../../../common/ScreenButton";
import { AddEditInvoice } from ".";
import { setInvoice } from "../../../actions/invoice";
import { useDispatch } from "react-redux";
import { Client } from "../Common";

export default ({ actionProps }) => {
  const dispatch = useDispatch();
  const addInvoiceRef = useRef();

  const handleSubmit = () => {
    if (addInvoiceRef) addInvoiceRef.current.addEditInvoice();
  };

  const handleSave = (invoiceDetail) => {
    dispatch(
      setInvoice({
        invoiceDetail,
      })
    );
    actionProps.handleNext();
  };

  return (
    <div class="plm_cmn_wiz_step plm_invwiz_stepw3">
      <UserCard title="3. Invoice Details">
        <Client />
        <div class="plm_cmn_wiz_content plm_inv_wiz_step3">
          <AddEditInvoice ref={addInvoiceRef} handleSave={handleSave} />
        </div>
      </UserCard>
      <ScreenButton {...actionProps} handleNext={handleSubmit} />
    </div>
  );
};
