import { takeLatest, call, put } from 'redux-saga/effects';
import TimesheetAPI from "../api/TimesheetAPI";
import { Timesheet } from "../actionTypes/timesheet";
import callAPI from './common';
import { Common } from '../actionTypes/common';

function* addTimesheetView(action)
{
    yield call(callAPI,TimesheetAPI.addTimesheetView,action,setCommonData);
}

function* authorisationView(action)
{
    yield call(callAPI,TimesheetAPI.authorisationView,action,setCommonData);
}

function* addEditTimesheets(action)
{
    yield call(callAPI,TimesheetAPI.addEditTimesheet,action,setResult);
}

function* setCommonData({common,timesheetData})
{
    yield put({type:Common.SET_COMMON_DATA,result:common});
    yield call(setResult,timesheetData);
}

function* getTimesheets(action)
{
    yield call(callAPI,TimesheetAPI.getTimesheets,action,setResult);
}

function* getTimesheetDetails(action)
{
    yield call(callAPI,TimesheetAPI.getTimesheetDetails,action,setResult);
}

function* updateTimesheetsStatus(action)
{
    yield call(callAPI,TimesheetAPI.updateTimesheetsStatus,action,setResult);
}

function* getSubmittedTimesheets(action)
{
    yield call(callAPI,TimesheetAPI.getSubmittedTimesheets,action,setResult);
}

function* getTimesheetByStatus(action)
{
    yield call(callAPI,TimesheetAPI.getTimesheetByStatus,action,setResult);
}

function* getTimesheetByType(action)
{
    yield call(callAPI,TimesheetAPI.getTimesheetByType,action,setResult);
}

function* setResult(result)
{
    yield put({type:Timesheet.TIMESHEET_SUCCESS,result});
}


export default function* rootSaga() {
    yield takeLatest(Timesheet.ADD_TIMESHEET_VIEW, addTimesheetView);
    yield takeLatest(Timesheet.ADD_EDIT_TIMESHEETS, addEditTimesheets);
    yield takeLatest(Timesheet.GET_TIMESHEETS, getTimesheets);
    yield takeLatest(Timesheet.GET_TIMESHEET_DETAILS, getTimesheetDetails);
    yield takeLatest(Timesheet.AUTHORISATION_VIEW, authorisationView);
    yield takeLatest(Timesheet.UPDATE_TIMESHEETS_STATUS, updateTimesheetsStatus);
    yield takeLatest(Timesheet.GET_SUBMITTED_TIMESHEETS, getSubmittedTimesheets);
    yield takeLatest(Timesheet.GET_TIMESHEET_BY_STATUS, getTimesheetByStatus);
    yield takeLatest(Timesheet.GET_TIMESHEET_BY_TYPE, getTimesheetByType);
}