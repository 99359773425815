import React from "react";
import { ReportHeader } from "../Components";
import useOrgResources from "../../../hooks/useOrgResources";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { displayDate } from "../../../libs/dateHelper";
import IncomeGrid from "./IncomeGrid";
import usePrintHook from "../../hooks/usePrintHook";

export default (props) => {
  const {
    timesheetDate,
    orgPartnerId,
    partnerSourceId,
    reportTitle,
    startDate,
    endDate,
    reportType,
  } = props;
  const {
    partnerLabel,
    sourceLabel,
    orgPartner,
    partnerSource,
  } = useOrgResources(orgPartnerId, partnerSourceId);
  const { WORD, EXCEL } = usePrintHook({});

  return (
    <div className="feeIncomeSummaryRptContainer">
      <ReportHeader heading={reportTitle} reportType={reportType} />
      {reportType == WORD || reportType == EXCEL ? (
        <table width="100%" className="mb20">
          <tr>
            <td width="50%">
              <CustomDisplayLabel
                label={partnerLabel}
                value={orgPartner}
                customClass="plm_carep_top_first"
              />
            </td>
            <td>
              <CustomDisplayLabel
                label="Date Range"
                width={200}
                value={`${displayDate(startDate)}-${displayDate(endDate)}`}
                customClass="plm_carep_top_first"
              />
            </td>
          </tr>
          <tr>
            <td width="50%">
              <CustomDisplayLabel
                label={sourceLabel}
                value={partnerSource}
                customClass="plm_carep_top_first"
              />
            </td>
            <td></td>
          </tr>
        </table>
      ) : (
        <React.Fragment>
          {/* <div className="row">
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-6">
              <DisplayLabel label={partnerLabel} value={orgPartner} />
            </div>
            <div className="col-xs-6 col-md-6 col-6">
              <DisplayLabel label={sourceLabel} value={partnerSource} />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-6">
              <DisplayLabel
                label="Date Range"
                value={`${displayDate(startDate)}-${displayDate(endDate)}`}
              />
            </div>
          </div> */}

            <div class="header-row">
              <div class="table-header w-32">
                <DisplayLabel label={partnerLabel} value={orgPartner} />
              </div>
              <div class="table-header w-32">
              <DisplayLabel label={sourceLabel} value={partnerSource} />
              </div>
              <div class="table-header w-32">
              <DisplayLabel
                label="Date Range"
                value={`${displayDate(startDate)}-${displayDate(endDate)}`}
              />
              </div>
            </div>
            <br />
        </React.Fragment>
      )}
      <IncomeGrid {...props} isReport={true} reportType={reportType} />
    </div>
  );
};
