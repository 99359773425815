import APIUtil from './APIUtil';

class OrgPartnerAPI
{

    addEditClient(payload) {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Client/AddEditClient`;
        return fetch(url, options);
    }

  addClientView(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Client/AddClientView`;
    return fetch(url, options);
  }

  getClients(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Client/GetClients`;
    return fetch(url, options);
  }

  getClientSelectList(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Client/GetClientSelectList`;
    return fetch(url, options);
  }

  getClientsWIP(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Client/GetClientsWIP`;
    return fetch(url, options);
  }

  deleteClient(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Client/DeleteClient`;
    return fetch(url, options);
  }
}

export default new OrgPartnerAPI();