import React, { useEffect, useState } from "react";
import { WordLabel } from "../../../components/Label";
import { LabelColor } from "../../../components/Label/WordLabel";
import { WordTable } from "../../../components/Table";
import { displayDate } from "../../../libs/dateHelper";
import { displayCost } from "../../../libs/functions";
import { WordReportHeader } from "../Components";

const totalCellStyle = {
  backgroundColor: LabelColor.Purple,
  color: "#eeeeee",
  border: "none",
};

const totalRow = (headers, totalText, cost, noOfUnits, backgroundColor) => ({
  ...headers.reduce(
    (prev, current) => ({
      ...prev,
      [current.key]: "",
      [current.key + "Props"]: { hidden: true },
    }),
    {}
  ),
  workDate: totalText,
  workDateProps: {
    style: {
      ...totalCellStyle,
      backgroundColor,
      width: (100 * 3) / headers.length + "%",
    },
    colSpan: 3,
  },
  cost: displayCost(cost),
  costProps: {
    style: {
      ...totalCellStyle,
      backgroundColor,
      width: 100 / headers.length + "%",
    },
  },
  noOfUnits,
  noOfUnitsProps: {
    style: {
      ...totalCellStyle,
      backgroundColor,
      width: (100 * 2) / headers.length + "%",
    },
    colSpan: 2,
  },
});

export default ({
  partnerLabel,
  orgPartner,
  startDate,
  endDate,
  sourceLabel,
  partnerSource,
  headers: initHeaders,
  gridData,
}) => {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (initHeaders) {
      setHeaders(
        initHeaders.map((c) => ({
          ...c,
          headerStyle: { width: 100 / initHeaders.length + "%" },
        }))
      );
    }
  }, [initHeaders]);

  return (
    <>
      <WordReportHeader heading="Non-Chargeables" />
      <table
        style={{ border: "none", width: "100%" }}
        cellSpacing={5}
        cellPadding={0}
      >
        <tr>
          <td style={{ border: "none", width: "50%" }}>
            <WordLabel
              label={partnerLabel}
              value={orgPartner}
              backgroundColor={LabelColor.Blue}
            />
          </td>
          <td style={{ border: "none", width: "50%" }}>
            <WordLabel
              label="Date Range"
              value={`${displayDate(startDate)}-${displayDate(endDate)}`}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        <tr>
          <td style={{ border: "none" }} colSpan={2}>
            <WordLabel
              label={sourceLabel}
              value={partnerSource}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        {gridData.map((c, i) => (
          <React.Fragment key={`P${i}`}>
            <tr>
              <td style={{ border: "none" }} colSpan={2}>
                <WordLabel
                  label={partnerLabel}
                  value={c.partnerName}
                  backgroundColor={LabelColor.Purple}
                />
              </td>
            </tr>
            {c.sourceDetails.map((s, sIndex) => (
              <React.Fragment key={`S${sIndex}`}>
                <tr>
                  <td style={{ border: "none" }}>
                    <WordLabel
                      label={sourceLabel}
                      value={s.sourceName}
                      backgroundColor={LabelColor.Blue}
                    />
                  </td>
                </tr>
                {s.empDetails.map((e, index) => (
                  <React.Fragment key={`E${index}`}>
                    <tr>
                      <td style={{ border: "none" }}>
                        <WordLabel
                          label="Staff"
                          value={e.employeeName}
                          backgroundColor={LabelColor.LightBlue}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none" }} colSpan={2}>
                        <WordTable
                          headers={headers}
                          data={e.gridData}
                          tableProps={{ cellSpacing: 0 }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none" }} colSpan={2}>
                        <WordTable
                          headers={headers}
                          hideHeaders
                          tableProps={{ cellSpacing: 0 }}
                          data={[
                            totalRow(
                              headers,
                              `Totals of: ${e.employeeName}`,
                              e.totalCost,
                              e.totalUnits,
                              LabelColor.LightBlue
                            ),
                          ]}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <td style={{ border: "none" }} colSpan={2}>
                    <WordTable
                      headers={headers}
                      tableProps={{ cellSpacing: 0 }}
                      hideHeaders
                      data={[
                        totalRow(
                          headers,
                          `Totals of: ${s.sourceName}`,
                          s.totalCost,
                          s.totalUnits,
                          LabelColor.Blue
                        ),
                      ]}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
            <tr>
              <td style={{ border: "none" }} colSpan={2}>
                <WordTable
                  headers={headers}
                  tableProps={{ cellSpacing: 0 }}
                  hideHeaders
                  data={[
                    totalRow(
                      headers,
                      `Totals of: ${c.partnerName}`,
                      c.totalCost,
                      c.totalUnits,
                      LabelColor.Purple
                    ),
                  ]}
                />
              </td>
            </tr>
          </React.Fragment>
        ))}
      </table>
    </>
  );
};
