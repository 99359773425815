import React from "react";
import WeekSelectBox from "../../../common/WeekSelectBox";
import { getWeekStartAndEnd } from "../../../libs/dateHelper";

export default ({ timesheetDates, handleChange, hideDefaultOption }) => {
  const { startDate, endDate } = timesheetDates;

  const onDateChange = (name, value) => {
    handleChange(getWeekStartAndEnd(value));
  };

  return (
    <div className="row wkrowdiv">
      {/* <div className='col-12 col-md-4'>
                <DisplayLabel
                    label="Week"
                    value={`${displayDate(startDate)}-${displayDate(endDate)}`}
                />
            </div> */}
      <div className="col-12 col-md-6 offset-md-6">
        <WeekSelectBox
          {...timesheetDates}
          handleChange={handleChange}
          hideDefaultOption={hideDefaultOption}
          selectClassName="weekSelect"
          showMonthYear
        />
      </div>
      {/* <div className='col-12 col-md-4 tDate'>
                <UserDatePicker
                    name="timesheetDate"
                    onChange={onDateChange}
                    placeholder="--Date--"
                    minDate={finStartDate}
                    maxDate={finEndDate}
                />
            </div> */}
    </div>
  );
};
