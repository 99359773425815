import {Account} from '../actionTypes/account';

export const loginUser=(payload)=>({
    type:Account.LOGIN_USER,
    payload
});

export const signOut=(payload)=>({
    type:Account.LOGOUT_USER,
    payload
});

export const impersonateUser=(payload)=>({
    type:Account.IMPERSONATE_USER,
    payload
});


export const stopImpersonation=()=>({
    type:Account.STOP_IMPERSONATION
});

export const setUserTheme=(payload)=>({
    type:Account.SET_USER_THEME,
    payload
});

export const verifyUser=(payload,cb)=>({
    type:Account.VERIFY_USER,
    payload,
    cb
});

export const changePassword=(payload,cb)=>({
    type:Account.CHANGE_PASSWORD,
    payload,
    cb
});

export const forgotPassword=(payload,cb)=>({
    type:Account.FORGOT_PASSWORD,
    payload,
    cb
});

export const resetPassword=(payload,cb)=>({
    type:Account.RESET_PASSWORD,
    payload,
    cb
});

export const confirmAccount=(payload,cb)=>({
    type:Account.CONFIRM_ACCOUNT,
    payload,
    cb
});