import APIUtil from './APIUtil';

class OrgBillingAPI
{
    
    addBillingNarratives(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgBilling/AddEditBillingNarratives`;
        return fetch(url, options);
    }

    getBillingNarratives(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgBilling/GetBillingNarratives`;
        return fetch(url, options);
    }

    deleteBillingNarratives(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgBilling/DeleteBillingNarratives`;
        return fetch(url, options);
    }
}

export default new OrgBillingAPI();