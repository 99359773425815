import React from "react";
import { UserTable } from "../../../components/Table";
import { GridAction } from "../../../utils/GridAction";
import useBillingHook from "../../hooks/useBillingHook";

export default ({ gridData, rowId, handleRowChange, handleDelete }) => {
  const { billingProps } = useBillingHook();

  let headers = [
    {
      key: "billNarrativesDesc",
      text: "Description",
      type: GridAction.CUSTOM_POPUP,
      ...billingProps,
      handleChange: handleRowChange,
    },
    {
      key: "deleteBtn",
      text: "Delete",
      type: GridAction.BUTTON,
      handleChange: handleDelete,
    },
  ];

  return <UserTable headers={headers} data={gridData} rowId={rowId} />;
};
