import React, { useEffect, useState } from "react";

export const TableCell = ({ cellProps, text, textColor, rowIndex }) => {
  return (
    <td
      align="left"
      {...cellProps}
      style={{
        border: "none",
        color: textColor ? textColor : "#475f7b",
        ...(rowIndex % 2 == 0 ? { backgroundColor: "#eeeeee" } : null),
        ...cellProps?.style,
      }}
    >
      <p
        style={{
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        &nbsp;{text}
      </p>
    </td>
  );
};

const Cell = ({ row, cellKey, textColor, rowIndex }) => {
  const cell = row.find((r) => r.key === cellKey);

  return (
    <>
      {!cell?.cellProps?.hidden && (
        <TableCell
          cellProps={cell?.cellProps}
          text={cell?.text}
          textColor={textColor}
          rowIndex={rowIndex}
        />
      )}
    </>
  );
};

export default ({
  headers,
  data,
  rowId,
  headerTextColor,
  textColor,
  hideHeaders,
  tableProps,
}) => {
  const [tableHeaders, setHeaders] = useState([]);
  const [tableData, setData] = useState([]);

  const setTableHeaders = () => {
    if (headers) setHeaders(headers);
  };

  const setTableData = (data) => {
    if (data) setData(normalizeData(data));
  };

  function normalizeData(data) {
    return data.map((td) => {
      const keys = Object.keys(td);
      return keys.map((key) => ({
        ...td,
        key,
        text: td[key],
        rowId: td[rowId],
        cellProps: td[key + "Props"],
      }));
    });
  }

  useEffect(setTableHeaders, [headers]);

  useEffect(() => setTableData(data), [data]);

  return (
    <table
      cellPadding={0}
      cellSpacing={5}
      style={{ border: "none", width: "100%", borderCollapse: "separate" }}
      {...tableProps}
    >
      {!hideHeaders && (
        <tr style={{ border: "none" }}>
          <>
            {tableHeaders.map((h, index) => (
              <th
                style={{ border: "none", ...h.headerStyle }}
                key={`H${index + 1}`}
                align="left"
              >
                <p
                  style={{
                    color: headerTextColor ? headerTextColor : "#475f7b",
                    marginTop: 0,
                    marginBottom: 0,
                    ...h.style,
                  }}
                >
                  &nbsp;{h.text}
                </p>
              </th>
            ))}
          </>
        </tr>
      )}
      {tableData.map((row, i) => (
        <tr key={`R${i}`}>
          {tableHeaders.map((prop, index) => (
            <Cell
              cellKey={prop.key}
              row={row}
              textColor={textColor}
              key={`R${i}Cell${index}`}
              rowIndex={i}
            />
          ))}
        </tr>
      ))}
    </table>
  );
};
