import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimesheetByType, setTimesheet } from "../../../actions/timesheet";
import { LookUp } from "../../../utils/lookUpConstant";
import { FeeHeader } from "../Components";
import IncomeGrid from "../EmployeeWorkSummary/IncomeGrid";
import { Report } from "../EmployeeWorkSummary";
import "./EmployeeWorkSummary.scss";
import useUserDetail from "../../../hooks/useUserDetail";

export default React.forwardRef(
  ({ showReport, reportTitle, reportType, toggleReportBtn, gridRef }, ref) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({});
    const { theme } = useUserDetail();

    const { timesheets } = useSelector((state) => state.timesheet);

    useEffect(() => {
      toggleReportBtn(timesheets && timesheets.length > 0);
    }, [timesheets]);

    useEffect(() => {
      dispatch(setTimesheet({ timesheets: [] }));
    }, []);

    const getTimesheets = (payload) => {
      setState(payload);
      dispatch(
        getTimesheetByType({
          ...payload,
          statusList: [LookUp.AUTHORISED_TS],
        })
      );
    };

    return (
      <>
        <FeeHeader handleView={getTimesheets} />
        <IncomeGrid {...state} />
        {showReport && (
          <div className="col-12 reportDataContainer">
            <div className={"row plm_rp_rptort_col App " + theme} ref={gridRef}>
              <div className="col-xs-12 col-12 col-md-12 reportWrapper  rwrapPdf">
                <Report
                  {...state}
                  reportTitle={reportTitle}
                  reportType={reportType}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
