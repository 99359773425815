import React, { useState } from 'react';
import useToggle from '../../hooks/useToggle';
import { useDispatch } from 'react-redux';
import { verifyUser, forgotPassword } from '../../actions/account';
import { useHistory } from 'react-router';
import { UserModal } from '../../components/Modal';
import { TextInput } from '../../components/TextInput';
import useForm from '../../hooks/useForm';
import { Error } from '../../components/Label';
import { RouteConstant } from '../../utils/routeConstant';

const ValidateUser=({isUser,isForgot,showModal,closeModal})=>{

    const validationSchema={
        email:{
            required: true
        },
        accountId:{
            required:isUser
        }
    };

    const dispatch=useDispatch();

    const {
        state,
        errors,
        handleChange,
        handleSubmit
    }=useForm({},validationSchema,handleSave);

    const [secretQuestion,setQuestion]=useState(null);
    const [error,setError]=useState(null);
    const history=useHistory();

    const {email,accountId,answer}=state;
    const {question}=secretQuestion?secretQuestion:{};

    const handleForgot=()=>{
        dispatch(forgotPassword(state));
        closeModal();
    }

    function handleSave(userDetail)
    {
        if(secretQuestion)
        {
            if(secretQuestion.answer==answer)
            {
                handleForgot();
            }
            else{
                setError('Wrong Answer');
            }
        }
        else{
            dispatch(verifyUser(userDetail,({secretQuestion})=>{
                if(isForgot)
                {
                    if(isUser)
                        setQuestion(secretQuestion);
                    else{
                            handleForgot();
                        }
                }
                else{
                    closeModal();
                    history.push(RouteConstant.RESET_PASSWORD,{
                        showOldPassword:true,
                        userDetail
                    });
                }
            }));
        }
    }

    return (
            <UserModal
                show={showModal}
                handleClose={closeModal}
                title="User Details"
                handleSave={handleSubmit}
            >   
                {secretQuestion?
                    <div className="formField">
                        <TextInput
                            name="answer"
                            label={question}
                            value={answer}
                            placeholder="Answer"
                            onChange={handleChange}
                            errors={errors}
                        />
                    </div>
              :
              <>
                <div className="formField">
                    <TextInput
                        name="email"
                        label="Username"
                        value={email}
                        placeholder="userName"
                        onChange={handleChange}
                        errors={errors}
                    />
                </div>
                {isUser &&
                    <div className="formField">
                        <TextInput
                            name="accountId"
                            label="Account Id"
                            value={accountId}
                            placeholder="Account Id"
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                }
                </>
            }
            {error &&
                <Error
                    error={error}
                />
            }
        </UserModal>
    )
}


export default ({isUser})=>{

    const [isForgot,setIsForgot]=useState(false);
    const {show:showValidate,toggle:toggleValidate}=useToggle(false);

    const handleClick=(isForgot)=>{
        setIsForgot(isForgot);
        toggleValidate();
    }

    const handleClose=()=>{
        toggleValidate();
    }
        

    return (
        <div className='row' style={{'justifyContent':'center'}}>
            <span className='cursor' onClick={()=>handleClick(true)}>
                Forgot
            </span>
            /
            <span className='cursor' onClick={()=>handleClick(false)}>
                Change Password?
            </span>
            {showValidate &&
                <ValidateUser
                    isForgot={isForgot}
                    isUser={isUser}
                    showModal={showValidate}
                    closeModal={handleClose}

                />
            }
        </div>
    )
}