import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartnerSource from "../../../common/PartnerSource";
import {
  formatAPIDate,
  getReportStartAndEndDate,
} from "../../../libs/dateHelper";
import { getTimesheetByStatus, setTimesheet } from "../../../actions/timesheet";
import { UserButton } from "../../../components/Button";
import { TimesheetGrid, Report } from "../NonSubmittedTimesheet";
import useUserDetail from "../../../hooks/useUserDetail";
import "./NonSubmittedReport.scss";
import { AppConstant } from "../../../utils/constant";
import EmployeeSelectBox from "../../../common/EmployeeSelectBox";
import { getTimesheetEmployees } from "../../../actions/report";
import { DateFilter } from "../Components";
import { UserDatePicker } from "../../../components/DateControl";
import ReportFilterWrapper from "../../../common/ReportFilterWrapper";

const { REPORT_FIRST_ITEM_TEXT } = AppConstant;

const reportDates = getReportStartAndEndDate();

export default React.forwardRef(
  ({ showReport, reportType, toggleReportBtn, gridRef }, ref) => {
    const dispatch = useDispatch();
    const { theme } = useUserDetail();
    const { timesheets } = useSelector((state) => state.timesheet);
    const [state, setState] = useState(reportDates);
    const { orgPartnerId, partnerSourceId, startDate, endDate, employeeId } =
      state;

    useEffect(() => {
      dispatch(getTimesheetEmployees());
      dispatch(setTimesheet({ timesheets: [] }));
    }, []);

    useEffect(() => {
      toggleReportBtn(timesheets && timesheets.length > 0);
    }, [timesheets]);

    const handleChange = (name, value) => {
      setState((prev) => ({ ...prev, [name]: value }));
    };

    const getTimesheets = () => {
      dispatch(
        getTimesheetByStatus({
          startDate: formatAPIDate(startDate),
          endDate: formatAPIDate(endDate),
          orgPartnerId,
          partnerSourceId,
          userId: employeeId,
        })
      );
    };

    return (
      <>
        <div className="ctop">
          <div className="ctop-flex">
            <PartnerSource
              hideAsterix={true}
              handleChange={handleChange}
              firstItemText={REPORT_FIRST_ITEM_TEXT}
              orgPartnerId={orgPartnerId}
              partnerSourceId={partnerSourceId}
              isReport
            />
            <ReportFilterWrapper label="From" labelClassName="back-blue">
              <DateFilter
                name="startDate"
                value={startDate}
                onChange={handleChange}
                className="filter-control"
              />
            </ReportFilterWrapper>
            <ReportFilterWrapper label="To" labelClassName="back-blue">
              <DateFilter
                name="endDate"
                value={endDate}
                onChange={handleChange}
                className="filter-control"
              />
            </ReportFilterWrapper>
            <EmployeeSelectBox
              value={employeeId}
              orgPartnerId={orgPartnerId}
              partnerSourceId={partnerSourceId}
              onChange={handleChange}
              isReport
            />
          </div>
          <UserButton
            text="View"
            onClick={getTimesheets}
            className="btn btn-purple"
          />
        </div>
        <TimesheetGrid {...state} />
        {showReport && (
          <div className="reportDataContainer">
            <div className={"row plm_rp_rptort_col App " + theme} ref={gridRef}>
              <div className="reportWrapper rwrapPdf">
                <Report {...state} reportType={reportType} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
