import { useSelector } from "react-redux";
import { useMemo } from "react";
import {LookUp} from '../../utils/lookUpConstant';

const useTimesheetStatus=()=>{

    const {timesheetStatusList}=useSelector(state=>state.common);
    let openTimesheetId,submittedTimesheetId,authorisedTimesheetId;

    const getStatus=(status)=>
        timesheetStatusList.find(c=>c.text==status)?.value;
    
    if(timesheetStatusList && timesheetStatusList.length>0)
    {
        openTimesheetId=getStatus(LookUp.OPEN_TS);
        submittedTimesheetId=getStatus(LookUp.SUBMITTED_TS);
        authorisedTimesheetId=getStatus(LookUp.AUTHORISED_TS);
    }

    return useMemo(()=>({
        openTimesheetId,
        submittedTimesheetId,
        authorisedTimesheetId
    }),[timesheetStatusList]);


}

export default useTimesheetStatus;