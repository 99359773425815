import {Pages} from '../actionTypes/pages';

const initialState={
}

export default (state=initialState,action)=>{
    switch(action.type)
    {
        case Pages.SET_PAGES:
            return {
                ...state,
                ...action.result
            }
        default:
            return state
    }
}