import React from "react";
import { CustomSelectBox } from "../SelectBox";
import { Cell } from "./Cell";
import { CustomInput } from "../TextInput";
import { CustomPopup } from "../Modal";

const Search = ({
  data,
  optionKey,
  handleSearch,
  searchBy,
  showCustomInput,
  inputProps,
}) => {
  const options = data.map((c) => ({
    text: c[optionKey],
    value: c[optionKey],
  }));
  if (showCustomInput) {
    return (
      <CustomPopup
        {...inputProps}
        name={optionKey}
        value={searchBy[optionKey]}
        onChange={handleSearch}
        showSearch={true}
      />
    );
  }
  return (
    <CustomSelectBox
      options={options}
      showSearch
      name={optionKey}
      handleChange={handleSearch}
      value={searchBy[optionKey]}
    />
  );
};

const Sort = ({ optionKey, sortBy, handleSort, showReportSortIc }) => {
  const value = sortBy[optionKey];

  return (
    <>
      {showReportSortIc ? (
        <>
          {value ? (
            <i
              onClick={() => handleSort(optionKey, value)}
              className={`pull-right fa fa-chevron-${
                value == "asc" ? "up" : "down"
              }`}></i>
          ) : (
            <span className="sortIc">
              <i
                onClick={() => handleSort(optionKey, value)}
                className="pull-right asc fa fa-chevron-up"></i>
              <i
                onClick={() => handleSort(optionKey, value)}
                className="pull-right desc fa fa-chevron-down"></i>
            </span>
          )}
        </>
      ) : (
        <i
          onClick={() => handleSort(optionKey, value)}
          className={"pull-right fa fa-sort" + (value ? "-" + value : "")}></i>
      )}
    </>
  );
};

const Header = ({
  optionKey,
  text,
  hType,
  hName,
  search,
  sort,
  data,
  colSpan,
  headerProps,
  headerActionProps,
  handleSearch,
  searchBy,
  sortBy,
  handleSort,
  handleChange,
  showCustomInput,
  inputProps,
  showSearchFirst,
  showReportSortIc,
}) => {
  return (
    <th className="th" colSpan={colSpan} {...headerProps}>
      {showSearchFirst ? (
        <>
          {search && (
            <div className="searchWrap">
              <Search
                optionKey={optionKey}
                data={data}
                handleSearch={handleSearch}
                searchBy={searchBy}
                showCustomInput={showCustomInput}
                inputProps={inputProps}
              />
            </div>
          )}
          <div className="th-cell">
            <Cell
              text={text}
              name={hName}
              handleChange={handleChange}
              type={hType}
              actionProps={headerActionProps}
            />
            {sort && (
              <Sort
                optionKey={optionKey}
                sortBy={sortBy}
                handleSort={handleSort}
                showReportSortIc={showReportSortIc}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className="th-cell">
            <Cell
              text={text}
              name={hName}
              handleChange={handleChange}
              type={hType}
              actionProps={headerActionProps}
            />
            {sort && (
              <Sort
                optionKey={optionKey}
                sortBy={sortBy}
                handleSort={handleSort}
                showReportSortIc={showReportSortIc}
              />
            )}
          </div>
          {search && (
            <div className="searchWrap">
              <Search
                optionKey={optionKey}
                data={data}
                handleSearch={handleSearch}
                searchBy={searchBy}
                showCustomInput={showCustomInput}
                inputProps={inputProps}
              />
            </div>
          )}
        </>
      )}
    </th>
  );
};

export default ({ headers, data, showActions, ...rest }) => {
  return (
    <thead className="thead">
      <tr className="tr">
        {headers.map((d, index) => (
          <Header key={index} {...d} data={data} optionKey={d.key} {...rest} />
        ))}
        {showActions && <th></th>}
      </tr>
    </thead>
  );
};
