import {Report} from '../actionTypes/report';

export const getReportView=(payload)=>({
    type:Report.GET_REPORT_VIEW,
    payload
});

export const getTimesheetEmployees=(payload)=>({
    type:Report.GET_TIMESHEET_EMPLOYEES,
    payload
});

export const downloadReport=(payload)=>({
    type:Report.DOWNLOAD_REPORT,
    payload
});

export const sendReportEmail=(payload)=>({
    type:Report.SEND_REPORT_EMAIL,
    payload
});