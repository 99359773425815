import { GridAction } from "../../utils/GridAction";
import { Timesheet } from "../../utils/timesheetConstant";
import useRole from "../../hooks/useRole";
const { useSelector } = require("react-redux");
const { useState, useEffect } = require("react");
const { default: useCustomGrid } = require("./useCustomGrid");

const { CLIENT_GRID_ID } = Timesheet;

const useClientHook = () => {
  const { clients: initClients } = useSelector((state) => state.common);
  const [clients, setClients] = useState([]);
  const { isUser } = useRole();
  useEffect(() => {
    if (initClients) setClients(initClients.filter((c) => c.isUserClient));
  }, [initClients]);

  const clientsData = useCustomGrid(
    CLIENT_GRID_ID,
    isUser ? clients : initClients
  );

  const filterClients = (filter) => {
    let clients = initClients;
    if (filter) clients = clients.filter((c) => c.isUserClient);

    setClients(clients);
  };

  let actions = [];
  if (isUser)
    actions = [
      {
        type: GridAction.BUTTON,
        text: "My Clients",
        actionProps: { className: "popupGridAction btn btn-P left" },
        handleChange: () => filterClients(true),
      },
      {
        type: GridAction.BUTTON,
        text: "All Clients",
        actionProps: { className: "popupGridAction btn btn-P right" },
        handleChange: () => filterClients(),
      },
    ];

  const clientProps = {
    actionProps: {
      headers: [
        { key: "clientCode", text: "Ref" },
        { key: "clientName", text: "Client" },
      ],
      ...clientsData,
      actions,
      tblClass: "clientPopupGrid",
    },
  };

  return {
    clientProps,
  };
};

export default useClientHook;
