import React from "react";
import { UserTable } from "../../../components/Table";
import { GridAction } from "../../../utils/GridAction";

export default (props) => {
  const {
    customers,
    rowId,
    handleChange,
    handleRowClick,
    handleHeaderChange,
    handleEnterSystem,
  } = props;

  const headers = [
    { key: "orgName", text: "Customer Name", sort: true },
    { key: "fullName", text: "Contact Name", sort: true },
    { key: "startDate", text: "Financial Year Start Date" },
    { key: "emailId", text: "Email" },
    { key: "phoneno", text: "Phone No" },
    { key: "accountId", text: "Account Id" },
    {
      key: "enable",
      type: GridAction.TOGGLE_BUTTON,
      text: "Status",
      handleChange,
    },
    { key: "noOfUsers", text: "No of Users" },
    {
      key: "enterSystem",
      type: GridAction.BUTTON,
      actionProps: {
        text: "Enter System",
      },
      handleChange: handleEnterSystem,
    },
  ];

  return (
    <UserTable
      headers={headers}
      data={customers}
      rowId={rowId}
      handleRowClick={handleRowClick}
      handleHeaderChange={handleHeaderChange}
    />
  );
};
