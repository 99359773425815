import React, { useEffect } from 'react';
import './CustomerList.scss';
import { UserTable } from '../../components/Table';
import { GridAction } from '../../utils/GridAction';
import useGridHook from '../../hooks/useGridHook';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveCustomers } from '../../actions/customer';
import { impersonateUser } from '../../actions/account';
import { UserCard } from '../../components/Container';

const rowId='customerId';

export default (props)=>{

    const dispatch=useDispatch();
    const {gridData,setGridData}=useGridHook(rowId);
    const {customers}=useSelector(state=>state.customer);
  
    const fetchCustomers=()=>{
        dispatch(getActiveCustomers());
    }

    const setCustomers=(customers)=>{
        if(customers)
            setGridData(customers.map(c=>({...c,enterSystem:''})));
    }

    useEffect(fetchCustomers,[]);

    useEffect(()=>setCustomers(customers),[customers]);


    const handleClick=(customerId,event)=>{
        if(event)
            event.stopPropagation();

        dispatch(impersonateUser({customerId}))
    }

    const headers=[
        {key:'orgName',text:'Customer Name',search:true,sort:true},
        {key:'accountId',text:'Account Id'},
        {key:'noOfUsers',text:'No of Users'},
        {
            key:'enterSystem',
            type:GridAction.BUTTON,
            actionProps:{
                text:"Enter System"
            },
            handleChange:handleClick
        },
    ]

    return (
        <div class="prm_customer_list_div">
             <div class="container-fluid prn_cfuid">
            <div class="row prn_row">
              <div class="col-12 prn_col12">
              <UserCard
            title="Customers List"
        >
                
            <UserTable
                headers={headers}
                data={gridData}
                rowId={rowId}
            />
            
        </UserCard>
       
              </div>
            </div>
            </div>
        </div>
    )

}