import React, { useState, useCallback, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { useSelector } from "react-redux";

export default function APIErrorProvider({ children }) {
  const { userDetail } = useSelector((state) => state.account);
  let initTheme;
  if (userDetail) ({ theme: initTheme } = userDetail);

  const [theme, setTheme] = useState();

  const changeTheme = (theme) => {
    setTheme(theme);
  };

  useEffect(() => {
    if (initTheme) changeTheme(initTheme);
    else changeTheme("PinkBlue");
  }, [initTheme]);

  const contextValue = {
    theme,
    updateTheme: useCallback((theme) => changeTheme(theme), []),
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}
