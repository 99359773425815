import React from "react";

export const LabelColor = {
  Purple: "#a42998",
  Blue: "#6093aa",
  LightBlue: "#AFC9D4",
};

export default ({ label, value, backgroundColor, width }) => {
  return (
    <table
      border="0"
      cellSpacing={0}
      cellPadding={0}
      style={{
        backgroundColor,
        color: "#ffffff",
        width: width ? width : "100%",
      }}
    >
      <tr>
        <td>
          <p style={{ marginBottom: 5, marginTop: 5, fontWeight: "bold" }}>
            &nbsp;{label}
            {value ? `: ${value}` : ""}
          </p>
        </td>
      </tr>
    </table>
  );
};
