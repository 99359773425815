import React, { useEffect, useState } from "react";
import GridButton from "../../common/GridButton";
import { useDispatch, useSelector } from "react-redux";
import useGridHook from "../../hooks/useGridHook";
import useToggle from "../../hooks/useToggle";
import { TaxRateGrid, AddTaxRate } from "./Components/TaxRates";
import { getTaxRates, deleteTaxRates } from "../../actions/taxRate";
import "./scss/TaxRates.scss";
import { UserCard } from "../../components/Container";
const rowId = "orgTaxId";

export default (props) => {
  const dispatch = useDispatch();
  const {
    gridData,
    handleChange,
    handleRowClick,
    setGridData,
    deleteRow,
  } = useGridHook(rowId, handleDelete);
  const { taxRates } = useSelector((state) => state.taxRate);
  const [selectedTaxRate, setSelectedTaxRate] = useState(null);

  const fetchTaxRates = () => {
    dispatch(getTaxRates());
  };

  const setTaxRates = (taxRates) => {
    if (taxRates)
      setGridData(
        taxRates.map((c) => ({
          ...c,
          default: c.isDefault ? "Yes" : "No",
        }))
      );
  };

  useEffect(fetchTaxRates, []);

  useEffect(() => setTaxRates(taxRates), [taxRates]);

  const { show: showAddTaxRate, toggle: toggleAddTaxRate } = useToggle();

  const handleAddEdit = (orgTaxId) => {
    setSelectedTaxRate(gridData.find((c) => c[rowId] == orgTaxId));
    toggleAddTaxRate();
  };

  function handleDelete(orgTaxId) {
    dispatch(deleteTaxRates(orgTaxId));
  }

  return (
    <div class="plm_taxratelist_div">
      <div class="container-fluid prn_cfuid">
        <div class="row prn_row">
          <div class="col-12 prn_col12">
            <UserCard title="Tax Rates">
              <TaxRateGrid
                rowId={rowId}
                taxRates={gridData}
                handleChange={handleChange.bind(this)}
                handleRowClick={handleRowClick.bind(this)}
                handleEdit={handleAddEdit}
                handleDelete={handleDelete}
              />
              <div className="plm_cmn_nocard">
                <GridButton handleAdd={handleAddEdit} />
              </div>
              {showAddTaxRate && (
                <AddTaxRate
                  showAddTaxRate={showAddTaxRate}
                  toggleModal={toggleAddTaxRate}
                  selectedTaxRate={selectedTaxRate}
                />
              )}
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
