import React, {useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import useForm from '../../../../hooks/useForm';
import { UserModal } from '../../../../components/Modal';
import { TextInput } from '../../../../components/TextInput';
import { addEditTaxRate, addTaxRateView } from '../../../../actions/taxRate';
import { AddTaxType } from '.';
import { CheckBox } from '../../../../components/CheckBox';
import {SelectBox} from '../../../../components/SelectBox';

const stateSchema={
    
};

const validationSchema={
    orgTaxTypeId:{
        required:true
    },
    taxName:{
        required:true
    },
    value:{
        required:true
    }
}

const AddTaxRate=({taxRateDetail,handleChange,errors})=>{

        const {
           orgTaxTypeId,
           value,
           taxName,
           isDefault,
           operationId,
           absTypeId,
        }=taxRateDetail;

        const {operations,absTypes,orgTaxTypes}=useSelector(state=>state.common);

        return (
                <div className='row'>
                    <div className='col-6'>
                        <TextInput
                            name="taxName"
                            label="Tax Component Name"
                            value={taxName}
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    <div className='col-6'></div>
                    <div className='col-6'>
                        <SelectBox
                            name="orgTaxTypeId"
                            value={orgTaxTypeId}
                            label="Tax Type"
                            onChange={handleChange}
                            options={orgTaxTypes}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    <div className='col-6'>
                        <AddTaxType
                        />
                    </div>
                    <div className='col-6'>
                        <SelectBox
                            name="operationId"
                            value={operationId}
                            label="Operation"
                            onChange={handleChange}
                            options={operations}
                            errors={errors}
                        />
                    </div>
                    <div className='col-6'></div>
                    <div className='col-6'>
                        <TextInput
                            name="value"
                            value={value}
                            label="Value"
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    <div className='col-6'></div>
                    <div className='col-6'>
                        <SelectBox
                            name="absTypeId"
                            value={absTypeId}
                            label="Abs/Percentage"
                            onChange={handleChange}
                            options={absTypes}
                        />
                    </div>
                    <div className='col-6'></div>
                    <div className='col-6'>
                        <CheckBox
                            name="isDefault"
                            value={isDefault}
                            label="Set as Default"
                            onChange={handleChange}
                            errors={errors}
                        />
                    </div>
                </div>
        )
}


export default ({showAddTaxRate,toggleModal,selectedTaxRate})=>{

    const {
        state:taxRateDetail,
        errors,
        handleChange,
        handleSubmit,
        handleStateChange
    }=useForm(stateSchema,validationSchema,handleSave);

    const {operations,absTypes,orgTaxTypes}=useSelector(state=>state.common);

    useEffect(()=>{
        if(!selectedTaxRate)
        {
            let state=taxRateDetail;
            if(operations && operations.length>0)
                state={...state,operationId:operations[0].value};
            if(absTypes && absTypes.length>0)
                state={...state,absTypeId:absTypes[0].value};

            handleStateChange(state);
        } 
    },[operations,absTypes]);

    useEffect(()=>{
        if(!selectedTaxRate && orgTaxTypes && orgTaxTypes.length>0)
        {
           handleStateChange({orgTaxTypeId:orgTaxTypes[0].value});
        } 
    },[orgTaxTypes]);

    const dispatch=useDispatch();

    useEffect(()=>{
        initPage();
        if(selectedTaxRate)
            handleStateChange(selectedTaxRate);
    },[]);

    const initPage=()=>{
        dispatch(addTaxRateView());
    }

    function handleSave(taxRateDetail){
        dispatch(addEditTaxRate(taxRateDetail));
        toggleModal();
    }

    return (
        <div className='col-12'>
            <UserModal
                show={showAddTaxRate}
                handleClose={toggleModal}
                title="Add Tax Rate"
                handleSave={handleSubmit}
            >
                <AddTaxRate
                    taxRateDetail={taxRateDetail}
                    handleChange={handleChange}
                    errors={errors}
                />
            </UserModal>
        </div>
    )
};