import React from "react";
import { WordLabel } from "../../../components/Label";
import { LabelColor } from "../../../components/Label/WordLabel";
import { WordTable } from "../../../components/Table";
import { WordReportHeader } from "../Components";

const headers = [
  { key: "clientCode", text: "Ref" },
  { key: "clientName", text: "Client Name" },
  { key: "billingName", text: "Billing Name" },
  { key: "address", text: "Address" },
  { key: "contactPerson", text: "Contact Name" },
  { key: "emailId", text: "Email" },
  { key: "mobileno", text: "Telephone" },
];

export default ({ partnerLabel, sourceLabel, gridData }) => {
  return (
    <>
      <WordReportHeader heading="Client List" />
      <table style={{ border: "none", width: "100%" }} cellSpacing={5}>
        {gridData.map((c) => (
          <React.Fragment key={c.orgPartner}>
            <tr>
              <td style={{ border: "none" }}>
                <WordLabel
                  label={partnerLabel}
                  value={c.orgPartner}
                  backgroundColor={LabelColor.Purple}
                />
              </td>
            </tr>
            {c.sources.map((s) => (
              <React.Fragment key={s.partnerSource}>
                <tr>
                  <td style={{ border: "none" }}>
                    <WordLabel
                      label={sourceLabel}
                      value={s.partnerSource}
                      backgroundColor={LabelColor.Blue}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "none" }}>
                    <WordTable headers={headers} data={s.clients} />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </table>
    </>
  );
};
