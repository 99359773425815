import { Timesheet } from "../../utils/timesheetConstant";
const { useSelector } = require("react-redux");
const { useState, useEffect } = require("react");
const { default: useCustomGrid } = require("./useCustomGrid");

const { ACTIVITY_GRID_ID } = Timesheet;

const useJobHook = (timesheetTypeId, defaultRate) => {
  const { activityCodes: initActivityCodes } = useSelector(
    (state) => state.common
  );
  const [activityCodes, setActivityCodes] = useState([]);
  const activityData = useCustomGrid(ACTIVITY_GRID_ID, activityCodes);

  useEffect(() => {
    if (initActivityCodes) {
      let activityCodes = initActivityCodes;
      if (timesheetTypeId)
        activityCodes = activityCodes.filter(
          (c) => c.activityTypeId == timesheetTypeId
        );
      if (defaultRate)
        setActivityCodes(activityCodes.map((c) => ({ ...c, ...defaultRate })));
      else setActivityCodes(activityCodes);
    }
  }, [initActivityCodes, defaultRate]);

  const activityProps = {
    actionProps: {
      headers: [
        { key: "activityName", text: "Code" },
        { key: "activityDesc", text: "Name" },
      ],
      ...activityData,
    },
  };

  return {
    activityProps,
  };
};

export default useJobHook;
