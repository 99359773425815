import {Common} from '../actionTypes/common';

const initialState={
    isLoading:false
}

export default (state=initialState,action)=>{
    switch(action.type)
    {
        case Common.SHOW_PROGRESS:
            return {
                ...state,
                isLoading:true
            }
        case Common.CLOSE_PROGRESS:
            return {
                ...state,
                isLoading:false
            }
        default:
            return {
                ...state
            }
    }
}