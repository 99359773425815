import React, { useEffect } from "react";
import useForm from "../../../hooks/useForm";
import {
  getTransactions,
  getInvoices,
  setInvoice,
} from "../../../actions/invoice";
import { useDispatch, useSelector } from "react-redux";
import ClientSelectBox from "../../../common/ClientSelectBox";
import TransactionDate from "../../../common/TransactionDate";
import { TransactionGrid, Report, InvoiceGrid } from "../ClientWIP";
import { UserButton } from "../../../components/Button";
import { getClientSelectList } from "../../../actions/client";
import { setTimesheet } from "../../../actions/timesheet";
import "./ClientWIPDetails.scss";
import { WIPGroupBy } from "../Components";
import { getReportStartAndEndDate } from "../../../libs/dateHelper";
import useUserDetail from "../../../hooks/useUserDetail";

const { endDate } = getReportStartAndEndDate();

const stateSchema = {
  timesheetDate: endDate,
  isJob: true,
};

const validationSchema = {
  clientName: {
    required: true,
  },
  clientCode: {
    required: true,
  },
};

export default React.forwardRef(
  ({ showReport, reportType, toggleReportBtn, gridRef }, ref) => {
    const dispatch = useDispatch();
    const { transactions } = useSelector((state) => state.invoice);
    const { state, errors, handleChange, handleStateChange, handleSubmit } =
      useForm(stateSchema, validationSchema, fetchTransaction);
    const { theme } = useUserDetail();

    useEffect(() => {
      dispatch(getClientSelectList());
      dispatch(
        setInvoice({
          invoices: [],
          invoiceDetails: [],
          transactions: [],
        })
      );
    }, []);
    useEffect(() => {
      toggleReportBtn(transactions && transactions.length > 0);
    }, [transactions]);

    const { timesheetDate } = state;

    function fetchTransaction(state) {
      dispatch(
        getTransactions({
          ...state,
          showAuthorised: true,
        })
      );
      dispatch(
        getInvoices({
          ...state,
        })
      );
    }

    return (
      <div className="clientWipDetailsRpt">
        <div className="ctop">
          <div className="ctop-flex">
            <ClientSelectBox
              state={state}
              asterix={true}
              errors={errors}
              onChange={handleStateChange}
              codeLabel="Reference"
              nameLabel="Client"
              isReport
            />
            <TransactionDate
              timesheetDate={timesheetDate}
              onChange={handleChange}
              asterix={true}
              allowMinDate
              isReport
            />
            <WIPGroupBy handleChange={handleChange} />
          </div>
          <UserButton
            text="View"
            onClick={handleSubmit}
            className="btn btn-purple"
          />
        </div>
        <TransactionGrid {...state} />
        {showReport && (
          <div className="col-12 reportDataContainer">
            <div className={"plm_rp_rptort_col App " + theme} ref={gridRef}>
              <div className="row reportWrapper rwrapPdf">
                <div className="col-xs-12 cwd-t-tbl">
                  <Report {...state} reportType={reportType} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);
