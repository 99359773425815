import { useEffect } from "react";
import useToggle from "./useToggle";

function useOnClickOutside(ref) {
    const {show,setShow}=useToggle();
    useEffect(
      () => {
        const listener = event => {
         
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
  
          setShow(false);
        };
  
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
  
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
     
      [ref]
    );

    return {
        show,
        setShow
    };
  }

  export default useOnClickOutside;