import React, { useState, useEffect } from "react";
import { UserTable } from "../../../components/Table";
import { formatNegativeCost } from "../../../libs/functions";
import useClientHook from "../../hooks/useClientHook";

export default ({
  invoices,
  rowId,
  handleRowClick,
  handleHeaderChange,
  tblClass,
  isReport,
}) => {
  const [gridData, setGridData] = useState([]);
  const {
    clientProps: { actionProps },
  } = useClientHook();

  useEffect(() => {
    if (invoices)
      setGridData(
        invoices.map((c) => ({
          ...c,
          calculatedAmt: formatNegativeCost(c.calculatedAmt),
          actualAmt: formatNegativeCost(c.actualAmt),
          surplusDeficitAmt: formatNegativeCost(c.surplusDeficitAmt),
          totalAmount: formatNegativeCost(c.totalAmount),
        }))
      );
  }, [invoices]);

  const headers = [
    {
      key: "clientName",
      text: "Client Name",
      search: !isReport,
      sort: !isReport,
      showCustomInput: true,
      inputProps: actionProps,
    },
    {
      key: "invoiceDate",
      text: "Invoice Date",
      search: !isReport,
      sort: !isReport,
    },
    {
      key: "invoiceNo",
      text: "Invoice Number",
      search: !isReport,
      sort: !isReport,
    },
    { key: "calculatedAmt", text: "Costing" },
    { key: "actualAmt", text: "Actual Fee" },
    { key: "surplusDeficitAmt", text: "Surplus/Deficit" },
    {
      key: "totalAmount",
      text: "Invoice Total",
      search: !isReport,
      sort: !isReport,
    },
  ];

  return (
    <UserTable
      headers={headers}
      data={gridData}
      rowId={rowId}
      handleRowClick={handleRowClick}
      handleHeaderChange={handleHeaderChange}
      tblClass={tblClass}
    />
  );
};
