import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeeHeader } from "../Components";
import { getInvoices } from "../../../actions/invoice";
import { IncomeGrid, Report } from "../FeeIncomeSummary";
import "./FeeIncomeSummary.scss";
import useUserDetail from "../../../hooks/useUserDetail";

export default React.forwardRef(
  ({ showReport, reportTitle, reportType, toggleReportBtn, gridRef }, ref) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({});
    const { theme } = useUserDetail();

    const { invoices } = useSelector((state) => state.invoice);

    useEffect(() => {
      toggleReportBtn(invoices && invoices.length > 0);
    }, [invoices]);

    const getFeeSummary = (payload) => {
      setState(payload);
      dispatch(
        getInvoices({
          ...payload,
        })
      );
    };

    return (
      <>
        <FeeHeader handleView={getFeeSummary} hideEmployee={true} />
        <IncomeGrid {...state} />
        {showReport && (
          <div className="col-12 reportDataContainer">
            <div className={"row plm_rp_rptort_col App " + theme} ref={gridRef}>
              <div className="col-xs-12 reportWrapper rwrapperFreeIncSum rwrapPdf">
                <Report
                  {...state}
                  reportTitle={reportTitle}
                  reportType={reportType}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
