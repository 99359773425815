import React from "react";
import { Modal, Button } from "react-bootstrap";
import { UserButton } from "../Button";
import "./_modal.scss";

export default ({ handleClose, show, message, title, handleSave }) => {
  return (
    <Modal show={show} size="sm" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          No
        </Button>
        <UserButton
          text="Yes"
          onClick={handleSave}
          className="btn btn-delete"
        />
      </Modal.Footer>
    </Modal>
  );
};
