import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import "./LeftNavigation.scss";
import useToggle from "../../hooks/useToggle";
import { LinkButton } from "../Button";
import { RouteConstant } from "../../utils/routeConstant";
import { RenamingGroups } from "../../containers/Setup";
import { cloneDeep } from "lodash";
import { updateInTree } from "../../libs/functions";
import { setPages } from "../../actions/pages";

const SubMenu = ({ item: { pageName, pageUrl } }) => {
  const { show: showResource, toggle: toggleResource } = useToggle();
  return (
    <>
      <NavLink
        exact
        to={`/${pageUrl}`}
        activeClassName="active"
        title={pageName}>
        {pageName}
      </NavLink>
      {pageUrl && RouteConstant.SETUP_GROUPS.includes(pageUrl) && (
        <div className="renamingBtnWrap">
          <LinkButton text="Edit" onClick={toggleResource} />
        </div>
      )}
      {showResource && (
        <RenamingGroups showModal={showResource} toggleModal={toggleResource} />
      )}
    </>
  );
};

const Menu = ({
  children,
  item: { pageName, pageId, isOpen },
  hideHeading,
}) => {
  const { permissions: initPermissions } = useSelector((state) => state.pages);
  const dispatch = useDispatch();

  const handleClick = () => {
    const permissions = updateInTree(
      cloneDeep(initPermissions),
      "pageId",
      [pageId],
      "isOpen",
      !isOpen
    );
    dispatch(setPages({ permissions }));
  };

  return (
    <>
      {!hideHeading && (
        <p className="rpt_title">
          {pageName}
          {/* <span>
            <i
              onClick={handleClick}
              className={
                "fa pull-right " +
                (isOpen ? "fa-minus-circle" : "fa-plus-circle")
              }></i>
          </span> */}
          <span className="pull-right circle-icon">
          <span
              onClick={handleClick}
              className={
                "material-icons "
              }>
                {(isOpen) ? "remove_circle" : "add_circle"}
              </span>
          </span>
        </p>
      )}
      {children.map((c, index) => (
        <React.Fragment key={index}>
          <ul className="list-unstyled left_reportmenu">
            {c.children && c.children.length > 0 ? (
              <li>
                <Menu {...c} />
              </li>
            ) : (
              <li>{isOpen && <SubMenu item={c.item} />}</li>
            )}
          </ul>
        </React.Fragment>
      ))}
    </>
  );
};

export default ({ menuName ,menuWrapperClass}) => {
  const { permissions: initPermissions } = useSelector((state) => state.pages);
  const [permissions, setPermissions] = useState([]);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (initPermissions) {
      setPermissions(
        initPermissions.filter((c) => c.item.pageName == menuName)
      );
    }
  }, [initPermissions]);

  useEffect(() => {
    const permissions = updateInTree(
      cloneDeep(initPermissions),
      "pageUrl",
      [pathname.substring(1)],
      "isOpen",
      true
    );
    dispatch(setPages({ permissions }));
  }, [pathname]);

  return (
    <div className={`plm_leftnav_wrap ${menuWrapperClass?'reportMenuWrapper':''}`}>
      {permissions.map((c, index) => (
        <Menu {...c} key={index} hideHeading />
      ))}
    </div>
  );
};
