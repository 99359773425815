import moment from "moment";
import { AppConstant } from "../utils/constant";

const { API_FORMAT, DATE_DISPLAY_FORMAT } = AppConstant;

const format = [
  "DD/MM/YYYY",
  "YYYY-MM-DD hh:mm:ss",
  "MM/DD/YYYY hh:mm:ss",
  API_FORMAT,
  DATE_DISPLAY_FORMAT,
  "MM/DD/YYYYHH:mm:ss",
];
const weekDayFormat = "ddd";
export const getMomentDate = (date) => (date ? moment(date, format) : date);

export const formatAPIDate = (date) => moment(date, format).format(API_FORMAT);

export const formatWeekDay = (date) =>
  moment(date, format).format(weekDayFormat);

export const getStartOfWeek = (date) => getMomentDate(date).startOf("isoweek");

export const getEndOfWeek = (date) => getMomentDate(date).endOf("isoweek");

export const displayDate = (date) =>
  date ? getMomentDate(date).format(DATE_DISPLAY_FORMAT) : "";

export const getWeeksDiff = (start, end) =>
  Math.abs(getStartOfWeek(end).diff(getStartOfWeek(start), "weeks"));

export const getWeekStartAndEnd = (date) => ({
  startDate: getStartOfWeek(date),
  endDate: getEndOfWeek(date),
});

export const getReportStartAndEndDate = () => ({
  startDate: getEndOfWeek(new Date()).add(-1,"year").toDate(),
  endDate: getEndOfWeek(new Date()).toDate(),
});

export const getWeekDays = (date) => {
  let startDate = moment(date ? date : new Date())
    .clone()
    .startOf("isoWeek");
  return [...new Array(7)].map((c, index) => {
    const weekDay = {
      key: startDate.format(weekDayFormat),
      text: startDate.format(weekDayFormat),
      weekDate: startDate.clone(),
    };

    startDate.add(1, "days");
    return weekDay;
  });
};
