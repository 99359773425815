import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginUser, signOut } from "../../actions/account";
import { TextInput } from "../../components/TextInput";
import useForm from "../../hooks/useForm";
import auth from "../../libs/auth";
import { LoginWrapper } from "./Components";
import "./_account.scss";
import ForgotChangePassword from "./ForgotChangePassword";

const stateSchema = {};

const validationSchema = {
  userName: {
    required: true,
  },
  password: {
    required: true,
  },
  accountId: {
    required: true,
  },
};

const LoginScreen = (props) => {
  const dispatch = useDispatch();
  const { state, errors, handleChange, handleSubmit } = useForm(
    stateSchema,
    validationSchema,
    submit
  );

  useEffect(() => {
    dispatch(signOut());
    auth.signout();
  }, []);

  function submit(state) {
    dispatch(
      loginUser({
        ...state,
      })
    );
  }

  return (
    <LoginWrapper handleSubmit={handleSubmit}>
      <div className="formField">
        <TextInput
          name="userName"
          label="Username"
          value={state["userName"]}
          placeholder="Username"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="formField">
        <TextInput
          name="password"
          type="password"
          label="Password"
          value={state["password"]}
          placeholder="Password"
          onChange={handleChange}
          errors={errors}
          asterix={true}
        />
      </div>
      <div className="formField">
        <TextInput
          name="accountId"
          label="Account Id"
          value={state["accountId"]}
          placeholder="Account Id"
          onChange={handleChange}
          errors={errors}
          asterix={true}
        />
      </div>
      <ForgotChangePassword isUser={true} />
    </LoginWrapper>
  );
};

export default LoginScreen;
