import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router'
import configureStore, {history} from './config/configureStore';
import './index.css';
import ThemeProvider from './provider/ThemeProvider';
import App from './App';
import * as serviceWorker from './serviceWorker';

const {store,persistor}=configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history} >
             <ThemeProvider>
              <App/>  
             </ThemeProvider>
          </ConnectedRouter> 
        </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
