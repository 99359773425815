import {Common} from '../actionTypes/common';

const initialState={
    error:''
}

export default (state=initialState,action)=>{
    switch(action.type)
    {
        case Common.SET_API_RESPONSE:
            return {
                ...state,
               ...action.result
            }
        default:
            return {
                ...state
            }
    }
}