import React from "react";
import { UserTable } from "../../../../components/Table";
import { GridAction } from "../../../../utils/GridAction";
import useClientHook from "../../../hooks/useClientHook";
import useJobHook from "../../../hooks/useJobHook";
import useRateHook from "../../../hooks/useRateHook";

export default (props) => {
  const {
    timesheets,
    rowId,
    handleRowClick,
    handleRowChange,
    timesheetTypeId,
    hideClient,
    disableTimesheet,
    isReport,
  } = props;

  const type = !isReport ? GridAction.CUSTOM_POPUP : null;

  let headers = [];
  if (!hideClient) {
    const { clientProps } = useClientHook();

    headers.splice(
      0,
      0,
      {
        key: "clientCode",
        text: "Ref",
        type,
        cellProps: { style: { minWidth: 100, width: 100 } },
        ...clientProps,
      },
      {
        key: "clientName",
        text: "Client",
        type,
        ...clientProps,
      }
    );
  }

  const { rateProps, defaultRate } = useRateHook();
  const { activityProps } = useJobHook(timesheetTypeId, defaultRate);

  headers.splice(
    3,
    0,
    {
      key: "activityName",
      text: "Job",
      type,
      cellProps: { style: { minWidth: 100, width: 100 } },
      ...activityProps,
    },
    {
      key: "activityDesc",
      text: "Description",
      type,
      ...activityProps,
    }
  );
  if (!isReport)
    headers.push({
      key: "rate",
      text: "Rate",
      type,
      cellProps: { style: { minWidth: 100, width: 100 } },
      ...rateProps,
    });
  else {
    headers = headers.map((c) => ({
      ...c,
      actionProps: { className: "gap-cell" },
    }));
  }

  return (
    <div className="tsgridtbldiv">
      <UserTable
        headers={headers}
        data={timesheets}
        rowId={rowId}
        disable={disableTimesheet}
        handleChange={handleRowChange}
        handleRowClick={handleRowClick}
        isReport={isReport}
        tableProps={
          isReport ? { cellSpacing: 0, cellPadding: 0, border: 0 } : null
        }
        tblClass={isReport ? "table table-stripe" : ""}
        wrapperClass={isReport ? " " : ""}
      />
    </div>
  );
};
