import APIUtil from './APIUtil';

class OrgUserAPI
{
    addUserView(payload) {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgUser/AddUserView`;
        return fetch(url, options);
    }

    addEditOrgUser(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgUser/AddEditOrgUser`;
        return fetch(url, options);
    }

    getOrgUsers(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgUser/GetOrgUsers`;
        return fetch(url, options);
    }

    deleteOrgUser(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgUser/DeleteOrgUser`;
        return fetch(url, options);
    }

    addEditOrgRole(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgUser/AddEditOrgRole`;
        return fetch(url, options);
    }

    getOrgRoles(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgUser/GetOrgRoles`;
        return fetch(url, options);
    }

    deleteOrgRole(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgUser/DeleteOrgRole`;
        return fetch(url, options);
    }

    addEditRolePermissions(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgUser/AddEditRolePermissions`;
        return fetch(url, options);
    }
}

export default new OrgUserAPI();