import { useState,  useCallback } from 'react';

const useForm=(stateSchema, validationSchema = {}, callback)=> {
  const [state, setState] = useState(stateSchema);
  const [errorDetail,setErrors] = useState({
    errors:{},
    summary:{}
  });
 

  const validateState = useCallback(() => {
    let isValid=true,errors={},summary={};
    Object.keys(validationSchema).forEach(key => {
        const schema=validationSchema[key];
        if(schema)
        {
            const {required,validator,label,showSummary}=schema;
            const value=state[key];
            if (required && !value)
            {
                isValid=false;
                errors[key] = 'This is required field.';
                if(showSummary)
                  summary[key]=`${label} is required.`;
            }
            if (validator!=null && value && !validator.regEx.test(value))
            {
                isValid=false;
                errors[key] = validator.error;
                if(showSummary)
                  summary[key]=validator.error;
            }
        }
    });

    setErrors({errors,summary});

    return isValid;
  }, [state, validationSchema]);

  
  const handleChange = useCallback((name,value) =>{
        setState(prevState => ({
            ...prevState,
            [name]:value,
        }));
    }
    ,[]
  )

   const handleStateChange = (state) =>{
     
        setState(prevState => ({
            ...prevState,
            ...state,
        }));
    };

  const handleSubmit = useCallback(
    event => {
      if(event)
      event.preventDefault();
     
      if (validateState()) {
            callback(state);
      }
    },
    [state]
  );

  return { state,...errorDetail,handleChange, handleSubmit,handleStateChange };
}

export default useForm;