import React, { useState, useEffect } from "react";
import TableHeader from "./Header";

export default (props) => {
    const {
        tblClass,
        headers,
        rows
    }=props;

    return (
        <table className={`table table-stripe ${tblClass}`} cellPadding='0' cellSpacing='0' border='0'>
            <TableHeader headers={headers}  data={[]} />
            <tbody>{rows}</tbody>
        </table> 
    )
}