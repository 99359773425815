import React from 'react';
import { TextInput } from '../../../../../../components/TextInput';
import PartnerSource from '../../../../../../common/PartnerSource';
import {SelectBox} from '../../../../../../components/SelectBox';

const GeneralDetail=({userDetail,handleChange,handlePartner,errors,secretQuestions})=>{

    const {
        firstName
        ,lastName
        ,username
        ,password
        ,orgPartnerId
        ,partnerSourceId
        ,secretQuestionId
        ,answer
    }=userDetail;

    return (
        <div className='row'>
            <div className='col-6'>
                <TextInput
                    name="firstName"
                    label="First Name"
                    value={firstName}
                    onChange={handleChange}
                    errors={errors}
                    asterix={true}
                />
            </div>
            <div className='col-6'>
                <TextInput
                    name="lastName"
                    value={lastName}
                    label="Last Name"
                    onChange={handleChange}
                    errors={errors}
                    asterix={true}
                />
            </div>
            <div className='col-6'>
                <TextInput
                    name="username"
                    value={username}
                    label="Username(Email address only)"
                    onChange={handleChange}
                    errors={errors}
                    asterix={true}
                />
            </div>
            <div className='col-6'>
                <TextInput
                    name="password"
                    value={password}
                    label="Password"
                    onChange={handleChange}
                    errors={errors}
                    asterix={true}
                    type="password"
                />
            </div>
            <div className="col-12">
            <PartnerSource
                handleChange={handlePartner}
                errors={errors}
                orgPartnerId={orgPartnerId}
                partnerSourceId={partnerSourceId}
            />
            </div>
            <div className='col-6'>
                <SelectBox
                    name="secretQuestionId"
                    value={secretQuestionId}
                    label="Secret Question"
                    onChange={handleChange}
                    options={secretQuestions}
                    addFirstItem={true}
                    errors={errors}
                    asterix={true}
                />
            </div>
            <div className='col-6'>
                <TextInput
                    name="answer"
                    value={answer}
                    label="Answer"
                    onChange={handleChange}
                    errors={errors}
                    asterix={true}
                />
            </div>
        </div>

    )
}

export default React.memo(GeneralDetail);