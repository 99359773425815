import React, { useEffect, useState } from 'react';
import { UserLink } from '../../components/Button';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { confirmAccount } from '../../actions/account';
import { RouteConstant } from '../../utils/routeConstant';

const {LOGIN}=RouteConstant;

export default (props)=>{

    const params=useParams();
    const dispatch=useDispatch();
    const [isConfirmed,setIsConfirmed]=useState(false);

    useEffect(()=>{
        dispatch(confirmAccount({...params},()=>{
            setIsConfirmed(true);
        }));
    },[]);

    return (
       <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <h2>Confirm Account</h2>
                    {isConfirmed &&
                    <div className="box-content">
                        <div className="form-block">
                            <p>
                                Your account has been activated. Please &nbsp;  
                                <UserLink
                                    className=" "
                                    url={LOGIN}
                                    text="Click here to log in"
                                />
                            </p>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>

    )
}