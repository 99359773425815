import {Timesheet} from '../actionTypes/timesheet';

const initialState={

}

export default (state=initialState,action)=>
{
    switch(action.type)
    {
        case Timesheet.TIMESHEET_SUCCESS:
            return {
                ...state,
                ...action.result
            }
        default:
            return {
                ...state
            }
    }
}