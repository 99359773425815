import React from 'react';
import {Redirect} from 'react-router-dom';
import { AppConstant } from '../utils/constant';

const {USER_KEY}=AppConstant;

class auth{
    get isAuthenticated() {
        return !!localStorage.getItem(USER_KEY);
    };
    get userKey() {
        return localStorage.getItem(USER_KEY);
    };

    signout() {
        localStorage.removeItem(USER_KEY);
    };

    verifyAuth() {
        if (!auth.isAuthenticated) {
            return <Redirect to="/login" />;
        }
    };

    onSignIn(user) {
        try{
            localStorage.setItem(USER_KEY, user)
            return true;
        }
        catch(e)
        {
           return false;
        }
       
    };
}


export default new auth();