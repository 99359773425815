import APIUtil from './APIUtil';

class ReportAPI
{
    getReportView(payload){
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Report/GetReportView`;
        return fetch(url, options);
    }

    getTimesheetEmployees(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Report/GetTimesheetEmployees`;
        return fetch(url, options);
    }

    downloadReport(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Report/DownloadReport`;
        return fetch(url, options);
    }

    sendReportEmail(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Report/SendReportEmail`;
        return fetch(url, options);
    }
}


export default new ReportAPI();
