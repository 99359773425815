import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../../../hooks/useForm";
import { UserModal } from "../../../../components/Modal";
import { TextInput } from "../../../../components/TextInput";
import { addEditClient, addClientView } from "../../../../actions/client";
import ContactDetails from "../../../../common/ContactDetails";
import { TabPanel, UserTab } from "../../../../components/Tabs";
import { SelectBox } from "../../../../components/SelectBox";
import { CheckBox } from "../../../../components/CheckBox";
import PartnerSource from "../../../../common/PartnerSource";
import useToast from "../../../../hooks/useToast";
import { ValidationSummary } from "../../../../components/Label";

const stateSchema = {};

const validationSchema = {
  clientName: {
    required: true,
  },
  orgPartnerId: {
    required: true,
  },
  partnerSourceId: {
    required: true,
  },
  clientCode: {
    required: true,
  },
  billingName: {
    required: true,
    label: "Billing Name",
    showSummary: true,
  },
};

const AddClient = ({ clientDetail, handleChange, errors, parentClients }) => {
  const {
    clientName,
    orgPartnerId,
    partnerSourceId,
    clientCode,
    isParent,
    parentClientId,
    billingName,
    emailId,
  } = clientDetail;

  return (
    <TabPanel defaultTab="general">
      <UserTab eventKey="general" title="General">
        <div className="row">
          <div className="col-6">
            <TextInput
              name="clientCode"
              label="Reference"
              value={clientCode}
              onChange={handleChange}
              errors={errors}
              asterix={true}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="clientName"
              value={clientName}
              label="Client Name"
              onChange={handleChange}
              errors={errors}
              asterix={true}
            />
          </div>
          <div className="col-12">
            <PartnerSource
              handleChange={handleChange}
              errors={errors}
              orgPartnerId={orgPartnerId}
              partnerSourceId={partnerSourceId}
            />
          </div>
          <div className="col-6">
            <TextInput
              name="emailId"
              value={emailId}
              label="Email"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <CheckBox
              name="isParent"
              value={isParent}
              label="Set as Parent"
              onChange={handleChange}
              errors={errors}
            />
          </div>
          <div className="col-6">
            <SelectBox
              name="parentClientId"
              value={parentClientId}
              label="Parent Client"
              onChange={handleChange}
              options={parentClients}
              errors={errors}
              addFirstItem={true}
            />
          </div>
        </div>
      </UserTab>
      <UserTab eventKey="address" title="Address">
        <div className="row">
          <ContactDetails
            handleChange={handleChange}
            contactDetail={clientDetail}
            errors={errors}
          />
        </div>
      </UserTab>
      <UserTab eventKey="invoice" title="Invoice Details">
        <div className="row">
          <div className="col-6">
            <TextInput
              name="billingName"
              label="Billing Name"
              value={billingName}
              onChange={handleChange}
              errors={errors}
              asterix={true}
            />
          </div>
        </div>
      </UserTab>
    </TabPanel>
  );
};

export default ({ showAddClient, toggleModal, selectedClient, clients }) => {
  const {
    state: clientDetail,
    errors,
    summary,
    handleChange,
    handleSubmit,
    handleStateChange,
  } = useForm(stateSchema, validationSchema, handleSave);

  const dispatch = useDispatch();
  const { parentClients: initParentClients } = useSelector(
    (state) => state.common
  );
  const [parentClients, setParentClients] = useState([]);
  const { showError } = useToast();
  const { orgPartnerId, partnerSourceId } = clientDetail;

  useEffect(() => {
    initPage();
    if (selectedClient)
      handleStateChange({ ...selectedClient, ...selectedClient.address });
  }, []);

  useEffect(() => {
    if (initParentClients) setParentClients(initParentClients);
  }, [initParentClients]);

  const initPage = () => {
    dispatch(addClientView());
  };

  useEffect(() => {
    if (initParentClients) {
      let parentClients = initParentClients.slice();
      if (orgPartnerId)
        parentClients = parentClients.filter(
          (c) => c.orgPartnerId == orgPartnerId
        );
      if (partnerSourceId)
        parentClients = parentClients.filter(
          (c) => c.partnerSourceId == partnerSourceId
        );

      setParentClients(parentClients);
      handleChange("parentClientId", null);
    }
  }, [orgPartnerId, partnerSourceId, initParentClients]);

  function handleSave(clientDetail) {
    if (
      clients &&
      !clients.some(
        (c) =>
          c.clientId != clientDetail.clientId &&
          c.clientCode == clientDetail.clientCode
      )
    ) {
      dispatch(
        addEditClient({
          ...clientDetail,
        })
      );
      toggleModal();
    } else showError("Reference already exists");
  }

  return (
    <div className="col-12">
      <UserModal
        show={showAddClient}
        handleClose={toggleModal}
        title="Add Client"
        handleSave={handleSubmit}>
        <ValidationSummary summary={summary} />
        <AddClient
          clientDetail={clientDetail}
          handleChange={handleChange}
          errors={errors}
          parentClients={parentClients}
        />
      </UserModal>
    </div>
  );
};
