import React from "react";
import { TransactionGrid, InvoiceGrid } from ".";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportHeader } from "../Components";

export default (props) => {
  let { reportType } = props;
  const { PDF } = usePrintHook({});

  return (
    <>
      {reportType == PDF && (
        <>
          <ReportHeader
            heading="Client Work in progress (WIP)"
            reportType={reportType}
          />
          <br />
          <br />
        </>
      )}
      <TransactionGrid {...props} isReport={true} reportType={reportType} />
    </>
  );
};
