export const Account={
    LOGIN_USER:'LOGIN_USER',
    LOGOUT_USER:'LOGOUT_USER',
    IMPERSONATE_USER:'IMPERSONATE_USER',
    STOP_IMPERSONATION:'STOP_IMPERSONATION',
    ACCOUNT_SUCCESS:'ACCOUNT_SUCCESS',
    ACCOUNT_ERROR:'ACCOUNT_ERROR',
    SET_USER_THEME:'SET_USER_THEME',
    VERIFY_USER:'VERIFY_USER',
    CHANGE_PASSWORD:'CHANGE_PASSWORD',
    RESET_PASSWORD:'RESET_PASSWORD',
    FORGOT_PASSWORD:'FORGOT_PASSWORD',
    CONFIRM_ACCOUNT:'CONFIRM_ACCOUNT'
}