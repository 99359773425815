import React from "react";
import { useSelector } from "react-redux";
import APIUtil from "../../api/APIUtil";

const baseUrl = APIUtil.getBaseUrl();

export default (props) => {
  const { userDetail } = useSelector((state) => state.account);
  const { orgLogoPath } = userDetail ? userDetail : {};
  var localImageUrl=`${process.env.PUBLIC_URL}/assests/img/thumb/Default_Logo.png`;

  return (
    <div>
      <table>
        <tr className="logo">
          <td>
            <span>
              <img
                className="imgLogo"
                style={{ width: 240, height: 80 }}
                src={
                  `${localImageUrl}`
                }
                onError={(e) => {
                  e.target.onerror = null;
                }}
              />
            </span>
          </td>
        </tr>
      </table>
    </div>
  );
};
