import React from "react";
import { UserTable } from "../../../../components/Table";
import useDayGrid from "../../../hooks/useDayGrid";

export default (props) => {
  const {
    gridData,
    rowId,
    handleRowClick,
    handleChange,
    disableTimesheet,
    isReport,
  } = props;
  const { daysData, gridHeaders } = useDayGrid({ gridData, isReport });

  return (
    <UserTable
      headers={gridHeaders}
      data={daysData}
      rowId={rowId}
      disable={disableTimesheet}
      handleChange={handleChange}
      handleRowClick={handleRowClick}
      tableProps={
        isReport ? { cellSpacing: 0, cellPadding: 0, border: 0 } : null
      }
      tblClass={isReport ? "table table-stripe" : ""}
    />
  );
};
