import React from "react";
import { Error } from "../Label";
import Styles from "./SelectBox.module.css";
import useToggle from "../../hooks/useToggle";

export default (props) => {
  const { name, value, errors, options, label, onChange } = props;
  const error = errors ? errors[name] : null;

  const { show, setShow } = useToggle();

  const onSelectChange = (value) => {
    onChange(name, value);
  };

  return (
    <>
      <div
        class={Styles.dropdown}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <div class="select-format">
          <span class="select">
            <span class="text-green">{label}:&nbsp;</span>
            {options.find((c) => c.value == value)?.text}
          </span>
        </div>
        <i class="fa fa-chevron-down pull-right icon-pink"></i>
        <div
          class={Styles.dropdownList + " dropdown_list"}
          onMouseEnter={() => setShow(true)}
        >
          {show && (
            <>
              {options
                .filter((c) => c.value != value)
                .map((c) => (
                  <div
                    class={Styles.dropdownListItem}
                    key={c.value}
                    onClick={() => onSelectChange(c.value)}
                  >
                    <span className="text-green">{label}:</span>
                    {c.text}
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
      {error && <Error error={error} />}
    </>
  );
};
