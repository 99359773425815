import { useContext, useEffect, useState, useCallback } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { useSelector } from 'react-redux';

const useTheme=()=>{

    const {theme:initTheme,updateTheme}=useContext(ThemeContext);
    const [themeData,setThemeData]=useState({
        theme:initTheme,
        themeId:null
    });
    const {themes}=useSelector(state=>state.account);

    useEffect(()=>{
        if(themes)
        {
            const selected=themes.find(c=>c.text==initTheme);
            if(selected)
                setThemeData({theme:initTheme+' ',themeId:selected.value});
        }
    },[initTheme,themes]);

    const setTheme=useCallback((themeId)=>{
        updateTheme(themes.find(c=>c.value==themeId)?.text);
    },[]);


    return {
        ...themeData,
        setTheme
    };
}

export default useTheme;