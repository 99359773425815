import React from "react";
import { UserTable } from "../../../../components/Table";

export default ({
  taxRates,
  rowId,
  handleRowClick,
  handleEdit,
  handleDelete,
}) => {
  const headers = [
    { key: "taxName", text: "TAX Name", search: true, sort: true },
    { key: "default", text: "Is Default" },
  ];

  return (
    <div class="tax_rate_inner">
      <UserTable
        headers={headers}
        data={taxRates}
        rowId={rowId}
        handleRowClick={handleRowClick}
        showActions={true}
        actionProps={{ handleEdit, handleDelete, deleteTitle: "tax rate" }}
      />
    </div>
  );
};
