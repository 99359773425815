import React from "react";
import { LinkButton } from "../Button";
import "./_list.scss";
import { useDispatch } from "react-redux";
import useToggle from "../../hooks/useToggle";
import { ConfirmModal } from "../Modal";

const Item = (props) => {
  const {
    item: { text, value },
    value: selectedValue,
    showActions,
    name,
  } = props;

  const handleClick = () => props.handleClick(name, value);
  const { show: showConfirm, toggle: toggleConfirm } = useToggle();

  const handleEdit = (event) => {
    if (event) event.stopPropagation();
    props.handleEdit(value);
  };

  const handleDelete = (event) => {
    if (event) event.stopPropagation();
    props.handleDelete(value);
    toggleConfirm();
  };

  return (
    <>
      <li
        onClick={handleClick}
        className={value == selectedValue ? "selected" : ""}>
        {text}
        {showActions && (
          <div className="pull-right">
            <LinkButton text="Edit" onClick={handleEdit} />
            <LinkButton text="Delete" onClick={toggleConfirm} />
          </div>
        )}
      </li>
      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          handleClose={toggleConfirm}
          title={`Delete ${text}`}
          message={`Are you sure you want to delete ${text}?`}
          handleSave={handleDelete}
        />
      )}
    </>
  );
};

export default (props) => (
  <div className="listWrap">
    <ul className="unstyleList">
      {props.items &&
        props.items.map((item) => (
          <Item item={item} {...props} key={item.value} />
        ))}
    </ul>
  </div>
);
