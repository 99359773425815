import React from "react";

const ReportFilterWrapper = ({ label, labelClassName, children }) => {
  return (
    <div class="ctop-inps">
      <div class="filter-inps">
        <h2 class={labelClassName}>{label}</h2>
        {children}
      </div>
    </div>
  );
};

export default ReportFilterWrapper;
