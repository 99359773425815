import {TaxRate} from '../actionTypes/taxRate';

export const addTaxType=(payload)=>({
    type:TaxRate.ADD_TAX_TYPE,
    payload
});

export const addTaxRateView=(payload)=>({
    type:TaxRate.ADD_TAX_RATE_VIEW,
    payload
});

export const addEditTaxRate=(payload)=>({
    type:TaxRate.ADD_EDIT_TAX_RATE,
    payload
});

export const getTaxRates=(payload)=>({
    type:TaxRate.GET_TAX_RATES,
    payload
});

export const deleteTaxRates=(payload)=>({
    type:TaxRate.DELETE_TAX_RATE,
    payload
});