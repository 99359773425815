import React, { useEffect, useState } from 'react';
import { SelectBoxContainer, SelectContainer,SelectHandler } from '../../components/Container';
import { UserTree } from '../../components/Tree';
import { CheckBox } from '../../components/CheckBox';
import { useSelector } from 'react-redux';

const parentName='orgPartnerId',childName='partnerSourceId';

const Item=({text,name,selected,onChange,value})=>(
    <React.Fragment>
        <CheckBox
            name={name}
            value={selected}
            label={text}
            onChange={onChange.bind(this,value)}
        />
    </React.Fragment>
)

const PartnerNodes=({orgPartners,partnerSources,selected,handleChange})=>{

    const partnerIds=partnerSources.filter(c=>selected.includes(c.value))
                .map(c=>c.orgPartnerId);

    const partners=orgPartners
    .filter(c=>partnerIds.includes(c.value))
    .map((c,index)=>{
        const childNodes=partnerSources
        .filter(p=>selected.includes(p.value) && c.value==p.orgPartnerId);
    return {
        item:<Item
                key={'P'+c.value}
                {...c}
                name={parentName}
                onChange={handleChange}
                selected={childNodes.every(c=>c.selected)}
             />
        ,nodeId:c.value
        ,childNodes:childNodes.map((p)=>({
                        item:<Item
                                key={p.value}
                                {...p}
                                name={childName}
                                onChange={handleChange}
                            />
                    }))
    }
});

    return (
        <UserTree
            nodes={partners}
        />
    )
}

export default ({assignedSources,handleChange,title})=>{

    let {orgPartners,partnerSources:initSources}=useSelector(state=>state.common);
    const [partnerSources,setSources]=useState([]);

    useEffect(()=>{
        if(initSources)
            setSources(initSources);
    },[initSources]);

    orgPartners=orgPartners?orgPartners:[];
    const availableSources=partnerSources
        .filter(c=>!assignedSources.includes(c.value))
        .map(c=>c.value);

    const handleClick=(addToAssigned)=>{
        let filtered=[];
        if(addToAssigned)
            filtered=partnerSources
            .filter(c=>assignedSources.includes(c.value) || c.selected);
        else
            filtered=partnerSources
            .filter(c=>assignedSources.includes(c.value) && !c.selected);

        handleChange(filtered.map(c=>c.value));
    }

    const onChange=(key,name,value)=>{
        if(name==parentName)
        {
            setSources(partnerSources.map(c=>({
                    ...c,
                    selected:c.orgPartnerId==key?value:c.selected
            })));
        }
        else{
            const index=partnerSources.findIndex(c=>c.orgPartnerId==key);
            if(index>=0)
            {
                partnerSources[index]={...partnerSources[index],selected:value};
                setSources(partnerSources.slice());
            }
        }
        
    }

    return (
            <SelectBoxContainer>
                <SelectContainer title={"Available "+title}>
                    <PartnerNodes
                        orgPartners={orgPartners}
                        partnerSources={partnerSources}
                        selected={availableSources}
                        handleChange={onChange}
                    />
                </SelectContainer>
                <SelectHandler
                    handleClick={handleClick}
                />
                <SelectContainer title={"Selected "+title}>
                    <PartnerNodes
                        orgPartners={orgPartners}
                        partnerSources={partnerSources}
                        selected={assignedSources}
                        handleChange={onChange}
                    />
                </SelectContainer>
            </SelectBoxContainer>
    )
}