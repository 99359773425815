import React from "react";
import { ClientGrid } from ".";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportHeader } from "../Components";

export default (props) => {
  const { labels, rowId, gridData, reportTitle, reportType } = props;
  const { WORD, EXCEL } = usePrintHook({});

  return (
    <>
      {reportType == WORD || reportType == EXCEL ? (
        <ClientGrid
          {...labels}
          rowId={rowId}
          clients={gridData}
          isReport={true}
          reportType={reportType}
        />
      ) : (
        <>
          <ReportHeader heading={reportTitle} reportType={reportType} />
          <ClientGrid
            {...labels}
            rowId={rowId}
            clients={gridData}
            isReport={true}
            reportType={reportType}
          />
        </>
      )}
    </>
  );
};
