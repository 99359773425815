import React, { useEffect, useState } from "react";
import { UserTable, CommonTable } from "../../../components/Table";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { groupBy, map, sumBy } from "lodash";
import { useSelector } from "react-redux";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import useOrgResources from "../../../hooks/useOrgResources";
import { displayDate } from "../../../libs/dateHelper";
import { displayCost } from "../../../libs/functions";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";
import { PartnerLabel, SourceLabel } from "../Components";
import TableCell from "../../../components/Table/Cell";
import TableHeader from "../../../components/Table/Header";
import { normalizeData } from "../../../libs/functions";

const rowId = "";

const TotalRow = ({ text, units, cost, className, tblClass, cellClass }) => {
  return (
    <UserTable
      tblClass="table table-stripe mb0 wipDetailReportGrid totalWipDetailTotalRow"
      tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
      wrapperClass={`totalRow ${className}`}
      headers={[
        { text, colSpan:2 },
        { text: units },
        { text: displayCost(cost) },
      ].map((c) => ({ ...c, headerActionProps: { className: "gap-cell" } }))}
    />
  );
};

export default (props) => {
  const [gridData, setGridData] = useState([]);
  const { clientsWIP } = useSelector((state) => state.client);
  const { isReport, isJob, reportType } = props;
  const { partnerLabel, sourceLabel } = useOrgResources();
  const { theme } = useUserDetail();
  const { WORD, EXCEL } = usePrintHook({});

  const calTotal = (data) => ({
    totalUnits: sumBy(data, (c) => c.noOfUnits),
    totalCost: sumBy(data, (c) => parseFloat(c.cost)),
  });

  useEffect(() => {
    if (clientsWIP) {
      const group = groupBy(
        clientsWIP.map((c) => ({
          ...c,
          timesheetDate: displayDate(c.timesheetDate),
          cost: TimesheetHelper.calAmount({ ...c, invoiceUnits: c.noOfUnits }),
        })),
        (c) => c.orgPartnerId
      );
      setGridData(
        map(group, (c) => {
          const { orgPartner } = c[0];
          const sourceGroup = groupBy(c, (s) => s.partnerSourceId);
          return {
            sources: map(sourceGroup, (s) => {
              const { partnerSource } = s[0];
              const clientGroup = groupBy(s, (cl) => cl.clientId);
              return {
                orgPartner,
                partnerSource,
                clients: map(clientGroup, (cl) => {
                  const empGroup = groupBy(cl, (empGroup) =>
                    isJob ? empGroup.orgActivityCodeId : empGroup.employeeId
                  );

                  return {
                    ...cl[0],
                    employees: map(empGroup, (emp) => ({
                      ...emp[0],
                      heading: isJob ? emp[0].activityDesc : emp[0].employee,
                      gridData: emp.map((g) => ({
                        ...g,
                        cost: displayCost(g.cost),
                      })),
                      ...calTotal(emp),
                    })),
                    ...calTotal(cl),
                  };
                }),
                ...calTotal(s),
              };
            }),
            orgPartner,
            ...calTotal(c),
          };
        })
      );
    }
  }, [clientsWIP, isJob]);

  const heading = isJob ? "Job Type" : "Employee";

  const headers = [
    { key: "timesheetDate", text: "Date" },
    { key: !isJob ? "activityDesc" : "employee", text: heading },
    { key: "noOfUnits", text: "Units" },
    { key: "cost", text: "Value" },
    { key: "addl", text: "" },
  ].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

    const getDataTable = (data, totalRows=[]) => {

          var rows=[];

            {normalizeData(data, rowId).map((row, i) => 
                rows.push(<tr
                    className="tbody"
                    key={i}>
                    {headers.map((prop) => 
                      <TableCell
                        {...prop}
                        key={prop.key + "" + i}
                        data={row.find((r) => r.key === prop.key)}
                        selected={row.find((r) => r.key === "selected" && r.text)}
                      />
                    )}
                  </tr>)
              )};

            {
              totalRows.map((totalRow)=> {
                  rows.push(<tr className="tbody totalRow-wrap">
                    {
                      totalRow.map((t)=> <td className={`td ${t.className}`} colspan={t.colSpan}><div className="gap-cell">{t.text}</div></td>)
                    }
                  </tr>)
              })
            }

      return rows;
    }

  const className = isReport ? "col-12 col-md-12 col-xs-12" : "col-12";
  var datatable=[];

  return (
    <>
      <ReportContainer hasData={gridData.length > 0}>
          <div className="totalWipDetailRpt">
              {gridData.map((g, index) => (
              <React.Fragment key={index}>
                {g.sources.map((s, sIndex) => 
                {
                  datatable=[];
                  return (
                    <React.Fragment key={sIndex}>
                      {reportType == WORD || reportType == EXCEL ? (
                        <React.Fragment>
                          <div className={className + " labelWrapper1"}>
                            <CustomDisplayLabel
                              label={partnerLabel}
                              value={s.orgPartner}
                              customClass="plm_carep_top_first"
                            />
                          </div>
                          <div className={className + " labelWrapper"}>
                            <CustomDisplayLabel
                              label={sourceLabel}
                              value={s.partnerSource}
                              customClass="plm_carep_top_first"
                            />
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <PartnerLabel partnerName={s.orgPartner} />
                          <SourceLabel sourceName={s.partnerSource} />
                        </React.Fragment>
                      )}
                      {s.clients.map((cl, clIndex) => 
                      {
                        datatable=[];
  
                        return (
                          <React.Fragment key={clIndex}>
                            <div class="header-row">
                              <div class="table-header">
                                Reference: <span>{cl.clientCode}</span>
                              </div>
                              <div class="table-header">
                                Client: <span>{cl.clientName}</span>
                              </div>
                            </div>
                            {cl.employees.map((emp, empIndex) => 
                            {
                              datatable=[];
                              return (
                                <React.Fragment key={empIndex}>
                                  <div class="header-row">
                                    <div class="table-header light-blue">
                                      {heading}: <span>{emp.heading}</span>
                                    </div>
                                  </div>
    
                                  {
                                    datatable= [datatable, getDataTable(emp.gridData, [
                                      [
                                        { text:`Totals for ${heading}: ${emp.heading}`, colSpan:2, className: "total back-light-blue" },
                                        { text: emp.totalUnits, className: "total back-light-blue" },
                                        { text: displayCost(emp.totalCost), className: "total back-light-blue" },
                                        { text: "", className: "total back-light-blue" },
                                      ]])]
                                  }
    
                                  {
                                    empIndex+1 < cl.employees.length &&
                                    <CommonTable tblClass="wipDetailReportGrid wipTotalReportGrid" headers={headers} rows={datatable} />
                                  }
    
                                </React.Fragment>
                              )
                            }
                            )}
  
                            {
                              datatable=[datatable, (<tr className="tbody totalRow-wrap">
                                <td className={`td total back-blue`} colspan="2"><div className="gap-cell">{`Totals for Client: ${cl.clientName}`}</div></td>
                                <td className={`td total back-blue`}><div className="gap-cell">{cl.totalUnits}</div></td>
                                <td className={`td total back-blue`}><div className="gap-cell">{displayCost(cl.totalCost)}</div></td>
                                <td className={`td total back-blue`}><div className="gap-cell">&nbsp;</div></td>
                              </tr>)]
                            }
    
                            {
                              clIndex+1 < s.clients.length &&
                              <CommonTable tblClass="wipDetailReportGrid wipTotalReportGrid" headers={headers} rows={datatable} />
                            }
                            
                          </React.Fragment>
                        )
  
                      }
                      
                      )}
  
                            {
                              datatable=[datatable, (<tr className="tbody totalRow-wrap">
                                <td className={`td total back-blue`} colspan="2"><div className="gap-cell">{`Totals of ${sourceLabel}: ${s.partnerSource}`}</div></td>
                                <td className={`td total back-blue`}><div className="gap-cell">{s.totalUnits}</div></td>
                                <td className={`td total back-blue`}><div className="gap-cell">{displayCost(s.totalCost)}</div></td>
                                <td className={`td total back-blue`}><div className="gap-cell">&nbsp;</div></td>
                              </tr>)]
                            }
    
                            {
                              sIndex+1 < g.sources.length &&
                              <CommonTable tblClass="wipDetailReportGrid wipTotalReportGrid" headers={headers} rows={datatable} />
                            }
  
                    </React.Fragment>
                  )
                }
                )}

                          {
                            datatable=[datatable, (<tr className="tbody totalRow-wrap">
                              <td className={`td total back-purple`} colspan="2"><div className="gap-cell">{`Totals of ${partnerLabel}: ${g.orgPartner}`}</div></td>
                              <td className={`td total back-purple`}><div className="gap-cell">{g.totalUnits}</div></td>
                              <td className={`td total back-purple`}><div className="gap-cell">{displayCost(g.totalCost)}</div></td>
                              <td className={`td total back-purple`}><div className="gap-cell">&nbsp;</div></td>
                            </tr>)]
                          }
  
                            <CommonTable tblClass="wipDetailReportGrid wipTotalReportGrid" headers={headers} rows={datatable} />


              </React.Fragment>
            ))}
          </div>
      </ReportContainer>
    </>
  );
};
