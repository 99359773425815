import React, { useEffect } from 'react';
import { UserModal } from '../../../components/Modal';
import useForm from '../../../hooks/useForm';
import { TextArea, TextInput } from '../../../components/TextInput';
import useUserDetail from '../../../hooks/useUserDetail';
import { sendReportEmail } from '../../../actions/report';

const Email=({handleChange,errors,emailDetail})=>{

    const {emailTo,subject,message}=emailDetail;

    return (
        <div className='row'>
            <div className='col-12'>
                <TextInput
                    name="emailTo"
                    label="Email To"
                    value={emailTo}
                    onChange={handleChange}
                    errors={errors}
                    asterix={true}
                />
            </div>
            <div className='col-12'>
                <TextInput
                    name="subject"
                    label="Sub"
                    value={subject}
                    onChange={handleChange}
                    errors={errors}
                    asterix={true}
                />
            </div>
            <div className='col-12'>
                <TextArea
                    name="message"
                    value={message}
                    onChange={handleChange}
                    errors={errors}
                    asterix={true}
                    rows={3}
                    cols={4}
                />
            </div>
        </div>
    )
}

const stateSchema={
    
};

const validationSchema={
    emailTo:{
        required:true
    },
    subject:{
        required:true
    },
    message:{
        required:true
    }
}


export default ({showModal,toggleModal,sendEmail})=>{

    const {
        state:emailDetail,
        errors,
        handleChange,
        handleStateChange,
        handleSubmit
    }=useForm(stateSchema,validationSchema,handleSave);

    const {emailId}=useUserDetail();

    useEffect(()=>{
        handleStateChange({
            emailTo:emailId,
            subject:'Email Report',
            message:'Hi,\nPlease find attached report FYI'
        });
    },[emailId]);

    function handleSave(emailDetail)
    {
        const {emailTo}=emailDetail;
        sendEmail(sendReportEmail,{
            ...emailDetail,
            receipents:emailTo.split(',').map(c=>({emailId:c}))
        })
    }

    return (
        <UserModal
            show={showModal}
            handleClose={toggleModal}
            title="Email Report"
            handleSave={handleSubmit}
            btnText="Send"
        >
            <Email
                emailDetail={emailDetail}
                errors={errors}
                handleChange={handleChange}
            />
        </UserModal>  
    )
}