import React, { useEffect } from "react";
import { UserCard } from "../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import useGridHook from "../../hooks/useGridHook";
import { InvoiceGrid, PrintInvoice } from "./ViewInvoice";
import {
  getInvoices,
  setInvoice,
  getInvoiceDetails,
} from "../../actions/invoice";
import { displayDate } from "../../libs/dateHelper";
import { UserButton, UserLink } from "../../components/Button";
import { RouteConstant } from "../../utils/routeConstant";
import useToggle from "../../hooks/useToggle";
import UpdateInvoice from "./ViewInvoice/UpdateInvoice";
import "./ViewInvoiceScreen.scss";

const rowId = "invoiceId";

const { CREATE_INVOICE } = RouteConstant;

export default (props) => {
  const dispatch = useDispatch();
  const {
    gridData,
    handleRowClick,
    setGridData,
    handleHeaderChange,
  } = useGridHook(rowId);
  const { invoices } = useSelector((state) => state.invoice);
  const { show: showEditModal, toggle: toggleEditModal } = useToggle();

  const fetchInvoices = () => {
    dispatch(getInvoices({}));
  };

  const setInvoices = (invoices) => {
    if (invoices)
      setGridData(
        invoices.map((c) => ({
          ...c,
          invoiceDate: displayDate(c.invoiceDt),
        }))
      );
  };

  useEffect(fetchInvoices, []);

  useEffect(() => setInvoices(invoices), [invoices]);

  useEffect(() => {
    const selected = gridData.find((c) => c.selected);
    if (selected) {
      dispatch(getInvoiceDetails(selected.invoiceId));
    }
  }, [gridData]);

  const handleEdit = () => {
    const invoiceDetail = gridData.find((c) => c.selected);
    if (invoiceDetail) {
      dispatch(
        setInvoice({
          invoiceDetail,
        })
      );
      toggleEditModal();
    }
  };

  return (
    <div className="plm_user_list_div">
      <div className="container-fluid prn_cfuid ">
        <div className="row prn_row">
          <div className="col-12 prn_col12 viewInvoices">
            <UserCard title="Invoices">
              <InvoiceGrid
                rowId={rowId}
                invoices={gridData}
                handleHeaderChange={handleHeaderChange.bind(this)}
                handleRowClick={handleRowClick.bind(this)}
              />
              <div className="hr-divider"></div>
              <div className="row">
                <div className="col-1">
                  <UserLink
                    text="Create"
                    className="btn btn-primary"
                    url={CREATE_INVOICE}
                  />
                </div>
                <div className="col-1">
                  <UserButton
                    text="Update"
                    className="btn btn-primary"
                    onClick={() => handleEdit()}
                    disabled={!gridData.some((c) => c.selected)}
                  />
                </div>
                <PrintInvoice
                  selectedInvoice={gridData.find((c) => c.selected)}
                />
              </div>
              {showEditModal && (
                <UpdateInvoice
                  showEditInvoice={showEditModal}
                  toggleModal={toggleEditModal}
                />
              )}
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
