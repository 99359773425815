import React, { useEffect } from 'react';
import useToast from '../../hooks/useToast';

const useVerifyReport=(showReport,state)=>{

    const {showError}=useToast();

    useEffect(()=>{
        if(showReport && (!state || Object.keys(state).length==0)){
            showError('Please select record.');
            return ;
        }
    },[showReport]);
}

export default useVerifyReport;