import  { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityTypes } from '../actions/activityCode';

export default (props)=>{

    const dispatch=useDispatch();

    useEffect(()=>{
        dispatch(getActivityTypes());
    },[]);

    const {activityTypes}=useSelector(state=>state.common);
    const defaultActivity=activityTypes && activityTypes.length>0?activityTypes[0].text:'';

    return {
        activityTypes,
        defaultActivity
    }

}