import React from "react";
import TableCell from "./Cell";
import { LinkButton } from "../Button";
import useToggle from "../../hooks/useToggle";
import { ConfirmModal } from "../Modal";

const Actions = (props) => {
  const { rowId, deleteTitle } = props;
  const { show: showConfirm, toggle: toggleConfirm } = useToggle();

  const handleEdit = () => {
    props.handleEdit(rowId);
  };

  const handleDelete = () => {
    props.handleDelete(rowId);
    toggleConfirm();
  };

  return (
    <>
      <div className="pull-right">
        <LinkButton text="Edit" onClick={handleEdit} />
        <LinkButton text="Delete" onClick={toggleConfirm} />
      </div>
      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          handleClose={toggleConfirm}
          title={`Delete ${deleteTitle}`}
          message={`Are you sure you want to delete ${deleteTitle}?`}
          handleSave={handleDelete}
        />
      )}
    </>
  );
};

export default ({
  data,
  meta,
  handleRowClick,
  handleChange,
  disable,
  showActions,
  actionProps,
}) => {
  return (
    <tbody>
      {data.map((row, i) => (
        <tr
          className="tbody"
          key={i}
          onClick={(e) => handleRowClick(e, row[0] ? row[0].rowId : null)}>
          {meta.map((prop) => (
            <TableCell
              disable={disable}
              handleChange={handleChange}
              {...prop}
              key={prop.key + "" + i}
              data={row.find((r) => r.key === prop.key)}
              selected={row.find((r) => r.key === "selected" && r.text)}
            />
          ))}
          {showActions && (
            <td>
              <Actions {...row[0]} {...actionProps} />
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};
