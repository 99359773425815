import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RouteConstant } from "../../utils/routeConstant";
import "./adminNavigation.scss";
import { ReactComponent as HomeIcon } from "../SvgIcons/Home.svg";
import { ReactComponent as CustomerIcon } from "../SvgIcons/Customer.svg";

const Menu = ({ currentPageURL, pageURL, children, pageName }) => (
  <li>
    <Link to={pageURL} className={pageURL === currentPageURL ? "active" : ""}>
      <span className="linkIc">{children}</span>
      <span className="linkText">{pageName}</span>
    </Link>
  </li>
);

export default (props) => {
  const currentPageURL = useLocation().pathname;

  return (
    <div className="navigation">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="ANavigation">
              <ul>
                <li>Customers</li>
              </ul>
               
              {/* <ul>
                <Menu
                    pageName="Home"
                    currentPageURL={currentPageURL}
                    pageURL={RouteConstant.ADMIN_HOME}
                >
                    <HomeIcon/>
                </Menu>
                <Menu
                    pageName="Customers"
                    currentPageURL={currentPageURL}
                    pageURL={RouteConstant.ADMIN_CUSTOMERS}
                >
                   <CustomerIcon
                   />
                </Menu>
            </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
