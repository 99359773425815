import React, { useEffect, useState } from "react";
import { AppConstant } from "../../../utils/constant";
import { useSelector } from "react-redux";
import { groupBy, map, uniqBy } from "lodash";
import moment from "moment";
import useWeekHook from "../../../hooks/useWeekHook";
import useGridHook from "../../../hooks/useGridHook";
import { ReportContainer } from "../../../components/Container";
import usePrintHook from "../../hooks/usePrintHook";
import useOrgResources from "../../../hooks/useOrgResources";
import { getMomentDate } from "../../../libs/dateHelper";
import WordReport from "./WordReport";
import { PartnerLabel, SourceLabel, StaffLabel } from "../Components";

const rowId = "employeeId";

export default (props) => {
  const [headers, setHeaders] = useState([]);
  const { startDate, endDate, reportType } = props;
  const { gridData, setGridData } = useGridHook(rowId);
  const { timesheets } = useSelector((state) => state.timesheet);
  const { options } = useWeekHook({});
  const { WORD, EXCEL } = usePrintHook({});
  const { partnerLabel, sourceLabel, orgPartner, partnerSource } =
    useOrgResources();

  useEffect(() => {
    if (options.length > 0) {
      let startDt = moment(startDate),
        endDt = moment(endDate),
        headers = [],
        rowData = [],
        index = 0;

      while (startDt < endDt) {
        const option = options.find((c) =>
          startDt.isBetween(c.startDate, c.endDate, "day", "[]")
        );
        if (option) {
          rowData.push({
            key: option.value,
            text: (
              <span>
                Week {option.value}:&nbsp;
                {option.startDate.format(AppConstant.DATE_DISPLAY_FORMAT)}
              </span>
            ),
            hasMultipleAction: true,
          });
          if (++index % 5 == 0) {
            headers.push(rowData);
            rowData = [];
          }
        }
        startDt.add(7, "days");
      }
      if (rowData.length > 0) headers.push(rowData);

      setHeaders(headers);
    }
  }, [timesheets, options]);

  useEffect(() => setTimesheets(timesheets), [timesheets]);

  const setTimesheets = (timesheets) => {
    if (timesheets) {
      let allTimsheets = [];
      timesheets.forEach((c) => {
        allTimsheets = allTimsheets.concat(
          c.partners
            ? c.partners.map((p) => ({
                ...c,
                ...p,
              }))
            : []
        );
      });
      const group = groupBy(allTimsheets, (c) => c.orgPartnerId);
      const gridData = map(group, (c) => {
        const empGroup = groupBy(c, (e) => e.employeeId);
        return {
          partnerName: c[0].partnerName,
          sourceName: uniqBy(c, (u) => u.sourceName)
            .map((c) => c.sourceName)
            .join(","),
          empDetails: map(empGroup, (e) => ({
            employeeName: e[0].fullName,
            gridData: e.map((g) => ({
              ...g,
              weekNo: options.find((o) =>
                getMomentDate(g.startDate).isBetween(
                  o.startDate,
                  o.endDate,
                  "day",
                  "[]"
                )
              )?.value,
            })),
          })),
        };
      });
      setGridData(gridData);
    }
  };

  if (reportType == WORD || reportType == EXCEL) {
    return (
      <WordReport
        partnerLabel={partnerLabel}
        sourceLabel={sourceLabel}
        orgPartner={orgPartner}
        partnerSource={partnerSource}
        startDate={startDate}
        endDate={endDate}
        gridData={gridData}
        headers={headers}
      />
    );
  }

  return (
    <ReportContainer hasData={gridData.length > 0}>
      {gridData.length > 0 && (
        <div className="nonSubmittedRpt">
          {gridData.map((c, i) => (
            <React.Fragment key={i}>
              <PartnerLabel partnerName={c.partnerName} />
              <SourceLabel sourceName={c.sourceName} />
              {c.empDetails.map((e, index) => (
                <React.Fragment key={index}>
                  <StaffLabel employeeName={e.employeeName} />
                  
                  <div class="table-responsive">
                    <table
                      class="table table-stripe mb20 cal-table"
                      cellpadding="0"
                      cellspacing="0"
                      border="0"
                    >
                      <tbody>
                        {headers.map((r, rIndex) => (
                          <tr>
                            {r.map((c, cIndex) => (
                              <React.Fragment key={`R${rIndex}C${cIndex}`}>
                                <td>
                                  <div
                                    class={`gap-cell text-center f700 ${
                                      e.gridData.find((g) => g.weekNo == c.key)
                                        ?.status != null
                                        ? "back-purple"
                                        : ""
                                    }`}
                                  >
                                    {c.text}
                                  </div>
                                </td>
                              </React.Fragment>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}
    </ReportContainer>
  );
};
