import React, { useEffect, useState } from "react";
import { SelectBox } from "../../../components/SelectBox";
import ReportFilterWrapper from "../../../common/ReportFilterWrapper";

const options = [
  { text: "Job Type", value: "job", className: "col-3" },
  { text: "Employee", value: "employee", className: "col-3" },
];

export default ({ handleChange }) => {
  const [transactionGroupBy, setGroupBy] = useState("job");

  useEffect(() => {
    let isJob;
    if (transactionGroupBy == "job") isJob = true;

    handleChange("isJob", isJob);
  }, [transactionGroupBy]);

  const onChange = (name, value) => {
    setGroupBy(value);
  };

  return (
    <ReportFilterWrapper label="Group By">
      <SelectBox
        name="transactionGroupBy"
        value={transactionGroupBy}
        options={options}
        onChange={onChange}
      />
    </ReportFilterWrapper>
  );
};
