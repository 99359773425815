import {User} from '../actionTypes/user';

export const addUserView=(payload)=>({
    type:User.ADD_USER_VIEW,
    payload
});

export const addEditOrgUser=(payload)=>({
    type:User.ADD_USER,
    payload
});

export const getUsers=(payload)=>({
    type:User.GET_USERS,
    payload
});

export const deleteOrgUser=(payload)=>({
    type:User.DELETE_USER,
    payload
});

export const addEditOrgRole=(payload)=>({
    type:User.ADD_ORG_ROLE,
    payload
});

export const getRoles=(payload)=>({
    type:User.GET_ORG_ROLES,
    payload
});

export const deleteOrgRole=(payload)=>({
    type:User.DELETE_ORG_ROLE,
    payload
});

export const addEditRolePermissions=(payload)=>({
    type:User.ADD_EDIT_ROLES_PERMISSIONS,
    payload
});