import React, { useEffect, useState } from "react";
import { UserCard, CardFoot } from "../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import useGridHook from "../../hooks/useGridHook";
import { TimesheetGrid, ViewTimesheet } from "./Components";
import PartnerSource from "../../common/PartnerSource";
import { UserButton } from "../../components/Button";
import {
  authorisationView,
  updateTimesheetsStatus,
  getSubmittedTimesheets,
} from "../../actions/timesheet";
import useTimesheetStatus from "../hooks/useTimesheetStatus";
import useToast from "../../hooks/useToast";
import "./AuthorisationScreen.scss";
import { AppConstant } from "../../utils/constant";

const rowId = "timesheetId";

const { REPORT_FIRST_ITEM_TEXT } = AppConstant;

export default (props) => {
  const dispatch = useDispatch();
  const {
    gridData,
    handleRowClick,
    handleChange,
    handleHeaderChange,
    setGridData,
  } = useGridHook(rowId);
  const { submittedTimesheets } = useSelector((state) => state.timesheet);
  const { authorisedTimesheetId } = useTimesheetStatus();
  const { showError } = useToast();
  const [state, setState] = useState({});

  const { partnerSourceId, orgPartnerId } = state;

  const fetchTimesheets = () => {
    dispatch(authorisationView({}));
  };

  const setTimesheets = (timesheets) => {
    if (timesheets)
      setGridData(
        timesheets.map((c) => ({
          ...c,
          isSelected: false,
          authorise: "Authorise",
        }))
      );
  };

  useEffect(fetchTimesheets, []);

  useEffect(() => setTimesheets(submittedTimesheets), [submittedTimesheets]);

  const handleStateChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAuthorise = (timesheetId) => {
    dispatch(
      updateTimesheetsStatus({
        timesheetIds: [timesheetId],
        timesheetStatusId: authorisedTimesheetId,
      })
    );
  };

  const handleAuthoriseBatch = () => {
    if (gridData.some((c) => c.isSelected))
      dispatch(
        updateTimesheetsStatus({
          timesheetIds: gridData
            .filter((c) => c.isSelected)
            .map((c) => c.timesheetId),
          timesheetStatusId: authorisedTimesheetId,
        })
      );
    else showError("Please select timesheet");
  };

  const handleView = () => {
    dispatch(getSubmittedTimesheets({ partnerSourceId, orgPartnerId }));
  };

  return (
    <div class="plm_authlist_div">
      <div class="container-fluid prn_cfuid">
        <div class="row prn_row">
          <div class="col-12 prn_col12">
            <UserCard title="Authorisation">
              <div className="row plm_authrow">
                <div className="col-12 plm_authcol_right">
                  <div class="vtime_cardiv">
                    <div class="vtime_topdiv">
                      <UserCard
                        // title="View Timesheets pending for Authorisation"
                        className=" ">
                        <div className="vtime_topsbox row">
                          <div className="col-12 clientreport_rowbox">
                            <PartnerSource
                              handleChange={handleStateChange}
                              orgPartnerId={orgPartnerId}
                              partnerSourceId={partnerSourceId}
                              hideAsterix={true}
                              firstItemText={REPORT_FIRST_ITEM_TEXT}
                            />
                          </div>
                          <div
                            className="col-12"
                            style={{ marginTop: 25, paddingLeft: 0 }}>
                            <UserButton text="View" onClick={handleView} />
                          </div>
                        </div>
                      </UserCard>
                    </div>
                  </div>
                </div>
                <div className="col-6 plm_authcol_left">
                  <UserCard
                    title={`Timesheets to be Authorised (${gridData.length})`}>
                    <TimesheetGrid
                      rowId={rowId}
                      timesheets={gridData}
                      handleRowClick={handleRowClick}
                      handleChange={handleChange}
                      handleHeaderChange={handleHeaderChange}
                      handleAuthorise={handleAuthorise}
                    />
                    <CardFoot>
                      <UserButton
                        text="Authorise Batch"
                        icon={<i className="fa fa-lock"></i>}
                        onClick={handleAuthoriseBatch}
                      />
                    </CardFoot>
                  </UserCard>
                </div>
                <div class="vtime_btmdiv col-6">
                  <UserCard title="View a Timesheet">
                    <ViewTimesheet handleView={handleView} {...state} />
                  </UserCard>
                </div>
              </div>
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
