import React from "react";

export default ({ checked, onChange }) => {
  const handleChange = (event) => {
    if (event) event.stopPropagation();
    onChange(!checked);
  };

  return (
    <>
      <div className="switch-wrapper">
        <input
          type="checkbox"
          className={"toggle-switch " + (checked ? "checked" : "")}
          checked={checked}
          onChange={handleChange}
        />
      </div>
    </>
  );
};
