import { takeLatest, call, put } from "redux-saga/effects";
import AccountAPI from "../api/AccountAPI";
import { Account } from "../actionTypes/account";
import auth from "../libs/auth";
import callAPI from "./common";
import { Pages } from "../actionTypes/pages";
import { push } from "connected-react-router";
import { RouteConstant } from "../utils/routeConstant";

function* authUser(action) {
  yield call(callAPI, AccountAPI.validateUser, action, setResult);
}

function* impersonateUser(action) {
  yield call(callAPI, AccountAPI.impersonateUser, action, setResult);
}

function* stopImpersonation(action) {
  yield call(callAPI, AccountAPI.stopImpersonation, action, setResult);
}

function* setResult(result) {
  const {
    authToken,
    permissions,
    roles,
    userDetail,
    homePage,
    orgResources,
    themes,
  } = result;
  var signedIn = yield call(auth.onSignIn, authToken);
  if (signedIn) {
    yield put({
      type: Account.ACCOUNT_SUCCESS,
      result: {
        userDetail,
        roles,
        homePage,
        themes,
      },
    });
    yield put({
      type: Pages.SET_PAGES,
      result: {
        permissions,
        orgResources: orgResources ? orgResources : [],
      },
    });
    yield put(push("/" + homePage));
  }
}

function* setUserTheme(action) {
  if (action.payload) yield call(callAPI, AccountAPI.setUserTheme, action);
}

function* verifyUser(action) {
  yield call(callAPI, AccountAPI.verifyUser, action, action.cb);
}

function* forgotPassword(action) {
  yield call(callAPI, AccountAPI.forgotPassword, action, action.cb);
}

function* confirmAccount(action) {
  yield call(callAPI, AccountAPI.confirmAccount, action, action.cb);
}

function* resetPassword(action) {
  yield call(callAPI, AccountAPI.resetPassword, action, redirectToLogin);
}

function* changePassword(action) {
  yield call(callAPI, AccountAPI.changePassword, action, redirectToLogin);
}

function* redirectToLogin() {
  yield put(push(RouteConstant.LOGIN));
}

export default function* rootSaga() {
  yield takeLatest(Account.LOGIN_USER, authUser);
  yield takeLatest(Account.IMPERSONATE_USER, impersonateUser);
  yield takeLatest(Account.STOP_IMPERSONATION, stopImpersonation);
  // yield takeLatest(Account.SET_USER_THEME, setUserTheme);
  // yield takeLatest(Account.LOGOUT_USER, setUserTheme);
  yield takeLatest(Account.VERIFY_USER, verifyUser);
  yield takeLatest(Account.CHANGE_PASSWORD, changePassword);
  yield takeLatest(Account.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(Account.RESET_PASSWORD, resetPassword);
  yield takeLatest(Account.CONFIRM_ACCOUNT, confirmAccount);
}
