import React, { useEffect, useState } from "react";
import { SelectBox } from "../components/SelectBox";
import { useSelector } from "react-redux";
import ReportFilterWrapper from "./ReportFilterWrapper";

export default ({
  onChange,
  value,
  orgPartnerId,
  partnerSourceId,
  placeholder,
  isReport,
}) => {
  const { employeeList } = useSelector((state) => state.common);
  const [employeeOptions, setEmployeeList] = useState([]);

  useEffect(() => {
    if (employeeList && employeeList.length > 0) setEmployees(employeeList);
  }, [employeeList]);

  const setEmployees = (employeeList) => {
    setEmployeeList(
      employeeList.map((c) => ({
        text: c.employee.fullName,
        value: c.employee.customerId,
      }))
    );
    if (employeeList.length > 0 && !placeholder)
      onChange("employeeId", employeeList[0].employee.customerId);
    else onChange("employeeId", null);
  };

  useEffect(() => {
    if (employeeList) {
      let filtered = employeeList;
      if (orgPartnerId)
        filtered = employeeList.filter((c) =>
          c.orgPartners.includes(parseInt(orgPartnerId))
        );
      if (partnerSourceId)
        filtered = employeeList.filter((c) =>
          c.partnerSources.includes(parseInt(partnerSourceId))
        );

      setEmployees(filtered);
    }
  }, [orgPartnerId, partnerSourceId, employeeList]);

  if (isReport) {
    return (
      <ReportFilterWrapper label="Employee" labelClassName="light-blue">
        {" "}
        <SelectBox
          name="employeeId"
          value={value}
          onChange={onChange}
          options={employeeOptions}
          className="filter-control"
          placeholder={placeholder}
        />
      </ReportFilterWrapper>
    );
  }

  return (
    <div className="selectWrap">
      <SelectBox
        name="employeeId"
        value={value}
        onChange={onChange}
        options={employeeOptions}
        label="Employee"
        placeholder={placeholder}
      />
    </div>
  );
};
