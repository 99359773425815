import { AppConstant } from "../utils/constant";
import React from "react";

export const contains = (value, searchBy) => {
  return (
    value &&
    value
      .toString()
      .toLowerCase()
      .replace(" ", "")
      .includes(searchBy.toString().replace(" ", "").toLowerCase())
  );
};

export const formatCost = (cost) => {
  if(cost==0)
    return (0).toFixed(AppConstant.DECIMAL_POMITS);

  return cost ? parseFloat(cost).toFixed(AppConstant.DECIMAL_POMITS) : (0).toFixed(AppConstant.DECIMAL_POMITS);
};

export const displayCost = (cost) => (!isNaN(cost) ? formatNegativeCost(cost) : "");

export const formatNegativeCost = (cost) =>  
  cost < 0 ? (
    <div className="negativeCost">{`(${AppConstant.CURRENCY}${formatCost(cost*-1)})`}</div>
  ) : (
    AppConstant.CURRENCY + formatCost(cost)
  );

export const applyOperator = (type, a, b) => {
  a = !isNaN(a) ? parseFloat(a) : 0;
  b = !isNaN(b) ? parseFloat(b) : 0;
  switch (type) {
    case "+":
      return a + b;
    case "-":
      return a - b;
    default:
      return 0;
  }
};

export const someInTree = (list, propToFind, value) => {
  return list.some((s) => {
    if (value.includes(s.item[propToFind])) return true;
    else return someInTree(s.children, propToFind, value);
  });
};

export const updateInTree = (
  list,
  propToFind,
  value,
  propToUpdate,
  valueToUpdate,
  updateChildren
) => {
  return list.map(({ item, children }) => {
    if (value && value.includes(item[propToFind])) {
      item[propToUpdate] = valueToUpdate;
      if (updateChildren)
        children = mapChildren(children, propToUpdate, valueToUpdate);
    }
    if (children && children.length > 0)
      children = updateInTree(
        children,
        propToFind,
        value,
        propToUpdate,
        valueToUpdate,
        updateChildren
      );

    return { item, children };
  });
};

const mapChildren = (list, propToUpdate, valueToUpdate) => {
  return list.map((c) => ({
    item: { ...c.item, [propToUpdate]: valueToUpdate },
    children: mapChildren(c.children, propToUpdate, valueToUpdate),
  }));
};

export const getFromTree = (list, propToFind, value, propToGet) => {
  let values = [];
  list.forEach((s) => {
    if (value == s.item[propToFind]) {
      values.push(s.item[propToGet]);
    }
    if (s.children && s.children.length > 0)
      values = values.concat(
        getFromTree(s.children, propToFind, value, propToGet)
      );
  });

  return values;
};


export const normalizeData = (data, rowId) => {
  return data.map((td) => {
    const keys = Object.keys(td);
    return keys.map((key) => ({
      ...td,
      key,
      text: td[key],
      rowId: td[rowId],
    }));
  });
};
