import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default (props)=>{

    const [state,setState]=useState({
        crop:{
            aspect: 16 / 9
        }
    });
    const {image,crop}=state;

    const getCroppedImg=(crop)=> {
        
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
       
        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );

        let {fileName}=props;
        const fileData=canvas.toDataURL().split(',');
        if(fileData.length>0)
            fileName=(fileName?fileName:'Org_Img')+'.'+fileData[0].split('/')[1].split(';')[0];

        if(fileData.length>1)
            return {
                contentType:fileData[0],
                fileDataAsBase64:fileData[1],
                fileName
            };

        return {};
    } 

    const handleChange=(name,value)=>
        setState(prev=>({...prev,[name]:value}));

    const onImageLoaded=(image)=>
    {
        setState(prev=>({...prev,image}));
        return false;
    }

    const setCropImage=(crop)=>
    {
        const {onChange,name}=props;
        if(image && crop.width && crop.height)
        {
            const croppedImage=getCroppedImg(crop);
            onChange(name,croppedImage);
        }
    }

    const {src,className,croppedImage,imageStyle}=props;

    return (
        <div className='row'>
            <div className='col-12'>
                <ReactCrop 
                    src={src} 
                    crop={crop}
                    keepSelection={false}
                    onChange={(value)=>handleChange('crop',value)}
                    className={className}
                    onImageLoaded={onImageLoaded.bind(this)}
                    onComplete={setCropImage.bind(this)}
                    circularCrop={true}
                    imageStyle={imageStyle}
                />
            </div>
            {croppedImage && (
                <div className={'col-6'+(className?' '+className:'')}>
                    <img alt="Crop" 
                        src={croppedImage} 
                        style={imageStyle}
                    />
                </div>
            )}
        </div>
    )
}