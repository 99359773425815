import React, { useState, useEffect } from "react";
import TableHeader from "./Header";
import TableData from "./Body";
import { orderBy, includes } from "lodash";
import "./_table.scss";
import useForm from "../../hooks/useForm";
import { SelectBox } from "../SelectBox";
import Pagination from "./Pagination";
import { TextInput } from "../TextInput";

export const filterGridData = (data, searchBy) => {
  Object.keys(searchBy).forEach((key) => {
    const values = searchBy[key];
    console.log(values);
    if (values && values.length > 0)
      data = data.filter((c) => includes(values, c[key]));
  });

  return data;
};

export const filterGridFieldsData = (data, state, headers) => {

    Object.keys(state).forEach((key) => {
      if(headers.some(h=> h.key == key)){
        const value = state[key];

      if (value && value.length > 0)
        data = data.filter((c) => c[key].toLowerCase().includes(value.toLowerCase()));
      }
    });

    return data;
};

function normalizeData(data, rowId) {
  return data.map((td) => {
    const keys = Object.keys(td);
    return keys.map((key) => ({
      ...td,
      key,
      text: td[key],
      rowId: td[rowId],
    }));
  });
}

const countOptions = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

export default (props) => {
  const {
    headers,
    data,
    rowId,
    handleHeaderChange,
    handleChange,
    disable,
    tblClass,
    actionProps,
    showActions,
    showSearchFirst,
    showReportSortIc,
    defaultSortBy,
    tableProps,
    wrapperClass,
    showPagination,
    showSearch,
    showFieldSearch
  } = props;
  const [tableHeaders, setHeaders] = useState([]);
  const [tableData, setData] = useState([]);
  const [searchBy, setSearchBy] = useState({});
  const [sortBy, setSortBy] = useState({ ...defaultSortBy });
  const { state, handleStateChange } = useForm({});
  const { noOfItems, searchText } = state;

  useEffect(() => {
    if (showPagination) {
      handleStateChange({ noOfItems: 50, startIndex: 0 });
    }
  }, []);

  const setStartIndex = (startIndex) => {
    handleStateChange({ startIndex });
  };

  const onChange = (name, value) => {
    handleStateChange({ [name]: value });
  };

  const setTableHeaders = () => {
    if (headers) setHeaders(headers);
  };

  const setTableData = (data, searchByFields=[]) => {
    if (data) {
      let filteredData = filterGridData(data, searchBy);

      if(searchByFields)
        filteredData= filterGridFieldsData(data, searchByFields, headers);

      if (sortBy) {
        const keys = Object.keys(sortBy);
        if (keys.length > 0) {
          filteredData = orderBy(
            data,
            keys,
            keys.map((key) => sortBy[key])
          );
        }
      }
      setData(normalizeData(filteredData, rowId));
    }
  };

  useEffect(setTableHeaders, [headers]);

  useEffect(() => setTableData(data), [data, searchBy, sortBy]);

  useEffect(()=> setTableData(data, state),[state]);

  const handleSearch = (name, value) => {
    setSearchBy((prev) => ({ ...prev, [name]: value }));
  };

  const handleSort = (name, value) => {
    let sortBy = "asc";
    if (value == sortBy) sortBy = "desc";

    setSortBy({ [name]: sortBy });
  };

  const handleClick = (event, id) => {
    if (event) event.stopPropagation();

    const { handleRowClick } = props;
    if (handleRowClick) handleRowClick(id);
  };

  return (
    <div className={wrapperClass ? wrapperClass : "tb-wrap"}>
      {showPagination && (
        <div class="dataTables_length row-wrap" id="DataTables_Table_0_length">
          Show&nbsp;
          <SelectBox
            name="noOfItems"
            value={noOfItems}
            onChange={onChange}
            options={countOptions}
          />
          &nbsp;entries
        </div>
      )}
      {showSearch && (
        <div id="DataTables_Table_0_filter" class="dataTables_filter row-wrap">
          Search:&nbsp;
          <TextInput
            class=" "
            name="searchText"
            value={searchText}
            onChange={onChange}
          />
        </div>
      )}

      {showFieldSearch && (
        <div id="DataTables_Table_0_filter" class="dataTables_filter row-wrap fields_filter">
          
          {
            headers.map((field)=>{
              return <TextInput
                class=" "
                name={field.key}
                value={state[field.key]}
                onChange={onChange}
              />
            })
          }

          {/* <TextInput
            class=" "
            name="clientName"
            value={searchText}
            onChange={onChange}
          />

          <TextInput
            class=" "
            name="invoiceDate"
            value={searchText}
            onChange={onChange}
          />

          <TextInput
            class=" "
            name="invoiceNumber"
            value={searchText}
            onChange={onChange}
          />

          <TextInput
            class=" "
            name="net"
            value={searchText}
            onChange={onChange}
          />

          <TextInput
            class=" "
            name="vat"
            value={searchText}
            onChange={onChange}
          />

          <TextInput
            class=" "
            name="gross"
            value={searchText}
            onChange={onChange}
          /> */}
        </div>
      )}
      <div
        className={
          wrapperClass ? wrapperClass : "table-responsive plm_cmn_tbldiv"
        }
      >
        <table
          className={"table " + (tblClass ? tblClass : "")}
          {...tableProps}
        >
          <TableHeader
            headers={tableHeaders}
            data={data ? data : []}
            handleSearch={handleSearch.bind(this)}
            searchBy={searchBy}
            sortBy={sortBy}
            handleSort={handleSort.bind(this)}
            handleChange={handleHeaderChange}
            showActions={showActions}
            showSearchFirst={showSearchFirst}
            showReportSortIc={showReportSortIc}
          />
          <TableData
            handleChange={handleChange}
            data={tableData}
            meta={headers}
            disable={disable}
            actionProps={actionProps}
            showActions={showActions}
            handleRowClick={handleClick.bind(this)}
          />
        </table>
        {showPagination && (
          <Pagination
            items={data}
            setItems={setTableData}
            itemPerPage={noOfItems}
            setStartIndex={setStartIndex}
          />
        )}
      </div>
    </div>
  );
};
