import React, { useEffect, useState } from "react";
import { getClientsWIP } from "../../../actions/client";
import { ClientGrid, Report } from "../ClientWIPSummary";
import { WIPHeader } from "../Components";
import "./ClientWIPSummary.scss";
import useUserDetail from "../../../hooks/useUserDetail";
import { useSelector } from "react-redux";

export default React.forwardRef(
  ({ showReport, reportTitle, reportType, toggleReportBtn, gridRef }, ref) => {
    const [state, setState] = useState({});
    const { clientsWIP } = useSelector((state) => state.client);
    const { theme } = useUserDetail();

    useEffect(() => {
      toggleReportBtn(clientsWIP && clientsWIP.length > 0);
    }, [clientsWIP]);

    return (
      <>
        <WIPHeader action={getClientsWIP} setHeader={setState} />
        <ClientGrid />
        {showReport && (
          <div className="reportDataContainer">
            <div className={"plm_rp_rptort_col App " + theme} ref={gridRef}>
              <div className="row reportWrapper rwrapper-summary rwrapPdf">
                <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 cws-tbl-new">
                  <Report
                    {...state}
                    reportTitle={reportTitle}
                    reportType={reportType}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
