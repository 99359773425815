import React, { useState, useEffect, useRef } from "react";
import { Report as Invoice } from "../../Report/Invoice";
import { Report as ClientCostingSummary } from "../../Report/ClientCostingSummary";
import { Report as FeeIncomeDetail } from "../../Report/FeeIncomeDetail";
import { downloadReport } from "../../../actions/report";
import usePrintHook from "../../hooks/usePrintHook";
import useToggle from "../../../hooks/useToggle";
import { SelectBox } from "../../../components/SelectBox";
import { UserButton } from "../../../components/Button";
import useVerifyReport from "../../hooks/useVerifyReport";
import useTheme from "../../../hooks/useTheme";

const InvoiceReport = ({ selectedInvoice, type, reportTitle, gridRef }) => {
  switch (type) {
    case 1:
      return (
        <div className="col-xs-12 col-md-12">
          <Invoice
            selectedInvoice={selectedInvoice}
            reportTitle={reportTitle}
          />
        </div>
      );
    case 2:
      return (
        <ClientCostingSummary
          selectedInvoice={selectedInvoice}
          reportTitle={reportTitle}
        />
      );
    case 3:
      return (
        <FeeIncomeDetail
          selectedInvoice={selectedInvoice}
          reportTitle={reportTitle}
          gridRef={gridRef}
        />
      );
    default:
      return <></>;
  }
};

export default ({ selectedInvoice }) => {
  const targetRef = useRef();
  const [type, setReportType] = useState(null);
  const { show: showReport, setShow: toggleReport } = useToggle();
  const [reportTitle, setReportTitle] = useState(null);
  const [format, setFormat] = useState();
  const { theme } = useTheme();
  const { handleAction, formatOptions, PDF } = usePrintHook({
    showReport,
    toggleReport,
    targetRef,
    format,
    reportName: reportTitle,
  });

  useVerifyReport(showReport, selectedInvoice);

  useEffect(() => {
    setFormat(PDF);
  }, []);

  const handlePrint = (type) => {
    setReportType(type);
    handleAction(downloadReport);
  };

  useEffect(() => {}, [targetRef.current]);

  useEffect(() => {
    setTitle();
  }, [type]);

  const setTitle = () => {
    let title;
    switch (type) {
      case 3:
        title = "Fee Income Details";
        break;
      case 2:
        title = "Client Costing Summary";
        break;
      case 1:
        title = "Invoice";
        break;
      default:
        title = "Report";
        break;
    }
    setReportTitle(title);
  };

  const handleChange = (name, value) => {
    setFormat(value);
  };

  return (
    <>
      <div className={`row plm_rp_rptort_col ${theme} print_invoice_report_container`}>
            <div className="reportDataContainer">
              <div ref={targetRef}>
                <InvoiceReport
                  selectedInvoice={selectedInvoice ? selectedInvoice : {}}
                  type={type}
                  reportTitle={reportTitle}
                  gridRef={targetRef}
                />
              </div>
            </div>
        </div>
      <div className="col-2 pdf-textWrap" style={{ marginTop: 0 }}>
        <SelectBox
          name="format"
          value={format}
          label="Format"
          onChange={handleChange}
          options={formatOptions}
        />
      </div>
      <div className="col-6 col-6-invoicebtnContainer">
        <UserButton
          text="Print Invoice"
          icon={<i className="fa fa-print"></i>}
          className="btn btn-primary"
          onClick={() => handlePrint(1)}
        />

        <UserButton
          text="Print Costing Summary"
          icon={<i className="fa fa-print"></i>}
          className="btn btn-primary"
          onClick={() => handlePrint(2)}
        />

        <UserButton
          text="Fee Income Details"
          icon={<i className="fa fa-print"></i>}
          className="btn btn-primary"
          onClick={() => handlePrint(3)}
        />
      </div>
      {/* <div className="col-2 px-0">
        
      </div>
      <div className="col-2">
        
      </div> */}
    </>
  );
};
