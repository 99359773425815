import React from 'react';
import PropTypes from 'prop-types';
import { Error, UserLabel } from '../Label';

const Input=(props)=>
{
    const {name,className,disabled,value,onChange}=props;
    return <>
                <input
                    type="radio"
                    name={name}
                    checked={value?value:false}
                    className={className ? `${className} form-control` : 'form-control'}
                    disabled={disabled}
                    onChange={onChange}
                />
                <span></span>
            </>
   }


const RadioButton=(props)=> {
    const {errors,label,name,value,disabled,onChange}=props;
    const error=errors?errors[name]:null;

    const onFieldChange=(event)=> {
        if(event)
            event.stopPropagation();
            
        if(!disabled)
            onChange(name, !value);
    }

       

        return (
            <div className='form-section'>
                <div className="form-section__field">
                {label?
                    <div className="chWrap">
                        <Input
                            {...props}
                            onChange={onFieldChange.bind(this)}
                        />
                        <UserLabel
                            {...props}
                        />
                    </div>
                :
                    <div className="row m-0">
                    <div className="col-sm-12 col-xs-12 col-lg-12 px-0">
                        <Input
                            {...props}
                            onChange={onFieldChange.bind(this)}
                        />
                    </div>
                    </div>
                }
                {error &&
                    <Error
                        error={error}
                    />
                }
            </div>
        </div>
        )
    }


RadioButton.propTypes = {
    id: PropTypes.string,
    error: PropTypes.string,
    class: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};

export default RadioButton;