import { takeLatest, call, put } from 'redux-saga/effects';
import OrgBillingAPI from "../api/OrgBillingAPI";
import { OrgBilling } from "../actionTypes/orgBilling";
import callAPI from './common';

function* addEditBillingNarratives(action)
{
    yield call(callAPI,OrgBillingAPI.addBillingNarratives,action,setResult);
}

function* getBillingNarratives(action)
{
    yield call(callAPI,OrgBillingAPI.getBillingNarratives,action,setResult);
}

function* deleteBillingNarratives(action)
{
    yield call(callAPI,OrgBillingAPI.deleteBillingNarratives,action,setResult);
}

function* setResult(result)
{
    yield put({type:OrgBilling.ORG_BILLING_SUCCESS,result});
}


export default function* rootSaga() {
    yield takeLatest(OrgBilling.ADD_BILLING_NARRATIVES, addEditBillingNarratives);
    yield takeLatest(OrgBilling.GET_BILLING_NARRATIVES, getBillingNarratives);
    yield takeLatest(OrgBilling.DELETE_BILLING_NARRATIVES, deleteBillingNarratives);
}