import React, {useEffect } from 'react';
import { useDispatch} from 'react-redux';
import useForm from '../../../../hooks/useForm';
import { UserModal } from '../../../../components/Modal';
import { TextInput ,TextArea} from '../../../../components/TextInput';
import { addBillingNarratives } from '../../../../actions/orgBilling';

const stateSchema={
    
};

const validationSchema={
    billingRef:{
        required:true
    },
    billNarrativesDesc:{
        required:true
    }
}

const AddBilling=({billingDetail,handleChange,errors})=>{

        const {
            billingRef,
            billNarrativesDesc
        }=billingDetail;

        return (
                <div className='row'>
                    <div className='col-6'>
                        <TextInput
                            name="billingRef"
                            label="Bill Reference"
                            value={billingRef}
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    <div className='col-6'>
                        <TextArea
                            name="billNarrativesDesc"
                            value={billNarrativesDesc}
                            label="Billing Narratives"
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                            rows={3}
                            cols={4}
                        />
                    </div>
                </div>
        )
}


export default ({showAddBilling,toggleModal,selectedBilling})=>{

    const {
        state:billingDetail,
        errors,
        handleChange,
        handleSubmit,
        handleStateChange
    }=useForm(stateSchema,validationSchema,handleSave);

    const dispatch=useDispatch();

    useEffect(()=>{
        if(selectedBilling)
            handleStateChange(selectedBilling);
    },[]);

    function handleSave(billingDetail){
        dispatch(addBillingNarratives(billingDetail));
        toggleModal();
    }

    return (
        <div className='col-12'>
            <UserModal
                show={showAddBilling}
                handleClose={toggleModal}
                title="Add Billing Narratives"
                handleSave={handleSubmit}
            >
                <AddBilling
                    billingDetail={billingDetail}
                    handleChange={handleChange}
                    errors={errors}
                />
            </UserModal>
        </div>
    )
};