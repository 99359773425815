import React, { useState, useEffect } from "react";
import { Invoice } from "../Components";
import { Report } from "../ClientCostingSummary";
import useVerifyReport from "../../hooks/useVerifyReport";
import useUserDetail from "../../../hooks/useUserDetail";

export default React.forwardRef(
  ({ showReport, reportTitle, reportType, toggleReportBtn, gridRef, toggleRowPrint }, ref) => {
    const [state, setState] = useState({});
    //useVerifyReport(showReport, state);
    const { theme } = useUserDetail();

    return (
      <>
        <Invoice setState={setState} toggleReportBtn={toggleReportBtn} toggleRowPrint={toggleRowPrint} />
        {showReport && (
          <div className="col-12 reportDataContainer">
            <div ref={gridRef}>
              <Report
                selectedInvoice={state}
                reportTitle={reportTitle}
                reportType={reportType}
              />
            </div>
          </div>
        )}
      </>
    );
  }
);
