import React from 'react';
import useTheme from '../../hooks/useTheme';
import { Link } from 'react-router-dom';

const UserLink = ({text,disabled = false, url,icon,showIconFirst,className}) =>{
   
    let btnText=showIconFirst?
                         (<span>{icon?icon:''} <span className="btext">{text}</span></span>)
                        :(<span><span className="btext">{text}</span> {icon?icon:''}</span>);

    const {theme}=useTheme();

    return (
        <Link 
            to={url}
            className={theme+(disabled ? "btn-disabled" : className ? className : "btn")} 
        >
            {btnText}
        </Link>
    )
};

export default UserLink;