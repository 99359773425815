import APIUtil from './APIUtil';

class CustomerAPI
{
    addCustomerView(payload)
    {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/Customer/AddCustomerView`;
        return fetch(url, options);
    }

    addEditCustomer(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Customer/AddUpdateCustomer`;
    return fetch(url, options);
  }

  getCustomers(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Customer/GetCustomers`;
    return fetch(url, options);
  }

  getActiveCustomers(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Customer/GetActiveCustomers`;
    return fetch(url, options);
  }

  enableDisableCustomer(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/Customer/EnableDisableCustomers`;
    return fetch(url, options);
  }

}

export default new CustomerAPI();