import React, { useEffect, useState } from "react";
import GridButton from "../../common/GridButton";
import { useDispatch, useSelector } from "react-redux";
import useGridHook from "../../hooks/useGridHook";
import useToggle from "../../hooks/useToggle";
import {
  getBillingNarratives,
  deleteBillingNarratives,
} from "../../actions/orgBilling";
import { BillingGrid, AddBilling } from "./Components/BillingNarratives";
import "./scss/BillingNarratives.scss";
import { UserCard } from "../../components/Container";

const rowId = "orgBillingId";

export default (props) => {
  const dispatch = useDispatch();
  const {
    gridData,
    handleChange,
    handleRowClick,
    setGridData,
    deleteRow,
  } = useGridHook(rowId, handleDelete);
  const { billingNarratives } = useSelector((state) => state.orgBilling);
  const [selectedBilling, setSelectedBilling] = useState(null);

  const fetchBilling = () => {
    dispatch(getBillingNarratives());
  };

  const setBilling = (billingNarratives) => {
    if (billingNarratives) setGridData(billingNarratives);
  };

  useEffect(fetchBilling, []);

  useEffect(() => setBilling(billingNarratives), [billingNarratives]);

  const { show: showAddBilling, toggle: toggleAddBilling } = useToggle();

  const handleAddEdit = (orgBillingId) => {
    setSelectedBilling(gridData.find((c) => c[rowId] == orgBillingId));
    toggleAddBilling();
  };

  function handleDelete(orgBillingId) {
    dispatch(deleteBillingNarratives(orgBillingId));
  }

  return (
    <div class="plm_billnaratelist_div">
      <div class="container-fluid prn_cfuid">
        <div class="row prn_row">
          <div class="col-12 prn_col12">
            <UserCard title="Billing Narratives">
              <BillingGrid
                rowId={rowId}
                billingNarratives={gridData}
                handleChange={handleChange.bind(this)}
                handleRowClick={handleRowClick.bind(this)}
                handleEdit={handleAddEdit}
                handleDelete={handleDelete}
              />
              <div className="plm_cmn_nocard">
                <GridButton handleAdd={handleAddEdit} />
              </div>
              {showAddBilling && (
                <AddBilling
                  showAddBilling={showAddBilling}
                  toggleModal={toggleAddBilling}
                  selectedBilling={selectedBilling}
                />
              )}
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
