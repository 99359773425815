import React, { useEffect, useState } from "react";
import { UserTable } from "../../../components/Table";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { groupBy, map } from "lodash";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";
import WordReport from "./WordReport";
import { PartnerLabel, SourceLabel } from "../Components";

export default (props) => {
  const [gridData, setGridData] = useState([]);
  const { theme } = useUserDetail();
  const {
    clients,
    rowId,
    partnerLabel,
    sourceLabel,
    search,
    sort,
    isReport,
    reportType,
  } = props;
  const { WORD, EXCEL } = usePrintHook({});

  useEffect(() => {
    if (clients) {
      const partnerGroup = groupBy(clients, (c) => c.orgPartnerId);
      setGridData(
        map(partnerGroup, (c) => {
          const { orgPartner } = c[0];
          return {
            orgPartner,
            sources: map(
              groupBy(c, (s) => s.partnerSourceId),
              (s) => {
                const { partnerSource } = s[0];
                return {
                  partnerSource,
                  clients: s,
                };
              }
            ),
          };
        })
      );
    }
  }, [clients]);

  const headers = [
    { key: "clientCode", text: "Ref", search, sort },
    { key: "clientName", text: "Client Name", search, sort },
    { key: "billingName", text: "Billing Name", search, sort },
    { key: "address", text: "Address", search, sort },
    { key: "contactPerson", text: "Contact Name", search, sort },
    { key: "emailId", text: "Email", search, sort },
    // { key: "website", text: "Website", search, sort },
    { key: "mobileno", text: "Telephone", search, sort },
    // { key: "country", text: "Country", search, sort },
  ].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

  const getLabelHeader=(heading, subheading)=>{
    const defaultText=" ";

    const headers = [
      { text: `${heading}: ${subheading}`, colSpan:2 },
      { text: defaultText },
      { text: defaultText },
      { text: defaultText },
      { text: defaultText },
      { text: defaultText },
    ].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

    return <UserTable
                headers={headers}
                data={[]}
                rowId={rowId}
                tblClass="table table-stripe tblLabelHeader"
                tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
              />

  }

  const className = isReport ? "col-12 col-md-12 col-xs-12" : "col-12";

  if (isReport && (reportType == WORD || reportType == EXCEL)) {
    return (
      <WordReport
        partnerLabel={partnerLabel}
        sourceLabel={sourceLabel}
        gridData={gridData}
      />
    );
  }

  return (
    <>
      {gridData.map((c, index) => (
        <React.Fragment key={index}>
          <PartnerLabel partnerName={c.orgPartner} />
          {c.sources.map((s) => (
            <React.Fragment key={s.partnerSource}>
              {/* <SourceLabel sourceName={s.partnerSource} /> */}

              {  getLabelHeader(sourceLabel, s.partnerSource) }

              <UserTable
                headers={headers}
                data={s.clients}
                rowId={rowId}
                tblClass="table table-stripe"
                tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </>
  );
};
