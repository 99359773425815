import React, { useRef } from 'react';
import { useDispatch} from 'react-redux';
import { UserModal } from '../../../components/Modal';
import {  AddEditInvoice } from '../CreateInvoice';
import { updateInvoice } from '../../../actions/invoice';

export default ({showEditInvoice,toggleModal})=>{

    const dispatch=useDispatch();
    const updateInvoiceRef=useRef();

    const handleSubmit=()=>{
        if(updateInvoiceRef)
            updateInvoiceRef.current.addEditInvoice();
    }

    function handleSave(invoiceDetail){
        dispatch(updateInvoice(invoiceDetail));
        toggleModal();
    }

    return (
        <div className='col-12'>
            <UserModal
                show={showEditInvoice}
                handleClose={toggleModal}
                title="Update Invoice"
                size="lg"
                handleSave={handleSubmit}
            >
                <AddEditInvoice
                    ref={updateInvoiceRef}
                    handleSave={handleSave}
                />
            </UserModal>
        </div>
    )
};