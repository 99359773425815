import React, { useState, useEffect } from "react";
import { SelectBox } from "../components/SelectBox";
import { useSelector } from "react-redux";
import useOrgResources from "../hooks/useOrgResources";
import ReportFilterWrapper from "./ReportFilterWrapper";

export default ({
  orgPartnerId,
  partnerSourceId,
  handleChange,
  errors,
  hideAsterix,
  firstItemText,
  isReport,
}) => {
  const { orgPartners, partnerSources: initSources } = useSelector(
    (state) => state.common
  );
  const { partnerLabel, sourceLabel } = useOrgResources();
  const [partnerSources, setSources] = useState([]);

  useEffect(() => {
    if (initSources) {
      if (orgPartnerId)
        setSources(initSources.filter((c) => c.orgPartnerId == orgPartnerId));
      else {
        setSources([]);
        handleChange("partnerSourceId", null);
      }
    }
  }, [orgPartnerId, initSources]);

  if (isReport) {
    return (
      <>
        <ReportFilterWrapper label={partnerLabel} labelClassName="back-purple">
          <SelectBox
            name="orgPartnerId"
            value={orgPartnerId}
            onChange={handleChange}
            options={orgPartners}
            errors={errors}
            asterix={!hideAsterix}
            firstItemText={firstItemText}
            className="filter-control"
            addFirstItem
          />
        </ReportFilterWrapper>
        <ReportFilterWrapper label={sourceLabel} labelClassName="back-blue">
          <SelectBox
            name="partnerSourceId"
            value={partnerSourceId}
            onChange={handleChange}
            options={partnerSources}
            errors={errors}
            asterix={!hideAsterix}
            firstItemText={firstItemText}
            className="filter-control"
            addFirstItem
          />
        </ReportFilterWrapper>
      </>
    );
  }

  return (
    // <div className='vtime_topsbox'>
    <>
      <div className="row">
        <div className="col-6 lbl-min-width">
          <SelectBox
            name="orgPartnerId"
            value={orgPartnerId}
            label={partnerLabel}
            onChange={handleChange}
            options={orgPartners}
            errors={errors}
            asterix={!hideAsterix}
            firstItemText={firstItemText}
            addFirstItem
            labelClassName="lbl-pink"
          />
        </div>
        <div className="col-6 lbl-min-width">
          <SelectBox
            name="partnerSourceId"
            value={partnerSourceId}
            label={sourceLabel}
            onChange={handleChange}
            options={partnerSources}
            errors={errors}
            asterix={!hideAsterix}
            firstItemText={firstItemText}
            addFirstItem
            labelClassName="lbl-green"
          />
        </div>
      </div>
    </>
  );
};
