import React, { useState } from 'react';
import './Tree.scss';

const ChildNode=({item,childNodes})=>{
    
    const [isOpen,toggleNode]=useState(false);

    const handleClick=(event)=>{
        if(event)
            event.stopPropagation();

        toggleNode(!isOpen);
    }

    return <li 
                style={{position:'relative'}}
            >
                <span className='plusIcWrapper' onClick={(e)=>handleClick(e)}>
                    {!isOpen &&
                        <span className='plusIc'>+</span>
                    }
                </span>
                {item}
                {isOpen &&
                    (childNodes && childNodes.length>0) &&
                        <ParentNode
                            nodes={childNodes}
                        />
                }
            </li>
}

const ParentNode=({nodes})=>(
    <ol className='orderedList'>
        {nodes && nodes.map(c=>(
            <ChildNode
                key={c.nodeId}
                {...c}
            />
        ))}
    </ol>
)

export default (props)=>(
    <ParentNode
        {...props}
    />
)