import React from "react";
import { UserTable } from "../../../../components/Table";

export default (props) => {
  const {
    billingNarratives,
    rowId,
    handleRowClick,
    handleDelete,
    handleEdit,
  } = props;

  const headers = [
    { key: "billingRef", text: "Bill Reference", search: true, sort: true },
    { key: "billNarrativesDesc", text: "Billing Narratives" },
  ];

  return (
    <div class="billing_narrat_inner plm_partboxcmn">
      <UserTable
        headers={headers}
        data={billingNarratives}
        rowId={rowId}
        handleRowClick={handleRowClick}
        showActions={true}
        actionProps={{
          handleEdit,
          handleDelete,
          deleteTitle: "billing narratives",
        }}
      />
    </div>
  );
};
