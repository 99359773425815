import React from "react";
import { UserButton } from "../components/Button";

export default ({ handleAdd }) => {
  return (
    <div className="tbBtn plm_cmn_cardbtn_div">
      <UserButton
        icon={<i className="fa fa-plus"></i>}
        className="btn btn-primary roundBtn"
        onClick={() => handleAdd(false)}
      />
    </div>
  );
};
