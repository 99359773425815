import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartnerSource from "../../../common/PartnerSource";
import WeekSelectBox from "../../../common/WeekSelectBox";
import { formatAPIDate } from "../../../libs/dateHelper";
import { getTimesheetDetails, setTimesheet } from "../../../actions/timesheet";
import { UserButton } from "../../../components/Button";
import { TimesheetGrid, Report } from "../WeeklyTimesheet";
import { getTimesheetEmployees } from "../../../actions/report";
import EmployeeSelectBox from "../../../common/EmployeeSelectBox";
import "./TimesheetReport.scss";
import { AppConstant } from "../../../utils/constant";
import useUserDetail from "../../../hooks/useUserDetail";

const { REPORT_FIRST_ITEM_TEXT } = AppConstant;

export default React.forwardRef(
  ({ showReport, reportType, toggleReportBtn, gridRef }, ref) => {
    const dispatch = useDispatch();
    const { theme } = useUserDetail();
    const { timesheet, timesheetDetails } = useSelector(
      (state) => state.timesheet
    );
    const { employeeList } = useSelector((state) => state.common);

    useEffect(() => {
      dispatch(getTimesheetEmployees());
      dispatch(setTimesheet({ timesheet: [], timesheetDetails: [] }));
    }, []);

    const [state, setState] = useState({});
    const {
      timesheetDates,
      employeeId,
      orgPartnerId,
      partnerSourceId,
      employeeName,
    } = state;
    const { timesheetStatus } = timesheet ? timesheet : {};

    const handleChange = (name, value) => {
      setState((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
      if (employeeId)
        handleChange(
          "employeeName",
          employeeList.find((c) => c.employee.customerId == employeeId)
            ?.employee.fullName
        );
    }, [employeeId]);

    useEffect(() => {
      toggleReportBtn(timesheetDetails && timesheetDetails.length > 0);
    }, [timesheetDetails]);

    const handleDateChange = (timesheetDates) => {
      handleChange("timesheetDates", timesheetDates);
      getTimesheets(null, timesheetDates);
    };

    const getTimesheets = (event, dates) => {
      const { startDate } = dates ? dates : timesheetDates;
      dispatch(
        getTimesheetDetails({
          timesheetDate: formatAPIDate(startDate),
          userId: employeeId,
        })
      );
    };

    return (
      <>
        <div className="ctop">
          <div className="ctop-flex">
            <PartnerSource
              hideAsterix={true}
              handleChange={handleChange}
              orgPartnerId={orgPartnerId}
              firstItemText={REPORT_FIRST_ITEM_TEXT}
              partnerSourceId={partnerSourceId}
              isReport
            />
            <EmployeeSelectBox
              value={employeeId}
              orgPartnerId={orgPartnerId}
              partnerSourceId={partnerSourceId}
              onChange={handleChange}
              isReport
            />
          </div>
          <div className="week-calendar">
            <div className="ctop-inps">
              <WeekSelectBox
                {...timesheetDates}
                handleChange={handleDateChange}
                selectClassName="week-calendar-inp"
                suffix={
                  timesheetStatus ? (
                    <div className="week-cal-label">{timesheetStatus}</div>
                  ) : (
                    ""
                  )
                }
              />
            </div>
          </div>
          <UserButton
            text="View"
            onClick={getTimesheets}
            className="btn btn-purple"
          />
        </div>
        {timesheetDetails && timesheetDetails.length > 0 && (
          <TimesheetGrid
            timesheetDates={timesheetDates}
            employeeName={employeeName}
          />
        )}
        {showReport && (
          <div className="reportDataContainer">
            <div className="timesheet_report_div" ref={gridRef}>
              <Report {...state} reportType={reportType} />
            </div>
          </div>
        )}
      </>
    );
  }
);
