import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { UserCard } from "../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import { getClients, deleteClient } from "../../actions/client";
import useGridHook from "../../hooks/useGridHook";
import { ClientGrid, AddClient } from "./Components/Client";
import GridButton from "../../common/GridButton";
import useOrgResources from "../../hooks/useOrgResources";
import "./scss/Client.scss";

const rowId = "clientId";

export default (props) => {
  const dispatch = useDispatch();
  const { gridData, handleChange, handleRowClick, setGridData } = useGridHook(
    rowId
  );
  const { clients } = useSelector((state) => state.client);
  const [selectedClient, setSelectedClient] = useState(null);
  const labels = useOrgResources();

  const fetchClients = () => {
    dispatch(getClients());
  };

  const setClients = (clients) => {
    if (clients) setGridData(clients);
  };

  useEffect(fetchClients, []);

  useEffect(() => setClients(clients), [clients]);

  const { show: showAddClient, toggle: toggleAddClient } = useToggle();

  const handleAddEdit = (clientId) => {
    const selectedClient = gridData.find((c) => c[rowId] == clientId);
    setSelectedClient(selectedClient);
    toggleAddClient();
  };

  function handleDelete(clientId) {
    dispatch(deleteClient(clientId));
  }

  return (
    <div class="plm_clients_list_div">
      <div class="container-fluid prn_cfuid">
        <div class="row prn_row">
          <div class="col-12 prn_col12">
            <UserCard title="Clients">
              <ClientGrid
                {...labels}
                rowId={rowId}
                clients={gridData}
                handleChange={handleChange.bind(this)}
                handleRowClick={handleRowClick.bind(this)}
                handleEdit={handleAddEdit}
                handleDelete={handleDelete}
              />
              <div className="hr-divider"></div>
              <GridButton handleAdd={handleAddEdit} />
              {showAddClient && (
                <AddClient
                  showAddClient={showAddClient}
                  toggleModal={toggleAddClient}
                  selectedClient={selectedClient}
                  clients={clients}
                />
              )}
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
