import React from 'react';
import { UserCard } from '../../../components/Container';
import { Link } from 'react-router-dom';
import { RouteConstant } from '../../../utils/routeConstant';


export default (props)=>{

    return (
        <div class="plm_cmn_wiz_step plm_invwiz_stepw6">
            <div class="plm_cmn_wiz_content plm_inv_wiz_step6">
            <UserCard
                title="6. Print Invoice Reports"
            >

            </UserCard>
            </div>
            <Link
                className="btn btn-P"
                to={RouteConstant.INVOICES}
            >
                Finish
            </Link>
        </div>
    )
}