import APIUtil from './APIUtil';

class OrgPartnerAPI
{

    addEditOrgPartner(payload) {
        let options=APIUtil.postOptions(payload);
        let url = `${APIUtil.getBaseUrl()}api/OrgPartner/AddEditOrgPartner`;
        return fetch(url, options);
    }

  addEditPartnerSource(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/OrgPartner/AddEditPartnerSource`;
    return fetch(url, options);
  }

  getOrgPartners(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/OrgPartner/GetOrgPartners`;
    return fetch(url, options);
  }

  deleteOrgPartner(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/OrgPartner/DeleteOrgPartner`;
    return fetch(url, options);
  }

  deletePartnerSource(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/OrgPartner/DeletePartnerSource`;
    return fetch(url, options);
  }

  addEditResource(payload) {
    let options=APIUtil.postOptions(payload);
    let url = `${APIUtil.getBaseUrl()}api/OrgPartner/AddEditOrgResources`;
    return fetch(url, options);
  }
}

export default new OrgPartnerAPI();