import { takeLatest, call, put } from 'redux-saga/effects';
import TaxRateAPI from "../api/TaxRateAPI";
import { TaxRate } from "../actionTypes/taxRate";
import callAPI from './common';
import { Common } from '../actionTypes/common';

function* addEditTaxType(action)
{
    yield call(callAPI,TaxRateAPI.addTaxType,action,setCommonData);
}

function* addTaxRateView(action)
{
    yield call(callAPI,TaxRateAPI.addTaxRateView,action,setCommonData);
}

function* setCommonData(result)
{
    yield put({type:Common.SET_COMMON_DATA,result});
}

function* addEditTaxRate(action)
{
    yield call(callAPI,TaxRateAPI.addTaxRates,action,setResult);
}

function* getTaxRates(action)
{
    yield call(callAPI,TaxRateAPI.getTaxRates,action,setResult);
}

function* deleteTaxRate(action)
{
    yield call(callAPI,TaxRateAPI.deleteTaxRate,action,setResult);
}

function* setResult(result)
{
    yield put({type:TaxRate.TAX_RATES_SUCCESS,result});
}


export default function* rootSaga() {
    yield takeLatest(TaxRate.ADD_TAX_RATE_VIEW, addTaxRateView);
    yield takeLatest(TaxRate.ADD_TAX_TYPE, addEditTaxType);
    yield takeLatest(TaxRate.ADD_EDIT_TAX_RATE, addEditTaxRate);
    yield takeLatest(TaxRate.GET_TAX_RATES, getTaxRates);
    yield takeLatest(TaxRate.DELETE_TAX_RATE, deleteTaxRate);
}