import {Timesheet} from '../actionTypes/timesheet';

export const addTimesheetView=(payload)=>({
    type:Timesheet.ADD_TIMESHEET_VIEW,
    payload
});

export const addEditTimesheet=(payload)=>({
    type:Timesheet.ADD_EDIT_TIMESHEETS,
    payload
});

export const getTimesheets=(payload)=>({
    type:Timesheet.GET_TIMESHEETS,
    payload
});

export const getSubmittedTimesheets=(payload)=>({
    type:Timesheet.GET_SUBMITTED_TIMESHEETS,
    payload
});

export const authorisationView=(payload)=>({
    type:Timesheet.AUTHORISATION_VIEW,
    payload
});

export const updateTimesheetsStatus=(payload)=>({
    type:Timesheet.UPDATE_TIMESHEETS_STATUS,
    payload
});

export const getTimesheetDetails=(payload)=>({
    type:Timesheet.GET_TIMESHEET_DETAILS,
    payload
});

export const setTimesheet=(result)=>({
    type:Timesheet.TIMESHEET_SUCCESS,
    result
})

export const getTimesheetByStatus=(payload)=>({
    type:Timesheet.GET_TIMESHEET_BY_STATUS,
    payload
});

export const getTimesheetByType=(payload)=>({
    type:Timesheet.GET_TIMESHEET_BY_TYPE
    ,
    payload
});