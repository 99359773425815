import {combineReducers} from "redux";
import { connectRouter } from 'connected-react-router'
import progress from './progress';
import customer from './customer';
import account from './account';
import response from './response';
import pages from './pages';
import orgPartner from './orgPartner';
import client from './client';
import common from './common';
import user from './user';
import activityCode from './activityCode';
import orgBilling from './orgBilling';
import taxRate from './taxRate';
import timesheet from './timesheet';
import invoice from './invoice';

export default (history)=>
    combineReducers({
        progress,
        customer,
        account,
        response,
        pages,
        orgPartner,
        client,
        common,
        user,
        activityCode,
        orgBilling,
        taxRate,
        timesheet,
        invoice,
        router: connectRouter(history)
    })
;