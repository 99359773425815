import React, { useEffect, useState } from "react";
import { UserTable } from "../../../components/Table";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { sumBy } from "lodash";
import { useSelector } from "react-redux";
import { displayDate } from "../../../libs/dateHelper";
import { ReportHeader } from "../Components";
import { displayCost } from "../../../libs/functions";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";

const rowId = "";

const headerProps = { className: "futureReportHeader" };

const TotalRow = ({ text, invoiceUnits, cost, className }) => (
  <div className={"totalRow " + className}>
    <UserTable
      tblClass="reportTbl futureReportTotalRow"
      headers={[
        { text, headerProps, colSpan:3 },
        // { text: "", headerProps },
        // { text: "", headerProps },
        { text: invoiceUnits?.toString(), headerProps, colSpan:2 },
        // { text: "", headerProps },
        { text: displayCost(cost), headerProps },
      ]}
    />
  </div>
);

export default (props) => {
  const [state, setState] = useState({
    gridData: [],
    totalRow: {},
  });
  const { invoiceDetails } = useSelector((state) => state.invoice);
  const { isReport, selectedInvoice, reportTitle, reportType } = props;
  const { clientCode, clientName, invoiceNo, invoiceDate } = selectedInvoice;
  const { theme } = useUserDetail();
  const calTotal = (data) => ({
    invoiceUnits: sumBy(data, (t) => t.invoiceUnits),
    cost: sumBy(data, (t) => parseFloat(t.amount)),
  });
  const { WORD, EXCEL } = usePrintHook({});

  useEffect(() => {
    if (invoiceDetails) {
      let gridData = [];
      const futureTimesheets = invoiceDetails
        .filter((c) => c.isFuture)
        .map((c) => ({
          ...c,
          cost: displayCost(c.amount),
          type: "Future Time",
        }));
      gridData = gridData.concat(futureTimesheets);
      gridData.push({
        type: "Future Time Totals",
        ...calTotal(futureTimesheets),
      });
      const prevTimesheets = invoiceDetails
        .filter((c) => c.isPrevious && !c.isFuture)
        .map((c) => ({
          ...c,
          cost: displayCost(c.amount),
          type: "Allocated Time",
        }));
      gridData = gridData.concat(prevTimesheets);
      gridData.push({
        type: "Allocated Time Totals:",
        ...calTotal(prevTimesheets),
      });
      setState({
        gridData,
        totalRow: calTotal(invoiceDetails.filter((c) => c.isPrevious)),
      });
    }
  }, [invoiceDetails]);

  const headers = [
    { key: "type", text: "Type", headerProps },
    { key: "employee", text: "User Name", headerProps },
    { key: "activityDesc", text: "JobType", headerProps },
    { key: "invoiceUnits", text: "Units", headerProps },
    { key: "rate", text: "Rate", headerProps },
    { key: "cost", text: "Cost", headerProps },
  ];

  const className = isReport ? "col-12 col-md-12 col-xs-12" : "";

  const { totalRow, gridData } = state;

  return (
    <ReportContainer hasData paddingLeft={10} paddingRight={15}>
      <ReportHeader heading={reportTitle} reportType={reportType} />
      {reportType == WORD || reportType == EXCEL ? (
        <table width="100%">
          <tr>
            <td width="50%">
              <CustomDisplayLabel
                label="Ref"
                value={clientCode}
                customClass="plm_carep_top_first"
              />
            </td>
            <td>
              <CustomDisplayLabel
                label="Invoice No"
                value={invoiceNo}
                width={150}
                customClass="plm_carep_top_first"
              />
            </td>
          </tr>
          <tr>
            <td width="50%">
              <CustomDisplayLabel
                label="Client"
                value={clientName}
                customClass="plm_carep_top_first"
              />
            </td>
            <td>
              <CustomDisplayLabel
                label="Invoice Date"
                value={displayDate(invoiceDate)}
                width={150}
                customClass="plm_carep_top_first"
              />
            </td>
          </tr>
        </table>
      ) : (
        <React.Fragment>
          <div class="header-row">
              <div class="table-header w-20">
                <DisplayLabel label="Ref" value={clientCode} />
              </div>
              <div class="table-header w-20">
                <DisplayLabel label="Invoice No" value={invoiceNo} width={120} />
              </div>
              <div class="table-header w-20">
                <DisplayLabel label="Client" value={clientName} />
              </div>
              <div class="table-header w-40">
                <DisplayLabel
                  label="Invoice Date"
                  value={displayDate(invoiceDate)}
                  width={110}
                />
              </div>
            </div>
        </React.Fragment>
      )}
      <div className={"futureReport " + theme}>
        <div className={className}>
          <UserTable
            headers={headers}
            data={gridData}
            rowId={rowId}
            tblClass="reportTbl futureReportGrid"
          />
        </div>
        <TotalRow
          text="Totals of (FT - AT)"
          {...totalRow}
          className={className}
        />
        <div className={className}>
          <p>
            Note: Future time added on this costing and Allocated time deducted
            from this costing.
          </p>
        </div>
      </div>
    </ReportContainer>
  );
};
