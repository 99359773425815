import React from 'react';
import { AdminNavigation, Navigation } from '../../components/Menu';
import useRole from '../../hooks/useRole';
import { Header } from '../../components/Header';

export default (props)=>{

    const {isAdmin,isCustomer,isUser}=useRole();

    return (
        <>
            <Header
              />
            {isAdmin &&
                <>
                    <AdminNavigation
                    />
                </>
            }
            {(isCustomer || isUser) &&
                <>
                    <Navigation
                    />
                </>
            }
        </>
    )
}