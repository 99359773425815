export const AppConstant = {
  USER_KEY: "USER_KEY",
  UPLOAD_DOCUMENT_SIZE_LIMIT: 100, //In MB
  FIRST_ITEM_TEXT: "Select",
  API_FORMAT: "MM/DD/YYYY hh:mm:ss",
  NO_OF_TIMESHEET_WEEKS: 52,
  DATE_DISPLAY_FORMAT: "DD MMM YYYY",
  DECIMAL_POMITS: 2,
  CURRENCY: "\u00A3",
  REPORT_FIRST_ITEM_TEXT: "All",
};
