import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  formatAPIDate,
  getReportStartAndEndDate,
} from "../../../libs/dateHelper";
import { UserButton } from "../../../components/Button";
import PartnerSource from "../../../common/PartnerSource";
import TransactionDate from "../../../common/TransactionDate";
import { AppConstant } from "../../../utils/constant";
import { WIPGroupBy } from ".";
import { setClient } from "../../../actions/client";

const { REPORT_FIRST_ITEM_TEXT } = AppConstant;

const { endDate } = getReportStartAndEndDate();

export default ({ action, setHeader, showGroupBy }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setClient({ clientsWIP: [] }));
  }, []);

  const [state, setState] = useState({
    timesheetDate: endDate,
  });
  const { orgPartnerId, partnerSourceId, timesheetDate } = state;

  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleGroupBy = (name, value) => setHeader({ [name]: value });

  const onClick = () => {
    dispatch(
      action({
        timesheetDate: formatAPIDate(timesheetDate),
        orgPartnerId,
        partnerSourceId,
      })
    );
    setHeader(state);
  };

  return (
    <>
      <div className="ctop">
        <div className="ctop-flex">
          <PartnerSource
            hideAsterix={true}
            handleChange={handleChange}
            orgPartnerId={orgPartnerId}
            partnerSourceId={partnerSourceId}
            firstItemText={REPORT_FIRST_ITEM_TEXT}
            isReport
          />
          <TransactionDate
            timesheetDate={timesheetDate}
            onChange={handleChange}
            asterix={true}
            allowMinDate
            isReport
          />
          {showGroupBy && <WIPGroupBy handleChange={handleGroupBy} isReport />}
        </div>
        <UserButton text="View" onClick={onClick} className="btn btn-purple" />
      </div>
    </>
  );
};
