import React from "react";
import { useSelector } from "react-redux";
import APIUtil from "../../../api/APIUtil";
import { displayDate } from "../../../libs/dateHelper";

const baseUrl = APIUtil.getBaseUrl();
const date = displayDate(new Date());
export default ({ heading }) => {
  const { userDetail } = useSelector((state) => state.account);
  const { orgLogoPath } = userDetail ? userDetail : {};

  return (
    <table
      style={{ border: "none", width: "100%" }}
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <td width="40%" style={{ border: "none" }}>
          <img
            className="imgLogo"
            style={{ width: 200, height: 80 }}
            src={
              baseUrl + (orgLogoPath ? orgLogoPath : "OrgLogo/Default_Logo.png")
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = baseUrl + "OrgLogo/Default_Logo.png";
            }}
          />
        </td>
        <td style={{ border: "none" }}>
          <p
            style={{
              fontSize: 18,
              textAlign: "center",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {heading}
          </p>
        </td>
        <td align="right" style={{ border: "none" }}>
          <p style={{ float: "right" }}>{date}</p>
        </td>
      </tr>
    </table>
  );
};
