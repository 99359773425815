import React from "react";
import PropTypes from "prop-types";
import { AppConstant } from "../../utils/constant";
import { Error, UserLabel } from "../Label";

const Input = (props) => {
  const {
    name,
    type,
    placeholder,
    maxLength,
    toUpper,
    className,
    pattern,
    disabled,
    multiple,
    prefix,
    suffix,
    value,
    onChange,
    accept,
  } = props;
  return (
    <span>
      <span className="txtPrefix">{prefix}</span>
      <input
        autoComplete={type == "password" ? "new-password" : `off`}
        id={name}
        type={type ? type : "text"}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength ? maxLength : 200}
        value={toUpper && value ? value.toUpperCase() : value ? value : ""}
        className={className ? `${className} form-control` : "form-control"}
        pattern={pattern ? pattern : null}
        disabled={disabled}
        multiple={multiple ? true : false}
        accept={accept}
      />
      <span className="txtSuffix">{suffix}</span>
    </span>
  );
};

const TextInput = (props) => {
  const getFile = (file, fileSize, cb) => {
    getBase64(file, (data) => {
      cb({
        fileDataAsBase64: data,
        fileName: file.name,
        length: fileSize,
        contentType: file.type,
      });
    });
  };

  const onFieldChange = (event) => {
    if (event) event.stopPropagation();

    const target = event.target;
    let value = target.value;
    const name = target.name;
    const { type, multiple, onChange, onError } = props;
    if (type === "file") {
      const selectedFiles = Array.from(target.files);
      let files = [];
      selectedFiles.every((file, index) => {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize < AppConstant.UPLOAD_DOCUMENT_SIZE_LIMIT) {
          getFile(file, fileSize, (data) => {
            files.push(data);
            if (selectedFiles.length == index + 1)
              onChange(name, multiple ? files : data);
          });
        } else {
          if (onError) onError(name, "File size exceeded.");
          return false;
        }
        return true;
      });
    } else {
      if (type == "float" && value) {
        value = value.replace(AppConstant.CURRENCY, "");
      }
      onChange(name, value);
    }
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.split(",")[1]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const { errors, label, name } = props;
  const error = errors ? errors[name] : null;

  return (
    <div className="form-section">
      <div className="form-section__field">
        {label ? (
          <div>
            <UserLabel {...props} />
            <Input {...props} onChange={onFieldChange.bind(this)} />
          </div>
        ) : (
          <div className="row m-0">
            <div className="col-sm-12 col-xs-12 col-lg-12 px-0 input-wrap">
              <Input {...props} onChange={onFieldChange.bind(this)} />
            </div>
          </div>
        )}
        {error && <Error error={error} />}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  alhpaNumeric: PropTypes.bool,
  button: PropTypes.func,
  url: PropTypes.string,
  error: PropTypes.string,
  link: PropTypes.string,
  pattern: PropTypes.instanceOf(RegExp),
  class: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  toUpper: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TextInput;
