import React from "react";
import { UserTable } from "../../../../components/Table";
import { GridAction } from "../../../../utils/GridAction";

export default ({
  roles,
  rowId,
  handleRowClick,
  handlePermission,
  handleEdit,
  handleDelete,
}) => {
  const headers = [
    { key: "roleName", text: "Role Name", search: true, sort: true },
    { key: "description", text: "Description" },
    {
      key: "permission",
      type: GridAction.BUTTON,
      text: "Permission",
      actionProps: {
        icon: <i className="fa fa-lock"></i>,
      },
      handleChange: handlePermission,
    },
  ];

  return (
    <UserTable
      headers={headers}
      data={roles}
      rowId={rowId}
      handleRowClick={handleRowClick}
      showActions={true}
      actionProps={{ handleEdit, handleDelete, deleteTitle: "role" }}
    />
  );
};
