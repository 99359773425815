import React, { useEffect, useState } from "react";
import { WordLabel, WordLabelList } from "../../../components/Label";
import { LabelColor } from "../../../components/Label/WordLabel";
import { TableCell } from "../../../components/Table/WordTable";
import { displayDate } from "../../../libs/dateHelper";
import { LookUp } from "../../../utils/lookUpConstant";
import { WordReportHeader } from "../Components";

const perChunk = 6;

export default ({
  startDate,
  endDate,
  orgPartner,
  partnerLabel,
  sourceLabel,
  partnerSource,
  gridData,
  headers,
}) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (headers) {
      const rows = headers.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);

      setRows(rows);
    }
  }, [headers]);

  return (
    <>
      <WordReportHeader heading="Non-Submitted Timesheet Report" />
      <table
        style={{ border: "none", width: "100%" }}
        cellSpacing={5}
        cellPadding={0}
      >
        <tr>
          <td style={{ border: "none" }} colSpan={perChunk / 2}>
            <WordLabel
              label="Date From"
              value={displayDate(startDate)}
              backgroundColor={LabelColor.Blue}
            />
          </td>
          <td style={{ border: "none" }} colSpan={perChunk / 2}>
            <WordLabel
              label={partnerLabel}
              value={orgPartner}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        <tr>
          <td style={{ border: "none" }} colSpan={perChunk / 2}>
            <WordLabel
              label="Date To"
              value={displayDate(endDate)}
              backgroundColor={LabelColor.Blue}
            />
          </td>
          <td style={{ border: "none" }} colSpan={perChunk / 2}>
            <WordLabel
              label={sourceLabel}
              value={partnerSource}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        {gridData &&
          gridData.map((c, index) => (
            <React.Fragment key={index}>
              <tr>
                <td style={{ border: "none" }} colSpan={perChunk}>
                  <WordLabel
                    label={partnerLabel}
                    value={c.partnerName}
                    backgroundColor={LabelColor.Purple}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ border: "none" }} colSpan={perChunk}>
                  <WordLabel
                    label={sourceLabel}
                    value={c.sourceName}
                    backgroundColor={LabelColor.Blue}
                  />
                </td>
              </tr>

              {c.empDetails.map((e, i) => (
                <React.Fragment key={i}>
                  <tr>
                    <td style={{ border: "none" }} colSpan={3}>
                      <WordLabel
                        label="Staff"
                        value={e.employeeName}
                        backgroundColor={LabelColor.LightBlue}
                      />
                    </td>
                  </tr>
                  {rows.map((r, rIndex) => (
                    <tr key={rIndex} style={{ border: "none" }}>
                      {r.map((cell, cIndex) => (
                        <TableCell
                          key={`R${rIndex}C${cIndex}`}
                          text={cell.text}
                          cellProps={{
                            width: 100 / 6 + "%",
                            style: {
                              border: "none",
                              backgroundColor: [
                                LookUp.AUTHORISED_TS,
                                LookUp.SUBMITTED_TS,
                              ].includes(
                                e.gridData.find((g) => g.weekNo == cell.key)
                                  ?.status
                              )
                                ? "#0000ff"
                                : "#eeeeee",
                              color: "#475f7b",
                            },
                          }}
                        />
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
      </table>
    </>
  );
};
