import React, { useEffect, useState } from "react";
import { UserTable } from "../../../components/Table";
import { useSelector } from "react-redux";
import { displayDate } from "../../../libs/dateHelper";
import { displayCost } from "../../../libs/functions";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { Logo } from "../../../components/Header";
import { ReportHeader } from "../Components";

const rowId = "";

const DisplayLabel = ({ label, value }) => (
  <div className="row">
    <div className="col-6 col-md-6 col-xs-6 invBorderWrap invColor col6_custom">
      {label}
    </div>
    <div className="col-6 col-md-6 col-xs-6 invBorderWrap col6_custom">{value}</div>
  </div>
);

export default (props) => {
  const [state, setState] = useState({
    gridData: [],
  });
  const { invoiceDetails } = useSelector((state) => state.invoice);
  const { isReport, selectedInvoice, reportTitle, reportType } = props;
  const {
    clientCode,
    clientName,
    invoiceNo,
    invoiceDate,
    clientAddress,
    actualAmt,
    taxValue,
    taxAmount,
    totalAmount,
  } = selectedInvoice;
  const { WORD, EXCEL } = usePrintHook({});
  const { theme } = useUserDetail();

  useEffect(() => {
    if (invoiceDetails && invoiceDetails.length > 0) {
      // let gridData = invoiceDetails.slice().map((c) => ({
      //   ...c,
      //   displayAmount: displayCost(c.amount),
      // }));
      let gridData = [];
      const { billingNarratives } = invoiceDetails[0];
      if (billingNarratives)
        gridData = billingNarratives.split(";").map((c) => ({
          activityDesc: c,
        }));
      gridData.push({
        activityDesc: "Total Fee",
        displayAmount: displayCost(actualAmt),
      });
      gridData.push({
        activityDesc: taxValue,
        displayAmount: displayCost(taxAmount),
      });
      gridData.push({
        activityDesc: "Total",
        displayAmount: displayCost(totalAmount),
      });
      setState({
        gridData,
      });
    }
  }, [invoiceDetails, selectedInvoice]);

  const headers = [
    { key: "activityDesc", text: "Description" },
    { key: "displayAmount", text: "Cost" },
  ];

  const className = isReport ? "col-12 col-md-12 col-xs-12" : "";

  const { totalRow, gridData } = state;

  return (
    <div style={{ fontSize: 22 }}>
          <ReportHeader
            heading="Invoice"
            reportType={reportType}
          />

      <div className="client_invoice header-row">
              <div class="table-header w-40">
                <DisplayLabel label="Client Name:" value={clientName} />
              </div>
              <div class="table-header w-60">
                <DisplayLabel label="Client Address:" value={clientAddress} />
              </div>
      </div>

      <div class="header-row">
              <div class="table-header w-32">
                <DisplayLabel label="Date" value={displayDate(invoiceDate)} />
              </div>
              <div class="table-header w-32">
                <DisplayLabel label="Invoice No" value={invoiceNo} />
              </div>
              <div class="table-header w-32">
              <DisplayLabel label="Our Ref" value={clientCode} />
              </div>
            </div>

      <div className="div_container_invoice">
        <div
          className={`${className} ${theme} invTblWrap`}
          style={{ marginTop: 20 }}
        >
          <UserTable
            headers={headers}
            data={gridData}
            rowId={rowId}
            tblClass="invoiceGrid"
          />
        </div>
      </div>
    </div>
  );
};
