import React from "react";
import useTheme from "../../hooks/useTheme";

const LinkButton = ({
  text,
  type,
  className,
  disabled = false,
  onClick,
  icon,
  showIconFirst,
  value,
  title,
}) => {
  let btnText = showIconFirst ? (
    <span>
      {icon ? icon : ""} <span className="btext">{text}</span>
    </span>
  ) : (
    <span>
      <span className="btext">{text}</span> {icon ? icon : ""}
    </span>
  );

  const { theme } = useTheme();

  return (
    <span
      title={title}
      value={value}
      className={
        theme +
        (disabled ? "btn-disabled" : className ? className : "linkPrimary")
      }
      onClick={onClick}>
      {btnText}
    </span>
  );
};

export default LinkButton;
