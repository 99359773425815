import { takeLatest, call, put } from 'redux-saga/effects';
import OrgUserAPI from "../api/OrgUserAPI";
import { User } from "../actionTypes/user";
import callAPI from './common';
import { Common } from '../actionTypes/common';

function* addEditUser(action)
{
    yield call(callAPI,OrgUserAPI.addEditOrgUser,action,setResult);
}

function* addUserView(action)
{
    yield call(callAPI,OrgUserAPI.addUserView,action,addUserViewResult);
}

function* addUserViewResult(result)
{
    yield put({type:Common.SET_COMMON_DATA,result});
}

function* getUsers(action)
{
    yield call(callAPI,OrgUserAPI.getOrgUsers,action,setResult);
}

function* deleteUser(action)
{
    yield call(callAPI,OrgUserAPI.deleteOrgUser,action,setResult);
}

function* addOrgRole(action)
{
    yield call(callAPI,OrgUserAPI.addEditOrgRole,action,setOrgRoleResult);
}

function* getOrgRoles(action)
{
    yield call(callAPI,OrgUserAPI.getOrgRoles,action,setOrgRoleResult);
}

function* deleteOrgRole(action)
{
    yield call(callAPI,OrgUserAPI.deleteOrgRole,action,setOrgRoleResult);
}

function* addEditRolePermission(action)
{
    yield call(callAPI,OrgUserAPI.addEditRolePermissions,action,setOrgRoleResult);
}

function* setOrgRoleResult({roleData,common})
{
    yield put({type:User.USER_SUCCESS,result:roleData});
    yield put({type:Common.SET_COMMON_DATA,result:common});
}


function* setResult(result)
{
    yield put({type:User.USER_SUCCESS,result});
}


export default function* rootSaga() {
    yield takeLatest(User.ADD_USER_VIEW, addUserView);
    yield takeLatest(User.ADD_USER, addEditUser);
    yield takeLatest(User.GET_USERS, getUsers);
    yield takeLatest(User.DELETE_USER, deleteUser);
    yield takeLatest(User.ADD_ORG_ROLE, addOrgRole);
    yield takeLatest(User.GET_ORG_ROLES, getOrgRoles);
    yield takeLatest(User.DELETE_ORG_ROLE, deleteOrgRole);
    yield takeLatest(User.ADD_EDIT_ROLES_PERMISSIONS, addEditRolePermission);
}