import {OrgPartner} from '../actionTypes/orgPartner';

const initialState={

}

export default (state=initialState,action)=>
{
    switch(action.type)
    {
        case OrgPartner.ADD_EDIT_ORG_PARTNER:
        case OrgPartner.ADD_EDIT_PARTNER_SOURCE:
        case OrgPartner.GET_ORG_PARTNERS:
        case OrgPartner.DELETE_ORG_PARTNER:
        case OrgPartner.DELETE_PARTNER_SOURCE:
            return {
                ...state
            }
        case OrgPartner.ORG_PARTNER_SUCCESS:
            return {
                ...state,
                ...action.result
            }
        default:
            return {
                ...state
            }
    }
}