import React, { useCallback, useEffect } from "react";
import useForm from "../../../hooks/useForm";
import {
  getInvoices,
  getInvoiceDetails,
  setInvoice,
} from "../../../actions/invoice";
import { useDispatch, useSelector } from "react-redux";
import ClientSelectBox from "../../../common/ClientSelectBox";
import { UserButton } from "../../../components/Button";
import { getClientSelectList } from "../../../actions/client";
import { UserDatePicker } from "../../../components/DateControl";
import useUserDetail from "../../../hooks/useUserDetail";
import useGridHook from "../../../hooks/useGridHook";
import { UserTable } from "../../../components/Table";
import {
  displayDate,
  getReportStartAndEndDate,
} from "../../../libs/dateHelper";
import "./Invoice.scss";
import { displayCost } from "../../../libs/functions";
import { ReportContainer } from "../../../components/Container";
import { DisplayLabel } from "../../../components/Label";
import ReportFilterWrapper from "../../../common/ReportFilterWrapper";

const reportDates = getReportStartAndEndDate();

const stateSchema = {
  ...reportDates,
};

const validationSchema = {
  clientName: {
    required: true,
  },
  clientCode: {
    required: true,
  },
};

const headers = [
  { key: "clientName", text: "Client Name", sort: true },
  { key: "invoiceDate", text: "Invoice Date", sort: true },
  { key: "invoiceNo", text: "Invoice Number", sort: true },
  { key: "calculatedAmt", text: "Net", sort: true },
  { key: "displaytaxAmount", text: "VAT", sort: true },
  {
    key: "totalCost",
    text: "Gross",
    sort: true,
    actionProps: { className: "gap-cell file" },
  },
].map((c) => ({
  ...c,
  actionProps: c.actionProps ?? { className: "gap-cell" },
}));

const rowId = "invoiceId";

export default ({ setState, showFutureTime, toggleReportBtn, toggleRowPrint }) => {
  const dispatch = useDispatch();

  const { gridData, handleRowClick, setGridData, handleHeaderChange } =
    useGridHook(rowId);

  useEffect(() => {
    dispatch(
      setInvoice({
        invoices: [],
        invoiceDetails: [],
      })
    );
  }, []);

  const { state, errors, handleChange, handleStateChange, handleSubmit } =
    useForm(stateSchema, validationSchema, fetchInvoices);

  const { startDate, endDate, clientName } = state;
  const { finStartDate, finEndDate } = useUserDetail();

  const { invoices } = useSelector((state) => state.invoice);

  useEffect(() => {
    toggleReportBtn(invoices && invoices.length > 0);
  }, [invoices]);

  const setInvoices = (invoices) => {
    if (invoices)
      setGridData(
        invoices.map((c) => ({
          ...c,
          totalCost: displayCost(c.totalAmount),
          calculatedAmt: displayCost(c.calculatedAmt),
          displaytaxAmount: displayCost(c.taxAmount),
          invoiceDate: displayDate(c.invoiceDt),
        }))
      );
  };

  useEffect(() => setInvoices(invoices), [invoices]);

  useEffect(() => {
    dispatch(getClientSelectList());
  }, []);

  useEffect(() => {
    const selected = gridData.find((c) => c.selected);
    if (selected) {
      setState(selected);
      dispatch(getInvoiceDetails(selected.invoiceId));
    }
  }, [gridData]);

  function fetchInvoices(state) {
    dispatch(
      getInvoices({
        ...state,
        showFutureTime,
      })
    );
  }

  const rows = useCallback(() => {
    return gridData.map((g) => ({
      ...g,
      totalCost: (
        <>
          {g.totalCost}
          <img onClick={()=>toggleRowPrint(true)}
            src={`${process.env.PUBLIC_URL}/assests/img/thumb/document.png`}
            className="fileicon"
          />
        </>
      ),
    }));
  }, [gridData]);

  return (
    <>
      <div className="ctop">
        <div className="ctop-flex">
          <ClientSelectBox
            state={state}
            asterix={true}
            errors={errors}
            onChange={handleStateChange}
            isReport
          />
          <ReportFilterWrapper label="From">
            <UserDatePicker
              name="startDate"
              value={startDate}
              onChange={handleChange}
              maxDate={finEndDate}
            />
          </ReportFilterWrapper>
          <ReportFilterWrapper label="To">
            <UserDatePicker
              name="endDate"
              value={endDate}
              onChange={handleChange}
              maxDate={finEndDate}
            />
          </ReportFilterWrapper>
        </div>
        <UserButton
          text="View"
          onClick={handleSubmit}
          className="btn btn-purple"
        />
      </div>
      <ReportContainer hasData={gridData.length > 0}>
        {gridData.length > 0 && (
          <>
            <div class="header-row">
              <div class="table-header">
                Client: <span>{clientName}</span>
              </div>
              <div class="table-header">
                From: <span>{displayDate(startDate)}</span>
              </div>
              <div class="table-header">
                To: <span>{displayDate(endDate)}</span>
              </div>
            </div>
            <UserTable
              headers={headers}
              data={rows()}
              rowId={rowId}
              handleRowClick={handleRowClick}
              handleHeaderChange={handleHeaderChange}
              tblClass="table table-stripe mb20 datatable dataTable no-footer invoiceRptGrid"
              tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
              wrapperClass={`table-responsive mt30 dataTables_wrapper no-footer`}
              showPagination
              showFieldSearch
            />
          </>
        )}
      </ReportContainer>
    </>
  );
};
