import {ActivityCode} from '../actionTypes/activityCode';

export const getActivityTypes=(payload)=>({
    type:ActivityCode.GET_ACTIVITY_TYPES,
    payload
});

export const getActivityCodes=(payload)=>({
    type:ActivityCode.GET_ACTIVITY_CODES,
    payload
});

export const addEditActivityCode=(payload)=>({
    type:ActivityCode.ADD_EDIT_ACTIVITY_CODE,
    payload
});

export const deleteActivityCode=(payload)=>({
    type:ActivityCode.DELETE_ACTIVITY_CODE,
    payload
});