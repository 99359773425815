import {TaxRate} from '../actionTypes/taxRate';

const initialState={

}

export default (state=initialState,action)=>
{
    switch(action.type)
    {
        case TaxRate.TAX_RATES_SUCCESS:
            return {
                ...state,
                ...action.result
            }
        default:
            return {
                ...state
            }
    }
}