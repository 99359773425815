import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useForm from "../../../../hooks/useForm";
import { UserModal } from "../../../../components/Modal";
import { TextInput } from "../../../../components/TextInput";
import { addEditOrgPartners } from "../../../../actions/orgPartner";
import ContactDetails from "../../../../common/ContactDetails";
import useOrgResources from "../../../../hooks/useOrgResources";

const stateSchema = {
  partnerName: "",
  units: "",
};

const validationSchema = {
  partnerName: {
    required: true,
  },
  units: {
    required: true,
  },
  contactPerson: {
    required: true,
  },
};

const AddBranch = ({ branchDetail, handleChange, errors, partnerLabel }) => {
  const { partnerName, units } = branchDetail;

  return (
    <>
      <div className="row">
        <div className="col-6">
          <TextInput
            name="partnerName"
            label={partnerLabel + " Name"}
            value={partnerName}
            onChange={handleChange}
            errors={errors}
            asterix={true}
          />
        </div>
        <div className="col-6">
          <TextInput
            name="units"
            value={units}
            label="Units Per Hour"
            onChange={handleChange}
            errors={errors}
            asterix={true}
          />
        </div>
      </div>
      <div className="row" style={{ marginTop: 40 }}>
        <div className="col-12">
          <h4>Details</h4>
        </div>
        <ContactDetails
          handleChange={handleChange}
          contactDetail={branchDetail}
          errors={errors}
          requiredField={validationSchema}
        />
      </div>
    </>
  );
};

export default ({ showAddBranch, toggleModal, selectedBranch, title }) => {
  const {
    state: branchDetail,
    errors,
    handleChange,
    handleSubmit,
    handleStateChange,
  } = useForm(stateSchema, validationSchema, handleSave);

  const dispatch = useDispatch();

  const { partnerLabel } = useOrgResources();

  useEffect(() => {
    if (selectedBranch)
      handleStateChange({ ...selectedBranch, ...selectedBranch.address });
  }, []);

  function handleSave(branchDetail) {
    dispatch(
      addEditOrgPartners({
        ...branchDetail,
        partnerLabel,
      })
    );
    toggleModal();
  }

  return (
    <div className="col-12">
      <UserModal
        show={showAddBranch}
        handleClose={toggleModal}
        title={title}
        handleSave={handleSubmit}>
        <AddBranch
          branchDetail={branchDetail}
          handleChange={handleChange}
          errors={errors}
          partnerLabel={partnerLabel}
        />
      </UserModal>
    </div>
  );
};
