import {OrgPartner} from '../actionTypes/orgPartner';

export const getOrgPartners=(payload)=>({
    type:OrgPartner.GET_ORG_PARTNERS,
    payload
});

export const addEditOrgPartners=(payload)=>({
    type:OrgPartner.ADD_EDIT_ORG_PARTNER,
    payload
});

export const addEditPartnerSource=(payload)=>({
    type:OrgPartner.ADD_EDIT_PARTNER_SOURCE,
    payload
});

export const deleteOrgpartner=(payload)=>({
    type:OrgPartner.DELETE_ORG_PARTNER,
    payload
});

export const deletePartnerSource=(payload)=>({
    type:OrgPartner.DELETE_PARTNER_SOURCE,
    payload
});

export const addEditOrgResources=(payload)=>({
    type:OrgPartner.ADD_EDIT_RESOURCES,
    payload
});
