import React from "react";
import { UserTable } from "../../../components/Table";
import { GridAction } from "../../../utils/GridAction";

export default ({
  timesheets,
  rowId,
  handleChange,
  handleAuthorise,
  handleRowClick,
  handleHeaderChange,
}) => {
  const headers = [
    {
      key: "isSelected",
      text: timesheets.every((c) => c.isSelected),
      type: GridAction.CHECKBOX,
      hType: GridAction.CHECKBOX,
      hName: "isSelected",
      handleChange,
    },
    { key: "fullName", text: "Employee" },
    { key: "weekNo", text: "Week" },
    { key: "startDate", text: "Start Date" },
    { key: "submittedOn", text: "Submitted" },
    {
      key: "authorise",
      type: GridAction.BUTTON,
      handleChange: handleAuthorise,
    },
  ];

  return (
    <UserTable
      headers={headers}
      data={timesheets}
      rowId={rowId}
      handleRowClick={handleRowClick}
      handleHeaderChange={handleHeaderChange}
    />
  );
};
