import React, { useMemo, useCallback } from "react";
import Routes from "./config/route";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./scss/console.scss";
import { Layout } from "./containers/Layout";
import APIResponse from "./common/APIResponse";
import Loading from "./common/Loading";
import useRole from "./hooks/useRole";
import useTheme from "./hooks/useTheme";

function App(props) {
  const { isAdmin, isCustomer, isUser, isLoggedIn } = useRole();
  const { theme } = useTheme();
  let mainClass;
  const containerClass = useMemo(() => {
    if (isAdmin) mainClass = "adminMainContent";
    else if (isCustomer || isUser) mainClass = "customerMainContent";

    return mainClass;
  }, [isAdmin, isCustomer, isUser]);

  return (
    <div className={(isLoggedIn ? theme : "") + "App"}>
      <div className="app_mainouter_wrap">
        {isLoggedIn && <Layout />}
        <div
          className={
            (isLoggedIn ? containerClass : "") +
            "app_inner_wrap easypsycmn_inner_wrap"
          }>
          <Routes />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <APIResponse />
        <Loading />
      </div>
    </div>
  );
}

export default App;
