import React, { useEffect, useState } from "react";
import { getClientsWIP } from "../../../actions/client";
import { WIPHeader } from "../Components";
import { ClientGrid, Report } from "../TotalWIP";
import "./TotalWIPDetails.scss";
import useUserDetail from "../../../hooks/useUserDetail";
import { useSelector } from "react-redux";

export default React.forwardRef(
  ({ showReport, reportTitle, reportType, toggleReportBtn, gridRef }, ref) => {
    const [state, setState] = useState({});
    const { theme } = useUserDetail();
    const { clientsWIP } = useSelector((state) => state.client);

    useEffect(() => {
      toggleReportBtn(clientsWIP && clientsWIP.length > 0);
    }, [clientsWIP]);

    const setHeader = (state) => setState((prev) => ({ ...prev, ...state }));

    return (
      <>
        <WIPHeader
          action={getClientsWIP}
          setHeader={setHeader}
          showGroupBy={true}
        />
        <ClientGrid {...state} />
        {showReport && (
          <div className="reportDataContainer">
            <div className={"plm_rp_rptort_col App " + theme} ref={gridRef}>
              <div className="row reportWrapper rwrapperWiptbl rwrapPdf">
                <div className="col-12 col-xs-12 col-md-12 col-lg-12 col-sm-12 twd-tbl">
                  <Report
                    {...state}
                    reportTitle={reportTitle}
                    reportType={reportType}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
