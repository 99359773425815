import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addEditCustomer, addCustomerView } from "../../actions/customer";
import { UserModal } from "../../components/Modal";
import { AddCustomer } from "./components";
import useForm from "../../hooks/useForm";
import { Regx } from "../../utils/regx";

const stateSchema = {
  orgName: "",
  accountId: "",
  firstName: "",
  lastName: "",
  emailId: "",
  finYearStartDate: "",
};

const validationSchema = {
  orgName: {
    required: true,
  },
  accountId: {
    required: true,
  },
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  emailId: {
    required: true,
    validator: {
      regEx: Regx.EMAIL,
      error: "Invalid Email",
    },
  },
  finYearStartDate: {
    required: true,
  },
};

export default ({ showAddCustomer, toggleModal, selectedCustomer }) => {
  const {
    state: customerDetail,
    errors,
    handleChange,
    handleSubmit,
    handleStateChange,
  } = useForm(stateSchema, validationSchema, handleSave);
  const addCustomerRef = useRef();

  const dispatch = useDispatch();
  const { accountId } = useSelector((state) => state.customer);

  useEffect(() => {
    initPage();
    if (selectedCustomer) handleStateChange(selectedCustomer);
  }, []);

  useEffect(() => {
    if (accountId && !selectedCustomer) handleStateChange({ accountId });
  }, [accountId]);

  const initPage = () => {
    dispatch(addCustomerView());
  };

  const uploadLogo = (event) => {
    if (event) event.stopPropagation();

    const { croppedImg } = customerDetail;
    handleStateChange({
      orgLogo: croppedImg,
      croppedImg: {},
    });
  };

  function handleSave(customerDetail) {
    const { invoiceNoPrefixList, invoiceNoSuffixList } =
      addCustomerRef.current?.getPrefixSuffix() ?? {};
    dispatch(
      addEditCustomer({
        ...customerDetail,
        invoiceNoPrefixList,
        invoiceNoSuffixList,
      })
    );
    toggleModal();
  }

  return (
    <div className="col-12">
      <UserModal
        show={showAddCustomer}
        handleClose={toggleModal}
        title="Add Customer"
        handleSave={handleSubmit}
      >
        <AddCustomer
          customerDetail={customerDetail}
          handleChange={handleChange}
          uploadLogo={uploadLogo.bind(this)}
          errors={errors}
          ref={addCustomerRef}
        />
      </UserModal>
    </div>
  );
};
