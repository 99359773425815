import {Account} from '../actionTypes/account';

const initialState={
    isLoggedIn:false,
    userDetail:{},
    roles:[]
}

export default (state=initialState,action)=>
{
    switch(action.type)
    {
        case Account.ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoggedIn:true,
                ...action.result
            }
        case Account.ACCOUNT_ERROR:
            return {
                ...state,
                isLoggedIn:false,
                error:action.result
            }
        case Account.LOGOUT_USER:
            return {
                ...initialState
            }
        default:
            return state
    }
}