import {  call, put } from 'redux-saga/effects';
import {Common} from '../actionTypes/common';
import { push } from 'connected-react-router';
import { RouteConstant } from '../utils/routeConstant';

export default function* callAPI(api,{payload},cb)
{
    try {
        yield put({type:Common.SHOW_PROGRESS});
        const response = yield call(api,payload);
        yield put({type:Common.CLOSE_PROGRESS});
        if(response.status>=200 && response.status<300)
        {
            const {result,success,message} = yield response.json();
            if(success)
            {
                yield put({ type: Common.SET_API_RESPONSE, result:{success,message} });
                if(cb)
                yield call(cb,result);
                return ;
            }
        }
        else if(response.status===401)
        {
            yield put(push(RouteConstant.LOGIN));
        }
        else{
            const {message} = yield response.json();
            yield put({ type: Common.SET_API_RESPONSE, result:{message} });
        }
    } catch (e) {
        yield put({ type: Common.SET_API_RESPONSE, result:{message: e.message} });
    }
}