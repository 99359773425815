import React, {useEffect } from 'react';
import { useDispatch} from 'react-redux';
import { UserModal } from '../../../../components/Modal';
import { addEditRolePermissions } from '../../../../actions/user';
import { PermissionDetails } from '../../../../common/Permissions';
import useForm from '../../../../hooks/useForm';

const stateSchema={
    permissionIdList:[],
    partnerSourceIdList:[]
}

export default ({showAddPermission,toggleModal,selectedRole})=>{

    const {
        state:roleDetail,
        handleChange,
        handleSubmit,
        handleStateChange
    }=useForm(stateSchema,{},handleSave);

    const dispatch=useDispatch();

    useEffect(()=>{
        if(selectedRole)
            handleStateChange(selectedRole);
    },[]);

    function handleSave(roleDetail){
        dispatch(addEditRolePermissions({
            ...roleDetail
        }));
        toggleModal();
    }

    const {permissionIdList,partnerSourceIdList}=roleDetail;

    return (
        <div className='col-12'>
            <UserModal
                show={showAddPermission}
                handleClose={toggleModal}
                title="Add Permission"
                handleSave={handleSubmit}
            >
                 <PermissionDetails
                    assignedSources={partnerSourceIdList}
                    permissionIdList={permissionIdList}
                    handleSourceChange={(value)=>handleChange('partnerSourceIdList',value)}
                    handlePermission={(value)=>handleChange('permissionIdList',value)}
                />
            </UserModal>
        </div>
    )
};