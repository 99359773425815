import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Styles from "./Loader.module.css";

export default (props) => (
  <>
    <div className={`loader ${Styles.loader}`}>
      <Loader type="ThreeDots" color="#4c6e5f" height="100" width="100" />
    </div>
  </>
);
