export const User={
    ADD_USER:'ADD_USER',
    ADD_USER_VIEW:'ADD_USER_VIEW',
    USER_SUCCESS:'USER_SUCCESS',
    GET_USERS:'GET_USERS',
    DELETE_USER:'DELETE_USER',
    ADD_ORG_ROLE:'ADD_ORG_ROLE',
    DELETE_ORG_ROLE:'DELETE_ORG_ROLE',
    GET_ORG_ROLES:'GET_ORG_ROLES',
    ADD_EDIT_ROLES_PERMISSIONS:'ADD_EDIT_ROLES_PERMISSIONS'
}