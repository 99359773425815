import React, { useCallback } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { ViewCustomerScreen, CustomerList } from "../containers/Customer";
import { RouteConstant } from "../utils/routeConstant";
import {
  AdminLoginScreen,
  LoginScreen,
  ResetPasswordScreen,
  ConfirmAccountScreen,
} from "../containers/Account";
import {
  Groups,
  Client,
  Users,
  UserRoles,
  RenamingGroups,
  ActivityCodes,
  BillingNarratives,
  TaxRates,
} from "../containers/Setup";
import { useSelector } from "react-redux";
import auth from "../libs/auth";
import { Timesheet } from "../containers/TimeAndExpenses";
import { AuthorisationScreen } from "../containers/Authorisation";
import { CreateInvoiceScreen, ViewInvoiceScreen } from "../containers/Invoices";
import { ReportScreen } from "../containers/Report";
import { someInTree } from "../libs/functions";
import { LeftNavigation } from "../components/Menu";

const {
  REPORTS,
  INVOICES,
  CREATE_INVOICE,
  AUTHORISATION,
  TIME_AND_EXPENSES,
  SETUP_TAX_RATES,
  SETUP_BILLING_NARRATIVES,
  SETUP_ACTIVITY_CODES,
  SETUP_RENAMING_GROUPS,
  SETUP_USER_ROLES,
  SETUP_USERS,
  SETUP_CLIENTS,
  SETUP_GROUPS,
  ADMIN_CUSTOMERS,
  ADMIN_HOME,
  DASHBOARD,
  LOGIN,
  ADMIN_LOGIN,
  RESET_PASSWORD,
  CONFIRM_ACCOUNT,
} = RouteConstant;

const LEFT_MENU = {
  SETUP: "Setup",
  INVOICES: "Invoices",
  REPORTS: "Reports",
};

const Routes = (props) => {
  const permissions = useSelector((state) => state.pages.permissions);
  const { pathname } = useLocation();
  const authorize = useCallback(
    (path) => {
      return permissions && someInTree(permissions, "pageUrl", path);
    },
    [permissions]
  );

  const PrivateRoute = useCallback(
    ({ component: Component, path, leftMenu,menuWrapperClass, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          if (auth.isAuthenticated && authorize(path)) {
            return (
              <>
                {leftMenu ? (
                  <div className="container-fluid prn_cfuid plm_layout_with_leftbardiv">
                    <div className="prn_row">
                      <div className="prn_colh plm_layout_left">
                        <LeftNavigation menuName={leftMenu} menuWrapperClass={menuWrapperClass} />
                      </div>
                      <div className="plm_layout_right">
                        <Component {...props} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Component {...props} />
                )}
              </>
            );
          }
          return <Redirect to={LOGIN} />;
        }}
      />
    ),
    [pathname]
  );

  return (
    <Switch>
      <PrivateRoute
        exact
        path={REPORTS + "/:type?"}
        leftMenu={LEFT_MENU.REPORTS}
        menuWrapperClass="reportMenuWrapper"
        component={ReportScreen}
      />
      <PrivateRoute
        exact
        path={INVOICES}
        leftMenu={LEFT_MENU.INVOICES}
        component={ViewInvoiceScreen}
      />
      <PrivateRoute
        exact
        path={CREATE_INVOICE}
        leftMenu={LEFT_MENU.INVOICES}
        component={CreateInvoiceScreen}
      />
      <PrivateRoute
        exact
        path={AUTHORISATION}
        component={AuthorisationScreen}
      />
      <PrivateRoute exact path={TIME_AND_EXPENSES} component={Timesheet} />
      <PrivateRoute
        exact
        path={SETUP_TAX_RATES}
        leftMenu={LEFT_MENU.SETUP}
        component={TaxRates}
      />
      <PrivateRoute
        exact
        path={SETUP_BILLING_NARRATIVES}
        leftMenu={LEFT_MENU.SETUP}
        component={BillingNarratives}
      />
      <PrivateRoute
        exact
        path={SETUP_ACTIVITY_CODES}
        leftMenu={LEFT_MENU.SETUP}
        component={ActivityCodes}
      />
      <PrivateRoute
        exact
        path={SETUP_RENAMING_GROUPS}
        leftMenu={LEFT_MENU.SETUP}
        component={RenamingGroups}
      />
      <PrivateRoute
        exact
        path={SETUP_USER_ROLES}
        leftMenu={LEFT_MENU.SETUP}
        component={UserRoles}
      />
      <PrivateRoute
        exact
        path={SETUP_USERS}
        leftMenu={LEFT_MENU.SETUP}
        component={Users}
      />
      <PrivateRoute
        exact
        path={SETUP_GROUPS}
        leftMenu={LEFT_MENU.SETUP}
        component={Groups}
      />
      <PrivateRoute
        exact
        path={SETUP_CLIENTS}
        leftMenu={LEFT_MENU.SETUP}
        component={Client}
      />
      <PrivateRoute exact path={DASHBOARD} component={CustomerList} />
      <PrivateRoute exact path={ADMIN_HOME} component={CustomerList} />
      <PrivateRoute
        exact
        path={ADMIN_CUSTOMERS}
        component={ViewCustomerScreen}
      />

      <Route
        exact
        path={RESET_PASSWORD + "/:email?/:code?"}
        component={ResetPasswordScreen}
      />
      <Route
        exact
        path={CONFIRM_ACCOUNT + "/:email?/:code?"}
        component={ConfirmAccountScreen}
      />
      <Route exact path={ADMIN_LOGIN} component={AdminLoginScreen} />
      <Route path={LOGIN} component={LoginScreen} />
      <Route path="" component={LoginScreen} />
    </Switch>
  );
};

export default React.memo(Routes);
