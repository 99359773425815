import React, { useState, useEffect } from "react";
import { TimesheetGrid, Report } from ".";
import { PartnerLabel, ReportHeader } from "../Components";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { useSelector } from "react-redux";
import { displayDate } from "../../../libs/dateHelper";
import useOrgResources from "../../../hooks/useOrgResources";
import usePrintHook from "../../hooks/usePrintHook";
import WordReport from "./WordReport";

export default (props) => {
  const [state, setState] = useState({});
  const { timesheetDates, employeeId, reportType } = props;

  const { employeeList } = useSelector((state) => state.common);
  const { timesheet } = useSelector((state) => state.timesheet);
  const { partnerLabel, sourceLabel } = useOrgResources();
  const { WORD, EXCEL } = usePrintHook({});

  useEffect(() => {
    if (employeeList && employeeId) {
      setState((prev) => ({
        ...prev,
        employeeName: employeeList.find(
          (c) => c.employee.customerId == employeeId
        )?.employee.fullName,
      }));
    }
  }, [employeeList, employeeId]);

  const { timesheetStatus, startDate, endDate, orgPartner, partnerSource } =
    timesheet ? timesheet : {};
  const { employeeName } = state;
  const { weekNo } = timesheetDates ? timesheetDates : {};

  return (
    <>
      {reportType == WORD || reportType == EXCEL ? (
        <WordReport
          {...timesheet}
          employeeName={employeeName}
          weekNo={weekNo}
          partnerLabel={partnerLabel}
          sourceLabel={sourceLabel}
        />
      ) : (
        <>
          <ReportHeader heading="Weekly Timesheet" reportType={reportType} />
          <div class="header-row">
            <div class="table-header w-32">
              <span>Name: <span>{employeeName}</span></span>
            </div>
            <div class="table-header w-32">
              <span>{partnerLabel}: <span>{orgPartner}</span></span>
            </div>
            <div class="table-header w-32">
              <span>Status: <span>{timesheetStatus}</span></span>
            </div>
          </div>

          <div class="header-row">
            
            <div class="table-header w-32">
              <span>{sourceLabel}: <span>{partnerSource}</span></span>
            </div>
            <div class="table-header w-32">
              <span>Week Period:{" "}
              <span>{`${displayDate(startDate)}-${displayDate(endDate)}`}</span></span>
            </div>
            <div class="table-header w-32">
              <span>Week No: <span>{weekNo}</span></span>
            </div>
          </div>
          
          <TimesheetGrid timesheetDates={timesheetDates} isReport />
        </>
      )}
    </>
  );
};
