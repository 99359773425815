import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../../../hooks/useForm";
import { UserModal } from "../../../../components/Modal";
import ContactDetails from "../../../../common/ContactDetails";
import { TabPanel, UserTab } from "../../../../components/Tabs";
import { Regx } from "../../../../utils/regx";
import { GeneralDetail, Rates } from "./Components/AddUser";
import { addUserView, addEditOrgUser } from "../../../../actions/user";
import { Permissions } from "../../../../common/Permissions";

const stateSchema = {
  rates: [],
  partnerSourceIdList: [],
  permissionIdList: [],
};

const validationSchema = {
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  username: {
    required: true,
    validator: {
      regEx: Regx.EMAIL,
      error: "Invalid Email",
    },
  },
  password: {
    required: true,
  },
  orgPartnerId: {
    required: true,
  },
  partnerSourceId: {
    required: true,
  },
  secretQuestionId: {
    required: true,
  },
  answer: {
    required: true,
  },
};

const AddUser = (props) => {
  const {
    userDetail,
    handleChange,
    errors,
    permissionIdList,
    assignedSources,
    handlePermission,
    handleSourceChange,
    handleRates,
    rates,
    secretQuestions,
    handlePartner,
  } = props;

  const { roleId } = userDetail;

  return (
    <TabPanel defaultTab="general">
      <UserTab eventKey="general" title="General">
        <GeneralDetail
          userDetail={userDetail}
          secretQuestions={secretQuestions}
          handleChange={handleChange}
          errors={errors}
          handlePartner={handlePartner}
        />
      </UserTab>
      <UserTab eventKey="address" title="Address">
        <div className="row">
          <ContactDetails
            handleChange={handleChange}
            contactDetail={userDetail}
            errors={errors}
          />
        </div>
      </UserTab>
      <UserTab eventKey="rates" title="Rates">
        <Rates rates={rates} errors={errors} handleChange={handleRates} />
      </UserTab>
      <UserTab eventKey="permissions" title="Permissions">
        <Permissions
          assignedSources={assignedSources}
          permissionIdList={permissionIdList}
          handleSourceChange={handleSourceChange}
          handlePermission={handlePermission}
          handleChange={handleChange}
          roleId={roleId}
        />
      </UserTab>
    </TabPanel>
  );
};

export default ({ showAddUser, toggleModal, selectedUser }) => {
  const {
    state: userDetail,
    errors,
    handleChange,
    handleSubmit,
    handleStateChange,
  } = useForm(
    stateSchema,
    selectedUser ? { ...validationSchema, password: null } : validationSchema,
    handleSave
  );
  const { rates, partnerSourceIdList, permissionIdList } = userDetail;

  const { secretQuestions } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    initPage();
    if (selectedUser)
      handleStateChange({ ...selectedUser, ...selectedUser.address });
  }, []);

  const initPage = () => {
    dispatch(addUserView());
  };

  function handleSave(userDetail) {
    const { rates } = userDetail;
    dispatch(
      addEditOrgUser({
        ...userDetail,
        rates: rates ? rates.filter((c) => c && c.ratePerHour) : null,
        permissionIdList,
      })
    );
    toggleModal();
  }

  const handleRates = useCallback((rates) => {
    // setRates(rates.slice());
    handleChange("rates", rates);
  }, []);

  const handlePermission = useCallback((permissionIdList) => {
    handleChange("permissionIdList", permissionIdList);
    //setPermissions(permissionIdList);
  }, []);

  const handleSourceChange = useCallback((partnerSourceIdList) => {
    // setSources(partnerSourceIdList.slice());
    handleChange("partnerSourceIdList", partnerSourceIdList);
  }, []);

  const handlePartnerChange = useCallback((name, value) => {
    if (name == "partnerSourceId")
      handleStateChange({
        partnerSourceIdList: [
          ...partnerSourceIdList,
          value ? parseInt(value) : "",
        ],
        [name]: value,
      });
    else handleChange(name, value);
  }, []);

  return (
    <div className="col-12">
      <UserModal
        show={showAddUser}
        handleClose={toggleModal}
        title="Add User"
        handleSave={handleSubmit}>
        <AddUser
          userDetail={userDetail}
          handleChange={handleChange}
          handlePartner={handlePartnerChange}
          errors={errors}
          permissionIdList={permissionIdList}
          handlePermission={handlePermission}
          handleSourceChange={handleSourceChange}
          assignedSources={partnerSourceIdList}
          rates={rates}
          handleRates={handleRates}
          secretQuestions={secretQuestions}
        />
      </UserModal>
    </div>
  );
};
