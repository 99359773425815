import React, { useState, useRef, useEffect } from "react";
import { UserCard } from "../../components/Container";
import {
  ClientReport,
  TimesheetReport,
  NonSubmittedReport,
  NonChargeableReport,
  ClientWIPDetails,
  ClientWIPSummary,
  TotalWIPDetails,
  FeeIncomeSummary,
  FeeIncomeDetails,
  EmployeeWorkSummary,
  ClientCostingSummary,
  Invoice,
  FutureTimeReport,
} from "./ReportScreens";
import { ReportButton } from "./Components";
import useToggle from "../../hooks/useToggle";
import { useDispatch, useSelector } from "react-redux";
import { getReportView } from "../../actions/report";
import "./scss/report.scss";
import { useParams } from "react-router";
import useUserDetail from "../../hooks/useUserDetail";
import usePrintHook from "../hooks/usePrintHook";
import { downloadReport } from "../../actions/report";

const Report = ({ reportToShow, ...rest }) => {
  switch (reportToShow) {
    case 1:
      return <ClientReport {...rest} />;
    case 2:
      return <TimesheetReport {...rest} />;
    case 3:
      return <NonSubmittedReport {...rest} />;
    case 4:
      return <NonChargeableReport {...rest} />;
    case 5:
      return <ClientWIPDetails {...rest} />;
    case 6:
      return <ClientWIPSummary {...rest} />;
    case 7:
      return <TotalWIPDetails {...rest} />;
    case 8:
      return <FeeIncomeSummary {...rest} />;
    case 9:
      return <FeeIncomeDetails {...rest} />;
    case 10:
      return <EmployeeWorkSummary {...rest} />;
    case 11:
      return <ClientCostingSummary {...rest} />;
    case 12:
      return <Invoice {...rest} />;
    case 13:
      return <FutureTimeReport {...rest} />;
  }
};

export default (props) => {
  const dispatch = useDispatch();
  let { type } = useParams();
  type = !type ? 1 : parseInt(type);
  const { show: showReport, setShow: toggleReport } = useToggle();
  const { show: showReportBtn, setShow: toggleReportBtn } = useToggle();
  const { show: rowPrint, setShow: toggleRowPrint } = useToggle();
  const { theme } = useUserDetail();
  const [reportTitle, setReportTitle] = useState({
    title: null,
    show: true,
  });

  const gridRef = useRef();

  const {PDF}=usePrintHook({});
  const [reportFormat, setReportFormat] = useState(PDF);
  const { handleAction } = usePrintHook({
    ...props,
    format:reportFormat,
    toggleReport,
    targetRef:gridRef,
    reportName: reportTitle.title
  });
  const { invoiceDetails } = useSelector((state) => state.invoice);

  useEffect(() => {
    if (type != 5) dispatch(getReportView());
  }, []);

  useEffect(() => {
    setTitle();
  }, []);

  const updateReportFormat = (format) => {
    setReportFormat(format);
  };

  useEffect(()=>{
    if(rowPrint && invoiceDetails && invoiceDetails.length > 0){
      handleAction(downloadReport);
      toggleRowPrint(false);
    }

    },[rowPrint, invoiceDetails]);

  const setTitle = () => {
    let title, show, wrapperClass;
    switch (type) {
      case 1:
        title = "Client List";
        break;
      case 2:
        title = "Weekly Timesheet";
        wrapperClass = "timesheetReportWrap";
        break;
      case 3:
        title = "Non-Submitted Timesheet";
        break;
      case 4:
        title = "Non-Chargeables";
        break;
      case 5:
        title = "Client WIP Details";
        break;
      case 6:
        title = "Total WIP Summary";
        break;
      case 7:
        title = "Total WIP Detail";
        break;
      case 8:
        title = "Fee Income Summary";
        break;
      case 9:
        title = "Fee Income Details";
        break;
      case 10:
        title = "Employee work summary";
        break;
      case 11:
        title = "Client Costing Summary";
        break;
      case 12:
        title = "Invoice";
        break;
      case 13:
        title = "Future Time Report";
        break;
      default:
        title = "";
        break;
    }
    setReportTitle({ title, show, wrapperClass });
  };

  const { title, show: showTitle, wrapperClass } = reportTitle;

  return (
    <div className={`reportBody ${theme} ${wrapperClass}`}>
      <Report
        reportToShow={type}
        gridRef={gridRef}
        reportTitle={title}
        showReport={true}
        reportType={reportFormat}
        toggleReportBtn={toggleReportBtn}
        toggleRowPrint={toggleRowPrint}
      />
      {showReportBtn && (
        <div className="content-bottom">
          <ReportButton
            showReport={showReport}
            targetRef={gridRef}
            reportName={title}
            toggleReport={toggleReport}
            setReportFormat={updateReportFormat}
          />
        </div>
      )}
    </div>
  );
};
