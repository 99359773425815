import useForm from "../../hooks/useForm";
import React, { useEffect } from "react";

const Page = ({ pageNo, currentPage, handleClick }) => {
  const onClick = () => {
    handleClick(pageNo);
  };

  return (
    <span
      onClick={onClick}
      className="cursor"
      style={{ backgroundColor: currentPage == pageNo ? "lightGrey" : null }}
    >
      {pageNo}
    </span>
  );
};

export default ({
  itemPerPage,
  items,
  setItems,
  totalItems,
  setStartIndex,
}) => {
  const { state, handleStateChange } = useForm({
    currentPage: 1,
    allPageNumbers: [],
    pageNumbers: [],
  });
  const { currentPage, allPageNumbers, startIndex, endIndex } = state;

  useEffect(() => {
    if (items || totalItems) {
      let allPageNumbers = [];
      const noOfItems = items ? items.length : totalItems;
      for (let i = 1; i <= Math.ceil(noOfItems / itemPerPage); i++) {
        allPageNumbers.push(i);
      }
      handleStateChange({ allPageNumbers });
    }
  }, [items, totalItems, itemPerPage]);

  useEffect(() => {
    setPage(1);
  }, [allPageNumbers]);

  useEffect(() => {
    if (items || totalItems) {
      const indexOfLastTodo = currentPage * itemPerPage;
      const indexOfFirstTodo = indexOfLastTodo - itemPerPage;
      if (items) {
        const currentItems = items.slice(indexOfFirstTodo, indexOfLastTodo);
        setItems(currentItems);
        handleStateChange({
          startIndex: indexOfFirstTodo,
          endIndex: indexOfFirstTodo + currentItems.length,
        });
      } else {
        setStartIndex(indexOfFirstTodo);
      }
    }
  }, [items, totalItems, currentPage, itemPerPage]);

  useEffect(() => {
    let pageNumbers = [];
    if (allPageNumbers.length > 0) {
      if (allPageNumbers.length <= 6) {
        pageNumbers = allPageNumbers;
      } else {
        pageNumbers.push(allPageNumbers[0]);
        let startIndex = currentPage - 3;
        let endIndex = startIndex + 5;
        pageNumbers = pageNumbers.concat(
          allPageNumbers.slice(startIndex > 0 ? startIndex : 0, endIndex)
        );
        pageNumbers.push(allPageNumbers.at(-1));
      }
    }
    handleStateChange({ pageNumbers: [...new Set(pageNumbers)] });
  }, [allPageNumbers, currentPage]);

  const setPage = (currentPage) => {
    handleStateChange({ currentPage });
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < allPageNumbers.length) {
      setPage(currentPage + 1);
    }
  };

  if (allPageNumbers?.length > 0) {
    return (
      <>
        <div class="dataTables_info" id="DataTables_Table_0_info">
          Showing {startIndex + 1} to {endIndex} of {items?.length} entries
        </div>
        <div
          class="dataTables_paginate paging_simple_numbers"
          id="DataTables_Table_0_paginate"
        >
          <a
            class={`paginate_button previous ${
              currentPage == 1 ? "disabled" : ""
            }`}
            aria-controls="DataTables_Table_0"
            aria-disabled="true"
            role="link"
            data-dt-idx="previous"
            tabindex="-1"
            id="DataTables_Table_0_previous"
            onClick={handlePrev}
          >
            Previous
          </a>
          <span>
            <a
              class="paginate_button current"
              aria-controls="DataTables_Table_0"
              role="link"
              aria-current="page"
              data-dt-idx="0"
              tabindex="0"
            >
              {currentPage}
            </a>
          </span>
          <a
            class={`paginate_button next ${
              currentPage == items?.length ? "disabled" : ""
            }`}
            aria-controls="DataTables_Table_0"
            aria-disabled="true"
            role="link"
            data-dt-idx="next"
            tabindex="-1"
            id="DataTables_Table_0_next"
            onClick={handleNext}
          >
            Next
          </a>
        </div>
      </>
    );
  }

  return <></>;
};
