import React from "react";
import useOrgResources from "../../../hooks/useOrgResources";

export const PartnerLabel = ({ partnerName }) => {
  const { partnerLabel, sourceLabel } = useOrgResources();

  return (
    <div class="table-title" style={{ marginBottom: 0 }}>
      <span>{partnerLabel}:&nbsp;</span>
      {partnerName}
    </div>
  );
};

export const SourceLabel = ({ sourceName }) => {
  const { sourceLabel } = useOrgResources();

  return (
    <div class="header-column">
      <div class="table-header">
        <span className="header-label">{sourceLabel}:&nbsp;</span>{sourceName}
      </div>
    </div>
  );
};

export const StaffLabel = ({ employeeName }) => {
  const { sourceLabel } = useOrgResources();

  return (
    <div class="header-column">
      <div class="table-header light-blue">
        <span className="header-label">Staff:&nbsp;</span>{employeeName}
      </div>
    </div>
  );
};
