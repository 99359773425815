import {Customer} from '../actionTypes/customer';

export const addEditCustomer=(payload)=>({
    type:Customer.ADD_CUSTOMER,
    payload
});

export const addCustomerView=(payload)=>({
    type:Customer.ADD_CUSTOMER_VIEW,
    payload
});

export const getCustomers=(payload)=>({
    type:Customer.GET_CUSTOMERS,
    payload
});

export const getActiveCustomers=(payload)=>({
    type:Customer.GET_ACTIVE_CUSTOMERS,
    payload
});

export const enableDisableCustomers=(payload)=>({
    type:Customer.ENABLE_DISABLE_CUSTOMERS,
    payload
});