import React from 'react';
import Styles from '../Login.module.css';
import { UserButton } from '../../../components/Button';

export default ({title,btnText,children,handleSubmit})=>(
    <div className={Styles.container}>
        <div className={Styles.loginWrapper}>
            <div className={Styles.loginHeading}>
                {title?title:'Login'}
            </div>
            <div className="loginBody">
                <form
                    className="formHorizontal"
                    onSubmit={handleSubmit}
                    method="post"
                >
                    {children}
                    <div className="loginFooter" style={{marginTop:20}}>
                        <div className="login-btn">
                            <UserButton
                                type="submit"
                                text={btnText?btnText:"Login"}
                                className="btn btn-primary"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
)