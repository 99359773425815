import React, { useState, useEffect } from "react";
import { TextInput } from "../../components/TextInput";
import { useSelector, useDispatch } from "react-redux";
import { addEditOrgResources } from "../../actions/orgPartner";
import useToast from "../../hooks/useToast";
import "./scss/RenamingGroups.scss";
import { UserModal } from "../../components/Modal";

const Group = ({ exisitngName, newValue, handleChange, index }) => (
  <div className="row">
    <div className="col-md-3">Group {index}</div>
    <div className="col-md-4">{exisitngName}</div>
    <div className="col-md-5">
      <TextInput name="newValue" value={newValue} onChange={handleChange} />
    </div>
  </div>
);

export default ({ showModal, toggleModal }) => {
  const { orgResources: initResources } = useSelector((state) => state.pages);
  const [orgResources, setResources] = useState([]);
  const dispatch = useDispatch();
  const { showError } = useToast();

  useEffect(() => {
    if (initResources) setResources(initResources);
  }, [initResources]);

  const handleChange = (index, name, value) => {
    let resources = orgResources.slice();
    resources[index] = { ...resources[index], [name]: value };
    setResources(resources);
  };

  const handleSave = () => {
    const filtered = orgResources.filter((c) => c.newValue);
    if (filtered.length > 0) {
      dispatch(
        addEditOrgResources(
          orgResources.map((c) => ({
            ...c,
            newValue: c.newValue ? c.newValue : c.value,
          }))
        )
      );
      toggleModal();
    } else showError("Please enter new name");
  };

  return (
    <UserModal
      show={showModal}
      handleClose={toggleModal}
      title="Edit Groups"
      btnText="Update"
      handleSave={handleSave}>
      {orgResources.map((c, index) => (
        <Group
          key={c.name}
          index={index + 1}
          exisitngName={c.value}
          newValue={c.newValue}
          handleChange={handleChange.bind(this, index)}
        />
      ))}
    </UserModal>
  );
};
