import React, { useState, useEffect } from "react";
import { UserTable } from "../../../../components/Table";
import { GridAction } from "../../../../utils/GridAction";
import useClientHook from "../../../hooks/useClientHook";
import useJobHook from "../../../hooks/useJobHook";
import { Timesheet as Constant } from "../../../../utils/timesheetConstant";
import { displayDate } from "../../../../libs/dateHelper";
import { displayCost } from "../../../../libs/functions";

const { DATE_NAME } = Constant;

export default (props) => {
  const {
    timesheets,
    rowId,
    handleRowClick,
    handleRowChange,
    isReport,
    timesheetTypeId,
    handleChange,
    startDate,
    endDate,
    disableTimesheet,
  } = props;
  const { clientProps } = useClientHook();
  const { activityProps } = useJobHook(timesheetTypeId);

  const [gridData, setGridData] = useState([]);

  let headers = [
    ...(!isReport
      ? [
          {
            key: DATE_NAME,
            text: "Date",
            type: isReport ? null : GridAction.DATE_PICKER,
            handleChange: handleChange,
            cellProps: { style: { minWidth: 150, width: 150 } },
            actionProps: {
              minDate: startDate,
              maxDate: endDate,
            },
          },
        ]
      : []),
    {
      key: "clientCode",
      text: "Ref",
      cellProps: { style: { minWidth: 100, width: 100 } },
      type: isReport ? null : GridAction.CUSTOM_POPUP,
      ...clientProps,
    },
    {
      key: "clientName",
      text: "Client",
      type: isReport ? null : GridAction.CUSTOM_POPUP,
      ...clientProps,
    },
    {
      key: "activityName",
      text: "Exp Ref",
      cellProps: { style: { minWidth: 100, width: 100 } },
      type: isReport ? null : GridAction.CUSTOM_POPUP,
      ...activityProps,
    },
    {
      key: "activityDesc",
      text: "Description",
      type: isReport ? null : GridAction.CUSTOM_POPUP,
      ...activityProps,
    },
    {
      key: "rate",
      text: "Amount",
      type: isReport ? null : GridAction.TEXT_INPUT,
      className: "gridInput",
      cellProps: { style: { minWidth: 150, width: 150 } },
      actionProps: {
        type: "number",
      },
      handleChange: handleChange,
    },
    ...(isReport ? [{ cellProps: { className: "blankCell" } }] : []),
  ];

  useEffect(() => {
    if (timesheets) {
      const gridData = timesheets.map((c) => ({
        ...c,
        [DATE_NAME]:
          c.workDetails && c.workDetails.length > 0
            ? displayDate(c.workDetails[0][DATE_NAME])
            : "",
        rate: c.activityDesc?displayCost(c.rate):c.rate
      }));

      setGridData(gridData);
    }
  }, [timesheets]);

  return (
    <UserTable
      headers={headers}
      data={gridData}
      rowId={rowId}
      disable={disableTimesheet}
      handleChange={handleRowChange}
      handleRowClick={handleRowClick}
      tblClass={isReport ? "table table-stripe" : ""}
    />
  );
};
