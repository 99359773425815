import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { UserCard } from "../../components/Container";
import { useDispatch, useSelector } from "react-redux";
import useGridHook from "../../hooks/useGridHook";
import GridButton from "../../common/GridButton";
import { RoleGrid, AddRole, AddPermission } from "./Components/UserRole";
import { getRoles, deleteOrgRole } from "../../actions/user";
import "./scss/UserRoles.scss";

const rowId = "orgRoleId";

export default (props) => {
  const dispatch = useDispatch();
  const { gridData, handleRowClick, setGridData, deleteRow } = useGridHook(
    rowId,
    handleDelete
  );
  const { roles } = useSelector((state) => state.user);
  const [selectedRole, setSelectedRole] = useState(null);

  const fetchRoles = () => {
    dispatch(getRoles());
  };

  const setRoles = (roles) => {
    if (roles) setGridData(roles.map((c) => ({ ...c, permission: "" })));
  };

  useEffect(fetchRoles, []);

  useEffect(() => setRoles(roles), [roles]);

  const { show: showAddRole, toggle: toggleAddRole } = useToggle();
  const { show: showPermissions, toggle: togglePermissionModal } = useToggle();

  const handleAddEdit = (roleId) => {
    setSelectedRole(gridData.find((c) => c[rowId] == roleId));
    toggleAddRole();
  };

  function handleDelete(orgRoleId) {
    dispatch(deleteOrgRole(orgRoleId));
  }

  const handlePermission = (id, event) => {
    if (event) event.stopPropagation();

    handleRowClick(id);
    togglePermissionModal(true);
  };

  return (
    <div class="plm_usrroles_list_div">
      <div class="container-fluid prn_cfuid">
        <div class="row prn_row">
          <div class="col-12 prn_col12">
            <UserCard title="User Roles">
              <RoleGrid
                rowId={rowId}
                roles={gridData}
                handleRowClick={handleRowClick}
                handlePermission={handlePermission}
                handleEdit={handleAddEdit}
                handleDelete={deleteRow}
              />
              <div className="hr-divider"></div>
              <GridButton handleAdd={handleAddEdit} />
              {showAddRole && (
                <AddRole
                  showAddRole={showAddRole}
                  toggleModal={toggleAddRole}
                  selectedRole={gridData.find((c) => c.selected)}
                />
              )}
              {showPermissions && (
                <AddPermission
                  selectedRole={gridData.find((c) => c.selected)}
                  showAddPermission={showPermissions}
                  toggleModal={togglePermissionModal}
                />
              )}
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
