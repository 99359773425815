import React from "react";
import { UserDatePicker } from "../components/DateControl";
import useUserDetail from "../hooks/useUserDetail";
import ReportFilterWrapper from "./ReportFilterWrapper";

export default ({
  timesheetDate,
  asterix,
  onChange,
  allowMinDate,
  isReport,
}) => {
  const { finEndDate, finStartDate } = useUserDetail();

  if (isReport) {
    return (
      <ReportFilterWrapper label="All transactions up to and including">
        <UserDatePicker
          name="timesheetDate"
          onChange={onChange}
          value={timesheetDate}
          minDate={allowMinDate ? null : finStartDate}
          maxDate={finEndDate}
          asterix={asterix}
        />
      </ReportFilterWrapper>
    );
  }

  return (
    <UserDatePicker
      name="timesheetDate"
      label="All transactions up to and including"
      onChange={onChange}
      value={timesheetDate}
      minDate={allowMinDate ? null : finStartDate}
      maxDate={finEndDate}
      asterix={asterix}
    />
  );
};
