import React from "react";
import useTheme from "../../hooks/useTheme";
import "./_card.scss";

export const CardFoot = ({ children }) => {
  const { theme } = useTheme();

  return (
    <div className="card-foot">
      <div className={theme + "hrDivider"}></div>
      {children}
    </div>
  );
};

export default ({ title, children, className }) => {
  return (
    <div className={`cardBox ${className ? className : "plm_cmn_cardbox"}`}>
      {title && (
        <div className="card-head">
          <h4 className="card-title">{title}</h4>
        </div>
      )}
      <div className="card-body">{children}</div>
    </div>
  );
};
