import React,{useState} from 'react';
import {useDispatch} from 'react-redux';
import {  changePassword, resetPassword } from '../../actions/account';
import { TextInput } from '../../components/TextInput';
import useForm from '../../hooks/useForm';
import { LoginWrapper } from './Components';
import './_account.scss';
import { Error } from '../../components/Label';
import { useParams, useLocation } from 'react-router';

const stateSchema={
  
};


const ResetPasswordScreen=(props)=>{

    const {state:locationState}=useLocation();
    const {
        userDetail,
        showOldPassword
    }=locationState?locationState:{};

    const validationSchema={
        oldPassword:{
            required: showOldPassword
        },
        password:{
            required: true
        },
        confirmPassword:{
            required:true
        }
    };

    const dispatch=useDispatch();
   
    const {
        state,
        errors,
        handleChange,
        handleSubmit
    }=useForm(stateSchema,validationSchema,submit);

    const [error,setError]=useState(null);

    const params=useParams();

    function submit(state) {
        const {password,confirmPassword}=state;
        if(password===confirmPassword)
        {
            if(showOldPassword)
                dispatch(changePassword({
                    ...state,
                    ...userDetail
                }));
            else
                dispatch(resetPassword({
                    ...state,
                    ...params
                }));
        }
        else{
            setError('Password and Confirm Password must be same.');
        }
    }

        return (
              <LoginWrapper
                    handleSubmit={handleSubmit}
                    title="Reset Password"
                    btnText="Update"
                >
                    {showOldPassword &&
                        <div className="formField">
                            <TextInput
                                name="oldPassword"
                                type="password"
                                label="Old Password"
                                value={state['oldPassword']}
                                placeholder="Old Password"
                                onChange={handleChange}
                                errors={errors}
                                asterix={true}
                            />
                        </div>
                    }
                    <div className="formField">
                        <TextInput
                            name="password"
                            type="password"
                            label="New Password"
                            value={state['password']}
                            placeholder="Password"
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    <div className="formField">
                        <TextInput
                            name="confirmPassword"
                            type="password"
                            label="Confirm Password"
                            value={state['confirmPassword']}
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            errors={errors}
                            asterix={true}
                        />
                    </div>
                    {error &&
                        <Error
                            error={error}
                        />
                    }
                </LoginWrapper>
        );
}

export default ResetPasswordScreen;