import React, { useState, useEffect } from "react";
import { UserCard } from "../../components/Container";
import { EmpStatus, DateFilter } from "./Components";
import useActivityTypes from "../../hooks/useActivityTypes";
import { TabPanel, UserTab } from "../../components/Tabs";
import useUserDetail from "../../hooks/useUserDetail";
import { formatAPIDate, getWeekDays } from "../../libs/dateHelper";
import { TimesheetTab } from "./Components/TimesheetTabs";
import { UserButton } from "../../components/Button";
import { addTimesheetView, addEditTimesheet } from "../../actions/timesheet";
import { useDispatch, useSelector } from "react-redux";
import "./_timesheet.scss";
import useToast from "../../hooks/useToast";
import { cloneDeep } from "lodash";
import { LookUp } from "../../utils/lookUpConstant";
import useTimesheetStatus from "../hooks/useTimesheetStatus";
import { useLocation, useHistory } from "react-router";
import useTimesheetHook from "../hooks/useTimesheetHook";

const rowId = "rowId";
const weekDays = getWeekDays();

export default (props) => {
  const dispatch = useDispatch();
  const { activityTypes, defaultActivity } = useActivityTypes();
  const { finStartDate, finEndDate, emailId: userEmailId } = useUserDetail();
  const [timesheetData, setTimesheetData] = useState({});
  const [hideDefaultOption, setDefault] = useState(false);
  const {
    startDate,
    endDate,
    timesheetStatus,
    timesheetStatusId,
  } = timesheetData;
  const { gridProps } = useTimesheetHook(rowId, activityTypes,startDate);
  const { gridData } = gridProps;
  const { showError } = useToast();
  const [emailId, setUserEmail] = useState(null);

  const { timesheet } = useSelector((state) => state.timesheet);
  const {
    openTimesheetId,
    submittedTimesheetId,
    authorisedTimesheetId,
  } = useTimesheetStatus();
  
  const disableTimesheet = timesheetStatusId == authorisedTimesheetId;

  const initTimesheets = (date, userId) => {
    dispatch(
      addTimesheetView({
        timesheetDate: formatAPIDate(date),
        userId: userId ? userId : props.employeeId,
      })
    );
  };

  useEffect(() => {
    let emailId = userEmailId;
    if (props.startDate) {
      const { startDate, employeeId } = props;
      ({ emailId } = props);
      handleChange({
        startDate,
        userId: employeeId,
      });
      setDefault(true);
    }
    setUserEmail(emailId);
  }, []);

  const setTimesheet = (timesheet) => {
    if (timesheet)
      setTimesheetData({
        ...timesheet,
        timesheetStatus: timesheet.timesheetStatus
          ? timesheet.timesheetStatus
          : LookUp.OPEN_TS,
      });
  };

  useEffect(() => {
    setTimesheet(timesheet);
  }, [timesheet]);

  const handleChange = (data) => {
    setTimesheetData((prev) => ({ ...prev, ...data }));
    initTimesheets(data?.startDate, data?.userId);
  };

  const handleAdd = (timesheetStatusId) => {
    let timesheetDetails = cloneDeep(gridData);
    const expensesId = activityTypes.find((c) => c.text == LookUp.EXPENSES)
      ?.value;
    timesheetDetails.forEach((c) => {
      if (c.timesheetTypeId != expensesId)
        c.workDetails = c.workDetails.filter((s) => s.noOfUnits);
      else if (!c.rate) c.workDetails = [];
    });

    const filtered = timesheetDetails.filter((c) => c.workDetails.length > 0);
    if (filtered.length > 0) {
      dispatch(
        addEditTimesheet({
          ...timesheetData,
          timesheetStatusId,
          startDate: formatAPIDate(startDate),
          endDate: formatAPIDate(endDate),
          timesheetDetails: filtered,
        })
      );
    } else showError("Please fill timesheet");
  };

  return (
    <div className="plm_timeexpnse_main_div">
      <div className="container-fluid prn_cfuid">
        <div className="row prn_row">
          <div className="col-12 prn_col12">
            <UserCard title="Time And Expenses">
              <div className="groupWrap">
                <div className="row emp_tsrow">
                  <div className="col-12 col-xl-6 emp_tscol">
                    <EmpStatus emailId={emailId} status={timesheetStatus} />
                  </div>
                  <div className="col-12 col-xl-6 emp_tscol">
                    <DateFilter
                      timesheetDates={{ startDate, endDate }}
                      finStartDate={finStartDate}
                      finEndDate={finEndDate}
                      hideDefaultOption={hideDefaultOption}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="plm_cmn_tabs plm_tabs_typ1 tsP">
                <TabPanel
                  defaultTab={defaultActivity}
                  mountOnEnter={true}
                  unmountOnExit={true}>
                  {activityTypes &&
                    activityTypes.map((c) => (
                      <UserTab eventKey={c.text} title={c.text} key={c.value}>
                        <TimesheetTab
                          {...c}
                          gridProps={{
                            ...gridProps,
                            startDate,
                            endDate,
                            rowId,
                            disableTimesheet,
                          }}
                          weekDays={weekDays}
                        />
                      </UserTab>
                    ))}
                </TabPanel>
              </div>

              <div className="tbBtn plm_cmn_cardbtn_alone_div">
                <UserButton
                  text="Save"
                  disabled={disableTimesheet}
                  className="btn btn-primary"
                  onClick={() => handleAdd(openTimesheetId)}
                />
                <UserButton
                  text="Print"
                  className="btn btn-primary"
                  //onClick={()=>handleEdit(true)}
                />
                <UserButton
                  text="Submit"
                  disabled={disableTimesheet}
                  className="btn btn-primary"
                  onClick={() => handleAdd(submittedTimesheetId)}
                />
              </div>
            </UserCard>
          </div>
        </div>
      </div>
    </div>
  );
};
