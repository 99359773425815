import React from "react";
import { TimesheetGrid } from ".";
import { ReportHeader } from "../Components";
import useOrgResources from "../../../hooks/useOrgResources";
import { DisplayLabel, CustomDisplayLabel } from "../../../components/Label";
import { displayDate } from "../../../libs/dateHelper";
import usePrintHook from "../../hooks/usePrintHook";

export default (props) => {
  const { startDate, endDate, orgPartnerId, partnerSourceId, reportType } =
    props;
  const { partnerLabel, sourceLabel, orgPartner, partnerSource } =
    useOrgResources(orgPartnerId, partnerSourceId);
  const { WORD, EXCEL } = usePrintHook({});

  return (
    <div className="nonChargeableRptContainer">
      {reportType == WORD || reportType == EXCEL ? (
        <TimesheetGrid
          {...props}
          isReport={true}
          reportType={reportType}
          orgPartner={orgPartner}
          partnerSource={partnerSource}
        />
      ) : (
        <>
          <ReportHeader heading="Non-Chargeables" reportType={reportType} />
          <div class="header-row">
            <div class="table-header w-32">
                <DisplayLabel label={partnerLabel} value={orgPartner} />
            </div>
            <div class="table-header w-32">
                <DisplayLabel
                  label="Date Range"
                  width={110}
                  value={displayDate(startDate) + "-" + displayDate(endDate)}
                />
            </div>
            <div class="table-header w-32">
                <DisplayLabel label={sourceLabel} value={partnerSource} />
            </div>
          </div>

          <div className="timesheetGrid_nonchargeable">
            <br />
            <TimesheetGrid {...props} isReport={true} reportType={reportType} />
          </div>
        </>
      )}
    </div>
  );
};
