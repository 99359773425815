import React from "react";
import { useSelector } from "react-redux";
import { DisplayLabel } from "../../../components/Label";

export default (props) => {
  const { clientDetail } = useSelector((state) => state.invoice);
  const { clientName } = clientDetail;

  return (
    <div className="transparentLabel client-lbl">
      <DisplayLabel label="Client Name" value={clientName} width="15%" />
    </div>
  );
};
