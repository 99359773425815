import React from "react";
import { UserTable } from "../../../../components/Table";

export default (props) => {
  const { activities, rowId, handleRowClick, handleEdit, handleDelete } = props;

  const headers = [
    { key: "activityName", text: "Activity Code", search: true, sort: true },
    { key: "activityDesc", text: "Activity" },
  ];

  return (
    <UserTable
      headers={headers}
      data={activities}
      rowId={rowId}
      handleRowClick={handleRowClick}
      showActions={true}
      actionProps={{ handleEdit, handleDelete, deleteTitle: "activity" }}
    />
  );
};
