import React, { useEffect, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import { ReactComponent as NotificationIcon } from "../SvgIcons/Notification.svg";
import { ReactComponent as LogoutIcon } from "../SvgIcons/Logout.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Header.scss";
import { RouteConstant } from "../../utils/routeConstant";
import useRole from "../../hooks/useRole";
import { useSelector, useDispatch } from "react-redux";
import { stopImpersonation, setUserTheme } from "../../actions/account";
import { SelectBox } from "../SelectBox";
import useTheme from "../../hooks/useTheme";
import Logo from "./Logo";
import { UserButton } from "../Button";

const HeaderList = ({ url, children, linkProps }) => (
  <li>
    <Link
      to={{
        pathname: url ? url : "/",
        state: { ...linkProps },
      }}>
      {children}
    </Link>
  </li>
);

export default (props) => {
  const { isCustomer, isAdmin } = useRole();
  const { themeId, setTheme } = useTheme();
  let history = useHistory();
  const dispatch = useDispatch();
  const { userDetail, themes } = useSelector((state) => state.account);
  const { fullName } = userDetail ? userDetail : {};
  const { pathname } = useLocation();

  const updateTheme = () => {
    dispatch(setUserTheme(themeId));
  };

  useEffect(() => {
    window.addEventListener("beforeunload", updateTheme);

    return () => window.removeEventListener("beforeunload", updateTheme);
  }, [themeId]);

  const handleChange = (name, value) => {
    setTheme(value);
  };

  const handleLogout = () => {
    const url =
      isAdmin || isCustomer ? RouteConstant.ADMIN_LOGIN : RouteConstant.LOGIN;
    updateTheme();
    history.push(url);
  };

  const handleAdmin = () => {
    dispatch(stopImpersonation());
  };

  const [open, setOpen] = useState(false);

  return (
    <header className={`header_wrap${pathname==RouteConstant.REPORTS?' reportHeaderWrap':''}`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-lg-4 col-xl-4">
            <div className="logo_wrap">
              <Logo />
              <div className="resp_div d-sm-block d-md-none d-lg-none d-xl-none">
                <Button
                  className="menuresp_btn"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}>
                  <i className="fa fa-bars"></i>
                </Button>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-8 col-xl-8">
            <Collapse in={open}>
              <div id="example-collapse-text" className="headmen_rbardiv">
                <div className="plm_menubar_wrap">
                  <ul className="header_rlist">
                    <li>
                      <img
                        className="profileIc"
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/img/thumb/profile.jpg"
                        }
                      />
                    </li>
                    <HeaderList>
                      <div className="pro-name">
                        <strong>{fullName}</strong>
                      </div>
                    </HeaderList>
                    <li>
                      <UserButton
                        text="Log Out"
                        className="btn btn-primary"
                        onClick={handleLogout}
                      />
                      {isCustomer && (
                        <UserButton
                          text="Admin"
                          className="btn btn-primary"
                          onClick={handleAdmin}
                        />
                      )}
                    </li>

                    {/* {!isAdmin && (
                      <li className="plm_theme_lst">
                        <SelectBox
                          name="themeId"
                          value={themeId}
                          onChange={handleChange}
                          options={themes}
                        />
                      </li>
                    )} */}
                  </ul>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </header>
  );
};
