import React from "react";
import { Modal, Button } from "react-bootstrap";
import { UserButton } from "../Button";
import "./_modal.scss";

export default ({
  handleClose,
  show,
  children,
  title,
  handleSave,
  size,
  btnText,
  hideFooter,
  dialogClassName,
}) => {
  return (
    <Modal
      show={show}
      size={size}
      onHide={handleClose}
      dialogClassName={dialogClassName}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {!hideFooter && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <UserButton
            text={btnText ? btnText : "Save"}
            onClick={handleSave}
            className="btn btn-primary"
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};
