import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './Tab.scss';

export const UserTab=({eventKey,title,children})=>(
    <Tab eventKey={eventKey} title={title}>
            {children}
    </Tab>
)

export default ({children,defaultTab,unmountOnExit,mountOnEnter})=>{

    const [activeTab,setActiveTab]=useState(null);

    useEffect(()=>{
        if(defaultTab)
            setActiveTab(defaultTab);
    },[defaultTab])

    return(
        <Tabs
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            unmountOnExit={unmountOnExit}
            mountOnEnter={mountOnEnter}
        >
            {children}
        </Tabs>
    )
}