import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FeeHeader } from "../Components";
import { getInvoices } from "../../../actions/invoice";
import { IncomeGrid, Report } from "../FeeIncomeDetail";
import "./FeeIncomeDetails.scss";

export default React.forwardRef(
  (
    {
      showReport,
      reportTitle,
      selectedInvoice,
      reportType,
      toggleReportBtn,
      gridRef,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({});

    const { invoices } = useSelector((state) => state.invoice);

    useEffect(() => {
      toggleReportBtn(invoices && invoices.length > 0);
    }, [invoices]);

    const getFeeSummary = (payload) => {
      setState(payload);
      dispatch(
        getInvoices({
          ...payload,
        })
      );
    };

    return (
      <>
        <FeeHeader handleView={getFeeSummary} hideEmployee={true} />
        <IncomeGrid {...state} />
        {showReport && (
          <div className="col-12 reportDataContainer">
            <Report
              {...state}
              reportTitle={reportTitle}
              selectedInvoice={selectedInvoice}
              reportType={reportType}
              gridRef={gridRef}
            />
          </div>
        )}
      </>
    );
  }
);
