import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimesheetByType, setTimesheet } from "../../../actions/timesheet";
import { TimesheetGrid, Report } from "../NonChargeable";
import { LookUp } from "../../../utils/lookUpConstant";
import { FeeHeader } from "../Components";
import "./NonChargeableReport.scss";
import useUserDetail from "../../../hooks/useUserDetail";

export default React.forwardRef(
  ({ showReport, reportType, toggleReportBtn, gridRef }, ref) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({});
    const { theme } = useUserDetail();
    const { timesheets } = useSelector((state) => state.timesheet);

    useEffect(() => {
      dispatch(setTimesheet({ timesheets: [] }));
    }, []);

    useEffect(() => {
      toggleReportBtn(timesheets && timesheets.length > 0);
    }, [timesheets]);

    const getTimesheets = (payload) => {
      setState(payload);
      dispatch(
        getTimesheetByType({
          ...payload,
          timesheetType: LookUp.NON_CHARGEABLE,
        })
      );
    };

    return (
      <>
        <FeeHeader handleView={getTimesheets} />
        <TimesheetGrid {...state} />
        {showReport && (
          <div className="col-12 reportDataContainer">
            <div className={"row plm_rp_rptort_col App " + theme} ref={gridRef}>
              <div className="col-xs-12 reportWrapper report-wrap-p rwrapPdf">
                <Report {...state} reportType={reportType} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
