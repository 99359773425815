import React, { useEffect } from "react";
import { UserTable, CommonTable } from "../../../components/Table";
import useGridHook from "../../../hooks/useGridHook";
import { useSelector } from "react-redux";
import { groupBy, map, sumBy } from "lodash";
import { CustomDisplayLabel } from "../../../components/Label";
import "./IncomeGrid.scss";
import useUserDetail from "../../../hooks/useUserDetail";
import usePrintHook from "../../hooks/usePrintHook";
import { ReportContainer } from "../../../components/Container";
import useOrgResources from "../../../hooks/useOrgResources";
import { displayDate } from "../../../libs/dateHelper";
import { displayCost } from "../../../libs/functions";
import { PartnerLabel, SourceLabel } from "../Components";
import TableCell from "../../../components/Table/Cell";
import { normalizeData } from "../../../libs/functions";

const rowId = "";

const TotalRow = ({ text, totalUnits, className, totalCost }) => {
  return (
    <UserTable
      tblClass="table table-stripe mb0 empIncomeSummaryGrid empIncomeSummaryTotalRow"
      tableProps={{ cellSpacing: 0, cellPadding: 0, border: 0 }}
      wrapperClass={`totalRow ${className}`}
      headers={[
        { text, colSpan:2 },
        // { text: "" },
        { text: totalUnits, colSpan:2 },
        // { text: "" },
        { text: totalCost },
      ].map((c) => ({ ...c, headerActionProps: { className: "gap-cell" } }))}
    />
  );
};

export default ({ isReport, reportType }) => {
  const { gridData, setGridData } = useGridHook();
  const { partnerLabel, sourceLabel } = useOrgResources();
  const { timesheets } = useSelector((state) => state.timesheet);
  const { theme } = useUserDetail();
  const { WORD, EXCEL } = usePrintHook({});

  const calTotal = (data) => ({
    totalUnits: sumBy(data, (t) => t.noOfUnits),
    totalCost: displayCost(sumBy(data, (t) => parseFloat(t.cost))),
  });

  useEffect(() => {
    if (timesheets) {
      let allTimsheets = [];
      timesheets.forEach((c) => {
        allTimsheets = allTimsheets.concat(
          c.partners
            ? c.partners.map((p) => ({
                ...c,
                ...p,
              }))
            : []
        );
      });
      const partnerGroup = groupBy(allTimsheets, (c) => c.orgPartnerId);
      const gridData = map(partnerGroup, (p) => {
        const sourceGroup = groupBy(p, (s) => s.partnerSourceId);
        return {
          partnerName: p[0].partnerName,
          sourceDetails: map(sourceGroup, (s) => {
            const empGroup = groupBy(s, (e) => e.employeeId);
            return {
              sourceName: s[0].sourceName,
              empDetails: map(empGroup, (e) => {
                const clientGroup = groupBy(e, (cl) => cl.clientId);
                return {
                  employeeName: e[0].fullName,
                  clientDetails: map(clientGroup, (cl) => ({
                    clientName: cl[0].clientName,
                    gridData: cl.map((c) => ({
                      ...c,
                      workDate: displayDate(c.workDate),
                      cost: displayCost(c.cost),
                    })),
                    ...calTotal(cl),
                  })),
                  ...calTotal(e),
                };
              }),
              ...calTotal(s),
            };
          }),
          ...calTotal(p),
        };
      });
      setGridData(gridData);
    }
  }, [timesheets]);

  const headers = [
    { key: "workDate", text: "Date" },
    { key: "activity", text: "Job Type" },
    { key: "noOfUnits", text: "Units" },
    { key: "rate", text: "Rate" },
    { key: "cost", text: "Value" },
  ].map((c) => ({ ...c, actionProps: { className: "gap-cell" } }));

    const getDataTable = (data, totalRows=[]) => {

          var rows=[];

            {normalizeData(data, rowId).map((row, i) => 
                rows.push(<tr
                    className="tbody"
                    key={i}>
                    {headers.map((prop) => 
                      <TableCell
                        {...prop}
                        key={prop.key + "" + i}
                        data={row.find((r) => r.key === prop.key)}
                        selected={row.find((r) => r.key === "selected" && r.text)}
                      />
                    )}
                  </tr>)
              )};

            {
              totalRows.map((totalRow)=> {
                  rows.push(<tr className="tbody totalRow-wrap">
                    {
                      totalRow.map((t)=> <td className={`td ${t.className}`} colspan={t.colSpan}><div className="gap-cell">{t.text}</div></td>)
                    }
                  </tr>)
              })
            }

      return rows;
    }

  const className = isReport ? "col-12 col-md-12 col-xs-12" : "col-12";
  var datatable=[];

  return (
    <ReportContainer hasData={gridData.length > 0}>
      {gridData.map((p, pIndex) => 
      {
        datatable=[];
        return (
          <div className="empWorkSummaryRpt" key={pIndex}>
            {reportType == WORD || reportType == EXCEL ? (
              <CustomDisplayLabel
                label={partnerLabel}
                value={p.partnerName}
                customClass="labelWrapper1"
              />
            ) : (
              <PartnerLabel partnerName={p.partnerName} />
            )}
            {p.sourceDetails.map((s, sIndex) => 
            {
              datatable=[];
              return (
                <React.Fragment key={`S${sIndex}`}>
                  <SourceLabel sourceName={s.sourceName} />
                  {s.empDetails.map((e, eIndex) => 
                  {
                    datatable=[];
                    return (
                      <React.Fragment key={`E${eIndex}`}>
                        <div class="header-row">
                          <div class="table-header back-light-blue">
                            Employee: <span>{e.employeeName}</span>
                          </div>
                        </div>
                        {e.clientDetails.map((cl, clIndex) => 
                        {
                          datatable=[];
                          return (
                            <React.Fragment key={`Cl${clIndex}`}>
                              <div class="header-row">
                                <div class="table-header">
                                  Client: <span>{cl.clientName}</span>
                                </div>
                              </div>

                              {
                                datatable= [datatable, getDataTable(cl.gridData, [
                                  [
                                    { text:`Totals for Client: ${cl.clientName??''}`, colSpan:2, className: "total back-blue" },
                                    { text: cl.totalUnits, colSpan:2, className: "total back-blue" },
                                    { text: cl.totalCost, className: "total back-blue" },
                                  ]])]            
                              }

                              {
                                  clIndex+1 < e.clientDetails.length &&
                                  <CommonTable tblClass="empIncomeSummaryGrid" headers={headers} rows={datatable} />             
                              }

                            </React.Fragment>
                          )
                        }
                        )}

                              {
                                datatable=[datatable, (<tr className="tbody totalRow-wrap">
                                <td className={`td total back-light-blue`} colspan="2"><div className="gap-cell">{`Totals for Employee: ${e.employeeName}`}</div></td>
                                <td className={`td total back-light-blue`} colspan="2"><div className="gap-cell">{e.totalUnits}</div></td>
                                <td className={`td total back-light-blue`}><div className="gap-cell">{e.totalCost}</div></td>
                                </tr>)]           
                              }

                              {
                                  eIndex+1 < s.empDetails.length &&
                                  <CommonTable tblClass="empIncomeSummaryGrid" headers={headers} rows={datatable} />             
                              }

                      </React.Fragment>
                    )
                  }
                  )}

                  {
                    datatable=[datatable, (<tr className="tbody totalRow-wrap">
                    <td className={`td total back-blue`} colspan="2"><div className="gap-cell">{`Totals for ${sourceLabel}: ${s.sourceName}`}</div></td>
                    <td className={`td total back-blue`} colspan="2"><div className="gap-cell">{s.totalUnits}</div></td>
                    <td className={`td total back-blue`}><div className="gap-cell">{s.totalCost}</div></td>
                    </tr>)]                         
                  }

                  {
                    sIndex+1 < p.sourceDetails.length &&
                    <CommonTable tblClass="empIncomeSummaryGrid" headers={headers} rows={datatable} />                                
                  }
                  
                </React.Fragment>
              )
            }
            )}


              {
                datatable=[datatable, (<tr className="tbody totalRow-wrap">
                <td className={`td total back-purple`} colspan="2"><div className="gap-cell">{`Totals for ${partnerLabel}: ${p.partnerName}`}</div></td>
                <td className={`td total back-purple`} colspan="2"><div className="gap-cell">{p.totalUnits}</div></td>
                <td className={`td total back-purple`}><div className="gap-cell">{p.totalCost}</div></td>
                </tr>)]                         
              }

              <CommonTable tblClass="empIncomeSummaryGrid" headers={headers} rows={datatable} /> 

          </div>
        )
      }
      )}
    </ReportContainer>
  );
};
