import React, { useState } from 'react';
import { RadioButtonGroup } from '../../components/RadioButton';
import {SelectBox} from '../../components/SelectBox';
import {  PermissionDetails } from '.';
import { useSelector } from 'react-redux';

const defaultOption='roleBased';

const options=[
    {label:'Role Based',value:'roleBased',className:'col-6'},
    {label:'Specific Options',value:'specificOptions',className:'col-6'}
]

const Permissions=(props)=>{

    const {roleId,handleChange}=props;
    const [permissionType,setType]=useState(defaultOption);
    const {orgRoles}=useSelector(state=>state.common);

    const onChange=(name,value)=>{
        setType(value);
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <RadioButtonGroup
                    name="permissionType"
                    value={permissionType}
                    options={options}
                    onChange={onChange}
                />
            </div>
            {permissionType===defaultOption?
                <div className='col-12'>
                    <SelectBox
                        name="roleId"
                        value={roleId}
                        onChange={handleChange}
                        options={orgRoles}
                        addFirstItem={true}
                    />
                </div>
                :
                <PermissionDetails
                    {...props}
                />
            }   
        </div>
    )
}

export default Permissions;