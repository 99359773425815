import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useForm from "../../../../hooks/useForm";
import { UserModal } from "../../../../components/Modal";
import { TextInput } from "../../../../components/TextInput";
import { addEditPartnerSource } from "../../../../actions/orgPartner";
import useOrgResources from "../../../../hooks/useOrgResources";

const stateSchema = {
  sourceName: "",
};

const validationSchema = {
  sourceName: {
    required: true,
  },
};

const AddPartner = ({ partnerDetail, handleChange, errors, sourceLabel }) => {
  const { sourceName } = partnerDetail;

  return (
    <div className="row">
      <div className="col-6">
        <TextInput
          name="sourceName"
          label={sourceLabel}
          value={sourceName}
          onChange={handleChange}
          errors={errors}
          asterix={true}
        />
      </div>
    </div>
  );
};

export default ({
  showAddPartner,
  toggleModal,
  selectedPartner,
  selectedBranch,
  title,
}) => {
  const {
    state: partnerDetail,
    errors,
    handleChange,
    handleSubmit,
    handleStateChange,
  } = useForm(stateSchema, validationSchema, handleSave);

  const dispatch = useDispatch();

  const { sourceLabel } = useOrgResources();

  useEffect(() => {
    if (selectedPartner)
      handleStateChange({
        sourceName: selectedPartner.text,
        partnerSourceId: selectedPartner.value,
      });
  }, []);

  function handleSave(partnerDetail) {
    const { orgPartnerId } = selectedBranch;
    dispatch(
      addEditPartnerSource({
        ...partnerDetail,
        orgPartnerId,
        sourceLabel,
      })
    );
    toggleModal();
  }

  return (
    <div className="col-12">
      <UserModal
        show={showAddPartner}
        handleClose={toggleModal}
        title={title}
        handleSave={handleSubmit}>
        <AddPartner
          partnerDetail={partnerDetail}
          handleChange={handleChange}
          errors={errors}
          sourceLabel={sourceLabel}
        />
      </UserModal>
    </div>
  );
};
