import { sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { WordLabel } from "../../../components/Label";
import { LabelColor } from "../../../components/Label/WordLabel";
import { WordTable } from "../../../components/Table";
import useActivityTypes from "../../../hooks/useActivityTypes";
import { displayDate, getWeekDays } from "../../../libs/dateHelper";
import TimesheetHelper from "../../../libs/TimesheetHelper";
import { LookUp } from "../../../utils/lookUpConstant";
import useDayGrid from "../../hooks/useDayGrid";
import useTimesheetHook from "../../hooks/useTimesheetHook";
import { WordReportHeader } from "../Components";

const chargeableHeaders = [
  { key: "clientCode", text: "Ref" },
  { key: "clientName", text: "Client" },
  { key: "activityName", text: "Job" },
  { key: "activityDesc", text: "Description" },
];

const nonChargeableHeaders = [
  { key: "activityName", text: "Job" },
  { key: "activityDesc", text: "Description" },
];

const expensiveHeader = [
  { key: "clientCode", text: "Ref" },
  { key: "clientName", text: "Client" },
  { key: "activityName", text: "Exp Ref" },
  { key: "activityDesc", text: "Description" },
  { key: "rate", text: "Amount" },
];

const totalCellProps = {
  style: {
    backgroundColor: LabelColor.Purple,
    color: "#eeeeee",
    border: "none",
  },
};

const totalRow = (headers, activityType) => ({
  ...headers.reduce(
    (prev, current) => ({
      ...prev,
      [current.key]: "",
      [current.key + "Props"]: { hidden: true },
    }),
    {}
  ),
  ...(activityType == LookUp.NON_CHARGEABLE
    ? {
        activityName: "Total",
        activityNameProps: { ...totalCellProps, colSpan: 2 },
      }
    : {
        clientCode: "Total",
        clientCodeProps: { ...totalCellProps, colSpan: 4 },
      }),
});

const rowId = "timesheetId";

const weekDays = getWeekDays();

const ActivityType = ({ text, value, gridData: initData }) => {
  const [gridData, setGridData] = useState([]);
  const { gridHeaders, daysData } = useDayGrid({ gridData, isReport: true });
  const [subTotalRow, setSubTotal] = useState({
    totalHeaders: [],
    totalData: [],
    rateTotal: 0,
  });
  const { totalData, rateTotal } = subTotalRow;

  useEffect(() => {
    const totalHeaders = TimesheetHelper.getTotalRow(gridData, weekDays);
    setSubTotal({
      totalHeaders: totalHeaders,
      rateTotal: sumBy(gridData, (c) => c.rate),
      totalData: [
        totalHeaders.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.key]: curr.text,
            [curr.key + "Props"]: totalCellProps,
          }),
          {}
        ),
      ],
    });
  }, [gridData]);

  useEffect(() => {
    setGridData(initData.filter((g) => g.timesheetTypeId == value));
  }, [initData]);

  const headers =
    text == LookUp.CHARGEABLE
      ? chargeableHeaders
      : text == LookUp.NON_CHARGEABLE
      ? nonChargeableHeaders
      : expensiveHeader;

  return (
    <>
      <tr style={{ border: "none" }}>
        <td style={{ border: "none" }} colSpan={2}>
          <WordLabel label={text} backgroundColor={LabelColor.Blue} />
        </td>
      </tr>
      <tr>
        <td style={{ border: "none" }}>
          <WordTable
            data={[
              ...gridData,
              {
                ...totalRow(headers, text),
                rate: rateTotal,
                rateProps: totalCellProps,
              },
            ]}
            headers={headers}
          />
        </td>
        {text != LookUp.EXPENSES && (
          <td style={{ border: "none" }}>
            <WordTable
              data={[...daysData, ...totalData]}
              headers={gridHeaders}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default ({
  timesheetStatus,
  startDate,
  endDate,
  orgPartner,
  partnerSource,
  employeeName,
  weekNo,
  timesheetDates,
  partnerLabel,
  sourceLabel,
}) => {
  const { activityTypes } = useActivityTypes();
  const {
    gridProps: { gridData },
  } = useTimesheetHook(rowId, activityTypes, timesheetDates);

  return (
    <>
      <WordReportHeader heading="Weekly Timesheet" />
      <table style={{ border: "none", width: "100%" }} cellSpacing={5}>
        <tr>
          <td style={{ border: "none" }} colSpan={2}>
            <WordLabel
              label="Name"
              value={employeeName}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        <tr>
          <td style={{ border: "none" }}>
            <WordLabel
              label={partnerLabel}
              value={orgPartner}
              backgroundColor={LabelColor.Blue}
            />
          </td>
          <td style={{ border: "none" }}>
            <WordLabel
              label="Status"
              value={timesheetStatus}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        <tr>
          <td style={{ border: "none" }}>
            <WordLabel
              label={sourceLabel}
              value={partnerSource}
              backgroundColor={LabelColor.Blue}
            />
          </td>
          <td style={{ border: "none" }}>
            <WordLabel
              label="Week Period"
              value={`${displayDate(startDate)}-${displayDate(endDate)}`}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        <tr>
          <td style={{ border: "none" }}></td>
          <td style={{ border: "none" }}>
            <WordLabel
              label="Week No"
              value={weekNo}
              backgroundColor={LabelColor.Blue}
            />
          </td>
        </tr>
        {activityTypes &&
          activityTypes.map((c) => (
            <ActivityType key={c.text} {...c} gridData={gridData} />
          ))}
      </table>
    </>
  );
};
